import React from 'react'
import { translate } from 'react-i18next'
const PostInCityListItem = ({t, item}) => (
	<div className="card border-0 text-center col-xl-2 col-md-4 col-12" >
		<a href={'/listings/details/' + item.seoPath+ "/" + item.id}>
		<img className="card-img-top" style={{height: '200px', borderRadius: '12px', objectFit: "cover"}} src={item.imageUrl?item.imageUrl:'/images/no_image.svg'} alt={item.title}/>
		</a>
		<div className="card-body">
			<a href={'/listings/details/' + item.seoPath+ "/" + item.id} className="card-text" style={{fontSize: '16px', fontWeight: 'bold', color: "#222b45"}}>
				{item.priceText}
			</a>
			<div className="d-flex flex-row justify-content-center">
				{ item.noBedRooms ? (
					<div className="card-text post-listings-item__body--attributes" style={{fontSize: '12px'}}>{item.noBedRooms + ' ' + t('room')}</div>): (<></>) }
				{ item.noBedRooms ? (<div className="card-text ml-2 mr-2 post-listings-item__body--attributes" style={{fontSize: '12px'}}>{'|'}</div>): (<></>) }
				<div className="card-text post-listings-item__body--attributes" style={{fontSize: '12px'}}>{item.areaText}
				</div>
			</div>
			<a href={'/listings/details/' + item.seoPath+ "/" + item.id} className="card-text">
				<address className="card-title" style={{fontSize: '22px', fontWeight: 'bold', color: "#222b45"}}>{item.address}</address>
			</a>
		</div>
	</div>
)
export default translate("translations")(PostInCityListItem)