import React, {Component} from 'react'
import { Form, Button } from 'react-bootstrap';
import Section from '../components/Section'
import { translate, Trans } from "react-i18next"
import NewsLetter from '../components/NewsLetter'
import NewPostListings from '../components/posts/NewPostListings'
import ProjectsInCity  from '../components/projects/ProjectsInCity'
import {NEWS_LIST, NEW_POST_IN_CITY_LIST,PROJECT_LIST} from '../configs/mocks'
import QuestionAnswerList from '../components/helps/QuestionAnswerList'
class FAQ extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [
				{
					id: 1,
					question: 'Đăng ký tài khoản sử dụng dịch vụ?',
					answer: 'Chúng tôi sử dịch vụ quản lý tài khoản người dùng được cung cấp bởi Zetgoo. Khi bạn đăng ký tài khoản Zetgoo, bạn có thể sử dụng toàn bộ hệ sinh thái ứng dụng của Zetgoo & TopLabs cung cấp.',
				},
				{
					id: 2,
					question: 'Đăng tin miễn phí như thế nào?',
					answer: 'Chúng tôi cung cấp dịch vụ đăng tin miễn phí trên web. Việc đăng tin này được áp dụng cho người dùng có tài khoản & không có tài khoản. Người dùng có tài khoản thành viên sẽ được sử dng hệ thống để theo dõi tình trạng tin đăng cũng như các số liệu thống kê liên quan.',
					open: false
				},
				{
					id: 3,
					question: 'Tôi có thể tải ứng dụng của TopReal ở đâu?',
					answer: 'Bạn có thể tải ứng dụng TopReal từ Google Play Store (đối với thiết bị chạy Android) hoặc App Store (đối với thiết bị chạy iOS) HOÀN TOÀN MIỄN PHÍ.',
				},
				{
					id: 4,
					question: 'Tại sao tôi không thể tải xuống Ứng dụng TopReal trên thiết bị của mình?',
					answer: 'Để cài đặt ứng dụng TopReal trên Android, thiết bị của bạn tối thiểu phải chạy hệ điều hành Android 4.0 Ice Cream Sandwich. Để cài đặt ứng dụng TopReal trên iOS, thiết bị của bạn tối thiểu phải chạy hệ điều hành iOS 7. Hãy bảo đảm rằng thiết bị của bạn kết nối với mạng 3G hoặc nguồn phát WiFi ổn định.',
				}				
			]
		}
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
                <main role="main">
                	<section className="section__breadcrumbs">
						<div className="container">
							<header role="title-page" className="text-center">
								<h2>{t('faq')}</h2>
								<h4>{t('faq_subheadline')}</h4>
							</header>
						</div>
				    </section>                    
    				<div className="section">
                    	<QuestionAnswerList items={this.state.items} />
                    </div>
					<NewPostListings cityName={"Hồ Chí Minh"} cityId={51} />
					<NewPostListings cityName={"Hà Nội"} cityId={2} />
                   	<ProjectsInCity cityName={"Hồ Chí Minh"} cityId={51}/>
                   	<ProjectsInCity cityName={"Hà Nội"} cityId={2}/>
                    <NewsLetter/>
                </main>
			</React.Fragment>
		)
	}
}
export default translate("translations")(FAQ);
