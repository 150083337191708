import React,{ Component } from 'react'
import { translate, Trans } from "react-i18next"
//import Parser from 'html-react-parser'

class PostThumbnail extends Component {
	constructor(props) {
		super(props)
		this.actionDelete = this.actionDelete.bind(this)
	}
	actionDelete(event){
		event.preventDefault()
		if (this.props.actionDelete) {
			this.props.actionDelete(this.props.idx)
		}
	}
	render() {
		let {item} = this.props
		return(
			<div className="post-thumbnail__item-wrapper mb-1 mr-1">
				<img src={item.thumbnailUrl} width="100px" height="80px"/>
				<div className="submit-property__thumbnail-item--overlay">
					{/*<button className="btn p-0 m-0"><i className="far fa-trash-alt fa-xs"></i></button>*/}
					<button className="btn btn-dark btn-sm rounded-circle" onClick={this.actionDelete}><i className="fas fa-trash fa-sm" style={{color:"#FFF"}}></i></button>

				</div>
			</div>
		)
	}
}
export default translate("translations")(PostThumbnail)