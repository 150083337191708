import React, {Component}  from 'react'
import { translate, Trans } from "react-i18next"
import feedbackServices from '../../services/feedbacks'
import { withAlert } from 'react-alert'
import compose from 'recompose/compose'
class KeepInTouchBox extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: '',
			isLoading: false,
			isError: false,
			errors: null,
			message: null
		}
		this.handleChangeEmail = this.handleChangeEmail.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleChangeEmail(event) {
		this.setState({email: event.target.value})
	}
	resetForm () {
		this.setState({
			email: ''
		})
	}
	handleSubmit(event) {
		// Call Submit API
		//
		if (!this.state.email) {
			this.setState({isError: true, errors: null, message: "Have to value"})
			return;
		}
		this.setState({ isLoading: true, isError: false, errors: null})
		feedbackServices.sendSubscribe(this.state.email)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ message: response.data, isLoading: false, isError: false })
				this.props.alert.show(this.props.t('msg_subcribe_success'))
				this.resetForm()

			}else{
				this.setState({ errors: response.errors, isLoading: false, isError: true })
				this.props.alert.show(this.props.t('msg_subcribe_failure'))

			}
		})
		.catch(error => this.setState({ message: null, errors: error, isLoading: false, isError: true }))
		event.preventDefault();
	}
	render () {
		const {t,item} = this.props
		return ( 
			<React.Fragment>
				<div className="container pt-4 pb-2" style={{borderRadius: "12px", backgroundColor: "#febd17"}}>
					<div className="news_aside__title">{t('keep_in_touch_topreal_news')}</div>
					
						<form onSubmit={this.handleSubmit} className="mt-2">
							<div className="form-group">
								<input value={this.state.email} onChange={this.handleChangeEmail} className="form-control search-from__input-text" type="email" placeholder={t('your_email_here')} required="required" data-error="Valid email is required."></input>
							</div>
							<div className="form-group">
								<button id="newsletter_submit" type="submit" className="btn btn-danger btn-block">{t('subscribe')}</button>
							</div>
							<div>
								{
									this.state.isError && <label>{this.state.message}</label>
								}
							</div>
						</form>
				</div>
			</React.Fragment>
		)
	}
}
export default compose(translate("translations"),withAlert())(KeepInTouchBox)