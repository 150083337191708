import React, {Component}from 'react'
import { translate } from 'react-i18next'
import cityServices from '../../services/cities'
import CityStatsItem from './CityStatsItem'
import NoItemListings from '../../components/NoItemListings'
import { css } from "@emotion/core"
import Loading from "react-spinners/ClockLoader"
class CityStats extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
		}
	}
	componentDidMount() {
		this.setState({ isLoading: true, isError: false, items:[]})
		cityServices.fectchAllCitiesStatsPrices()
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data, isLoading: false, isError: false })
			}else{
				this.setState({ items: [], isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({ items: [], errors: error, isLoading: false, isError: true }))
	}
	render () {
		const {t} = this.props
		return (
			<div>
				<ul className="manage-list manage-list--bookmark">
					<li className="manage-list__header">
						<span className="manage-list__title"><i className="fas fa-tachometer-alt"></i>&nbsp;&nbsp;{t('price_records_ref')}</span>
						<div className="manage-list__action">
							<a><i className="fas fa-arrow-up" style={{color: "green"}}></i><i className="fas fa-arrow-down" style={{color: "red"}}></i>&nbsp;&nbsp;{t('fluctuation_percentage')}</a>
						</div>
					</li>

					{
						this.state.items.map (item => <CityStatsItem item={item} />)
					}
				</ul>
				<div className="row">
					<Loading css={{display: 'block', margin: '0 auto', borderColor: 'red'} } size={70} color={"#e9eaec"} loading={this.state.isLoading} />
				</div>
			</div>
			)	
	}
}
export default translate("translations")(CityStats)