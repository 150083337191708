import React, { Component } from 'react'
import { translate, Trans } from "react-i18next";
import {DEFAUL_NO_IMAGE} from '../../configs/default-params'
import { Link } from "react-router-dom";

class FavouritePostListItem extends Component {
	constructor(props) {
		super(props)
		this.handleRemove = this.handleRemove.bind(this)
	}
	handleRemove(event){
		event.preventDefault()
		if (this.props.actionRemove) {
			this.props.actionRemove(this.props.item.id)
		}
	}
	render (){
		const {t, item} = this.props
		let detailsUrl = '/listings/details/' + item.seoPath+ "/" + item.id
		let query = null
		if (this.props.ids) {
			query = "ids="+this.props.ids
		}
		if (this.props.src) {
			if (query == null) {
				query="src="+this.props.src
			} else {
				query+="&src="+this.props.src
			}
		}
		if (query) {
			detailsUrl += "?"+query
		}
		return (
			<div className="card border-0 mb-2">
				<div className="row">
					<div className="col-md-6 col-12">
						<Link to={detailsUrl}>
							<img className="card-img-top d-flex flex-wrap post-listings-item__image" src={item.imageUrl?item.imageUrl:DEFAUL_NO_IMAGE} alt={item.title}/>
						</Link>
					</div>
					<div className="col-md-6 col-12" >
						<div className="card-body border-bottom post-listings-item__body">
							<div className="row">
								{item.noBedRooms &&<div className="mr-2 card-text d-flex" style={{fontSize: "14px", fontWeight:"bold"}}>
									<img className="mr-1" src="/images/bed.svg"/>{item.noBedRooms + ' ' +t('room')}
								</div>
								}
								{item.noBathRooms && <div className="mr-2 card-text d-flex" style={{fontSize: "14px", fontWeight:"bold"}}>
									<img className="mr-1" src="/images/shower.svg"/>{item.noBathRooms + ' ' +t('room')}
								</div>
								}
								{item.areaText && <div className="card-text d-flex" style={{fontSize: "14px", fontWeight:"bold"}}>
									<img className="mr-1" src="/images/sq.svg"/>{item.areaText}
								</div>}
							</div>
							<div className="address">
								<address className="text-truncate" style={{fontSize: "14px", color: "#222b45"}}>
									{item.address}
								</address>
							</div>
							<p className="title text-truncate" style={{fontSize: "14px", color: "#222b45"}}>{item.title}</p>
							<time></time>
							<Link to={detailsUrl}>
								<div className="post-listings-item__body--price">
									{item.priceText}
								</div>
							</Link>
							<div className="row">
								<div className="col">
									<a href="#" className="btn btn-outline-primary float-right" onClick={this.handleRemove}><i className="fas fa-times"></i>&nbsp;&nbsp;{t('remove')}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default translate("translations")(FavouritePostListItem)