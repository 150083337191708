import {MAX_ITEM_SIZE, KEY_FAVOURITE_POSTS, KEY_MY_POSTS,KEY_USER_ID,MAX_DRAFT_ITEM_SIZE,KEY_DARFT_POSTS} from '../../configs/constants'

export default {
	addNewPost(id) { // Call when user publish 
		let uid = localStorage.getItem(KEY_USER_ID)
		let items = []
		let key = KEY_MY_POSTS
		if (uid) {
			key = KEY_MY_POSTS+'-'+uid
		}
		items = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
		
		if (items.length == 0) {
			///items.push({id: id, createdAt: new Date()})
			items.unshift({id: id, createdAt: new Date()}) ///Add head
			
		} else {
			let idx = items.findIndex(x => x.id === id)
			if (idx !== -1) {
				//Make sure order by date
				let removeItems = items.splice(idx,1)
				items.unshift({id: id, createdAt: new Date()}) 
			} else {
				if (items.length === MAX_ITEM_SIZE) {
					items.shift()
					items.unshift({id: id, createdAt: new Date()})
				} else {
					items.unshift({id: id, createdAt: new Date()})
				}
			}
		}
		localStorage.setItem(key, JSON.stringify(items))
		return true
	},
	saveDraftPost(item) {
		let uid = localStorage.getItem(KEY_USER_ID)
		let items = []
		let key = KEY_DARFT_POSTS
		if (uid) {
			key = KEY_DARFT_POSTS+'-'+uid
		}
		items = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
		
		if (items.length == 0) {
			items.unshift(item)
		} else {
			let idx = items.findIndex(x => x.id === item.id)
			if (idx !== -1) {
				//Make sure order by date
				let removeItems = items.splice(idx,1)
				items.unshift(item)
			} else {
				if (items.length === MAX_DRAFT_ITEM_SIZE) {
					items.shift()
					items.unshift(item)
				} else {
					items.unshift(item)
				}
			}
		}
		localStorage.setItem(key, JSON.stringify(items))
		return true
	},
	removePost(id) {
		let uid = localStorage.getItem(KEY_USER_ID)
		let items = []
		let key = KEY_MY_POSTS
		if (uid) {
			key = KEY_MY_POSTS+'-'+uid
		}
		items = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
		if (items.length === 0)
			return false
		let idx = items.findIndex(x => x.id === id)
		let removeItems = items.splice(idx,1)
		localStorage.setItem(key, JSON.stringify(items))
		if (removeItems.length > 0) {
			return true
		}
		return false
	},
	addFavouritePost(id) { // Call when user publish 
		let uid = localStorage.getItem(KEY_USER_ID)
		let items = []
		let key = KEY_FAVOURITE_POSTS
		if (uid) {
			key = KEY_FAVOURITE_POSTS+'-'+uid
		}
		items = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
		
		if (items.length == 0) {
			items.unshift({id: id, createdAt: new Date()})
		} else {
			let idx = items.findIndex(x => x.id === id)
			if (idx !== -1) {
				//Make sure order by date
				let removeItems = items.splice(idx,1)
				items.unshift({id: id, createdAt: new Date()}) 
			} else {
				if (items.length === MAX_ITEM_SIZE) {
					items.shift()
					items.unshift({id: id, createdAt: new Date()})
				} else {
					items.unshift({id: id, createdAt: new Date()})
				}
			}
		}
		localStorage.setItem(key, JSON.stringify(items))
		return true
	},
	checkFavourite(id) {
		let uid = localStorage.getItem(KEY_USER_ID)
		let items = []
		let key = KEY_FAVOURITE_POSTS
		if (uid) {
			key = KEY_FAVOURITE_POSTS+'-'+uid
		}
		items = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
		if (items.length === 0)
			return false
		let idx = items.findIndex(x => x.id === id)
		if (idx != -1) {
			return true
		}
		return false
	},
	removeFavouritePost(id) {
		let uid = localStorage.getItem(KEY_USER_ID)
		let items = []
		let key = KEY_FAVOURITE_POSTS
		if (uid) {
			key = KEY_FAVOURITE_POSTS+'-'+uid
		}
		items = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
		if (items.length === 0)
			return false
		let idx = items.findIndex(x => x.id === id)
		let removeItems = items.splice(idx,1)
		localStorage.setItem(key, JSON.stringify(items))
		if (removeItems.length > 0) {
			return true
		}
		return false
	},
	getFavouritePosts() {
		let uid = localStorage.getItem(KEY_USER_ID)
		let key = KEY_FAVOURITE_POSTS
		if (uid) {
			key = KEY_FAVOURITE_POSTS+'-'+uid
		}
		return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
	},
	getAllPostsByMe() {
		let uid = localStorage.getItem(KEY_USER_ID)
		let key = KEY_MY_POSTS
		if (uid) {
			key = KEY_MY_POSTS+'-'+uid
		}
		return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
	}
}