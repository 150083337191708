import {ROOT_URL, POST_V1, POST_REPORT_V1} from '../configs/configs'
import format from 'string-format'
import postLocalServices from './local/posts'
import authServices from './auth'

export default {
	fetchFeaturedPosts (limit) 
	{
		let query = ROOT_URL + POST_V1.FEATURED_POSTS
		if (limit) {
			query += "?limit="+limit
		}
		return fetch(query, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	searchPosts (criteria) {
		var query = ''
		if(criteria.catId && criteria.catId != -1)
		{
			query += 'catId=' + criteria.catId
		}
		if (criteria.method !== -1 && criteria.method) {
			query += '&method=' + criteria.method
		}
		if(criteria.wardId && criteria.wardId != -1)
		{
			query += '&wardId=' + criteria.wardId
		}else{
			if(criteria.districtId && criteria.districtId != -1)
			{
				query += '&districtId=' + criteria.districtId
			}else{
				if(criteria.cityId && criteria.cityId != -1)
				{
					query += '&cityId=' + criteria.cityId
				}
			}
		}
		if(criteria.noBeds && criteria.noBeds!= -1)
		{
			query += '&noBeds=' + criteria.noBeds
		}
		if(criteria.noBaths && criteria.noBaths!= -1)
		{
			query += '&noBaths=' + criteria.noBaths
		}
		if(criteria.pMin && criteria.pMin != -1)
		{
			query += '&pMin=' + criteria.pMin
		}
		if(criteria.pMax && criteria.pMax != -1)
		{
			query += '&pMax=' + criteria.pMax
		}
		if(criteria.aMin && criteria.aMin != -1)
		{
			query += '&aMin=' + criteria.qami
		}
		if(criteria.aMax && criteria.aMax != -1)
		{
			query += '&aMax=' + criteria.aMax
		}
		if(criteria.page && criteria.page != -1)
		{
			query += '&page=' + criteria.page
		}
		if(criteria.size && criteria.size != -1)
		{
			query += '&size=' + criteria.size
		}
		if(criteria.q) {
			query += '&q=' + criteria.q
		}
		if (criteria.sort) {
			query += '&sort=' + criteria.sort
		}
		if (criteria.age) {
			query += '&age=' + criteria.age
		}
		return fetch(ROOT_URL + POST_V1.SEARCH_POST + '?' + query, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getById (id) {
		return fetch(ROOT_URL + format(POST_V1.GET_DETAILS_BY_ID,id), {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	publishFree(reqData) {
		return fetch(ROOT_URL + POST_V1.PUBLISH_FREE, {
    		method: 'POST',
    		headers: {
               "Content-Type": "application/json; charset=utf-8",
               "X-Zetgoo-ClientId": localStorage.getItem('X-Zetgoo-ClientId'),
               "X-Authorization": authServices.headerAuthToken()
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			},
    		body: JSON.stringify(reqData),      
  		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	uploadFeaturedImage(file) {
		const data = new FormData();
		data.append('file', file)
  		return fetch(ROOT_URL + POST_V1.UPLOAD_FEATURED_IMAGE, {
    		method: 'POST',
    		headers: {
    			'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				"X-Authorization": authServices.headerAuthToken()
              // "Content-Type": "multipart/form-data",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			},
    		body: data,      
  		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	uploadImage(file) {
		const data = new FormData();
		data.append('file', file)
		return fetch(ROOT_URL + POST_V1.UPLOAD_IMAGE, {
			method: 'POST',
			headers: {
				'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				"X-Authorization": authServices.headerAuthToken()
			// "Content-Type": "multipart/form-data",
			// "Content-Type": "application/x-www-form-urlencoded",
			// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			},
			body: data,      
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	///Upload miltiple 
	uploadImages(files) {
		const data = new FormData();
		for (const file of files) {
    		data.append('files', file, file.name);
    	}
  		return fetch(ROOT_URL + POST_V1.UPLOAD_IMAGES, {
    		method: 'POST',
    		headers: {
				'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				"X-Authorization": authServices.headerAuthToken()
              // "Content-Type": "multipart/form-data",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			},
    		body: data,      
  		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	createReport(reqData) {
		return fetch(ROOT_URL + POST_REPORT_V1.CREATE, {
    		method: 'POST',
    		headers: {
               "Content-Type": "application/json; charset=utf-8"
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			},
    		body: JSON.stringify(reqData),      
  		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getPostsListItemsByPhone(phone, page, size) {
		let query = ""
		if (page) {
			query += "page="+page
		} else {
			query += "page=1"
		}
		if (size) {
			query += "&size="+size
		} 
		let urlQuery =  ROOT_URL + format(POST_V1.GET_POSTS_LIST_ITEMS_BY_PHONE,phone)
		if (query) {
			urlQuery += "?"+ query
		}
		return fetch(urlQuery, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getPostsListItemsByContact(fingerprint, page, size) {
		let query = ""
		if (page) {
			query += "page="+page
		} else {
			query += "page=1"
		}
		if (size) {
			query += "&size="+size
		} 
		let urlQuery =  ROOT_URL + format(POST_V1.GET_POSTS_LIST_ITEMS_BY_CONTACT,fingerprint)
		if (query) {
			urlQuery += "?"+ query
		}
		return fetch(urlQuery, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getPostsListItemsAndCountByContact(fingerprint, page, size) {
		let query = ""
		if (page) {
			query += "page="+page
		} else {
			query += "page=1"
		}
		if (size) {
			query += "&size="+size
		} 
		let urlQuery =  ROOT_URL + format(POST_V1.GET_POSTS_COUNT_LIST_ITEMS_BY_CONTACT,fingerprint)
		if (query) {
			urlQuery += "?"+ query
		}
		return fetch(urlQuery, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	addToFavourites(id) {
		//Store local max 100
		return postLocalServices.addFavouritePost(Number(id))
	},
	fetchListItemsByIds(ids) {
		
		return fetch(ROOT_URL + POST_V1.GET_POSTS_LIST_ITEMS_BY_IDS+"?ids=" + ids.toString(), {
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				// "Content-Type": "application/x-www-form-urlencoded",
				// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchAllPostPackages() {
		
		return fetch(ROOT_URL + POST_V1.GET_ALL_POST_PACKAGES, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				// "Content-Type": "application/x-www-form-urlencoded",
				// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getPostByIdForEditing(id, token) {
		let queryURL = ROOT_URL + format(POST_V1.GET_EDITING_POST,id) + "?token=" + token
		return fetch(queryURL, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				// "Content-Type": "application/x-www-form-urlencoded",
				"X-Authorization": authServices.headerAuthToken()
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	update(id,token,reqData) {
		let queryURL = ROOT_URL + format(POST_V1.GET_EDITING_POST,id) + "?token=" + token
		return fetch(queryURL, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				// "Content-Type": "application/x-www-form-urlencoded",
				"X-Authorization": authServices.headerAuthToken()
			},
			body: JSON.stringify(reqData)
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	///fetch Post By UserID or Cache PostID in local store
	deleteFeaturedImage(photoId) {
		let queryURL = ROOT_URL + format(POST_V1.DELETE_FEATURED_IMAGE,photoId)
		return fetch(queryURL, {
			method: 'DELETE',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				// "Content-Type": "application/x-www-form-urlencoded",
				"X-Authorization": authServices.headerAuthToken()
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	deleteImage(photoId) {
		let queryURL = ROOT_URL + format(POST_V1.DELETE_IMAGE,photoId)
		return fetch(queryURL, {
			method: 'DELETE',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				// "Content-Type": "application/x-www-form-urlencoded",
				"X-Authorization": authServices.headerAuthToken()
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	}
}