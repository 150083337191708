import {ROOT_URL, COMPANY_CATEGORY_V1, COMPANY_V1} from '../configs/configs'
import format from 'string-format'

export default {
	fetchAllCompanyCategories() {
		return fetch(ROOT_URL + COMPANY_CATEGORY_V1.FETCH_ALL_CATEGORIES, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getCategoryById (id) {
		return fetch(ROOT_URL + format(COMPANY_CATEGORY_V1.GET_DETAILS_BY_ID,id),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getById (id) {
		return fetch(ROOT_URL + format(COMPANY_V1.GET_DETAILS_BY_ID,id),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	searchCompanies (criteria) 
	{
		let query = ''
		let and = ''
		if(criteria.page && criteria.page != -1)
		{
			query += 'page=' + criteria.page
		} else {
			query += 'page=1'
		}
		if(criteria.catId && criteria.catId != -1)
		{
			query += '&catId=' + criteria.catId
		}
		if(criteria.wardId && criteria.wardId != -1)
		{
			query += '&wardId=' + criteria.wardId
		}else{
			if(criteria.districtId && criteria.districtId != -1)
			{
				query += '&districtId=' + criteria.districtId
			}else{
				if(criteria.cityId && criteria.cityId != -1)
				{
					query += '&cityId=' + criteria.cityId
				}
			}
		}
		return fetch(ROOT_URL + COMPANY_V1.SEARCH_COMPANIES + '?' + query, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	}
}