import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import TermsBreadcrumbsSection from '../components/TermsBreadcrumbsSection'

class Terms extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"TopReal | Điều khoản sử dụng dịch vụ của TopReal"} </title>
					<meta property="og:title" content="Điều khoản sử dụng dịch vụ của TopReal" />
					<meta property="og:description" content="Điều khoản sử dụng dịch vụ của TopReal"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Điều khoản sử dụng dịch vụ của TopReal"/>
					<meta name="twitter:description" content="Điều khoản sử dụng dịch vụ của TopReal"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<TermsBreadcrumbsSection />
				<div className="section">
					<div className="container">
						<div className="row">
							<div className="col-12 section_title">
								<h4 style={{textAlign: "justify", textTransform: "uppercase", marginBottom: "16px"}} >
									Xin vui lòng đọc kỹ điều khoản sử dụng. Khi sử dụng website hoặc ứng dụng, bạn đồng ý chịu ràng buộc của các điều khoản dưới đây. Nếu bạn không đồng ý với các điều khoản này, vui lòng thoát khỏi website ngay lập tức.
								</h4>
								<br/>
								<div>
									<p style={{textAlign: "justify"}} >
									Xin lưu ý rằng chúng tôi có thể thay đổi, sửa đổi, thêm và xóa các Điều khoản này bất cứ lúc nào mà không cần thông báo trước. Các điều khoản này phải được đọc định kỳ. Bằng cách tiếp tục sử dụng website này sau những thay đổi như vậy đối với các Điều khoản này, khách truy cập, người dùng hoặc Người dùng đã đăng ký đồng ý và đồng ý với các thay đổi. Nếu bạn sử dụng bất kỳ dịch vụ nào khác của chúng tôi, thì việc sử dụng của bạn dựa trên việc đệ trình và chấp nhận các điều khoản và điều kiện áp dụng cho các dịch vụ đó.
									</p>
								</div>
								<br/>
								<div>
									<h5>1. Phạm vi dịch vụ</h5>
									<p style={{textAlign: "justify"}} >
										- Thông qua website này, TopReal cung cấp nền tản trực tuyến để bạn có thể tìm kiếm các thông tin về thị trường bất động sản tại Việt Nam. Cho phép đăng tin quảng cáo mua bán, cho thuê bất động sản và quản lý dịch vụ kinh doanh bất động sản. 
									</p>
									<p style={{textAlign: "justify"}} >
										- Sự biến động của thị trường hoặc điều kiện đặc biệt có thể dẫn đến thông tin được cung cấp không chính xác hoặc không thoả đáng trong khoản thời gian nhất định. Trong trường hợp có bất kỳ vấn đề gì, vui lòng liên hệ với bộ phận chăm sóc khách hàng của chúng tôi để được hỗ trợ thoả đáng.
									</p>
									<p style={{textAlign: "justify"}} >
										- Mọi khoản thanh toán các dịch vụ sẽ được thực hiện trực tiếp vào tài khoản ngân hàng của TopReal hoặc các kênh thanh toán của TopReal. Chúng tôi không chịu trách nhiệm về tính hợp lệ của các dịch vụ có phí, nếu khoản thanh toán không được thực hiện trực tiếp vào tài khoản ngân hàng TopReal hoặc các kênh thanh toán của TopReal.
									</p>
									<p style={{textAlign: "justify"}}>
									- Website và dịch vụ không dành cho trẻ em dưới 13 tuổi ở Hoa Kỳ, 16 tuổi ở EEA và dưới độ tuổi chấp thuận thu thập thông tin cá nhân ở bất kỳ khu vực pháp lý nào khác và bạn không được sử dụng website hoặc dịch vụ hoặc đăng ký các tính năng bổ sung, nếu bạn ở độ tuổi bắt buộc. Bạn tuyên bố và bảo đảm rằng bạn có độ tuổi cần thiết để sử dụng website và dịch vụ.
									</p>
									<p>
									- Chúng tôi có quyền bất cứ lúc nào và đôi khi sửa đổi, đình chỉ, ngừng hoặc chấm dứt dịch vụ (hoặc bất kỳ phần nào trong đó) có hoặc không có thông báo. Bạn đồng ý rằng chúng tôi sẽ không chịu trách nhiệm với bạn hoặc bất kỳ bên thứ ba nào về bất kỳ sửa đổi, đình chỉ, ngừng hoặc chấm dứt dịch vụ.
									</p>
								</div>
								<br/>
								<div>
									<h5>2. Đăng ký tài khoản</h5>
									<p style={{textAlign: "justify"}} >
									Website cho phép bạn tạo tài khoản người dùng dựa trên dữ liệu bạn cung cấp. Bằng cách cung cấp dữ liệu, đăng ký và tạo tài khoản của bạn, bạn đảm bảo rằng: i) thông tin bạn cung cấp là đầy đủ và chính xác; ii) bạn sẽ cập nhật thông tin để đảm bảo thông tin đăng ký là đầy đủ và chính xác.
									</p>
								</div>
								<br/>
								<div>
									<h5>3. Chương trình thành viên</h5>
									<p style={{textAlign: "justify"}} >
										- Khi đăng ký tài khoản tại TopReal, bạn mặc định trở thành viên của chương trình khách hàng thân thiết của TopLabs (TopReal, TopAuto) và Zetgoo.
									</p>
									<p style={{textAlign: "justify"}} >
										- Khi là thành viên của TopReal bạn có thể truy cập vào xem điểm, khuyến mãi, giảm giá và các lợi ích khác được cung cấp bởi TopReal và/hoặc của các đối tác thứ ba.
									</p>
								</div>
								<br/>
								<div>
									<h5>4. Đăng tin quảng cáo và quản lý tin</h5>
									<p style={{textAlign: "justify"}} >
									- Đăng tin quảng cáo: chúng tôi cung cấp dịch vụ đăng tin có phí và miễn phí trên hệ thống: i) tin miễn phí đăng trực tiếp trên website bằng cách cung cấp chính xác thông tin theo yêu cầu của hệ thống; ii) bạn phải có tài khoản trên hệ thống để được đăng tin có phí. Trong trường hợp không đăng ký được tài khoản hoặc đăng nhập không thành công, xin vui lòng gửi phản hồi hoặc liên hệ bằng Hotline để được tư vấn, hỗ trợ.
									</p>
									<p style={{textAlign: "justify"}} >
									- Duyệt tin: tin bài đăng của khách hàng sau khi được đệ trình chờ duyệt sẽ được các biên tập viên tại TopReal hỗ trợ biên tập lại trong trường hợp cần thiết, tin đăng được kiểm duyệt trong vòng 8 tiếng và được xác nhận xuất bản thành công trong trường hợp tin đăng của khách hàng không vi phạm các quy chuẩn tin đăng của TopReal hay Quy định sử dụng website hoặc ứng dụng.
									</p>
									<p style={{textAlign: "justify"}} >
									- Quản lý tin: Tất cả bài đăng của khách hành trên website sẽ được hiển thị ngay trên giao diện của website và được lưu lại trên cơ sở dữ liệu của TopReal. Trong trường hợp tin đăng hết hạn sử dụng, chúng tôi sẽ gửi thông báo bằng Email hoặc trạng thái hiện thị tới khách hàng, tin đăng sẽ được tiếp tục gia hạn phụ thuộc vào nhu cầu của khách hàng.
									</p>
									<p style={{textAlign: "justify"}} >
									- Lời khuyên khi đăng tin: tin đăng sẽ được đánh giá theo mức độ tin cây dựa vào thông tin bạn cung cấp trước khi trả kết quả cho người dùng. Vì vậy bạn cần cung cấp thông tin đầy đủ và chính xác, hình ảnh rõ nét, mô tả đúng các tính chất của tài sản liên quan. 
									</p>
								</div>
								<br/>
								<div>
									<h5>5. Giá và khuyến mãi </h5>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi cung cấp giá và khuyến mãi thay đổi theo thời gian. Xin lưu ý rằng những điều này có thể liên quan đến các điều kiện và yêu cầu khác nhau vì nó liên quan đến chính sách đăng tin khác nhau.
									</p>
									<p style={{textAlign: "justify"}} >
									- Nếu có bất kỳ khuyến mãi nào được cung cấp trực tiếp bởi đối tác thứ ba, thì các quyền và yêu cầu đối với chương trình khuyến mãi sẽ hoàn toàn thuộc về nhà cung cấp thứ 3 và có thể không được áp dụng tại TopReal.
									</p>
								</div>
								<br/>
								<div>
									<h5>6. Liên kết với website khác</h5>
									<p style={{textAlign: "justify"}} >
										Website của tôi có thể chứa hoặc sử dụng liên kết với các website khác. Tuy nhiên, khi bạn đã sử dụng các liên kết này để rời khỏi website của chúng tôi, bạn nên lưu ý rằng chúng tôi không có bất kỳ quyền kiểm soát nào đối với website khác đó. Xin lưu ý rằng chúng tôi không chịu trách nhiệm về chính sách quyền riêng tư hoặc điều khoản của các website khác đó và bạn nên đọc kỹ chính sách quyền riêng tư của mỗi website bạn truy cập.
									</p>
								</div>
								<br/>
								<div>
									<h5>7. Sở hữu trí tuệ</h5>
									<p style={{textAlign: "justify"}} >
									- Tất cả các tài sản trí tuệ trên website nay thuộc sở hữu của TopReal. Các tài liệu và thông tin, bao gồm nhưng không giới hạn: phần mềm, văn bản, dữ liệu, đồ họa, hình ảnh, âm thanh, video, nhãn hiệu thương mại, logo, biểu tượng, mã html và các mã khác trên website này đều bị cấm xuất bản, sửa đổi, sao chép hoặc thay đổi trong bất kỳ cách nào bên ngoài khu vực của website này mà không có sự cho phép rõ ràng bằng văn bản của TopReal. Nếu bạn vi phạm các quyền này, TopReal có quyền đưa ra yêu cầu bồi thường dân sự đối với toàn bộ số tiền thiệt hại hoặc tổn thất phải chịu. Những vi phạm này cũng có thể cấu thành tội hình sự.
									</p>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi xây dựng website, thiết lập mã nguồn và thực hiện hỗ trợ phần mềm, có thể cấp phép sử dụng phần mềm nguồn mở từ bên thứ ba thông qua Giấy phép Công cộng GNU (Giấy phép GPL). Bất kỳ việc sử dụng nào của TopReal từ phần mềm nguồn mở và tài sản trí tuệ quyền của bên thứ ba đều cần các giấy phép hoặc sự cho phép cần thiết.
									</p>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi cho phép các đối tác sử dung nội dung từ website không cần sự cho phép nếu cung cấp nguồn trích dẫn liên kết với nội dung gốc từ website.
									</p>
								</div>
								<br/>
								<div>
									<h5>8. Khiếu nại quyền sở hữu trí tuệ</h5>
									<p style={{textAlign: "justify"}} >
									- Nếu có bằng chứng vi phạm quyền sở hữu trí tuệ của bạn, bạn hoặc đại diện của bạn (gọi chung là "Người gửi") có thể gửi thông báo có chứa các chi tiết sau được liệt kê bên dưới: i) tên, địa chỉ, số điện thoại và email người gửi; ii) thông tin chi tiết để chứng minh sự tồn tại của vi phạm quyền sở hữu trí tuệ, bao gồm địa chỉ trực tuyến của bản sao điện tử; iii) Một tuyên bố rằng Người gửi (a) là chủ sở hữu hoặc chủ sở hữu độc quyền của quyền sở hữu trí tuệ đang bị vi phạm; hoặc (b) có thẩm quyền pháp lý để thay mặt chủ sở hữu hoặc chủ sở hữu độc quyền; iv) yêu cầu xóa hoặc vô hiệu quyền truy cập vào bản sao hoặc phiên bản điện tử vi phạm;
									</p>
									<p style={{textAlign: "justify"}}>
									- Bức thư phải được người gửi ký tên và gửi thực tế và qua email đến địa chỉ sau: 861/72/11/2a Đường Trần Xuân Soạn, Quận 7, Tp Hồ Chí Minh
									</p>
									<p style={{textAlign: "justify"}}>
									- Tất cả các khiếu nại, tranh chấp, tranh cãi hoặc khác biệt phát sinh do vi phạm quyền sở hữu trí tuệ trước tiên phải được gửi đến chúng tôi để thực hiện gỡ bỏ hoặc thực hiện qui trình hoà giải theo pháp luật.
									</p>
									<p style={{textAlign: "justify"}}>
									- Chúng tôi sẽ xem xét và xử lý các thông báo theo các yêu cầu trên và theo luật pháp Việt Nam.
									</p>
								</div>
								<br/>
								<div>
									<h5>9. Pháp luật</h5>
									<p style={{textAlign: "justify"}} >
										Điều khoản sử dụng này được điều chỉnh và giải thích theo luật Việt Nam.
									</p>
								</div>
								<br/>
								<div>
									<h5>10. Điều khoản khác</h5>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi rất hoan nghênh quí khách hàng và người dùng cung cấp nhưng phản hồi về website và dịch vụ mà chúng tôi cung cấp. Mọi phản hồi đều được cố gắng trả lời trong vòng 48 tiếng. Chúng tôi luôn có chính sách thoả đáng cho các phản hồi giúp cho website và dịch vụ của chúng tôi cung cấp cho khách hàng ngày càng tốt hơn.
									</p>
									<p style={{textAlign: "justify"}} >
									- Các vần đề phát sinh ngoài ý muốn trong quá trình sử dụng website và dịch vụ của chúng tôi hoặc các khiếu nại về nội dung vui lòng liên hệ với chúng tôi để được xem xét và gỡ bỏ theo đúng qui định của website và pháp luật.
									</p>
								</div>
								<br/>
								<div>
									<h5>11. Giới hạn trách nhiệm</h5>
									<p style={{textAlign: "justify"}} >
									- Website và dịch vụ chỉ cung cấp cho mục đích thông tin. Không có bất kỳ nội dung nào trên website hoặc dịch vụ được định hướng tới các tổ chức tư vấn chuyên nghiệp, tài chính, pháp lý. Chúng tôi sẽ không có trách nhiệm hoặc trách nhiệm pháp lý cho bất cứ kết quả nào liên quan trực tiếp hoặc gián tiếp từ các hành động nào bạn thực hiện hoặc thất bại dựa trên thông tin, dịch vụ hoặc tài nguyên khác trên website. Bạn sử dụng thông tin và dịch vụ của chúng tôi để hỗ trợ là chính, các rủi ro chỉ của riêng bạn. Chúng tôi từ chối tất cả các loại đảm bảo, rõ ràng hoặc ngầm hiểu, bao gồm nhưng không giới hạn chất lượng chủ đề, đối tác, sự phù hợp thông tin cho bất kỳ mục đích cụ thể nào và không xâm phạm.
									</p>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi không đảm bảo rằng website và dịch vụ cung cấp đáp ứng yêu cầu của bạn, hoặc sẽ không bi gián đoạn hoặc không lỗi hoặc kết quả tìm kiếm hoặc nội dung từ website hoặc dịch vụ là chính xác, tức thời, hữu ích hoặc chính xác tuyệt đối.
									</p>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi không đảm bảo bất kỳ tài liệu nào trên website và dịch vụ sẽ có tất cả các thông tin về quyền sở hữu hoặc theo đúng các qui dịnh của pháp luật. Nếu có bất kỳ nội dung nào vi phạm các quyền về sở hữu và qui định pháp luật, vui lòng liên hệ với chúng tôi để được gỡ bỏ trong vòng 36 giờ.
									</p>
								</div>
								<br/>
								<div>
									<h5>12. Thông tin liên lạc</h5>
									<p style={{textAlign: "justify"}} >
										Nếu bạn có bất cứ câu hỏi hoặc phản hồi nào về điều khoản này hoặc bất kỳ vấn đề gì liên quan, vui lòng liên hệ với chúng tôi <a href="http://topreal.vn/contact">tại đây</a> hoặc gửi email: <a href="mailto:contact.topreal@gmail.com?Subject=Hello" target="_top">contact.topreal@gmail.com</a>.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
export default Terms;