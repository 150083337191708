import * as types from './action-types'
import companies from '../services/companies'
import coreDataServices from '../services/core-data'

export function fetchAllCompanyCategories()
{
	return function (dispatch) {
		dispatch(fetchAllCompanyCategoriesRequest())
		companies.fetchAllCompanyCategories()
		.then(response => {
			if (response.status === 'OK')
			{
				dispatch(fetchAllCompanyCategoriesSuccess(response.data))
			}else{
				dispatch(fetchAllCompanyCategoriesError(response.errors))
			}
		})
		.catch(error => dispatch(fetchAllCompanyCategoriesError(error)))
	}
}
export function fetchAllCompanyCategoriesRequest()
{
	return {type: types.FETCH_ALL_COMPANY_CATEGORIES_REQUEST,payload: []}
}
export function fetchAllCompanyCategoriesSuccess(resData)
{
	return {type: types.FETCH_ALL_COMPANY_CATEGORIES_SUCCESS,payload: resData}
}
export function fetchAllCompanyCategoriesError(errors)
{
	return {type: types.FETCH_ALL_COMPANY_CATEGORIES_ERROR,payload: errors}
}
export function searchCompanies(criteria) {
	return function (dispatch) {
		dispatch(searchCompaniesRequest())
		companies.searchCompanies(criteria)
		.then(response => {
			if (response.status === 'OK')
			{
				dispatch(searchCompaniesSucccess(response.data))
			}else{
				if (response.status === 'ZERO_RESULTS') {
					dispatch(searchCompaniesZeroResults())
				} else {
					dispatch(searchCompaniesError(response.errors))	
				}
				
			}
		})
		.catch(error => dispatch(searchCompaniesError(error)))
	}
}
export function searchCompaniesRequest()
{
	return {type: types.SEARCH_COMPANIES_REQUEST,payload: null}
}
export function searchCompaniesSucccess(resData)
{
	return {type: types.SEARCH_COMPANIES_SUCCESS,payload: resData}
}
export function searchCompaniesZeroResults()
{
	return {type: types.SEARCH_COMPANIES_ZERO_RESULTS,payload: null}
}
export function searchCompaniesError(errors)
{
	return {type: types.SEARCH_COMPANIES_ERROR,payload: errors}
}

export function changeSearchCity(cityId) {

	///update city ID
	/// Call api get district
	return function (dispatch) {
		//Fetch Cities
		dispatch(fetchDistrictsByCityCompanySearchRequest(cityId))
		dispatch(changeSearchWard(-1))
		dispatch(changeSearchDistrict(-1))
		dispatch(fetchWardsByDistrictCompanySearchSuccess([]))
		coreDataServices.fetchDistricts(cityId)
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchDistrictsByCityCompanySearchSuccess(response.data))
				}else{
					dispatch(fetchDistrictsByCityCompanySearchSuccess([]))
				}
			})
			.catch(error => {dispatch(fetchDistrictsByCityCompanySearchError(error))})
	} 
}
export function fetchDistrictsByCityCompanySearchRequest(cityId) {
	return {type: types.FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_REQUEST,payload: cityId}
}
export function fetchDistrictsByCityCompanySearchSuccess(data) {
	return {type: types.FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_SUCCESS,payload: data}
}
export function fetchDistrictsByCityCompanySearchError(errors) {
	return {type: types.FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_ERROR,payload: errors}
}
export function changeSearchDistrict(districtId) {
	/// Update DistrictId
	///Cal API Wards
	return function (dispatch) {
		//Fetch Cities
		dispatch(changeSearchWard(-1))
		dispatch(fetchWardsByDistrictCompanySearchRequest(districtId))
		coreDataServices.fetchWards(districtId)
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchWardsByDistrictCompanySearchSuccess(response.data))
				}else{
					dispatch(fetchWardsByDistrictCompanySearchSuccess([]))
				}
			})
			.catch(error => {dispatch(fetchWardsByDistrictCompanySearchError(error))})
	} 
}
export function fetchWardsByDistrictCompanySearchRequest(districtId) {
	return {type: types.FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_REQUEST,payload: districtId}
}
export function fetchWardsByDistrictCompanySearchSuccess(data) {
	return {type: types.FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_SUCCESS,payload: data}
}
export function fetchWardsByDistrictCompanySearchError(errors) {
	return {type: types.FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_ERROR,payload: errors}
}
export function changeSearchWard(wardId) {
	return {type: types.CHANGE_WARD_COMPANY_SEARCH_REQUEST,payload: wardId}
}
export function changeCategory(catId) {
	return {type: types.CHANGE_CATEGORY_COMPANY_SEARCH_REQUEST, payload: catId}
}

export function fetchCompanyById(id)
{
	return function (dispatch) {
		dispatch(fetchCompanyByIdRequest())
		companies.getById(id)
		.then(response => {
			if (response.status === 'OK')
			{
				dispatch(fetchCompanyByIdSuccess(response.data))
			}else{
				dispatch(fetchCompanyByIdError(response.errors))
			}
		})
		.catch(error => dispatch(fetchCompanyByIdError(error)))
	}
}
export function fetchCompanyByIdRequest()
{
	return {type: types.FETCH_COMPANY_BY_ID_REQUEST,payload: null}
}
export function fetchCompanyByIdSuccess(resData)
{
	return {type: types.FETCH_COMPANY_BY_ID_SUCCESS,payload: resData}
}
export function fetchCompanyByIdError(errors)
{
	return {type: types.FETCH_COMPANY_BY_ID_ERROR,payload: errors}
}