import * as types from '../actions/action-types'
const INITIAL_STATE_COMPANY_SEARCH = {
	cityId: -1,
	districtId: -1,
	wardId: -1,
    categoryId: -1,
    districts: [],
    wards: [],
    categories: []
}
export const companySearchParams = (state = INITIAL_STATE_COMPANY_SEARCH, action) => {
	switch (action.type) {
		case types.CHANGE_CATEGORY_COMPANY_SEARCH_REQUEST:
            return Object.assign({},state, {categoryId: Number(action.payload)} );
        case types.FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_REQUEST:
            return Object.assign({},state, {cityId: Number(action.payload)} );
        case types.FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_SUCCESS:
             return Object.assign({},state, {districts: action.payload} ); 
        case types.FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_ERROR:
             return Object.assign({},state, {districts: []} );
        case types.FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_REQUEST:
            return Object.assign({},state, {districtId: Number(action.payload)} ); 
        case types.FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_SUCCESS:
             return Object.assign({},state, {wards: action.payload} ); 
        case types.FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_ERROR:
             return Object.assign({},state, {wards: []} ); 
        case types.CHANGE_WARD_COMPANY_SEARCH_REQUEST:
            return Object.assign({},state, {wardId: Number(action.payload)} ); 
            return Object.assign({},state, { categories: [], hasError: false, errors: null, isLoading: true}); 
        case types.FETCH_ALL_COMPANY_CATEGORIES_SUCCESS:
            return Object.assign({},state, {categories: action.payload, hasError: false, errors: null, isLoading: false}); 
        case types.FETCH_ALL_COMPANY_CATEGORIES_ERROR:
            return Object.assign({},state, {categories: [], hasError: true,errors: action.payload, isLoading: false} ); 
    	default:
    		return state
    }
}
