import React from 'react'
import { translate } from 'react-i18next'
import FundingList from './FundingList'
const FundingsInCity = ({t,cityName, items}) => (
	<div className="section">
		<div className="container mb-3">
			<div className="row">
				<div className="col">
					<div className="section_title text-center">
						<h3>{t('new_posts_listings_in') + " " + cityName}</h3>
						<a href="#" className="section_subtitle_a">{t('view_all')}</a>
          			</div>
        		</div>
			</div>
		</div>
		<FundingList items={items}/>
	</div>
)
export default translate("translations")(FundingsInCity)