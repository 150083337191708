import React, {Component} from 'react'
import { translate } from "react-i18next"
import FieldDropdownItem from '../searches/FieldDropdownItem'
import withSizes from 'react-sizes'
import compose from 'recompose/compose'
const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480
})
class AsideSearchBox extends Component {
	constructor(props) {
		super(props)
		this.state = {
			expanded: false
		}
		this.props.fetchAllProjectCategories()
		this.changeCity = this.changeCity.bind(this)
		this.changeDistrict = this.changeDistrict.bind(this)
		this.changeWard = this.changeWard.bind(this)
		this.changeCategory = this.changeCategory.bind(this)
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this)	

	}
	changeCategory(item) {
		if (item) {
			this.props.changeSearchProjectCategory(item)
		}
	}
	changeCity(item) {
		if (item) {
			this.props.changeSearchProjectCity(item)
		}
	}
	changeDistrict(item) {
		if (item) {
			this.props.changeSearchProjectDistrict(item)
		}
	}
  	changeWard(item) {
  		if (item) {
  			this.props.changeSearchProjectWard(item)
  		}
  	}
  	handleSearchSubmit(e) {
  		this.props.handleSubmit(e)
  		this.setState({expanded: !this.state.expanded})
  	}
  	_renderExpandFilter () {
		let {t} = this.props
		let tmp = <React.Fragment>
					<div className="row pt-4 pb-2">
						<div className="col-12">
							<FieldDropdownItem title={t('project_category')} selected={this.props.categoryId} onChange={this.changeCategory} items={[{ id: -1, name: 'Tất cả' }, ...this.props.categories]}/>
						</div>
						<div className="col-12">
							<FieldDropdownItem title={t('city')} selected={this.props.cityId} onChange={this.changeCity} items={[{ id: -1, name: 'Tất cả' }, ...this.props.cities]}/>
						</div>
						<div className="col-6">
							<FieldDropdownItem title={t('district')} selected={this.props.districtId} onChange={this.changeDistrict} items={[{ id: -1, name: 'Tất cả' },...this.props.districts]}/>
						</div>
						<div className="col-6">
							<FieldDropdownItem title={t('ward')} selected={this.props.wardId} onChange={this.changeWard} items={[{ id: -1, name: 'Tất cả' },...this.props.wards]}/>
						</div>
						<div className="col-12">
							<div className="form-group">
								<button type="button" onClick={this.handleSearchSubmit} className="btn btn-danger btn-block" style={{height: "60px", fontSize:"20px"}}>{t('search')}</button>
							</div>
						</div>
					</div>
			</React.Fragment>
		if (this.props.isMobile === true) {
			if (this.state.expanded === false) {
				tmp = null
			}
		}
		return tmp
	}
  	handleShowFilter = (e) => {
		this.setState({expanded: !this.state.expanded})
	}
	_renderShowFilterButton() {
		let {t} = this.props
		if (this.props.isMobile === false) {
			return null
		}
		if (this.state.expanded === true) {
			return null
		}
		return (
			<div className="row pt-2 pb-2">
				<div className="col-12">
					<button type="button" className="btn btn-danger btn-block" onClick={this.handleShowFilter}>{t('show_filter')}</button>
				</div>
			</div>
		)
	}
	render (){
		const {t} = this.props
		const showFilterBtn = this._renderShowFilterButton()
		const expandedFilter = this._renderExpandFilter()
		return (
			<React.Fragment>
				<div className="container search-box">
					{
						expandedFilter
					}
				</div>
				{showFilterBtn}
			</React.Fragment>
		)
	}
}
export default compose(translate("translations"),withSizes(mapSizesToProps))(AsideSearchBox)