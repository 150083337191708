import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import Breadcrumbs from '../../components/news/Breadcrumbs'
import NewsList from '../../components/news/NewsList'
import AsideNews from '../../components/news/AsideNews'
import SearchBox from '../../containers/news/SearchBox'
import AdsBox from '../../components/ads/AdsBox'
import KeepInTouchBox from '../../components/news/KeepInTouchBox'
import NewsCategories from '../../components/news/NewsCategories'
import Pagination from '../../components/Pagination'
import NewPostListings from '../../components/posts/NewPostListings'
import {NEWS_V1} from '../../configs/configs'
import newsServices from '../../services/news'
import queryString from 'query-string'
// import ReactCountdownClock from 'react-countdown-clock'
class News extends Component {
	constructor(props) {
		super(props)
		this.state = {
			total: 0,
			currentPage: 1,
			totalPages: 0,
			pageSize: 8,
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
			keywords: null,
			catId: -1,
			categories: []
		}
		this.changePage = this.changePage.bind(this)
		this.nextPage = this.nextPage.bind(this)
		this.prevPage = this.prevPage.bind(this)
		this.fetchData = this.fetchData.bind(this)
		this.fetchSEO = this.fetchSEO.bind(this)
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
		///Load Metadata for SEO
	} 
	//Fetch Data from API
	componentDidMount() {
		//Feach SEO data
		this.fetchSEO()
		//Fectch Data
		this.fetchData()	
		window.scrollTo(0, 0)
	}
	handleSearchSubmit(event) {
		let criteria = {}
		if (this.props.query)
		{
			criteria.q = this.props.query
			criteria.page = 1
			criteria.size = this.state.pageSize
		} else {
			criteria.page = this.state.currentPage
			criteria.size = this.state.pageSize
		}
		newsServices.searchNews(criteria)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })
			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(criteria).toString()})
	}
    fetchSEO() {
    	///Categories
    	newsServices.fetchAllNewsCategories()
    	.then(response => {
			if (response.status === 'OK')
			{
				this.setState({categories: response.data})
			}else{
				this.setState({ categories: []})

			}
		})
		.catch(error => console.log(error))
    }
    fetchData() {
    	const values = queryString.parse(this.props.location.search)
		if (!values.page) {
			values.page = this.state.currentPage
		}
		if (!values.size) {
			values.size = this.state.pageSize
		}
		if (values.q) {
			this.props.onChangeQuery(values.q)
		}
		newsServices.searchNews(values)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })
			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})		
    }
	nextPage(event) {
		if (this.state.currentPage < this.state.totalPages) {
			this.changePage(this.state.currentPage+1)
		}
	}
	prevPage(event) {
		if (this.state.currentPage > 1) {
			this.changePage(this.state.currentPage-1)
		}
	}
	changePage(page) {
		let criteria = {}
		const values = queryString.parse(this.props.location.search)
		values.page = page
		if (!values.size) {
			values.size = this.state.pageSize
		}
		newsServices.searchNews(values)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})
      	window.scrollTo(0, 0)
	}
	renderHeader() {
		return (<Helmet>
			{/*facebook*/}
			<title> {"Tin tức mua bán bất động sản tại Việt Nam"} </title>
			<meta property="og:title" content="Tin tức mua bán bất động sản tại Việt Nam" />
			<meta property="og:description" content="Tin tức mua bán bất động sản tại Việt Nam"/>
			<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://topauto.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Tin tức mua bán bất động sản tại Việt Nam"/>
			<meta name="twitter:description" content="Tin tức mua bán bất động sản tại Việt Nam"/>
			<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>)
	}
	renderPaging() {
		if (this.state.total > this.state.pageSize) {
			return <Pagination totalPages = {this.state.totalPages} currentPage={this.state.currentPage} margin={2}
			 goto={this.changePage} next={this.nextPage} prev={this.prevPage}/>
		}
		return null
	}
	render () {
		const {t} = this.props
		const header = this.renderHeader()
		const pagination = this.renderPaging()
		return (
			<React.Fragment>
				{header}
				<Breadcrumbs items={[]}/>
				<div className="container">
					<div className="row mb-1">
						<div className="col-lg-8 p-0 mb-3">
							<NewsList items={this.state.items}/>
							<div className="center">
								{pagination}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="order-ms-0">
								<SearchBox  onSubmit={this.handleSearchSubmit}/>
							</div>
							
							<div className="row">
								<div className="col">
									<NewsCategories/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<AsideNews title={t('latest_news')} api={NEWS_V1.GET_LASTEST_NEWS} />
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<AsideNews title={t('most_views')} api={NEWS_V1.GET_MOST_VIEW_NEWS}/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<AdsBox item={{headline: "Liên hệ quảng cáo", subTitle: "0982437172"}}/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<KeepInTouchBox />
								</div>
							</div>
						</div>
					</div>
				</div>
				<NewPostListings cityName={"Hồ Chí Minh"} cityId={51}/>			
			</React.Fragment>
		)
	}
}
export default translate("translations")(News)
