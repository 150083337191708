import React, {Component}from 'react'
import { translate } from 'react-i18next'
import projectServices from '../../services/projects'
import withSizes from 'react-sizes'
import compose from 'recompose/compose'
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css"
// import ReactCountdownClock from 'react-countdown-clock'
const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
})
class AdsBanners extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
			count: 0
		}
	}
	componentDidMount() {
		this.setState({ isLoading: true, isError: false, items:[], count:0})
		projectServices.fetchAdsBanners()
		.then(response => {
			if (response.status === 'OK')
			{
				let items = []
				for (let i = 0 ; i < response.data.length; i++) {
					items.push(
						{
							original: response.data[i].imageUrl,
							thumbnail: response.data[i].imageUrl,
							title: response.data[i].name,
							description: response.data[i].description,
							address: response.data[i].address,
							url: "/projects/"+response.data[i].seoPath + '/'+response.data[i].id,
							renderItem: this._renderAdsItem.bind(this),
							renderThumbInner: this._renderThumbInner.bind(this)
						}
					)
				}
				this.setState({ items: items, isLoading: false, isError: false })
			}else{
				this.setState({ items: [], isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({ items: [], errors: error, isLoading: false, isError: true }))
	} 
	_renderAdsItem(item) {
		return (
			<div className="card border-0">
				<img className="card-img-top border-0 image-gallery-image card-ads-banner__image" src={item.original}/>
				{
					this.props.isMobile?
					<div className="card-body" style={{background: "rgba(34, 43, 69, 0.9)"}}>
    					<h3 className="card-title text-left" style={{color: '#fff'}}>{item.title}</h3>
    					<address className="card-text text-left" style={{color: '#9b9b9b'}}>{item.address}</address>
    					<p className="card-text text-left mt-5" style={{color: '#fff'}}>{item.description}</p>
    					<a href={item.url} title={item.title} className="btn btn-info float-left mt-2" style={{backgroundColor: "#febd17", borderColor: "#febd17", color: "#222b45", fontSize: "bold"}}>{this.props.t('view_details')}</a>
  					</div>
					:
					<div className="card-img-overlay project-image-gallery__ads-banner" style={{right: '0', left: 'initial'}}>
    					<h3 className="card-title text-left">{item.title}</h3>
    					<address className="card-text text-left" style={{color: '#9b9b9b'}}>{item.address}</address>
    					<p className="card-text text-left mt-4">{item.description}</p>
    					<a href={item.url} title={item.title} className="btn btn-info float-left mt-3" style={{backgroundColor: "#febd17", borderColor: "#febd17", color: "#222b45", fontSize: "bold"}}>{this.props.t('view_details')}</a>
  					</div>
				}
			</div>
		)
	}
	_renderThumbInner(item) {
		return (
			<div className="image-gallery-thumbnail-inner">
				<img className="project-ads-banner__image-gallery-thumbnail-image" src={item.thumbnail}/>
			</div>
			)
	}
	render () {
		const {t} = this.props
		let thumbnailPosition = "right"
		if (this.props.isMobile) {
			thumbnailPosition = "bottom"
		}
		return(
			<div style={{backgroundColor: "#222b45"}}>
				<ImageGallery items={this.state.items} thumbnailPosition={thumbnailPosition} showPlayButton={false} showBullets={false}
				showNav={false} useBrowserFullscreen={false} showFullscreenButton={false} />
			</div>
		)
	}
}
export default compose(translate("translations"),withSizes(mapSizesToProps))(AdsBanners)