import React, {Component} from 'react'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"
import NewsLetter from '../../components/NewsLetter'
import Breadcrumbs from '../../components/cities/Breadcrumbs'
import AdsBox from '../../components/ads/AdsBox'
import NewPostListings from '../../components/posts/NewPostListings'
import ProjectsInCity  from '../../components/projects/ProjectsInCity'
// import ReactCountdownClock from 'react-countdown-clock'
class Cities extends Component {
	constructor(props) {
		super(props)
	}
	componentDidMount() {
		window.scrollTo(0, 0)
		///Fetch Cities By ID
		/*
		1. Summary Sale, Rent, Project
		*/


	}
	_renderHeader() {
		return (<Helmet>
					{/*facebook*/}
					<title> {"TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam"} </title>
					<meta property="og:title" content="TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam" />
					<meta property="og:description" content="TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam"/>
					<meta name="twitter:description" content="TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
			</Helmet>
		)
	}
	render () {
		const {t} = this.props
		const header = this._renderHeader()
		return (
			<React.Fragment>
				{ header }
				<Breadcrumbs/>
				<div className="container mt-3">
					<div className="row">
				 	</div>
				 	<NewPostListings cityName={"Hồ Chí Minh"} cityId={51} />
				 	<ProjectsInCity cityName={"Hồ Chí Minh"} cityId={51}/>
				</div> 
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Cities)