import React, { Component } from 'react'
import { translate, Trans } from "react-i18next"
import feedbackServices from '../services/feedbacks'
import { withAlert } from 'react-alert'
import compose from 'recompose/compose'

class NewsLetter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: '',
			isLoading: false,
			isError: false,
			errors: null,
			message: null
		}
		this.handleChangeEmail = this.handleChangeEmail.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleChangeEmail(event) {
		this.setState({email: event.target.value})
	}
	resetForm () {
		this.setState({
			email: ''
		})
	}
	handleSubmit(event) {
		// Call Submit API
		//
		if (!this.state.email) {
			this.setState({isError: true, errors: null, message: "Have to value"})
			return;
		}
		this.setState({ isLoading: true, isError: false, errors: null})
		feedbackServices.sendSubscribe(this.state.email)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ message: response.data, isLoading: false, isError: false })
				this.props.alert.show(this.props.t('msg_subcribe_success'))
				this.resetForm()

			}else{
				this.setState({ errors: response.errors, isLoading: false, isError: true })
				this.props.alert.show(this.props.t('msg_subcribe_failure'))

			}
		})
		.catch(error => this.setState({ message: null, errors: error, isLoading: false, isError: true }))
		event.preventDefault();
	}
	render (){
		const {t} = this.props
		return (
			<div className="newsletter">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="newsletter_title">
								<h3>{t('subscribe_to_our_newsletter')}</h3>
								<span className="newsletter_subtitle">{t('get_the_latest_offers')}</span>
							</div>
							<div className="newsletter_form_container">
								<form onSubmit={this.handleSubmit}>
									<div className="newsletter_form_content d-flex flex-row">
										<input value={this.state.email} onChange={this.handleChangeEmail} className="newsletter_email" type="email" placeholder={t('your_email_here')} required="required" data-error="Valid email is required."></input>
										<button id="newsletter_submit" type="submit" className="newsletter_submit_btn trans_200" value="Submit">{t('subscribe')}</button>
									</div>
									<div>
										{
											this.state.isError && <label>{this.state.message}</label>
										}
									</div>
								</form>
							</div>
						</div>

						<div className="col-lg-6">
							<a href="#">
								<div className="weekly_offer">
									<div className="weekly_offer_content d-flex flex-row">
										<div className="weekly_offer_icon d-flex flex-column align-items-center justify-content-center">
											<img src="/images/prize.svg" alt=""></img>
										</div>
										<div className="weekly_offer_text text-center">{t('weekly_offer')}</div>
									</div>
									<div className="weekly_offer_image" style={{backgroundImage: 'url("/images/weekly.jpg")'}}></div>
								</div>
							</a>
						</div>

					</div>
				</div>
			</div>
		)
	}
}

export default compose(translate("translations"),withAlert())(NewsLetter)