import React, { Component } from 'react'
import { translate, Trans } from "react-i18next"
import InDevelopmentBox from '../InDevelopmentBox'
class Wallet extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
		}
	}
	handleSaveChange(event) {
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<InDevelopmentBox />
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="package package--featured">
								<div className="package__wrapper">
									<header className="package__header">
										<h2 className="package__name">ZetCoin</h2>
										<h3 className="package__price">1.00 ~ 15K</h3>
										<p className="package__description">{t('wallet_description')}</p>
									</header>
									<div className="package__body">
										<ul className="package__features">
											<li className="package__feature">
												<strong>{t('balance_current')}</strong>
												<span>3</span>
											</li>
											<li className="package__feature">
												<strong>{t('balance_available')}</strong>
												<span>2</span>
											</li>
											<li className="package__feature">
												<strong>{t('balance_pending')}</strong>
												<span>1</span>
											</li>
										</ul>
									</div>
									<footer className="package__footer">
										<a href="#" className="package__cta package__cta--featured">{t('wallet_register_now')}</a>
									</footer> 
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<ul className="manage-list manage-list--my-property">
								<li className="manage-list__header">
									<span className="manage-list__title"><i className="far fa-bookmark"></i>&nbsp;&nbsp;{t('recent_events')}</span>
									<span className="manage-list__title"><i className="far fa-calendar-alt"></i>&nbsp;&nbsp;{t('date')}</span>
								</li>
							</ul>
						</div>

					</div>
					<div className="row">
						<div className="col">
							<hr width="100%" />
						</div>
					</div>
					<div className="row mb-3">
						<div className="col">
							<h3>{t('transaction_history')}</h3>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
export default translate("translations")(Wallet)