import React from 'react'
import { translate } from 'react-i18next'
import {ADS_PACK_COLORS} from '../../configs/configs'

const ProjectListingsListItem = ({t, item}) => (
	<div className="card border-0 mb-2" >
		<div className="row">
			<div className="col-md-6 col-12">
				<img className="card-img-top" style={{ height: '200px', borderRadius: '0px', objectFit:"cover"}} 
				src={item.imageUrl?item.imageUrl:"/images/no_image.svg"} alt={item.name}/>
			</div>
			<div className="col-md-6 col-12">
				<div className="card-body border-bottom" style={{height: '200px'}}>
					<div className="row">
						<a href={'/projects/' + item.seoPath+ "/" + item.id} className="col-12 card-title" style={{ fontWeight: "600", color:ADS_PACK_COLORS[item.adsPack], fontSize: "19px"}}
						title={item.name}>{item.name}</a>
						<div className="col-12 card-text">
							<address style={{fontSize: "14px"}} className="d-flex"><img src="/images/location.svg"/>{item.address}</address>
						</div>
						<div className="col-12 card-text d-flex" style={{fontSize: "14px"}}>
							<img src="/images/business.svg"/>{item.investor?item.investor: t('updating')}
						</div>
						<div className="col-6 card-text d-flex" style={{fontSize: "14px"}}>
							<img src="/images/sq.svg"/>{item.area?item.area:t('updating')}
						</div>
						<div className="col-6 card-text d-flex" style={{fontSize: "14px"}}>
							<img src="/images/loading.svg"/>{item.progress?item.progress:t('updating')}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)
export default translate("translations")(ProjectListingsListItem)