import React, {Component}from 'react'
import { translate } from 'react-i18next'
import NewsCategoryList from './NewsCategoryList'
import { css } from "@emotion/core"
import Loading from "react-spinners/ClockLoader";

//Fetch Data From API
import {ROOT_URL, NEWS_V1} from '../../configs/configs'

const override = {
 	display: 'block',
    margin: '0 auto',
    borderColor: 'red',
}
class NewsCategories extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null
		}
	}
	componentDidMount() {
		this.setState({ isLoading: true, isError: false, items:[]})
		fetch(ROOT_URL+NEWS_V1.GET_ALL_CATEGORIES_SEO)
			.then(response => response.json())
			.then(data => this.setState({ items: data.data, isLoading: false, isError: false }))
			.catch(error => this.setState({ items: [], errors: error, isLoading: false, isError: true }))
	}
	render (){
		const {t} = this.props
		let items = null
		return (
			<div className="container mb-2" style={{borderRadius: "12px", backgroundColor: "#63ab78"}}>
				<div className="row pt-2 pl-4">
					<label className="news_aside__title">{t("news_categories")}</label>
				</div>
				<div className="row">
					<Loading css={override} size={70} color={"#e9eaec"} loading={this.state.isLoading} />
				</div>
				<div className="row">
					<div className="col">
					<hr with="100%" style={{color:"#63ab78"}}/>
					</div>
				</div>
				<div className="row pl-4 pb-3">
					<NewsCategoryList items={this.state.items?this.state.items:[]}/>
				</div>
			</div>
		)
	}
}
export default translate("translations")(NewsCategories)