import {ROOT_URL, PROJECT_V1} from '../configs/configs'
import format from 'string-format'
import authServices from './auth'

export default {
	fetchAllProjectCategories () {
		return fetch(ROOT_URL + PROJECT_V1.GET_ALL_CATEGORIES,{
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				// "Content-Type": "application/x-www-form-urlencoded",
				// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	searchProjects (criteria) {
		var query = ''
		if(criteria.page && criteria.page != -1)
		{
			query += 'page=' + criteria.page
		}
		if (criteria.catId && criteria.catId != -1) {
			query += '&catId=' + criteria.catId
		} 
		if(criteria.wardId && criteria.wardId != -1)
		{
			query += '&wardId=' + criteria.wardId
		}else{
			if(criteria.districtId && criteria.districtId != -1)
			{
				query += '&districtId=' + criteria.districtId
			}else{
				if(criteria.cityId && criteria.cityId != -1)
				{
					query += '&cityId=' + criteria.cityId
				}
			}
		}
		return fetch(ROOT_URL + PROJECT_V1.SEARCH_PROJECTS + '?' + query,{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getById (id) {
		return fetch(ROOT_URL + format(PROJECT_V1.GET_DETAILS_BY_ID,id),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchFeaturedProjectsAndCount (limit) {
		let query = ROOT_URL + PROJECT_V1.FETCH_FEATURED_PROJECTS_AND_COUNT;
		if (limit && limit > 0) {
			query += '?size=' + limit 
		}
		return fetch(query,{
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				// "Content-Type": "application/x-www-form-urlencoded",
				// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchAllProjects() {
		let query = ROOT_URL + PROJECT_V1.GET_ALL_PROJECTS
		return fetch(query,{
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				// "Content-Type": "application/x-www-form-urlencoded",
				// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchAdsBanners () {
		let query = ROOT_URL + PROJECT_V1.FETCH_ADS_BANNERS
		return fetch(query,{
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				// "Content-Type": "application/x-www-form-urlencoded",
				// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	loadOptions(name) {
		let queryURL = ROOT_URL + PROJECT_V1.LOAD_OPTIONS + "?name=" + name
		return fetch(queryURL, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				// "Content-Type": "application/x-www-form-urlencoded",
				"X-Authorization": authServices.headerAuthToken()
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getProjectForOptionsById(id) {
		return fetch(ROOT_URL + format(PROJECT_V1.GET_PROJECT_FOR_OPTIONS_BY_ID,id), {
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				// "Content-Type": "application/x-www-form-urlencoded",
				"X-Authorization": authServices.headerAuthToken()
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	}
}