import React from 'react'
import { translate } from 'react-i18next'
import {ADS_PACK_COLORS} from '../../configs/configs'

const CompanyListingsList = ({t, item}) =>{
	let url = '/companies/'
	if (item.seoPath) {
		url += item.seoPath + "/" + item.id
	} else {
		url += item.id
	}
	return (
	<div className="card border-0 mb-2" >
		<div className="row">
			<div className="col-md-5 col-12">
				<img className="card-img-top" style={{ height: '200px', borderRadius: '0px', objectFit:"cover"}} 
				src={item.logoUrl?item.logoUrl:"/images/no_image.svg"} alt={item.name}/>
			</div>
			<div className="col-md-7 col-12">
				<div className="card-body border-bottom" style={{height: '200px'}}>
					<div className="row">
						<a href={url} className="col-12 card-title" style={{ fontWeight: "600", color: ADS_PACK_COLORS[item.adsPack]? ADS_PACK_COLORS[item.adsPack]: "#222b45", fontSize: "19px"}}
						title={item.name}>{item.name}</a>
						<div className="col-12 d-flex align-items-center">
							<i class="fas fa-map-marker-alt"></i>&nbsp;<address style={{fontSize: "14px"}} className="card-text">{item.address}</address>
						</div>
						<div className="col-6 d-flex align-items-center">
							<i className="fas fa-phone-square fa-lg"></i>&nbsp;<div style={{fontSize: "14px"}} className="card-text">{item.phone?item.phone:t('updating')}</div>
						</div>
						<div className="col-6 card-text d-flex align-items-center">
							<i class="fas fa-mobile-alt"></i>&nbsp;<div className="card-text" style={{fontSize: "14px"}}>{item.mobilePhone?item.mobilePhone:t('updating')}</div>
						</div>
						
						<div className="col-12 card-text d-flex align-items-center">
							<i class="far fa-envelope"></i>&nbsp;<div className="card-text" style={{fontSize: "14px"}}>{item.email?item.email:t('updating')}</div>
						</div>
						<div className="col-12 card-text d-flex align-items-center">
							<i class="fas fa-globe"></i>&nbsp;<div className="card-text" style={{fontSize: "14px"}}>{item.website?item.website:t('updating')}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)
} 
export default translate("translations")(CompanyListingsList)