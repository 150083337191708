import React, {Component} from 'react'
import Select from '../Select'

class FieldInputCheck extends Component {
	constructor(props) {
		super(props)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleSubmit(event) {
		//this.setState({checked: !this.state.checked})
		this.props.onChange(event)
	}
	render () {
		const title = this.props.title
		return (
			<React.Fragment>
				<div className="form-check ml-3">
					<input className="form-check-input search_form_check_input--red" type="radio" onChange={this.props.onChange} name={this.props.name} value={this.props.value} checked={this.props.checked}/>
					<label className="form-check-label">
						{title}
					</label>
				</div>
			</React.Fragment>
		)
	}
}
export default FieldInputCheck