import * as types from '../actions/action-types'
const INITIAL_STATE = {
	cityId: -1,
	districtId: -1,
	wardId: -1,
    categoryId: -1,
    priceMaxId: -1,
    priceMinId: -1,
    districts: [],
    wards: []
}
const projectSearchParams = (state=INITIAL_STATE, action) => {
	switch (action.type) {
		case types.CHANGE_SEARCH_PROJ_CATEGORY_REQUEST:
			return Object.assign({},state, {categoryId: Number(action.payload)})
    	case types.CHANGE_SEARCH_PROJ_CITY_REQUEST:
    		return Object.assign({},state, {cityId: Number(action.payload)})
        case types.CHANGE_SEARCH_PROJ_DISTRICT_REQUEST:
            return Object.assign({},state, {districtId: Number(action.payload)})
        case types.CHANGE_SEARCH_PROJ_WARD_REQUEST:
            return Object.assign({},state, {wardId: Number(action.payload)})
        case types.CHANGE_SEARCH_PROJ_PRICE_MIN_REQUEST:
            return Object.assign({},state, {priceMinId: Number(action.payload)})
        case types.CHANGE_SEARCH_PROJ_PRICE_MAX_REQUEST:
            return Object.assign({},state, {priceMaxId: Number(action.payload)})
        case types.FETCH_PROJ_DISTRICTS_BY_CITY_SUCCESS:
            return Object.assign({},state, {districts: action.payload})
        case types.FETCH_PROJ_WARDS_BY_DISTRICT_SUCCESS:
            return Object.assign({},state, {wards: action.payload})
    	default:
    		return state
    }
}
export default projectSearchParams