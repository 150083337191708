
export const ADS_PACK_COLORS = {
	"DIAMOND" : "#dc3545",
	"GOLD": "#ffc107",
	"SILER": "#17a2b8",
	"BASIC": "#222B45"
}
//export const ACCOUNT_URL = 'http://localhost:8087/login?redirectURL=http://localhost:3000'
export const ACCOUNT_URL = 'https://topreal.zetgoo.com/login?redirectURL=https://topreal.vn'
export const APP_DOMAIN = 'https://topreal.vn'
export const REDIRECT_LOGIN_URL = 'https://topreal.vn'
//export const ROOT_URL = 'http://localhost:8765'
//export const ROOT_URL = 'http://192.168.1.3:8765'
export const ROOT_URL = 'https://cool.zetgoo.com'
///export const 
export const APP_CONFIG_FIREBASE = {
	apiKey: 'AIzaSyAkjkIceO0xIOXEjEKpULSL6AExTMrnfdk',
	authDomain: "localhost",
	databaseURL: "https://topreal-18c97.firebaseio.com",
	projectId: "topreal-18c97",
	storageBucket: "localhost",
	messagingSenderId: "469774288098"
}
export const FEEDBACK_V1 = {
	SAY_HELLO: '/api/zrealestate/contacts/v1',
	SUBSCRIBE: '/api/zrealestate/subscribes/v1',
	SEND_FEEDBACK: '/api/zrealestate/feedbacks/v1'
}
export const CORE_DATA_V1 = {
	CITIES_BY_COUNTRY: '/api/zcore/countries/v1/{0}/cities',
	DISTRICTS_BY_CITY: '/api/zcore/cities/v1/{0}/districts',
	WARDS_BY_DISTRICT: '/api/zcore/districts/v1/{0}/wards',
	STREETS_BY_DISTRICTS: '/api/zcore/districts/v1/{0}/streets',
	PROPERTY_CATEGORIES_BY_PARENT: '/api/zrealestate/posts/categories/v1/{0}/children',
	PROPERTY_CATEGORIES_BY_TYPE: '/api/zrealestate/posts/categories/v1/types/{0}',
	UOMS_BY_TYPE: '/api/zrealestate/uoms/v1/types/{0}',
	ALL_LEGAL_STATUSES: '/api/zrealestate/legal_statuses/v1',
	ALL_NO_ROOMS: '/api/zrealestate/legal_statuses/v1',
	ALL_REPORT_TYES: '/api/zrealestate/posts/reports/types/v1',
	BUY_PRICE_RANGES: '/api/zrealestate/search/params/v1/buy_price_ranges',
	RENT_PRICE_RANGES: '/api/zrealestate/search/params/v1/rent_price_ranges',
	AREA_RANGES: '/api/zrealestate/search/params/v1/area_ranges',
	GET_ALL_CALLING_CODES: '/api/zcore/countries/v1/calling_codes'
}
export const POST_V1 = {
	SEARCH_POST: '/api/zrealestate/posts/seo/v1/s',
	FEATURED_POSTS: '/api/zrealestate/posts/v1/featured_posts',
	GET_DETAILS_BY_ID: '/api/zrealestate/posts/v1/{0}',
	UPLOAD_IMAGES: '/api/zrealestate/posts/v1/upload_images',
	PUBLISH_FREE: '/api/zrealestate/posts/v1.1/publish_free',
	GET_POSTS_LIST_ITEMS_BY_PHONE: '/api/zrealestate/posts/seo/v1/phone/{0}/list_items',
	GET_POSTS_LIST_ITEMS_BY_IDS: '/api/zrealestate/posts/seo/v1/list_items',
	GET_POSTS_COUNT_LIST_ITEMS_BY_CONTACT: '/api/zrealestate/posts/seo/v1/contacts/{0}/count_list_items',
	GET_POSTS_LIST_ITEMS_BY_CONTACT: '/api/zrealestate/posts/seo/v1/contacts/{0}/list_items',
	GET_ALL_POST_PACKAGES: '/api/zrealestate/posts/packages/v1',
	GET_ALL_POST_PACKAGES: '/api/zrealestate/posts/packages/v1',
	UPLOAD_FEATURED_IMAGE: '/api/zrealestate/posts/v1/upload_featured_photo',
	UPLOAD_IMAGE: '/api/zrealestate/posts/v1/upload_image',
	GET_EDITING_POST: '/api/zrealestate/posts/v1/{0}/edit',
	DELETE_FEATURED_IMAGE:'/api/zrealestate/posts/v1/delete_featured_photo/{0}',
	DELETE_IMAGE:'/api/zrealestate/posts/v1/delete_photo/{0}'
}
export const NEWS_V1 = {
	GET_ALL_CATEGORIES: '/api/zrealestate/news/categories/v1',
	GET_ALL_CATEGORIES_SEO: '/api/zrealestate/news/categories/seo/v1',
	SEARCH_NEWS: '/api/zrealestate/news/seo/v1/s',
	GET_DETAILS_BY_ID: '/api/zrealestate/news/seo/v1/{0}',
	GET_LASTEST_NEWS: '/api/zrealestate/news/seo/v1/lastest_posts',
	GET_MOST_VIEW_NEWS: '/api/zrealestate/news/seo/v1/most_views'
}
export const PROJECT_V1 = {
	GET_ALL_CATEGORIES: '/api/zrealestate/projects/categories/v1',
	SEARCH_PROJECTS: '/api/zrealestate/projects/seo/v1/s',
	GET_DETAILS_BY_ID: '/api/zrealestate/projects/v1/{0}',
	FETCH_FEATURED_PROJECTS_AND_COUNT: "/api/zrealestate/projects/seo/v1/featured_and_count",
	FETCH_ADS_BANNERS: "/api/zrealestate/projects/seo/v1/ads_banners",
	GET_ALL_PROJECTS: '/api/zrealestate/projects/v1/options',
	LOAD_OPTIONS: "/api/zrealestate/projects/v1/load_options",
	GET_PROJECT_FOR_OPTIONS_BY_ID: '/api/zrealestate/projects/v1/{0}/options',
}
export const CITY_V1 = {
	GET_HOTTEST_MARKETS: '/api/zrealestate/cities/v1/hottest_markets',
	GET_HOTTEST_MARKETS_STATISTICS: '/api/zrealestate/cities/v1/hottest_markets/sl',
	GET_COUNT_POSTS: '/api/zrealestate/cities/v1/count_posts',
	GET_RANKING: '/api/zrealestate/cities/v1/ranking',
	GET_LATEST_POSTS_AND_COUNT: '/api/zrealestate/cities/seo/v1/{0}/latest_posts_and_count',
	GET_LATEST_PROJECTS_AND_COUNT: '/api/zrealestate/cities/seo/v1/{0}/latest_projects_and_count',
	FETCH_ALL_CITY_STATS_PRICES: '/api/zrealestate/cities/statistics/v1/prices'

}
export const COMPANY_CATEGORY_V1 = {
	FETCH_ALL_CATEGORIES: '/api/zrealestate/companies/categories/v1',
	GET_DETAILS_BY_ID: '/api/zrealestate/companies/categories/v1/{0}',
}
export const COMPANY_V1 = {
	SEARCH_COMPANIES: '/api/zrealestate/companies/v1/s',
	GET_DETAILS_BY_ID: '/api/zrealestate/companies/v1/{0}'
}
export const AGENT_V1 = {
	SEARCH_AGENTS: '/api/zrealestate/agents/v1/s',
	GET_DETAILS_BY_ID: '/api/zrealestate/agents/v1/{0}',
	CREATE_CONTACT_MESSAGE: "/api/zrealestate/agents/contact_messages/v1"

}
export const POST_REPORT_V1 = {
	CREATE : '/api/zrealestate/posts/reports/v1'
}
export const DEVICES_V1 = {
	GET_DEVICE_SESSION: '/api/zauth/devices/v1/web/session'
}
export const USERS_V1 = {
	GET_ME: '/api/zusers/v1/me'
}