import React, { Component } from 'react'
import { translate, Trans } from "react-i18next";
class ChangePassword extends Component {
	constructor(props) {
		super(props)
		this.state = {
			currentPassoword:null,
			newPassword: null,
			conformedNewPassword: null
		}
		this.handleSaveChange = this.handleSaveChange.bind()
	}
	handleSaveChange(event) {
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<div className="form-group submit-property__group">
					<label for="current-password" className="submit-property__label">{t('current_password') + ' *' }</label>
					<input type="password" className="form-control submit-property__field" id="current-password" placeholder={t('current_password')} required="true" onChange={(e) => this.setState({currentPassoword: e.target.value})} value={this.state.currentPassoword}/>
				</div>
				<div className="form-group submit-property__group">
					<label for="new-password" className="submit-property__label">{t('new_password') + ' *' }</label>
					<input type="password" className="form-control submit-property__field" id="new-password" placeholder={t('new_password')} required="true" onChange={(e) => this.setState({newPassword: e.target.value})} value={this.state.newPassword}/>
				</div>
				<div className="form-group submit-property__group">
					<label for="conform-new-password" className="submit-property__label">{t('confirm_new_password') + ' *' }</label>
					<input type="password" className="form-control submit-property__field" id="confirm-new-password" placeholder={t('confirm_new_password')} required="true" onChange={(e) => this.setState({conformedNewPassword: e.target.value})} value={this.state.conformedNewPassword}/>
				</div>
				<div className="form-group">
					<button className="btn btn-info float-left" onClick={this.handleSaveChange}>{t('save_change')}</button>
				</div>

			</React.Fragment>
		)
	}
}
export default translate("translations")(ChangePassword)