import React from 'react'
import { translate } from 'react-i18next'
import NewsListItem from './NewsListItem'
function NewsList({t, items}){
	let array = []
	for(let i = 0; i < items.length; i++) {
		
		if (i === 0 || i === 7) {
			array.push(<NewsListItem key={items[i].id} item={items[i]} classes="card border-0 col-12 mb-5"/>)
		} else {
			if (items.length % 2 === 0 && i === items.length -1) {
				array.push(<NewsListItem key={items[i].id} item={items[i]} classes="card border-0 col-12 mb-5"/>)
			} else {
				array.push(<NewsListItem key={items[i].id} item={items[i]} classes="card border-0 col-md-6 col-12 mb-5"/>)
			}
		}

	}
	return (
		<div className="container">
			<div className="row">
				{array}
			</div>
		</div>
	)
}
export default translate("translations")(NewsList)