import React from 'react'
import { translate } from 'react-i18next'
///card border-0 col-12 mb-5
const FeaturedPostsAsideListItem = ({t, item, classes}) => (
	<div className="card border-0 col-12 mb-1" >
		<div className="row">
		<img className="card-img col-5 p-0" style={{maxHeight: '110px', objectFit: "cover"}} src={item.imageUrl?item.imageUrl:"/images/no_image.svg"} alt=""/>
		<div className="card-body col-7">
			<a href={'/news/details/' + item.seoPath + "/" + item.id} className="card-title post-listings-aside-card-body__headline" style={{fontSize: '16px'}}>{item.title}</a>
			<div className="post-listings-item-aside__body--price">
				{item.priceText}
			</div>
		</div>
		
		</div>
	</div>
)
export default translate("translations")(FeaturedPostsAsideListItem)