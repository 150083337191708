import * as types from './action-types'
import projectServices from '../services/projects'
import coreDataServices from '../services/core-data'


export function changeSearchProjectCategory(id)
{
	return {type: types.CHANGE_SEARCH_PROJ_CATEGORY_REQUEST,payload:id}
}
export function changeSearchProjectCity(id)
{
	return function (dispatch) {
		dispatch(changeSearchProjectCityRequest(id))
		dispatch(changeSearchProjectDistrictRequest(-1))
		dispatch(changeSearchProjectWard(-1))
		dispatch(fetchProjectWardsByDistrictSuccess([]))
		coreDataServices.fetchDistricts(id)
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchProjectDistrictsByCitySuccess(response.data))
				}else{
					dispatch(fetchProjectDistrictsByCitySuccess([]))
				}
			})
			.catch(error => {dispatch(fetchProjectDistrictsByCityError(error))})
	}
}
export function changeSearchProjectCityRequest(id)
{
	return {type: types.CHANGE_SEARCH_PROJ_CITY_REQUEST,payload:id}
}
export function fetchProjectDistrictsByCitySuccess(data) {
	return {type: types.FETCH_PROJ_DISTRICTS_BY_CITY_SUCCESS,payload:data}
}
export function fetchProjectDistrictsByCityError(errors) {
	return {type: types.FETCH_PROJ_DISTRICTS_BY_CITY_ERROR,payload:errors}
}

export function changeSearchProjectDistrict(id)
{
	return function (dispatch) {
		dispatch(changeSearchProjectDistrictRequest(id))
		dispatch(changeSearchProjectWard(-1))
		coreDataServices.fetchWards(id)
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchProjectWardsByDistrictSuccess(response.data))
				}else{
					dispatch(fetchProjectWardsByDistrictSuccess([]))
				}
			})
			.catch(error => {dispatch(fetchProjectWardsByDistrictError(error))})
	}
}
export function fetchProjectWardsByDistrictSuccess(data) {
	return {type: types.FETCH_PROJ_WARDS_BY_DISTRICT_SUCCESS,payload:data}
}
export function fetchProjectWardsByDistrictError(errors) {
	return {type: types.FETCH_PROJ_WARDS_BY_DISTRICT_ERROR,payload:errors}
}
export function changeSearchProjectDistrictRequest(id)
{
	return {type: types.CHANGE_SEARCH_PROJ_DISTRICT_REQUEST,payload:id}
}
export function changeSearchProjectWard(id)
{
	return {type: types.CHANGE_SEARCH_PROJ_WARD_REQUEST,payload:id}
}

export function changeSearchProjectPriceMax(id)
{
	return {type: types.CHANGE_SEARCH_PROJ_PRICE_MAX_REQUEST,payload:id}
}
export function changeSearchProjectPriceMin(id)
{
	return {type: types.CHANGE_SEARCH_PROJ_PRICE_MIN_REQUEST,payload:id}
}

export function fetchAllProjectCategories()
{
	return function (dispatch) {
		dispatch(fetchAllProjectCategoriesRequest())
		projectServices.fetchAllProjectCategories()
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchAllProjectCategoriesSuccess(response.data))
				}else{
					dispatch(fetchAllProjectCategoriesError(response.errors))
				}
			})
			.catch(error => {dispatch(fetchAllProjectCategoriesError(error))})
	}
}
export function fetchAllProjectCategoriesRequest()
{
	return {type: types.FETCH_ALL_PROJECT_CATEGORIES_REQUEST, payload: null}
}
export function fetchAllProjectCategoriesSuccess(categories)
{
	return {type: types.FETCH_ALL_PROJECT_CATEGORIES_SUCCESS, payload: categories}
}
export function fetchAllProjectCategoriesError(errors)
{
	return {type: types.FETCH_ALL_PROJECT_CATEGORIES_ERROR, payload: errors}
}

export function searchProjects(criteria)
{
	return function (dispatch) {
		dispatch(searchProjectsRequest())
		projectServices.searchProjects(criteria)
		.then(response => {
			if (response.status === 'OK')
			{
				dispatch(searchProjectsSucccess(response.data))
			}else{
				dispatch(searchProjectsError(response.errors))
			}
		})
		.catch(error => dispatch(searchProjectsError(error)))
	}
}
export function searchProjectsRequest()
{
	return {type: types.SEARCH_PROJECTS_REQUEST,payload: null}
}
export function searchProjectsSucccess(resData)
{
	return {type: types.SEARCH_PROJECTS_SUCCESS,payload: resData}
}
export function searchProjectsError(errors)
{
	return {type: types.SEARCH_PROJECTS_ERROR,payload: errors}
}
export function fetchProjectById(projectId)
{
	return function (dispatch) {
		dispatch(fetchProjectByIdRequest())
		projectServices.getById(projectId)
		.then(response => {
			if (response.status === 'OK')
			{
				dispatch(fetchProjectByIdSucccess(response.data))
			}else{
				dispatch(fetchProjectByIdError(response.errors))
			}
		})
		.catch(error => dispatch(fetchProjectByIdError(error)))
	}
}
export function fetchProjectByIdRequest()
{
	return {type: types.FETCH_PROJECT_BY_ID_REQUEST,payload: null}
}
export function fetchProjectByIdSucccess(resData)
{
	return {type: types.FETCH_PROJECT_BY_ID_SUCCESS,payload: resData}
}
export function fetchProjectByIdError(errors)
{
	return {type: types.FETCH_PROJECT_BY_ID_ERROR,payload: errors}
}

