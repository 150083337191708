import React, {Component} from 'react'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"
import PostsByAdvertiser from '../../components/posts/PostsByAdvertiser'
import AdvertiserContactBox from '../../components/advertisers/AdvertiserContactBox'
import NewsLetter from '../../components/NewsLetter'
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css"
import Parser from 'html-react-parser'
import GoogleMapReact from 'google-map-react';
import moment from "moment"

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon} from 'react-share'
import { withAlert,positions } from 'react-alert'
import compose from 'recompose/compose'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import Button from 'react-bootstrap/Button'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import VisionCaptcha from '../../components/VisionCaptcha'
import Select from '../../components/Select'
import {generateCaptchaSecret} from '../../helpers/captcha-utils'
import {generateUUID} from '../../helpers/uuid-utils'
import {validateEmail,validatePhone} from '../../helpers/validator-utils'
import {isEmpty,isEmail,isPhone} from '../../helpers/string-utils'
import * as errors from '../../configs/errors'
import postServices from '../../services/posts'
import mapServices from '../../services/maps'
import coreServices from '../../services/core-data'
import * as constrainst from '../../configs/constraints'
import postLocalStorages from '../../services/local/posts'
import queryString from 'query-string'
import {NO_IMAGE_GRAY} from '../../configs/constants'

// import ReactCountdownClock from 'react-countdown-clock'
const MapMarker = ({ text, url}) => <a href={url} target="_blank"><i className="fas fa-map-marker-alt fa-5x" style={{color: "red"}} aria-hidden="true"></i><div>{text}</div></a>
const KEY_ATTRIBUTES = {
		legalStatus: "legal_status",
		area: "area",
		noBathRooms: "no_baths",
		noBedRooms: "no_beds",
		furniture: "furniture",
		noFloors: "no_floors",
		homeDirection: "home_direction",
		balconyDirection: "balcony_direction"
	}
class PostDetails extends Component {
	constructor(props) {
		super(props)
		const values = queryString.parse(this.props.location.search)
		let src = null
		if (values.src) {
			src = values.src
		}
		let ids = []
		let idx = -1

		if (values.ids) {
			ids = values.ids.split(',').map(item => item)
			idx = ids.findIndex(id => id === this.props.match.params.id)
		}
		this.state = {
			postId: null,
			isFavourite: false,
			zoom: 11,
			lat: 10.82302,
			lon: 106.62965,
			isLoading: false,
			isError: false,
			errors: null,
			details: null,
			reportTypes: [],
			shareModalShow: false,
			shareUrlCopied: false,
			reportModalShow: false,
			isFullscreen: false,
			report: {
				captchaCode: '',
				typeId: -1,
				content: '',
				name: '',
				phone: '',
				email: '',
				secret: '',
				token: '',
			},
			reportErrors: {
				type: '',
				content: '',
				name: '',
				phone:'',
				email: '',
				secret: ''
			},
			src: src,
			ids: ids,
			currentIdx: idx
		}
		this.addToFavourites = this.addToFavourites.bind(this)
		this.handleShareAction = this.handleShareAction.bind(this)
		this.handleReportAction = this.handleReportAction.bind(this)
		this.handleCloseShareModal = this.handleCloseShareModal.bind(this)
		this.handleCloseReportModal = this.handleCloseReportModal.bind(this)
		this.handleSendReportModal = this.handleSendReportModal.bind(this)
		this.refreshCaptcha = this.refreshCaptcha.bind(this)
		this.changeReportTypes = this.changeReportTypes.bind(this)
		this.handleReportContent = this.handleReportContent.bind(this)
		this.handleReportContactName = this.handleReportContactName.bind(this)
		this.handleReportContactEmail = this.handleReportContactEmail.bind(this)
		this.handleReportContactPhone = this.handleReportContactPhone.bind(this)
		this.handleCaptchaCode = this.handleCaptchaCode.bind(this)
	}
	componentDidMount() {
		this.setState({ details: null, isLoading: true, isError: false, errors: null, postId: null, isFavourite:false})
		postServices.getById(this.props.match.params.id)
		.then(response => {
			if (response.status === 'OK')
			{
				if (!response.data.lat || !response.date.lon) {
					mapServices.getLatLonFromAddress(response.data.address).
					then (response => {
						if (response.status === "OK" && response.results.length > 0) {
							let lat = response.results[0].geometry.location.lat
							let lon = response.results[0].geometry.location.lng
							this.setState({lat: lat, lon: lon})
						}
					})
					.catch(error => this.setState({lat: 10.82302,lon: 106.62965}))
					//this.setState({lat: 10.82302,lon: 106.62965})
				}
				let ok = postLocalStorages.checkFavourite(response.data.id)
				this.setState({ details: response.data, isLoading: false, isError: false, errors: null, postId: this.props.match.params.id, isFavourite: ok})
			}else{
				//Not Found
				this.setState({ details: null, isLoading: false, isError: false, errors: response.errors, postId: null})
				this.props.history.push('/error/not-found')
			}
		})
		.catch(error => this.setState({details: null, errors: error, isLoading: false, isError: true, postId: null}))

		coreServices.fetchAllReportTypes()
		.then (response => {
			this.setState({reportTypes: response.data})
		})
		.catch(error => this.setState({reportTypes: []}))
		window.scrollTo(0,0)
		///Fetch Report Types
	}
	handleShareAction () {
		this.setState({shareModalShow: true})
	}
	handleCloseShareModal () {
		this.setState({shareModalShow: false, shareUrlCopied:false})
	}
	handleReportAction () {
		let captchaCode = generateCaptchaSecret(6).join('')
		let token = generateUUID()
		let {report} = this.state
		report.captchaCode = captchaCode
		report.token = token 
		this.setState({reportModalShow: true, report})
	}
	_resetReportForm () {
		let report = {
				typeId: -1,
				content: '',
				name: '',
				phone: '',
				email: '',
				secret: '',
				token: '',
		}
		this.setState({report})
	}
	_resetReportErrors () {
		let {reportErrors} = this.state
		reportErrors.type = ''
		reportErrors.content = ''
		reportErrors.name = ''
		reportErrors.phone = ''
		reportErrors.email = ''
		reportErrors.secret = ''
		this.setState({reportErrors})
	}

	_validateReportForm() {
		let {reportErrors, report} = this.state
		let hasError = false
		if (report.typeId === -1) {
			hasError = true
			reportErrors.type = this.props.t('required_field')
		}
		if (isEmpty(report.content)){
			hasError = true
			reportErrors.content = this.props.t('required_field')
		} else {
			if (report.content.length > constrainst.MAX_LEN_CONTENT) {
				hasError = true
				reportErrors.name = this.props.t('too_long_max_len') + constrainst.MAX_LEN_CONTENT
			}
		}
		if (isEmpty(report.email) === false) {
			if (!isEmail(report.email)) {
				hasError = true
				reportErrors.email = this.props.t('email_incorrect')
			}
		} else {
			reportErrors.email = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(report.phone) === false) {
			if (!isPhone(report.phone)) {
				hasError = true
				reportErrors.phone = this.props.t('phone_incorrect')
			}
		} else {
			reportErrors.phone = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(report.name) === false) {
			if (report.name.length > constrainst.MAX_LEN_NAME) {
				reportErrors.name = this.props.t('too_loong_max_len') + constrainst.MAX_LEN_NAME
				hasError = true
			}
		} else {
			reportErrors.name = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(report.secret)) {
			reportErrors.secret = this.props.t('required_field')
			hasError = true
		} else {
			if (report.secret !== report.captchaCode) {
				reportErrors.secret = this.props.t('captcha_incorrect')
				hasError = true
			}
		}
		this.setState({reportErrors})
		return hasError
	}
	_processReportErrors(resErrors) {
		let {reportErrors} = this.state
		if (resErrors[errors.ERROR_CODE_POST_REPORT_TYPE_ID_NOT_NULL]) {
			reportErrors.type = this.props.t('required_field')
		}
		if (resErrors[errors.ERROR_CODE_CONTENT_NOT_EMPTY]) {
			reportErrors.content = this.props.t('required_field')
		}
		if (resErrors[errors.ERROR_CODE_CONTENT_TOO_LONG]) {
			reportErrors.content = this.props.t('too_long_max_len') +constrainst.MAX_LEN_CONTENT
		}
		if (resErrors[errors.ERROR_CODE_NAME_NOT_EMPTY]) {
			reportErrors.name = this.props.t('required_field')
		}
		if (resErrors[errors.ERROR_CODE_NAME_TOO_LONG]) {
			reportErrors.name = this.props.t('too_long_max_len') + constrainst.MAX_LEN_NAME
		}
		if (resErrors[errors.ERROR_CODE_EMAIL_NOT_EMPTY]) {
			reportErrors.email = this.props.t('required_field')
		}
		if (resErrors[errors.ERROR_CODE_EMAIL_INCORRECT]) {
			reportErrors.email = this.props.t('email_incorrect')
		}
		if (resErrors[errors.ERROR_CODE_PHONE_NOT_EMPTY]) {
			reportErrors.phone = this.props.t('required_field')
		}
		if (resErrors[errors.ERROR_CODE_PHONE_INCORRECT]) {
			reportErrors.phone = this.props.t('phone_incorrect')
		}
		this.setState({reportErrors})
	}
	handleCloseReportModal () {
		this._resetReportForm()
		this._resetReportErrors()
		this.setState({
			reportModalShow: false})
  	}
  	handleSendReportModal () {
  		this._resetReportErrors()
  		let hasError = this._validateReportForm()
		if (hasError === false) {
			let reqData = {
				postId: this.state.details.id,
				typeId: this.state.report.typeId,
				description: this.state.report.content,
				contactName: this.state.report.name,
				contactPhone: this.state.report.phone,
				contactEmail: this.state.report.email,
				token: this.state.report.token,
				secret: this.state.report.secret
			}
			postServices.createReport(reqData)
			.then (response => {
				if (response.status === "OK") {
					this.props.alert.show(this.props.t('msg_send_report_success'), { position: positions.BOTTOM_RIGHT })
					this._resetReportForm()
					this.setState({reportModalShow: false})
				} else {
					this._processReportErrors(response.errors)
				}
			})
			.catch(error => {
				this.props.alert.show(this.props.t('msg_send_report_failure'),{ position: positions.BOTTOM_RIGHT })
			})
		}
	}
	handleReportContent (event) {
		let {report} = this.state
  		report.content = event.target.value
  		this.setState({report})
	}
	handleReportContactName (event) {
		let {report} = this.state
  		report.name = event.target.value
  		this.setState({report})
	}
	handleReportContactPhone (event) {
		let {report} = this.state
  		report.phone = event.target.value
  		this.setState({report})
	}
	handleReportContactEmail (event) {
		let {report} = this.state
  		report.email = event.target.value
  		this.setState({report})
	}
	handleCaptchaCode(event) {
		let {report} = this.state
  		report.secret = event.target.value
  		this.setState({report})
	}
	refreshCaptcha (captcha) {
		//Generate captcha
		//let secret = generateCaptchaSecret(6).join('')
		//Generate Token
		let token = generateUUID()
		let {report} = this.state
		report.token = token
		report.captchaCode = captcha
  		this.setState({report})
	}
	changeReportTypes(item) {
  		if (item) {
  			let {report} = this.state
  			report.typeId = item
  			this.setState({report})
  		}
  	}
	_renderThumbInner(item) {
		return (
			<div className="image-gallery-thumbnail-inner">
				<img className="post-details__image-gallery-thumbnail-image" src={item.thumbnail} loading="lazy"/>
			</div>
			)
	}
	_onScreenChange(fullScreenElement) {
    	this.setState({isFullscreen:!!fullScreenElement})
  	}
	_renderImage(item) {
		if (this.state.isFullscreen === false) {
			return (
			<div>
				<img className="post-details__image-gallery-image" src={item.original} loading="lazy"/>
			</div>
			)
		} else {
			return (
			<div>
				<img className="image-gallery-image" src={item.original} loading="lazy"/>
			</div>
			)
		}
		
	}
	_renderHeader() {
		let details = this.state.details
		if (!details) {
			return (<Helmet>
			</Helmet>)
		}
		return  (<Helmet>
					{/*facebook*/}
					<title> {details.title}</title>
					<meta property="og:title" content={details.title} />
					<meta property="og:description" content={details.title}/>
					<meta property="og:image" content={details.imageUrl?details.imageUrl: NO_IMAGE_GRAY}/>
					<meta property="og:url" content={'https://topreal.vn/listings/' + this.state.id}/>
					{/*Twtiter*/}
					<meta name="twitter:title" content={details.title}/>
					<meta name="twitter:description" content={details.title}/>
					<meta name="twitter:image" content={details.imageUrl?details.imageUrl: NO_IMAGE_GRAY}/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>)
	}
	_renderPostAttributes() {
		let details = this.state.details
		let attItem = null
		
			let items = []
			if (details.project) {
				if (details.project.name) {
					items.push(<li className="post-details__item d-flex"><span className="post-details-item--cat mr-2">{this.props.t('project') + ":"}</span><p className="font-weight-bold">{details.project.name}</p></li>)
				}
			}
			if (details.priceText) {
				items.push(<li className="post-details__item d-flex"><span className="post-details-item--cat mr-2">{this.props.t('price') + ":"}</span><p className="font-weight-bold">{details.priceText}</p></li>)
			}
			if (details.areaText) {
				items.push(<li className="post-details__item d-flex"><span className="post-details-item--cat mr-2">{this.props.t('area') + ":"}</span><p className="font-weight-bold">{details.areaText}</p></li>)
			}
			if (details.attributes && Object.keys(details.attributes).length > 0) {
				for (let propKey in KEY_ATTRIBUTES) {
					if (details.attributes[propKey]) {
						items.push(<li className="post-details__item d-flex"><span className="post-details-item--cat mr-2">{this.props.t(KEY_ATTRIBUTES[propKey]) + ":"}</span><p className="font-weight-bold">{details.attributes[propKey]}</p></li>)
					}
				}
			}

		attItem = <ul className="post-details__list">{items}</ul> 
		return attItem
	}
	_computePublishedAt(){
		let date = this.state.details.publishedAt
		if (!date) {
			return this.props.t('published_at_today')
		}
		//2019-03-17T07:00:00.000Z
		let publishedAt = moment(date,moment.ISO_8601)
		let now = moment()
		let diff = now.diff(publishedAt, 'days')
		if (diff < 1)
		{
			return this.props.t('published_at_today')
		}
		if (diff == 1) {
			return this.props.t('published_at_1_day_ago')
		}
		if (diff <= 2)
			return this.props.t('published_at_2_days_ago')
		if (diff <= 3) {
			return this.props.t('published_at_3_days_ago')
		}
		if (diff == 7)
			return this.props.t('published_at_7_days_ago')
		if (diff >= 30 && diff <=32) 
			return this.props.t('published_at_month_ago')
		return publishedAt.format('DD/MM/YYYY')
	}
	_renderShareModal() {
		const {t} = this.props
		if (!this.state.details) {
			return <></>
		}
		return (
		<Modal size={"lg"} scrollable="true" show={this.state.shareModalShow} onHide={this.handleCloseShareModal}>
			<Modal.Header closeButton>
				<Modal.Title>{t('share_post')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="form-group row">
					<div className="col-sm-8">
						<input className="form-control" value={'https://topreal.vn/listings/' + this.props.match.params.id} disabled/>
						{this.state.shareUrlCopied === true && <small id="titleHelp" className="form-text text-success">{t('share_copied_url')}</small>}
					</div>
					<div className="col-sm-4">
						<CopyToClipboard text={'https://topreal.vn/listings/' + this.props.match.params.id} onCopy={() => this.setState({shareUrlCopied: true})}>
							<button className={'form-control btn btn-info d-flex'}><i className="far fa-clipboard"></i>{' ' + t('copy')}</button>
	  					</CopyToClipboard>
	  				</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-2">
						<FacebookShareButton url={'https://topreal.vn/listings/' + this.props.match.params.id} quote={'TopReal - ' + this.state.details.title}>
							<FacebookIcon size={48} round={true} />
						</FacebookShareButton>
					</div>
					<div className="col-sm-2">
						<TwitterShareButton url={'https://topreal.vn/listings/' + this.props.match.params.id} quote={'TopReal - ' + this.state.details.title}>
							<TwitterIcon size={48} round={true} />
						</TwitterShareButton>													
					</div>
				</div>
          </Modal.Body>
        </Modal>
        )
	}
	_renderReportModal() {
		const{t} = this.props
		return (
			<Modal size={"lg"} scrollable="true" show={this.state.reportModalShow} onHide={this.handleCloseReportModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t('report_post')}</Modal.Title>
					</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<label htmlFor="contactName" className="col-sm-3 col-form-label d-flex">{t('title')}<span>(*)</span></label>
						<div className="col-sm-9">
							<Select onChange={this.changeReportTypes} classes={"form-control form-control__bg--gray"} selected={this.state.report.typeId} items={[{id:-1, name:"Chọn chủ đề"}, ...this.state.reportTypes]}/>
		  					{this.state.reportErrors.type.length > 0 && <small id="titleHelp" className="form-text text-danger">{this.state.reportErrors.type}</small>}
		  				</div>
					</div>
					<div className="form-group row">
						<label className="col-sm-3 col-form-label d-flex">{t('content')}<span className="post_editor_require_field"> (*) </span></label>
						<div className="col-sm-9">
							<textarea required="required" value={this.state.report.content} onChange={this.handleReportContent} className="form-control form-control__bg--gray" name="message" placeholder={t('content')} required="required" data-error="Please, write us a message."></textarea>
							{this.state.reportErrors.content.length > 0 && <small id="titleHelp" className="form-text text-danger">{this.state.reportErrors.content}</small>}
						</div>
					</div>
					<hr/>
					<div className="form-group row">
						<label className="col-sm-3 col-form-label d-flex">{t('fullname')} <span className="post_editor_require_field"> (*) </span></label>
						<div className="col-sm-9">
							<input required="required" type="input" value={this.state.report.name} onChange={this.handleReportContactName} className="form-control form-control__bg--gray" id="contactName" aria-describedby="emailHelp" placeholder={t('fullname')}/>
							{this.state.reportErrors.name.length > 0 &&<small id="titleHelp" className="form-text text-danger">{this.state.reportErrors.name}</small>}
						</div>
					</div>
					<div className="form-group row">
						<label className="col-sm-3 col-form-label d-flex">{t('contact_phone')} <span className="post_editor_require_field"> (*) </span></label>
						<div className="col-sm-9">
							<input required="required" type="input" value={this.state.report.phone} onChange={this.handleReportContactPhone} className="form-control form-control__bg--gray" id="contactName" aria-describedby="emailHelp" placeholder={t('contact_phone')}/>
							{this.state.reportErrors.phone.length > 0 && <small id="titleHelp" className="form-text text-danger">{this.state.reportErrors.phone}</small>}
						</div>
					</div>
					<div className="form-group row">
						<label className="col-sm-3 col-form-labe d-flex">{t('email')} <span> (*) </span></label>
						<div className="col-sm-9">
							<input required="required" data-error="Valid email is required." type="email" value={this.state.report.email} onChange={this.handleReportContactEmail} className="form-control form-control__bg--gray" id="contactName" aria-describedby="emailHelp" placeholder={t('email')}/>
							{this.state.reportErrors.email.length > 0 && <small id="titleHelp" className="form-text text-danger">{this.state.reportErrors.email}</small>}
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="contactName" className="col-sm-3 col-form-label d-flex">{t('captcha_code')} <span> (*) </span></label>
						<div className="col-sm-4">
							<input required="required" type="input" value={this.state.report.secret} onChange={this.handleCaptchaCode} className="form-control form-control__bg--gray" aria-describedby="emailHelp" placeholder={t('captcha_code')}/>
							{this.state.reportErrors.secret.length > 0 &&  <small id="titleHelp" className="form-text text-danger">{this.state.reportErrors.secret}</small>}
						</div>
						<div className="col-sm-5">
							<VisionCaptcha width={110} height={38} secret={this.state.report.captchaCode} refresh={this.refreshCaptcha}/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleCloseReportModal}>{t('discard')}</Button>
					<Button variant="primary" onClick={this.handleSendReportModal}>{t('send')}</Button>
				</Modal.Footer>
	        </Modal>
		)
	}
	addToFavourites(event) {
		if (this.state.details) {
			if (postServices.addToFavourites(this.state.details.id)) {
				this.setState({isFavourite:true})
				this.props.alert.show(this.props.t('msg_add_favourite_success'))
			}
		}
	}
	_hasNext() {
		if (this.state.currentIdx === -1) {
			return false
		}
		if (this.state.currentIdx + 1 < this.state.ids.length)
		{
			return true
		}
		return false
	}
	_fetchById(id) {
		this.setState({ details: null, isLoading: true, isError: false, errors: null, postId: null, isFavourite:false})
		postServices.getById(id)
		.then(response => {
			if (response.status === 'OK')
			{
				if (!response.data.lat || !response.date.lon) {
					mapServices.getLatLonFromAddress(response.data.address).
					then (response => {
						if (response.status === "OK" && response.results.length > 0) {
							let lat = response.results[0].geometry.location.lat
							let lon = response.results[0].geometry.location.lng
							this.setState({lat: lat, lon: lon})
						}
					})
					.catch(error => this.setState({lat: 10.82302,lon: 106.62965}))
					//this.setState({lat: 10.82302,lon: 106.62965})
				}
				let ok = postLocalStorages.checkFavourite(response.data.id)
				this.setState({ details: response.data, isLoading: false, isError: false, errors: null, postId: this.props.match.params.id, isFavourite: ok})
			}else{
				//Not Found
				this.setState({ details: null, isLoading: false, isError: false, errors: response.errors, postId: null})
				this.props.history.push('/error/not-found')
			}
		})
		.catch(error => this.setState({details: null, errors: error, isLoading: false, isError: true, postId: null}))
	}
	_hasPrev() {
		if (this.state.currentIdx === -1) {
			return false
		}
		if (this.state.currentIdx - 1 >= 0) {
			return true
		}
		return false
	}
	handleNext = (e) => {
		e.preventDefault()
		
		let {currentIdx} = this.state
		if (currentIdx + 1 >= this.state.ids.length) {
			return
		}
		currentIdx = currentIdx + 1
		this.setState({currentIdx})
		this._fetchById(this.state.ids[currentIdx])
		
	}
	handlePrev = (e) => {
		e.preventDefault()
		let {currentIdx} = this.state
		if (currentIdx - 1 < 0) {
			return
		}
		currentIdx = currentIdx - 1
		this.setState({currentIdx})
		this._fetchById(this.state.ids[currentIdx])
	}
	render () {
		const {t} = this.props
		const header = this._renderHeader()
		let address = null
		let images = []
		let noBedRooms = null
		let noBathRooms = null
		let area = null
		let price = null
		let attributes = null
		let contactMobilePhone = null
		let contactFingerprint = null
		let contactName = null
		let publishedAt = null
		let telNow = null
		let shareModal = this._renderShareModal()
		let reportModal = this._renderReportModal()
		let ref = null
		let favourite = <i className="far fa-heart"></i>
		if (this.props.match.params.id) {
			ref = this.props.match.params.id
		}
		if (this.state.details) {
			if (this.state.details.address) {
				address = this.state.details.address
			}
			if (this.state.details.imageList) {
				for (let i = 0;i < this.state.details.imageList.length;i++) {
					images.push(
						{
							original: this.state.details.imageList[i], 
							thumbnail: this.state.details.thumbnails[i], 
							renderThumbInner: this._renderThumbInner.bind(this),
							renderItem: this._renderImage.bind(this)
						})
				}
			}
			if (this.state.details.attributes) {
				if (this.state.details.attributes.noBedRooms) {
					noBedRooms = <div className="mr-2 card-text d-flex" style={{fontSize: "18px", fontWeight:"bold"}}>
								<img className="mr-1" src="/images/bed.svg"/>{this.state.details.attributes.noBedRooms + ' ' +t('room')}
							</div>
				}
				if (this.state.details.attributes["noBathRooms"]) {
					noBathRooms = <div className="mr-2 card-text d-flex" style={{fontSize: "18px", fontWeight:"bold"}}>
								<img className="mr-1" src="/images/shower.svg"/>{this.state.details.attributes["noBathRooms"] + ' ' +t('room')}
							</div>
				}
			}
			if (this.state.details.areaText) {
				area = <div className="mr-2 card-text d-flex" style={{fontSize: "18px", fontWeight:"bold"}}>
								<img className="mr-1" src="/images/sq.svg"/>{this.state.details.areaText}
							</div>
			}
			if (this.state.details.contact) {
				contactMobilePhone = this.state.details.contact.mobilePhone
				contactName = this.state.details.contact.name
				contactFingerprint = this.state.details.contact.contactFingerprint
				telNow = <a className="btn" href={'tel:' + this.state.details.contact.mobilePhone}><i className="fas fa-phone"></i></a>
			}
			attributes = this._renderPostAttributes()
			publishedAt = this._computePublishedAt()
			if (this.state.isFavourite) {
				favourite = <i className="fas fa-heart" style={{color: '#e31e25'}}></i>
			}
			
		}
		let back = "/listings"
		if (this.state.src) {
			back = this.state.src
		}
		let hasNext = this._hasNext()
		let hasPrev = this._hasPrev()
		return (
			<React.Fragment>
				{header}
				<div className="container mt-3 mb-3">
					<div className="row">
						<div className="col-lg-8">
							<div className="row mb-2">
								<div className="col p-0">
									<nav className="navbar p-0">
										<div className="d-flex">
											<a href={back} className="nav-link d-flex" style={{color: "#63ab78"}}><img src={'/images/back.svg'}/>{t('back_to_results')}</a>
										</div>
										<div className="navbar">
											{hasPrev === true && <a className="nav-item mr-2 d-flex" href="#" style={{color: "#222b45"}} onClick={this.handlePrev}><img src={'/images/arrow-outline-left.svg'}/>{t('previous')}</a>}
											{hasNext === true && <a className="nav-item d-flex" href="#" style={{color: "#222b45"}} onClick={this.handleNext}>{t('next')}<img src={'/images/arrow-outline-right.svg'}/></a>}
										</div>
									</nav>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col">
									{images.length > 0 && <ImageGallery items={images} showNav={false} autoPlay={true} onScreenChange={this._onScreenChange.bind(this)}/>}
								</div>
							</div>
							<div className="row">
								<div className="col">
									<div className="row">
										<div className="col-md-8">
											<div className="d-flex">
											{noBedRooms}
											{noBathRooms}
											{area}
											</div>
										</div>
										<div className="col-md-4">
											<div className="post-listings-item__body--price float-right" style={{fontSize: "18px"}}>
												{this.state.details?this.state.details.priceText: t('negotiate')}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-7">
											<h1 style={{fontSize: "18px", fontWeight:"normal"}}>{this.state.details?this.state.details.title:''}</h1>
										</div>
										<div className="col-md-5">
											<div className="row">
												<div className="col">
													<p className="float-right" style={{fontSize: "14px", color: "#7a808f"}}>{t('published_at') + ': ' + publishedAt?publishedAt: ''}</p>
												</div>
											</div>
											<div className="row">
												<div className="col">
													<div className="float-right">
														<button className="btn" alt={t('add_to_favourite')} onClick={this.addToFavourites}>{favourite}</button>
														<button className="btn" onClick={this.handleShareAction}><i className="fa fa-share"></i></button>
														<button className="btn" onClick={this.handleReportAction}><i className="fa fa-bug"></i></button>
														{telNow}
														<CopyToClipboard text={'https://topreal.vn/listings/' + this.props.match.params.id} onCopy={() => {this.setState({shareUrlCopied: true}); this.props.alert.show(this.props.t('copied_to_clipboard'))}}>
															<button className="btn btn-outline-primary"><i className="far fa-clipboard mr-2"></i>{this.state.details?this.state.details.id:''}</button>
														</CopyToClipboard>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col">
									<div className="row mt-2 mb-2">
										<div className="col">
											<hr className="m-0" width="100%"/>
										</div>
									</div>
									<div className="row">
										<div className="col post-details__headline">{t('location')}</div>
									</div>
									
									<div className="row mb-1">
										<div className="col">
											<a href={"https://www.google.com/maps/search/"+address} target="_blank"><address style={{fontSize:"18px", fontWeight: "bold", color:"#222b45"}}>{address}</address></a>
										</div>
									</div>
									<div className="row">
										<div className="col" style={{ height: '35vh', width: '100%' }}>
											<GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyCciBLb8DpANO0ltCE9xJ3K0RktaPXnpcU" }} defaultCenter={{lat: this.state.lat, lng: this.state.lon}} defaultZoom={this.state.zoom}>
												<MapMarker lat={this.state.lat} lng={this.state.lon} text={""} url={"https://www.google.com/maps/search/"+address}/>
											</GoogleMapReact>
								      </div>
									</div>

								</div>
							</div>
							<div className="row mb-2">
								<div className="col">
									<div className="row mt-2 mb-2">
										<div className="col">
											<hr className="m-0" width="100%"/>
										</div>
									</div>
									<div className="row">
										<div className="col post-details__headline">{t('main_info')}</div>
									</div>
									
									<div className="row">
										<div className="col">
											{attributes}
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col">
									<div className="row mt-2 mb-2">
										<div className="col">
											<hr className="m-0" width="100%"/>
										</div>
									</div>
									<div className="row">
										<div className="col post-details__headline">{t('description')}</div>
									</div>
									
									<div className="row">
										<p className="col text-justify">{Parser(this.state.details?this.state.details.description: "")}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<AdvertiserContactBox phone={contactMobilePhone} name={contactName} refRes={"PROPERTY:" + ref}/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							{shareModal}
							{reportModal}
						</div>
					</div>
				</div>
				<PostsByAdvertiser fingerprint={contactFingerprint} phone={contactMobilePhone} name={contactName} deletedIds={[Number(this.props.match.params.id)]}/>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default compose(translate("translations"),withAlert())(PostDetails)