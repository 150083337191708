import React, {Component} from 'react'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"
import SearchBox from '../../containers/projects/SearchBox'
import NewPostListings from '../../components/posts/NewPostListings'
import TopProjects from '../../components/projects/TopProjects'
import AdsBanners from '../../components/projects/AdsBanners'
import ProjectsInCity  from '../../components/projects/ProjectsInCity'
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css"
import "./details_styles.css"
import Carousel from 'react-bootstrap/Carousel'
import NewsLetter from '../../components/NewsLetter'
import projectServices from '../../services/projects'
import queryString from 'query-string'
import withSizes from 'react-sizes'
import compose from 'recompose/compose'

// import ReactCountdownClock from 'react-countdown-clock'
const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
})
class Projects extends Component {
	constructor(props) {
		super(props)
		/*this.state = {
			adsProjects: [
			  {
			  	original: 'https://picsum.photos/id/1018/1000/600/',
			    thumbnail: 'https://picsum.photos/id/1018/250/150/',
			    title: "D-Homme",
			    description: "D-Homme",
			    address: "Ho chi minh",
			    url: "test",
			    renderItem: this._renderAdsItem.bind(this)
			  },
			  {
			    original: 'https://picsum.photos/id/1015/1000/600/',
			    thumbnail: 'https://picsum.photos/id/1015/250/150/',
			    title: "The Metropole Thủ Thiêm",
			    description: "The Metropole Thủ Thiêm",
			    address: "Ho chi minh, Thu thiem",
			    url: "test",
			    renderItem: this._renderAdsItem.bind(this)
			  },
			  {
			    original: 'https://picsum.photos/id/1019/1000/600/',
			    thumbnail: 'https://picsum.photos/id/1019/250/150/',
			    title: "Khu đô thị Cienco 5 Mê Linh",
			    description: "The Metropole Thủ Thiêm",
			    address: "Ho chi minh, Thu thiem",
			    url: "test",
			    renderItem: this._renderAdsItem.bind(this)
			  }
			]
		}*/
	}
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	/*_renderAdsItem(item) {
		return (
			<div className="card border-0">
				<img className="card-img-top border-0 image-gallery-image" src={item.original} />
				{
					this.props.isMobile?
					<div className="card-body" style={{background: "rgba(34, 43, 69, 0.9)"}}>
    					<h3 className="card-title text-left" style={{color: '#fff'}}>{item.title}</h3>
    					<address className="card-text text-left" style={{color: '#9b9b9b'}}>{item.address}</address>
    					<p className="card-text text-left mt-5" style={{color: '#fff'}}>{item.description}</p>
    					<a href={item.url} title={item.title} className="btn btn-info float-left mt-2" style={{backgroundColor: "#febd17", borderColor: "#febd17", color: "#222b45", fontSize: "bold"}}>{this.props.t('view_details')}</a>
  					</div>
					:
					<div className="card-img-overlay image-gallery-ads" style={{right: '0', left: 'initial'}}>
    					<h3 className="card-title text-left">{item.title}</h3>
    					<address className="card-text text-left" style={{color: '#9b9b9b'}}>{item.address}</address>
    					<p className="card-text text-left mt-4">{item.description}</p>
    					<a href={item.url} title={item.title} className="btn btn-info float-left mt-3" style={{backgroundColor: "#febd17", borderColor: "#febd17", color: "#222b45", fontSize: "bold"}}>{this.props.t('view_details')}</a>
  					</div>
				}
				
			</div>
		)
	}*/
	/*
	<div style={{backgroundColor: "#222b45"}}>
							<ImageGallery items={this.state.adsProjects} thumbnailPosition={thumbnailPosition} showPlayButton={false} showBullets={false}
							showNav={false} useBrowserFullscreen={false} showFullscreenButton={false} />
							</div>
	*/
	_renderHeader() {
		return (<Helmet>
			{/*facebook*/}
			<title> {"Trang thông tin dự án bất động sản tại Việt Nam"} </title>
			<meta property="og:title" content="Trang thông tin dự án bất động sản tại Việt Nam" />
			<meta property="og:description" content="Trang thông tin dự án bất động sản tại Việt Nam"/>
			<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://topauto.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Trang thông tin dự án bất động sản tại Việt Nam"/>
			<meta name="twitter:description" content="Trang thông tin dự án bất động sản tại Việt Nam"/>
			<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>)
	}
	render () {
		const {t} = this.props
		const header = this._renderHeader()
		return (
			<React.Fragment>
				{header}
				<div className="container">
					<div className="row m-3">
						<div className="col-md-4">
							<h3>{t('find_projects')}</h3>
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-12">
							<SearchBox />
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<AdsBanners />
						</div>
					</div>
				</div>
				<TopProjects/>
				<ProjectsInCity cityName={"Hồ Chí Minh"} cityId={51}/>
				<ProjectsInCity cityName={"Hà Nội"} cityId={2}/>
				<NewPostListings cityName={"Hồ Chí Minh"} cityId={51} />
				<NewPostListings cityName={"Hà Nội"} cityId={2}/>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default compose(translate("translations"),withSizes(mapSizesToProps))(Projects)
