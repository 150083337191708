import React from 'react'
import { translate } from 'react-i18next'
import PostsByAdvertiserListItem from './PostsByAdvertiserListItem'
const PostsByAdvertiserList = ({t, items, src, ids}) => (
	<div className="container" style={{backgroundColor: "#f5f5f5"}}>
		<div className="row ml-0 mr-0">
		{items.map(item => <PostsByAdvertiserListItem key={item.id} item={item} src={src} ids={ids}/>)}
		</div>
	</div>
)
export default translate("translations")(PostsByAdvertiserList)