import React from 'react'
import { translate } from 'react-i18next'
import {ADS_PACK_COLORS} from '../../configs/configs'

const CompanyListItem = ({t, item}) => (
	<div className="card border-0 col-md-4 col-12" >
		<a href={'/companies/' + item.seoPath+ "/" + item.id}>
		<img className="card-img-top" style={{ height: '200px', borderRadius: '0px', objectFit: "cover"}} src={item.logoUrl} alt={item.name}/>
		</a>
		<div className="card-body">
			<div className="row">
				<a href={'/companies/' + item.seoPath+ "/" + item.id} className="col-12 card-title" style={{ fontWeight: "600", color:ADS_PACK_COLORS[item.adsPack], fontSize: "19px"}}
				title={item.name}>{item.name}</a>
				<div className="col-12 card-text">
					<address style={{fontSize: "14px"}} className="d-flex"><img src="/images/location.svg"/>{item.address}</address>
				</div>
			</div>
		</div>
	</div>
)
export default translate("translations")(CompanyListItem)