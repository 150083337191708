import React from 'react'
import Section from '../../components/Section'
import {Helmet} from "react-helmet"

const NotFound = ({props}) => (
	<main role="main">
		<Helmet>
			{/*facebook*/}
			<title> {"Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"} </title>
			<meta property="og:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam" />
			<meta property="og:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
			<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://topreal.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
			<meta name="twitter:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
			<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>
		<Section title="Page Not Found" subTitle="Please go to home pages."/>
		<section className="section-notfound">
			<div className="container">
				<header role="title-page" className="text-center">
					<h2>The website is underconstruction.</h2>
					<h4></h4>
				</header>
				<div className="clearfix"></div>
			</div>
		</section>
	</main>
)
export default NotFound;