export const SEARCH_METHOD_BUY = 1
export const SEARCH_METHOD_RENT = 2
export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY'
export const DAY_MS = 86400000
export const DEFAUL_NO_IMAGE = 'http://topreal.vn/images/no_image.svg' 
export const SEARCH_METHOD = [
	{
		id: 1,
		name: 'Mua bán'
	},
	{
		id: 2,
		name: 'Cho thuê'
	}
]

export const SEARCH_BUY_PROPERTY_TYPE = [
	{
        name: "Căn Hộ",
        id: 4
    },
    {
        name: "Nhà Riêng",
        id: 5
    },
    {
        name: "Nhà Phố",
        id: 6
    },
    {
        name: "Nhà Biệt Thự, Liền Kề ",
        id: 17
    },
    {
        name: "Đất",
        id: 7
    },
    {
        name: "BĐS Khác",
        id: 8
    }
]
export const SEARCH_RENT_PROPERTY_TYPE = [
 	{
        id: 9,
        name: "Căn Hộ"
    },
    {
        id: 10,
        name: "Nhà Riêng"
    },
    {
        id: 11,
        name: "Nhà Phố"
    },
    {
        id: 12,
        name: "Nhà Biệt Thự"
    },
    {
        id: 13,
        name: "Nhà Trọ, Phòng Trọ"
    },
    {
        id: 14,
        name: "Văn Phòng"
    },
    {
        id: 15,
        name: "Đất, Xưởng, Kho Bãi"
    },
    {
        id: 29,
        name: "Căn Hộ Dịch Vụ"
    },
    {
        id: 30,
        name: "Cửa Hàng, Mặt Bằng Bán Lẻ"
    },
    {
        id: 16,
        name: "BĐS Khác"
    }
]
export const SEARCH_BUY_PRICE_RANGE = [
	{
		id: -1,
		name: 'Bất kỳ'
	},
	{
		id: 1,
		name: '500 Triệu'
	},
	{
		id: 2,
		name: '1 Tỷ'
	},
	{
		id: 3,
		name: '3 Tỷ'
	},
	{
		id: 4,
		name: '5 Tỷ'
	},
	{
		id: 5,
		name: '7 Tỷ'
	},
	{
		id: 6,
		name: '10 Tỷ'
	},
	{
		id: 7,
		name: '15 Tỷ'
	},
	{
		id: 8,
		name: '20 Tỷ'
	},
	{
		id: 9,
		name: '30 Tỷ'
	}
]
export const SEARCH_RENT_PRICE_RANGE = [
	{
		id: 21,
		name: '1 Triệu/tháng'
	},
	{
		id: 22,
		name: '3 Triệu/tháng'
	},
	{
		id: 23,
		name: '5 Triệu/tháng'
	},
	{
		id: 24,
		name: '7 Triệu/tháng'
	},
	{
		id: 25,
		name: '10 Triệu/tháng'
	},
	{
		id: 26,
		name: '15 Triệu/tháng'
	},
	{
		id: 27,
		name: '20 Triệu/tháng'
	},
	{
		id: 28,
		name: '25 Triệu/tháng'
	},
	{
		id: 29,
		name: '30 Triệu/tháng'
	},
	{
		id: 30,
		name: '40 Triệu/tháng'
	},
	{
		id: 31,
		name: '50 Triệu/tháng'
	},
	{
		id: 32,
		name: '70 Triệu/tháng'
	},
	{
		id: 33,
		name: '100 Triệu/tháng'
	}
]

export const SEARCH_AREA_PRICE_RANGE = [
	{
		id: 1,
		name: "70 m2"
	},
	{
		id: 2,
		name: "100 m2"
	},
	{
		id: 3,
		name: "150 m2"
	},
	{
		id: 4,
		name: "200 m2"
	},
	{
		id: 5,
		name: "250 m2"
	}
	,{
		id: 6,
		name: "350 m2"
	}
	,{
		id: 7,
		name: "400 m2"
	},
	{
		id: 8,
		name: "500 m2"
	}

]
export const NO_BED_ROOMS = [
	{
		id: 1,
		name: '1'
	},
	{
		id: 2,
		name: '2'
	},
	{
		id: 3,
		name: '3'
	},
	{
		id: 4,
		name: '4'
	},
	{
		id: 5,
		name: '5+'
	}
]
export const NO_BATH_ROOMS = [
	{
		id: 1,
		name: '1'
	},
	{
		id: 2,
		name: '2'
	},
	{
		id: 3,
		name: '3'
	},
	{
		id: 4,
		name: '4'
	},
	{
		id: 5,
		name: '5+'
	}
]
export const LEGAL_STATUSES =[
	{
		id: 1,
		name: "Sổ đỏ"
	},
	{
		id: 2,
		name: "Sổ hồng"
	},
	{
		id: 3,
		name: "Hợp đồng mua bán"
	},
	{
		id: 4,
		name: "Giấy tờ hợp lệ"
	},
	{
		id: 5,
		name: "Giấy tờ viết tay"
	},
	{
		id: 6,
		name: "Khác"
	}
]
export const HOME_DIRECTIONS =[
	{
		id: 1,
		name: "Đông"
	},
	{
		id: 2,
		name: "Tây"
	},
	{
		id: 3,
		name: "Nam"
	},
	{
		id: 4,
		name: "Bắc"
	},
	{
		id: 5,
		name: "Đông-Bắc"
	},
	{
		id: 6,
		name: "Tây-Bắc"
	},
	{
		id: 7,
		name: "Tây-Nam"
	},
	{
		id: 8,
		name: "Đông-Nam"
	}
]
export const SEARCH_NO_BED_ROOMS = [
	{
		id: 1,
		name: '1'
	},
	{
		id: 2,
		name: '2'
	},
	{
		id: 3,
		name: '3'
	},
	{
		id: 4,
		name: '4'
	},
	{
		id: 5,
		name: '5+'
	}
]
export const SEARCH_NO_BATH_ROOMS = [
	{
		id: 1,
		name: '1'
	},
	{
		id: 2,
		name: '2'
	},
	{
		id: 3,
		name: '3'
	},
	{
		id: 4,
		name: '4'
	},
	{
		id: 5,
		name: '5+'
	}
]
export const AGE_POST_RANGES = [
	{
		name: "Hôm qua",
		id: 1
	},
	{
		name: "3 ngày trước",
		id: 2
	},
	{
		name: "1 tuần trước",
		id: 3
	},
	{
		name: "1 tháng trước",
		id: 4
	}
]
export const POST_SORTS = [
	{
	name: "Top đề xuất",
	id: 1
	},
	{
		name: "Giá: Thấp -> Cao",
		id: 2
	},
	{
		name: "Giá: Cao -> Thấp",
		id: 3
	},
	{
		name: "Ngày đăng: Mới -> Củ",
		id: 4
	},
	{
		name: "Diện tích: Lớn -> Nhỏ",
		id: 5
	},
	{
		name: "Số phòng: Lớn -> Nhỏ",
		id: 6
	}
]
export const CITY_IMAGES = {
	"51": "/images/hochiminh-1.jpg",
	"2": "/images/hanoi-1.jpg",
	"33": "/images/danang-1.jpg",
	"48": "/images/binhduong-1.jpg",
	"49": "/images/dongnai-1.jpg"

}