import React from 'react'
import ContractTemplateList from './ContractTemplateList'
const AsideContractTemplates = ({title,items}) => (
	<div className="container">
		<div className="row border-bottom pb-2">
			<label className="news_aside__title">{title}</label>
		</div>
		<div className="row mt-3">
			<ContractTemplateList items={items}/>
		</div>
	</div>
)
export default AsideContractTemplates