import React, {Component} from 'react'
import { Navbar, NavDropdown, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { withAlert,positions } from 'react-alert'
import { translate } from "react-i18next"
import compose from 'recompose/compose'

import Modal from 'react-bootstrap/Modal'
import VisionCaptcha from '../components/VisionCaptcha'
import {generateUUID} from '../helpers/uuid-utils'
import {generateCaptchaSecret} from '../helpers/captcha-utils'
import {ACCOUNT_URL,APP_DOMAIN} from '../configs/configs'

import languageLocalServices from '../services/local/languages'
import authServices from '../services/auth'
import feedbackServices from '../services/feedbacks'
import {isEmpty,isEmail,isPhone} from '../helpers/string-utils'
import {MAX_LEN_NAME,MAX_LEN_CONTENT} from '../configs/constraints'
import * as errors from '../configs/errors'


///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {
			language: languageLocalServices.readLang(),
			showFeedbackModal: false,
			expanded: false,
			feedback: {
				currentUrl: '',
				subject: '',
				content: '',
				name: '',
				phone: '',
				email: '',
				secret: '',
				token: '',
				captchaCode: ''
			},
			feedbackErrors: {
				currentUrl: '',
				subject: '',
				content: '',
				name: '',
				phone: '',
				email: '',
				secret: '',
				token: '',
				captchaCode: ''	
			}
		}
		this.onChangeVi = this.onChangeVi.bind(this)
		this.onChangeEn = this.onChangeEn.bind(this)
		this.handleLogout = this.handleLogout.bind(this)
		this.handleAddAds = this.handleAddAds.bind(this)

	}
	componentDidMount() {
		
	}
	onToggle = (event) => {
		this.setState({expanded: !this.state.expanded})
	}
	onChangeVi (event) {
		this.setState({language: "vi"})
		this.props.i18n.changeLanguage("vi")
		languageLocalServices.saveLang("vi")
		this.onToggle()
	}
	onChangeEn (event) {
		this.setState({language: "en"})
		this.props.i18n.changeLanguage("en")
		languageLocalServices.saveLang("en")
		this.onToggle()

	}
	handleLogout (event) {
		this.onToggle()
		authServices.logout()
		this.props.history.push('/')
		event.preventDefault()
	}
	handleAddAds (event) {
		//Is Login -> users/add-ads
		this.onToggle()
		event.preventDefault()
		if(authServices.isLoggin() == true){
			this.props.history.push('/users/add-ads')
		} else {
			this.props.history.push('/posts/add-ads')
		}
	}
	handleFeedback = (e) => {
		let {feedback} = this.state
		let captchaCode = generateCaptchaSecret(6).join('')
		let token = generateUUID()
		feedback.captchaCode = captchaCode
		feedback.token = token 
		feedback.currentUrl = APP_DOMAIN+ this.props.location.pathname + this.props.location.search
		this.setState({showFeedbackModal:true, feedback })
	}
	handleCloseFeedbackModal = (e) => {
		this._resetFeedbachForm()
		this._resetFeedbackError()
		this.setState({showFeedbackModal:false})
	}
	handleCaptchaCode = (event) => {
		let {feedback} = this.state
  		feedback.secret = event.target.value
  		this.setState({feedback})
	}
	refreshCaptcha = (captcha) =>{
		//Generate captcha
		//let secret = generateCaptchaSecret(6).join('')
		//Generate Token
		let token = generateUUID()
		let {feedback} = this.state
		feedback.token = token
		feedback.captchaCode = captcha
  		this.setState({feedback})
	}
	handleFeedbackContent = (e) => {
		let {feedback} = this.state
		feedback.content = e.target.value
		 this.setState({feedback})

	}
	handleFeedbackName = (e) => {
		let {feedback} = this.state
		feedback.name = e.target.value
		this.setState({feedback})
	}
	handleFeedbackPhone = (e) => {
		let {feedback} = this.state
		feedback.phone = e.target.value
		this.setState({feedback})

	}
	handleFeedbackEmail = (e) => {
		let {feedback} = this.state
		feedback.email = e.target.value
		this.setState({feedback})

	}
	_resetFeedbachForm() {
		let {feedback} = this.state
		feedback.currentUrl =  ''
		feedback.subject = ''
		feedback.content = ''
		feedback.name = ''
		feedback.phone = ''
		feedback.email = ''
		feedback.secret = ''
		this.setState({feedback})
	}
	_resetFeedbackError() {
		let {feedbackErrors} = this.state
		feedbackErrors.currentUrl = ''
		feedbackErrors.subject = ''
		feedbackErrors.content = ''
		feedbackErrors.name = ''
		feedbackErrors.phone = ''
		feedbackErrors.email =  ''
		feedbackErrors.secret = ''
		feedbackErrors.token= ''
		feedbackErrors.captchaCode= ''	
		this.setState({feedbackErrors})
	}
	_validateFeedbackForm () {
		let {feedbackErrors} = this.state
		let hasError = false
		if (isEmpty(this.state.feedback.currentUrl)) {
			feedbackErrors.currentUrl = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(this.state.feedback.content) === false) {
			if (this.state.feedback.content.length > MAX_LEN_CONTENT) {
				hasError = true
				feedbackErrors.content =  this.props.t('too_long_max_len') + MAX_LEN_CONTENT
			}	
		} else {
			feedbackErrors.content = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(this.state.feedback.name) === false) {
			if (this.state.feedback.name.length > MAX_LEN_NAME) {
				hasError = true
				feedbackErrors.name =  this.props.t('too_long_max_len') + MAX_LEN_NAME
			}
		} else {
			feedbackErrors.name = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(this.state.feedback.email) === false) {
			if (isEmail(this.state.feedback.email) == false) {
				hasError = true
				feedbackErrors.email =  this.props.t('email_incorrect')
			}
		} else {
			feedbackErrors.email = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(this.state.feedback.phone) === false) {
			if (isPhone(this.state.feedback.phone) == false) {
				hasError = true
				feedbackErrors.phone =  this.props.t('phone_incorrect')
			}
		} else {
			feedbackErrors.phone = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(this.state.feedback.secret) === true) {
			feedbackErrors.state = this.props.t('required_field')
			hasError = true
		} else {
			if (this.state.feedback.secret !== this.state.feedback.captchaCode) {
				feedbackErrors.secret = this.props.t('captcha_incorrect')
				hasError = true
			}
		}
		this.setState({feedbackErrors})
		return hasError
	}
	handleSendFeedbackModal = (e) => {
		e.preventDefault()
		let hasError = this._validateFeedbackForm()
		if (hasError === false) {
			let reqData = {
				subject: "Feedback",
				url: this.state.feedback.currentUrl?this.state.feedback.currentUrl:null,
				content: this.state.feedback.content?this.state.feedback.content: null,
				name: this.state.feedback.name?this.state.feedback.name: null,
				phone: this.state.feedback.phone?this.state.feedback.phone: null,
				email: this.state.feedback.email?this.state.feedback.email: null,
				secret: this.state.feedback.secret?this.state.feedback.secret: null,
				token: this.state.feedback.token?this.state.feedback.token: null,
			}
			feedbackServices.sendFeedback(reqData)
			.then (response => {
				if (response.status === "OK") {
					this.props.alert.show(this.props.t('msg_send_feedback_success'), { position: positions.BOTTOM_RIGHT })
					///Reset Datat State
					this._resetFeedbachForm()
					this._resetFeedbackError()
					this.setState({showFeedbackModal:false})


				} else {
					this._processErrors(response.errors)
				}
			})
			.catch(error => {
				this.props.alert.show(this.props.t('msg_send_feedback_failure'),{ position: positions.BOTTOM_RIGHT })
			})
		}
	}
	_processFeedbachError(resErrors) {
		let {feedbackErrors} = this.state
		if (resErrors[errors.ERROR_CODE_CONTENT_NOT_EMPTY]) {
			feedbackErrors.content = this.props.t('required_field')
		}
		if (resErrors[errors.ERROR_CODE_CONTENT_TOO_LONG]) {
			feedbackErrors.content = this.props.t('too_long_max_len') + MAX_LEN_CONTENT
		}
		if (resErrors[errors.ERROR_CODE_NAME_NOT_EMPTY]) {
			feedbackErrors.name = this.props.t('required_field')
		}
		if (resErrors[errors.ERROR_CODE_NAME_TOO_LONG]) {
			feedbackErrors.name = this.props.t('too_long_max_len') + MAX_LEN_NAME
		}
		if (resErrors[errors.ERROR_CODE_EMAIL_NOT_EMPTY]) {
			feedbackErrors.email = this.props.t('required_field')
		}
		if (resErrors[errors.ERROR_CODE_EMAIL_INCORRECT]) {
			feedbackErrors.email = this.props.t('email_incorrect')
		}
		if (resErrors[errors.ERROR_CODE_PHONE_NOT_EMPTY]) {
			feedbackErrors.phone = this.props.t('required_field')
		}
		if (resErrors[errors.ERROR_CODE_PHONE_INCORRECT]) {
			feedbackErrors.phone = this.props.t('phone_incorrect')
		}
		if (resErrors[errors.ERROR_CODE_SECRET_IN_CORRECT]) {
			feedbackErrors.secret = this.props.t('captcha_incorrect')
		}
		this.setState({feedbackErrors})
	}
	_renderFeedbackModal() {
		let {t} = this.props
		return (
			<Modal scrollable="true" show={this.state.showFeedbackModal} onHide={this.handleCloseFeedbackModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t('feedback')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					
					<div className="form-group">
						<label className="form-control-label d-flex">{t('url')}&nbsp;<span>(*) </span></label>
						<input type="input" className="form-control form-control__bg--gray" aria-describedby="emailHelp" placeholder={t('url')} value={this.state.feedback.currentUrl}/>
						{this.state.feedbackErrors.currentUrl.length > 0 &&  <small className="form-text text-danger">{this.state.feedbackErrors.currentUrl}</small>}					
					</div>
					<div className="form-group">
						<label className="form-control-label d-flex">{t('content')}&nbsp;<span> (*) </span></label>
						<textarea className="form-control form-control__bg--gray" aria-describedby="emailHelp" placeholder={t('content')} value={this.state.feedback.content} onChange={this.handleFeedbackContent}/>
						{this.state.feedbackErrors.content.length > 0 &&  <small className="form-text text-danger">{this.state.feedbackErrors.content}</small>}					
											  			
					</div>
					<div className="form-group row">
						<label className="col-sm-4 form-control-label d-flex">{t('fullname')}&nbsp;<span> (*) </span></label>
						<div className="col-sm-8">
						<input type="input" className="form-control form-control__bg--gray" aria-describedby="emailHelp" placeholder={t('name')} value={this.state.feedback.name} onChange={this.handleFeedbackName}/>
						{this.state.feedbackErrors.name.length > 0 &&  <small className="form-text text-danger">{this.state.feedbackErrors.name}</small>}					
						</div>										  			
					</div>
					<div className="form-group row">
						<label className="col-sm-4 form-control-label d-flex">{t('phone')}&nbsp;<span> (*) </span></label>
						<div className="col-sm-8">
						<input type="input" className="form-control form-control__bg--gray" aria-describedby="emailHelp" placeholder={t('phone')} value={this.state.feedback.phone} onChange={this.handleFeedbackPhone}/>
						{this.state.feedbackErrors.phone.length > 0 &&  <small className="form-text text-danger">{this.state.feedbackErrors.phone}</small>}					
						</div>										  			
					</div>
					<div className="form-group row">
						<label className="col-sm-4 form-control-label d-flex">{t('email')}&nbsp;<span> (*) </span></label>
						<div className="col-sm-8">
						<input type="email" className="form-control form-control__bg--gray" aria-describedby="emailHelp" placeholder={t('email')} value={this.state.feedback.email} onChange={this.handleFeedbackEmail}/>
						{this.state.feedbackErrors.email.length > 0 &&  <small className="form-text text-danger">{this.state.feedbackErrors.email}</small>}					
						</div>										  			
					</div>
					<div className="form-group row">
						<label className="col-sm-4 col-form-label d-flex">{t('captcha_code')} <span> (*) </span></label>
						<div className="col-sm-8">
							<input required="required" type="input" value={this.state.feedback.secret} onChange={this.handleCaptchaCode} className="form-control form-control__bg--gray" aria-describedby="emailHelp" placeholder={t('captcha_code')}/>
							{this.state.feedbackErrors.secret.length > 0 &&  <small id="titleHelp" className="form-text text-danger">{this.state.feedbackErrors.secret}</small>}
						</div>
					</div>
					<div className="form-group row">
						<label className="col-sm-4 col-form-label d-flex"></label>
						<div className="col-sm-8">
							<VisionCaptcha width={110} height={38} secret={this.state.feedback.captchaCode} refresh={this.refreshCaptcha}/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" className="float-left" onClick={this.handleCloseFeedbackModal}>{t('cancel')}</Button>
					<Button variant="primary" className="float-right" onClick={this.handleSendFeedbackModal}>{t('send')}</Button>
				</Modal.Footer>
			</Modal>
		)
	}
	render ()
	{
		const {t} = this.props
		let loginNav = null
		let navItems = []
		if (!authServices.isLoggin()) {
			navItems.push(<NavDropdown.Item href={ACCOUNT_URL}><i className="fas fa-sign-in-alt pr-2"></i>{t('login')}</NavDropdown.Item>)
		}/*else {
			navItems.push(<NavDropdown.Item href="/users/profile"><i className="far fa-user pr-2"></i>{t('my_profile')}</NavDropdown.Item>)

		}*/
		navItems.push(<NavDropdown.Item href="/users/my-favourites"><i className="far fa-heart pr-2"></i>{t('my_favourites')}</NavDropdown.Item>)
		navItems.push(<NavDropdown.Item href="/users/my-properties"><i className="fas fa-home pr-2"></i>{t('my_properties')}</NavDropdown.Item>)
		if (authServices.isLoggin()) {
			navItems.push(<NavDropdown.Item onClick={this.handleLogout}><i className="fas fa-sign-out-alt pr-2"></i>{t('logout')}</NavDropdown.Item>)
		}
		let userToken = authServices.getUserProfileFromAuthToken()
		let welcome = t('account')
		if (userToken)
		{
			welcome = "Hi, " + userToken.lna + " " + userToken.fna
		}
		loginNav = <NavDropdown title={welcome} id="collasible-nav-dropdown" className="align-middle" style={{fontSize: '14px'}}>
				  {navItems}
				  </NavDropdown>
		let feedbackModal = this._renderFeedbackModal()
		return (
			<header className="header-area">
				<div style={{ height: "75px"}}>
					<Navbar className="pr-md-5 pl-md-5" collapseOnSelect={false} expanded={this.state.expanded} expand="lg" bg="navbar" variant="dark" fixed="top">
						<Navbar.Brand href="/"><img src="/images/logo.svg" alt="TopReal" width="120"/></Navbar.Brand>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={this.onToggle}/>
						<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto">
							<Nav.Link onClick={this.onToggle} as={Link} to="/">{t('home')}</Nav.Link>
							{/*<Nav.Link as={Link} to="/listings">{t('listings')}</Nav.Link>*/}
							<NavDropdown title={t('buy')} id="collasible-nav-dropdown" className="align-middle">
								<NavDropdown.Item href="/listings?method=1&catId=1">{('Căn hộ')}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=1&catId=2">{'Nhà riêng'}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=1&catId=3">{'Biệt thự, nhà liền kề'}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=1&catId=4">{'Nhà mặt phố'}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=1&catId=5">{'Đất nền dự án'}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=1&catId=6">{'Đất'}</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title={t('rent')} id="collasible-nav-dropdown" className="align-middle">
								<NavDropdown.Item href="/listings?method=2&catId=10">{('Căn hộ')}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=2&catId=11">{'Nhà riêng'}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=2&catId=12">{'Biệt thự, nhà liền kề'}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=2&catId=13">{'Nhà mặt phố'}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=2&catId=14">{'Nhà trọ, phòng trọ'}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=2&catId=15">{'Văn phòng'}</NavDropdown.Item>
								<NavDropdown.Item href="/listings?method=2&catId=17">{'Kho, nhà xưởng, đất'}</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link onClick={this.onToggle} as={Link} to="/projects">{t('projects')}</Nav.Link>
							{/*<Nav.Link as={Link} to="/fundings">{t('investment')}</Nav.Link>*/}
							<Nav.Link onClick={this.onToggle} as={Link} to="/news">{t('news')}</Nav.Link>
							<NavDropdown title={t('support')} id="collasible-nav-dropdown">
								<NavDropdown.Item onClick={this.handleFeedback}>{t('feedback')}</NavDropdown.Item>
								<NavDropdown.Item onClick={this.onToggle} as={Link} to="/helps">{t('help')}</NavDropdown.Item>
								<NavDropdown.Item onClick={this.onToggle} as={Link} to="/laws">{t('laws_lib')}</NavDropdown.Item>
								<NavDropdown.Item onClick={this.onToggle} as={Link} to="/faq">FAQ</NavDropdown.Item>
								<NavDropdown.Item onClick={this.onToggle} as={Link} to="/terms">{t('terms')}</NavDropdown.Item>
								<NavDropdown.Item onClick={this.onToggle} as={Link} to="/privacy">{t('privacy')}</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item onClick={this.onToggle} as={Link} to="/about">{t('about_us')}</NavDropdown.Item>
							</NavDropdown>
						</Nav>
						<Nav>
							<Nav.Item>
								<Button onClick={this.handleAddAds} variant="light" style={{backgroundColor: '#63ab78', borderColor: "#63ab78",   borderRadius: "12px", color: "#222b45" }}><i className="fas fa-plus-circle mr-2"></i>{t('submit_ads')}</Button>
							</Nav.Item>
							{loginNav}
							<NavDropdown title={t(this.state.language)} id="collasible-nav-dropdown" className="align-middle" style={{fontSize: '14px'}}>
								<NavDropdown.Item onClick={this.onChangeVi} className="d-flex"><img src="/images/vi_flag.svg" with="30" height="30" className="d-inline-block align-top mr-2" alt="Vietnamese"/><div>{t('vietnamese')}</div></NavDropdown.Item>
								<NavDropdown.Item onClick={this.onChangeEn} className="d-flex"><img src="/images/en_flag.svg" with="30" height="30" className="d-inline-block align-top mr-2" alt="Vietnamese"/><div>{t('english')}</div></NavDropdown.Item>
							</NavDropdown>
			    		</Nav>
			  			</Navbar.Collapse>
			  		</Navbar>
		  		</div>
		  		{feedbackModal}
			</header>	
		)
	}
}
export default compose(translate("translations"),withAlert())(withRouter(Header))