import React from 'react'
import { translate } from "react-i18next"
///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
const NoItemListings = ({title,subTitle,goto}) => (
	<div className="container">
		<div className="row">
			<div className="col p-0">
				<div className="jumbotron">
					<h5 className="display-5">{title}</h5>
					<p className="lead">{subTitle}</p>
				</div>
			</div>
		</div>
		
	</div>
)
export default translate("translations")(NoItemListings)