import React, {Component}from 'react'
import { translate } from 'react-i18next'

import AsideNewsList from './AsideNewsList'
import { css } from "@emotion/core"
import Loading from "react-spinners/ClockLoader"
import {ROOT_URL, NEWS_V1} from '../../configs/configs'

//Props title, url
class AsideNews extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null
		}
	}
	componentDidMount() {
		this.setState({ isLoading: true, isError: false, items:[]})
		fetch(ROOT_URL + this.props.api)
			.then(response => response.json())
			.then(data => this.setState({ items: data.data==null?[]:data.data , isLoading: false, isError: false }))
			.catch(error => this.setState({items:[], errors: error, isLoading: false, isError: true }))
	}
	render() {
		return (
			<div className="container">
				<div className="row border-bottom pb-2">
					<label className="news_aside__title">{this.props.title}</label>
				</div>
				<div className="row">
					<Loading css={{display: 'block', margin: '0 auto', borderColor: 'red'} } size={70} color={"#e9eaec"} loading={this.state.isLoading} />
				</div>
				<div className="row mt-2">
					<AsideNewsList items={this.state.items} />
				</div>
			</div>
		)
	}
}
export default translate("translations")(AsideNews)