import * as types from '../actions/action-types'
import {SEARCH_BUY_PRICE_RANGE, SEARCH_RENT_PRICE_RANGE, SEARCH_AREA_PRICE_RANGE,
SEARCH_NO_BED_ROOMS, SEARCH_NO_BATH_ROOMS, SEARCH_METHOD,SEARCH_BUY_PROPERTY_TYPE, AGE_POST_RANGES, POST_SORTS} from '../configs/default-params'
const INITIAL_STATE = {
    cityId: -1,
    districtId: -1,
    wardId: -1,
    propertyTypeId: -1,
    noBedRoomId: -1,
    noBathRoomId: -1,
    priceMaxId: -1,
    priceMinId: -1,
    areaMaxId: -1,
    areaMinId: -1,
    sortId: 1,
    ageId: -1,
    method: 1,
    query: '',
    areaRanges: [],
    priceRanges: [],
    noBathRooms: SEARCH_NO_BATH_ROOMS,
    noBedRooms: SEARCH_NO_BED_ROOMS,
    buyPropertyTypes: [],
    rentPropertyTypes: [],
    sorts: POST_SORTS,
    ageRanges: AGE_POST_RANGES,
    criteria: null,
    postIds: []
}
const postSearchParams = (state=INITIAL_STATE, action) => {

    switch (action.type) {
        case types.CHANGE_SEARCH_METHOD_REQUEST:
            return Object.assign({},state, {method: Number(action.payload)})
        case types.CHANGE_SEARCH_PROPERTY_TYPE_REQUEST:
            return Object.assign({},state, {propertyTypeId: Number(action.payload)})
        case types.CHANGE_SEARCH_CITY_REQUEST:
            return Object.assign({},state, {cityId: Number(action.payload)})
        case types.CHANGE_SEARCH_DISTRICT_REQUEST:
            return Object.assign({},state, {districtId: Number(action.payload)})
        case types.CHANGE_SEARCH_WARD_REQUEST:
            return Object.assign({},state, {wardId: Number(action.payload)})
        case types.CHANGE_SEARCH_AREA_MAX_REQUEST:
            return Object.assign({},state, {areaMaxId: Number(action.payload)})
        case types.CHANGE_SEARCH_AREA_MIN_REQUEST:
            return Object.assign({},state, {areaMinId: Number(action.payload)})
        case types.CHANGE_SEARCH_NO_BED_ROOMS_REQUEST:
            return Object.assign({},state, {noBedRoomId: Number(action.payload)})
        case types.CHANGE_SEARCH_NO_BATH_ROOMS_REQUEST:
            return Object.assign({},state, {noBathRoomId: Number(action.payload)})
        case types.CHANGE_SEARCH_PRICE_MIN_REQUEST:
            return Object.assign({},state, {priceMinId: Number(action.payload)})
        case types.CHANGE_SEARCH_PRICE_MAX_REQUEST:
            return Object.assign({},state, {priceMaxId: Number(action.payload)})
        case types.CHANGE_SEARCH_SORT_REQUEST:
            return Object.assign({},state, {sortId: Number(action.payload)})
        case types.CHANGE_SEARCH_AGE_POST_REQUEST:
            return Object.assign({},state, {ageId: Number(action.payload)})
        case types.CHANGE_POST_SEARCH_QUERY_REQUEST:
            return Object.assign({},state, {query: action.payload})
        case types.FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_REQUEST:
            return Object.assign({},state, {loading: true, priceRanges: [], errors: null, hasError: false})
        case types.FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_SUCCESS:
            return Object.assign({},state, {loading: false, priceRanges: action.payload, errors: null, hasError: false})
         case types.FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_ERROR:
            return Object.assign({},state, {loading: false, priceRanges: SEARCH_BUY_PRICE_RANGE, errors: action.payload, hasError: true})
        case types.FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_SUCCESS:
            return { ...state, priceRanges: action.payload}
        case types.FETCH_SEARCH_PARAMS_AREA_RANGE_SUCCESS:
            return { ...state, areaRanges: action.payload}
        case types.FETCH_SEARCH_PARAMS_AREA_RANGE_ERROR:
            return { ...state, areaRanges: SEARCH_AREA_PRICE_RANGE}
        case types.RESET_SEARCH_PARAMS_PRICE_RANGE:
            return { ...state, priceRanges: action.payload}
        case types.CHANGE_SEARCH_POST_QUERY_REQUEST:
            return { ...state, query: action.payload}
        case types.CHANGE_SEARCH_POST_CRITERIA_REQUEST:
            return { ...state, criteria: action.payload}
        case types.CHANGE_SEARCH_POST_IDS_REQUEST:
            return { ...state, postIds: action.payload}
        default:
            return state
    }
}
export default postSearchParams