import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import AsideSearchBox from '../../containers/posts/AsideSearchBox'
import PostList from '../../components/posts/PostList'
import Pagination from '../../components/Pagination'
import NewsLetter from '../../components/NewsLetter'

import SelectedItems from '../../components/SelectedItems'
import postServices from '../../services/posts'
import queryString from 'query-string'
import {formatNumber} from '../../helpers/number-utils'
import Select from '../../components/Select'
import { css } from "@emotion/core"
import Loading from "react-spinners/ClockLoader"

// import ReactCountdownClock from 'react-countdown-clock'
class Listings extends Component {
	constructor(props) {
		super(props)
		this.state = {
			total: 0,
			currentPage: 1,
			totalPages: 0,
			pageSize: 10,
			items: [],
			isLoading: false,
			isError: false,
			errors: null
		}
		this.changePage = this.changePage.bind(this)
		this.nextPage = this.nextPage.bind(this)
		this.prevPage = this.prevPage.bind(this)
		this.fetchData = this.fetchData.bind(this)
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
		this.handleSearchMoreSubmit = this.handleSearchMoreSubmit.bind(this)
		this.handleChangeSort = this.handleChangeSort.bind(this)
	}
	componentDidMount() {
		this.fetchData()
		window.scrollTo(0, 0)
	}
	fetchData() {
		const values = queryString.parse(this.props.location.search)
		if (values.method) {
			this.props.onChangeMethod(values.method)
		} else {
			this.props.onChangeMethod(this.props.method)
		}
		if (values.catId) {
			this.props.onChangePropertyType(values.catId)
		}
		if (values.cityId) {
			this.props.onChangeCity(values.cityId)
		}
		if (values.districtId) {
			this.props.onChangeDistrict(values.districtId)
		}
		if (values.wardId) {
			this.props.onChangeWard(values.wardId)
		}
		if (values.pMin) {
			this.props.onChangePriceMin(values.pMin)
		}
		if (values.pMax) {
			this.props.onChangePriceMax(values.pMax)
		}
		if (values.aMin) {
			this.props.onChangeAreaMin(values.aMin)
		}
		if (values.aMax) {
			this.props.onChangeAreaMax(values.aMax)
		}
		if (values.q) {
			this.props.changeSearchPostQuery(values.values.q)	
		}
		if (values.noBeds) {
			this.props.onChangeNoBedRooms(values.noBeds)
		}
		if (values.noBaths) {
			this.props.onChangeNoBathRooms(values.noBaths)
		}
		if (values.sort) {
			this.props.changeSearchSort(values.sort)
		}
		if (values.age) {
			this.props.changeSearchAgePost(values.age)
		}
		///Refress Page
		if (!values.page) {
			values.page = this.state.currentPage
		}
		if (!values.size) {
			values.size = this.state.pageSize
		}
		this.setState({isLoading: true})
		postServices.searchPosts(values)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})		
	}
	handleChangeSort(id) {
		this.props.changeSearchSort(id)
		this.handleSearchSubmit()
	}
	handleSearchSubmit(event){
		let criteria = {}
		if (this.props.method) {
			criteria.method = this.props.method
		}
		if (this.props.propertyTypeId && this.props.propertyTypeId != -1) {
			criteria.catId = this.props.propertyTypeId
		}
		if (this.props.cityId && this.props.cityId !== -1) {
			criteria.cityId = this.props.cityId
		}
		if (this.props.priceMinId && this.props.priceMinId != -1) {
			criteria.pMin = this.props.priceMinId
		}
		if (this.props.noBedRoomId && this.props.noBedRoomId != -1) {
			criteria.noBeds = this.props.noBedRoomId
		}
		if (this.props.query) {
			criteria.q = this.props.query
		}
		if (this.props.sortId) {
			criteria.sort = this.props.sortId	
		}
		postServices.searchPosts(criteria)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(criteria).toString()})
		///event.preventDefault()
	}
	handleSearchMoreSubmit(event) {
		let criteria = {}
		if (this.props.method) {
			criteria.method = this.props.method
		}
		if (this.props.propertyTypeId && this.props.propertyTypeId != -1) {
			criteria.catId = this.props.propertyTypeId
		}
		if (this.props.cityId && this.props.cityId !== -1) {
			criteria.cityId = this.props.cityId
		}
		if (this.props.districtId && this.props.districtId !== -1) {
			criteria.districtId = this.props.districtId
		}
		if (this.props.priceMinId && this.props.priceMinId != -1) {
			criteria.pMin = this.props.priceMinId
		}
		if (this.props.priceMaxId && this.props.priceMaxId != -1) {
			criteria.pMax = this.props.priceMaxId
		}
		if (this.props.areaMinId && this.props.areaMinId != -1) {
			criteria.aMin = this.props.areaMinId
		}
		if (this.props.areaMaxId && this.props.areaMaxId != -1) {
			criteria.aMax = this.props.areaMaxId
		}
		if (this.props.noBedRoomId && this.props.noBedRoomId != -1) {
			criteria.noBeds = this.props.noBedRoomId
		}
		if (this.props.noBathRoomId && this.props.noBathRoomId != -1) {
			criteria.noBaths = this.props.noBathRoomId
		}
		if (this.props.ageId && this.props.ageId != -1) {
			criteria.age = this.props.ageId
		}
		if (this.props.query) {
			criteria.q = this.props.query
		}
		if (this.props.sortId) {
			criteria.sort = this.props.sortId	
		}
		this.setState({isLoading: true})
		postServices.searchPosts(criteria)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(criteria).toString()})
		
	}
	nextPage(event) {
		if (this.state.currentPage < this.state.totalPages) {
			this.changePage(this.state.currentPage+1)
		}
	}
	prevPage(event) {
		if (this.state.currentPage > 1) {
			this.changePage(this.state.currentPage-1)
		}
	}
	changePage(page) {
		let criteria = {}
		const values = queryString.parse(this.props.location.search)
		values.page = page
		if (!values.size) {
			values.size = this.state.pageSize
		}
		this.setState({isLoading: true})
		postServices.searchPosts(values)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})
      	window.scrollTo(0, 0)
	}
	renderPaging() {
		if (this.state.total > this.state.pageSize) {
			return <Pagination totalPages = {this.state.totalPages} currentPage={this.state.currentPage} margin={2}
			 goto={this.changePage} next={this.nextPage} prev={this.prevPage}/>
		}
		return null
	}
	prepareHeader() {
		return(<Helmet>
			{/*facebook*/}
			<title> {"Tìm kiếm thông tin dự án bất động sản tại Việt Nam"} </title>
			<meta property="og:title" content="Tìm kiếm thông tin dự án bất động sản tại Việt Nam" />
			<meta property="og:description" content="Tìm kiếm thông tin dự án bất động sản tại Việt Nam"/>
			<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://topreal.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Tìm kiếm thông tin dự án bất động sản tại Việt Nam"/>
			<meta name="twitter:description" content="Tìm kiếm thông tin dự án bất động sản tại Việt Nam"/>
			<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>)
	}
	render () {
		const {t} = this.props
		const header = this.prepareHeader()
		const pagination = this.renderPaging()
		const ids = this.state.items.map(item => item.id)
		const src = this.props.location.pathname + this.props.location.search
		return (
			<React.Fragment>
				{header}
				<div className="container mb-5">
					<div className="row">
						<div className="col-md-4">
							<div className="row m-3 p-0">
								<h3>{t('find_houses')}</h3>
							</div>
							<div className="row m-3">
								<div className="col-12 p-0">
									<AsideSearchBox searchSubmit={this.handleSearchSubmit} searchMoreSubmit={this.handleSearchMoreSubmit}/>
								</div>
							</div>
						</div>
						<div className="col-md-8">
							<div className="row m-3 p-0">
								<div className="col-md-6 p-0">
									<h3 className="text-lowercase">{formatNumber(this.state.total) + ' ' + t('results')}</h3>
								</div>
								<div className="col-md-6">
									<div className="d-flex float-right">
										<label style={{paddingTop: "4px"}}>{t('sort')}</label>
										<div>
											<Select classes={"search_from__select-input--while float-right"} items={[...this.props.sorts]} onChange={this.handleChangeSort}/>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<PostList items={this.state.items} ids={ids} src={src}/>
									<div className="center">
										{pagination}
									</div>
								</div>
							</div>
							<div className="row">
								<Loading css={{display: 'block', margin: '0 auto', borderColor: 'red'}} size={70} color={"#0e1d41"} loading={this.state.isLoading} />
							</div>
						</div>
					</div>
				</div>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Listings)
