
//Fetch featured posts in home page
export const FETCH_FEATURED_POSTS_REQUEST = 'FETCH_FEATURED_POSTS_REQUEST'
export const FETCH_FEATURED_POSTS_SUCCESS = 'FETCH_FEATURED_POSTS_SUCCESS'
export const FETCH_FEATURED_POSTS_LOADING = 'FETCH_FEATURED_POSTS_LOADING'
export const FETCH_FEATURED_POSTS_ERROR = 'FETCH_FEATURED_POSTS_ERROR'

// Async
export const FEEDBACK_SAY_HELLO_REQUEST = 'FEEDBACK_SAY_HELLO_REQUEST'
export const FEEDBACK_SAY_HELLO_SUCCESS = 'FEEDBACK_SAY_HELLO_SUCCESS'
export const FEEDBACK_SAY_HELLO_ERROR = 'FEEDBACK_SAY_HELLO_ERROR'
export const FEEDBACK_SAY_HELLO_ERROR_RESET = 'FEEDBACK_SAY_HELLO_ERROR_RESET'

export const FEEDBACK_SUBSCRIBE_REQUEST = 'FEEDBACK_SAY_SUBSCRIBE_REQUEST'
export const FEEDBACK_SUBSCRIBE_SUCCESS = 'FEEDBACK_SAY_SUBSCRIBE_SUCCESS'
export const FEEDBACK_SUBSCRIBE_ERROR = 'FEEDBACK_SAY_SUBSCRIBE_ERROR'
export const FEEDBACK_SUBSCRIBE_RESET = 'FEEDBACK_SAY_SUBSCRIBE_RESET'

export const FETCH_SEARCH_PARAMS_POST_CATEGORY_REQUEST = 'FETCH_SEARCH_PARAMS_POST_CATEGORY_REQUEST'
export const FETCH_SEARCH_PARAMS_POST_CATEGORY_SUCCESS = 'FETCH_SEARCH_PARAMS_POST_CATEGORY_SUCCESS'
export const FETCH_SEARCH_PARAMS_POST_CATEGORY_LOADING = 'FETCH_SEARCH_PARAMS_POST_CATEGORY_LOADING'
export const FETCH_SEARCH_PARAMS_POST_CATEGORY_ERROR = 'FETCH_SEARCH_PARAMS_POST_CATEGORY_ERROR'

export const FETCH_SEARCH_PARAMS_AREA_RANGE_REQUEST = 'FETCH_SEARCH_PARAMS_AREA_RANGE_REQUEST'
export const FETCH_SEARCH_PARAMS_AREA_RANGE_SUCCESS = 'FETCH_SEARCH_PARAMS_AREA_RANGE_SUCCESS'
export const FETCH_SEARCH_PARAMS_AREA_RANGE_LOADING = 'FETCH_SEARCH_PARAMS_AREA_RANGE_LOADING'
export const FETCH_SEARCH_PARAMS_AREA_RANGE_ERROR = 'FETCH_SEARCH_PARAMS_AREA_RANGE_LOADING'

export const FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_REQUEST = 'FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_REQUEST'
export const FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_SUCCESS = 'FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_SUCCESS'
export const FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_LOADING = 'FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_LOADING'
export const FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_ERROR = 'FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_ERROR'

export const FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_REQUEST = 'FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_REQUEST'
export const FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_SUCCESS = 'FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_SUCCESS'
export const FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_LOADING = 'FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_LOADING'
export const FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_ERROR = 'FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_ERROR'
export const RESET_SEARCH_PARAMS_PRICE_RANGE = 'RESET_SEARCH_PARAMS_PRICE_RANGE'
export const RESET_SEARCH_PARAMS_CITY_ID = 'RESET_SEARCH_PARAMS_CITY_ID'
export const RESET_SEARCH_PARAMS_DISTRICT_ID = 'RESET_SEARCH_PARAMS_DISTRICT_ID'
export const RESET_SEARCH_PARAMS_WARD_ID = 'RESET_SEARCH_PARAMS_WARD_ID'
export const RESET_SEARCH_PARAMS_STREET_ID = 'RESET_SEARCH_PARAMS_STREET_ID'


export const FETCH_CITIES_BY_COUNTRY_REQUEST = 'FETCH_CITIES_BY_COUNTRY_REQUEST'
export const FETCH_CITIES_BY_COUNTRY_SUCCESS = 'FETCH_CITIES_BY_COUNTRY_SUCCESS'
export const FETCH_CITIES_BY_COUNTRY_ERROR = 'FETCH_CITIES_BY_COUNTRY_ERROR'

export const FETCH_CITIES_HOTTEST_MARKET_REQUEST = 'FETCH_CITIES_HOTTEST_MARKET_REQUEST'
export const FETCH_CITIES_HOTTEST_MARKET_SUCCESS = 'FETCH_CITIES_HOTTEST_MARKET_SUCCESS'
export const FETCH_CITIES_HOTTEST_MARKET_ZERO_RESULTS = 'FETCH_CITIES_HOTTEST_MARKET_ZERO_RESULTS'
export const FETCH_CITIES_HOTTEST_MARKET_ERROR = 'FETCH_CITIES_HOTTEST_MARKET_ERROR'

export const FETCH_CITIES_HOTTEST_MARKET_STATS_REQUEST = 'FETCH_CITIES_HOTTEST_MARKET_STATS_REQUEST'
export const FETCH_CITIES_HOTTEST_MARKET_STATS_SUCCESS = 'FETCH_CITIES_HOTTEST_MARKET_STATS_SUCCESS'
export const FETCH_CITIES_HOTTEST_MARKET_STATS_ERROR = 'FETCH_CITIES_HOTTEST_MARKET_STATS_ERROR'

export const FETCH_DISTRICTS_BY_CITY_REQUEST = 'FETCH_DISTRICTS_BY_CITY_REQUEST'
export const FETCH_DISTRICTS_BY_CITY_SUCCESS = 'FETCH_DISTRICTS_BY_CITY_SUCCESS'
export const FETCH_DISTRICTS_BY_CITY_ERROR = 'FETCH_DISTRICTS_BY_CITY_ERROR'

export const FETCH_WARDS_BY_DISTRICT_REQUEST = 'FETCH_WARDS_BY_DISTRICT_REQUEST'
export const FETCH_WARDS_BY_DISTRICT_SUCCESS = 'FETCH_WARDS_BY_DISTRICT_SUCCESS'
export const FETCH_WARDS_BY_DISTRICT_LOADING = 'FETCH_WARDS_BY_DISTRICT_LOADING'
export const FETCH_WARDS_BY_DISTRICT_ERROR = 'FETCH_WARDS_BY_DISTRICT_ERROR'


export const FETCH_STREETS_BY_DISTRICT_REQUEST = 'FETCH_STREETS_BY_DISTRICT_REQUEST'
export const FETCH_STREETS_BY_DISTRICT_SUCCESS = 'FETCH_STREETS_BY_DISTRICT_SUCCESS'
export const FETCH_STREETS_BY_DISTRICT_ERROR = 'FETCH_STREETS_BY_DISTRICT_ERROR'

export const CHANGE_SEARCH_METHOD_REQUEST = 'CHANGE_SEARCH_METHOD_REQUEST'
export const CHANGE_SEARCH_CITY_REQUEST = 'CHANGE_SEARCH_CITY_REQUEST'
export const CHANGE_SEARCH_DISTRICT_REQUEST = 'CHANGE_SEARCH_DISTRICT_REQUEST'
export const CHANGE_SEARCH_WARD_REQUEST = 'CHANGE_SEARCH_WARD_REQUEST'
export const CHANGE_SEARCH_PROPERTY_TYPE_REQUEST = 'CHANGE_SEARCH_PROPERTY_TYPE_REQUEST'
export const CHANGE_SEARCH_NO_BED_ROOMS_REQUEST = 'CHANGE_SEARCH_NO_BED_ROOMS_REQUEST'
export const CHANGE_SEARCH_NO_BATH_ROOMS_REQUEST = 'CHANGE_SEARCH_NO_BATH_ROOMS_REQUEST'
export const CHANGE_SEARCH_PRICE_MAX_REQUEST = 'CHANGE_SEARCH_PRICE_MAX_REQUEST'
export const CHANGE_SEARCH_PRICE_MIN_REQUEST = 'CHANGE_SEARCH_PRICE_MIN_REQUEST'
export const CHANGE_SEARCH_AREA_MAX_REQUEST = 'CHANGE_SEARCH_AREA_MAX_REQUEST'
export const CHANGE_SEARCH_AREA_MIN_REQUEST = 'CHANGE_SEARCH_AREA_MIN_REQUEST'
export const CHANGE_SEARCH_SORT_REQUEST = 'CHANGE_SEARCH_SORT_REQUEST'
export const CHANGE_SEARCH_AGE_POST_REQUEST = 'CHANGE_SEARCH_AGE_POST_REQUEST'
export const CHANGE_SEARCH_POST_QUERY_REQUEST = 'CHANGE_SEARCH_POST_QUERY_REQUEST'
export const CLEAR_SEARCH_POST_REQUEST = 'CLEAR_SEARCH_POST_REQUEST'
export const CHANGE_SEARCH_POST_CRITERIA_REQUEST = 'CHANGE_SEARCH_POST_CRITERIA_REQUEST'
export const CHANGE_SEARCH_POST_IDS_REQUEST = 'CHANGE_SEARCH_POST_IDS_REQUEST'
export const FETCH_ALL_LEGAL_STATUSES_REQUEST = 'FETCH_ALL_LEGAL_STATUSES_REQUEST'
export const FETCH_ALL_LEGAL_STATUSES_SUCCESS = 'FETCH_ALL_LEGAL_STATUSES_SUCCESS'
export const FETCH_ALL_LEGAL_STATUSES_ERROR = 'FETCH_ALL_LEGAL_STATUSES_ERROR'

export const FETCH_ALL_DIRECTIONS_REQUEST = 'FETCH_ALL_DIRECTIONS_REQUEST'
export const FETCH_ALL_DIRECTIONS_SUCCESS = 'FETCH_ALL_DIRECTIONS_SUCCESS'
export const FETCH_ALL_DIRECTIONS_ERROR = 'FETCH_ALL_DIRECTIONS_ERROR'

export const FETCH_ALL_REPORT_TYPES_REQUEST = 'FETCH_ALL_REPORT_TYPES_REQUEST'
export const FETCH_ALL_REPORT_TYPES_SUCCESS = 'FETCH_ALL_REPORT_TYPES_SUCCESS'
export const FETCH_ALL_REPORT_TYPES_ERROR = 'FETCH_ALL_REPORT_TYPES_ERROR'

export const FETCH_PROPERTY_TYPES_BY_PARENT_REQUEST = 'FETCH_PROPERTY_TYPES_BY_PARENT_REQUEST'
export const FETCH_PROPERTY_TYPES_BY_PARENT_SUCCESS = 'FETCH_PROPERTY_TYPES_BY_PARENT_SUCCESS'
export const FETCH_PROPERTY_TYPES_BY_PARENT_LOADING = 'FETCH_PROPERTY_TYPES_BY_PARENT_LOADING'
export const FETCH_PROPERTY_TYPES_BY_PARENT_ERROR = 'FETCH_PROPERTY_TYPES_BY_PARENT_ERROR'

export const FETCH_PROPERTY_CATEGORIES_BY_TYPE_REQUEST = 'FETCH_PROPERTY_CATEGORIES_BY_TYPE_REQUEST'
export const FETCH_PROPERTY_CATEGORIES_BY_TYPE_SUCCESS = 'FETCH_PROPERTY_CATEGORIES_BY_TYPE_SUCCESS'
export const FETCH_PROPERTY_CATEGORIES_BY_TYPE_LOADING = 'FETCH_PROPERTY_CATEGORIES_BY_TYPE_LOADING'
export const FETCH_PROPERTY_CATEGORIES_BY_TYPE_ERROR = 'FETCH_PROPERTY_CATEGORIES_BY_TYPE_ERROR'

export const SEARCH_POSTS_REQUEST = 'SEARCH_POSTS_REQUEST'
export const SEARCH_POSTS_SUCCESS = 'SEARCH_POSTS_SUCCESS'
export const SEARCH_POSTS_LOADING = 'SEARCH_POSTS_LOADING'
export const SEARCH_POSTS_ZERO_RESULTS = 'SEARCH_POSTS_ZERO_RESULTS'
export const SEARCH_POSTS_ERROR = 'SEARCH_POSTS_ERROR'

export const FETCH_POST_DETAILS_REQUEST = 'FETCH_POST_DETAILS_REQUEST'
export const FETCH_POST_DETAILS_SUCCESS = 'FETCH_POST_DETAILS_SUCCESS'
export const FETCH_POST_DETAILS_LOADING = 'FETCH_POST_DETAILS_LOADING'
export const FETCH_POST_DETAILS_ERROR = 'FETCH_POST_DETAILS_ERROR'

export const CREATE_POST_DETAILS_REQUEST = 'CREATE_POST_DETAILS_REQUEST'
export const CREATE_POST_DETAILS_SUCCESS = 'CREATE_POST_DETAILS_SUCCESS'
export const CREATE_POST_DETAILS_ERROR = 'CREATE_POST_DETAILS_ERROR'

export const FETCH_DISTRICTS_BY_CITY_POST_EDITOR_REQUEST = 'FETCH_DISTRICTS_BY_CITY_POST_EDITOR_REQUEST'
export const FETCH_DISTRICTS_BY_CITY_POST_EDITOR_SUCCESS = 'FETCH_DISTRICTS_BY_CITY_POST_EDITOR_SUCCESS'
export const FETCH_DISTRICTS_BY_CITY_POST_EDITOR_ERROR = 'FETCH_DISTRICTS_BY_CITY_POST_EDITOR_ERROR'

export const FETCH_WARDS_BY_DISTRICT_POST_EDITOR_REQUEST = 'FETCH_WARDS_BY_DISTRICT_POST_EDITOR_REQUEST'
export const FETCH_WARDS_BY_DISTRICT_POST_EDITOR_SUCCESS = 'FETCH_WARDS_BY_DISTRICT_POST_EDITOR_SUCCESS'
export const FETCH_WARDS_BY_DISTRICT_POST_EDITOR_ERROR = 'FETCH_WARDS_BY_DISTRICT_POST_EDITOR_ERROR'

export const FETCH_STREETS_BY_DISTRICT_POST_EDITOR_REQUEST = 'FETCH_STREETS_BY_DISTRICT_POST_EDITOR_REQUEST'
export const FETCH_STREETS_BY_DISTRICT_POST_EDITOR_SUCCESS = 'FETCH_STREETS_BY_DISTRICT_POST_EDITOR_SUCCESS'
export const FETCH_STREETS_BY_DISTRICT_POST_EDITOR_ERROR = 'FETCH_STREETS_BY_DISTRICT_POST_EDITOR_ERROR'

export const UPLOAD_IMAGES_POST_EDITOR_REQUEST = 'UPLOAD_IMAGES_POST_EDITOR_REQUEST'
export const UPLOAD_IMAGES_POST_EDITOR_SUCCESS = 'UPLOAD_IMAGES_POST_EDITOR_SUCCESS'
export const UPLOAD_IMAGES_POST_EDITOR_ERROR = 'UPLOAD_IMAGES_POST_EDITOR_ERROR'

export const LIKE_POST_DETAILS_REQUEST = 'LIKE_POST_DETAILS_REQUEST'

export const PUBLISH_FREE_POST_EDITOR_REQUEST = 'PUBLISH_FREE_POST_EDITOR_REQUEST'
export const PUBLISH_FREE_POST_EDITOR_SUCCESS = 'PUBLISH_FREE_POST_EDITOR_SUCCESS'
export const PUBLISH_FREE_POST_EDITOR_ERROR = 'PUBLISH_FREE_POST_EDITOR_ERROR'

export const CHANGE_WARD_POST_EDITOR_REQUEST = 'CHANGE_WARD_POST_EDITOR_REQUEST'
export const CHANGE_STREET_POST_EDITOR_REQUEST = 'CHANGE_STREET_POST_EDITOR_REQUEST'
export const CHANGE_METHOD_POST_EDITOR_REQUEST = 'CHANGE_METHOD_POST_EDITOR_REQUEST'

export const FETCH_PROPERTY_TYPES_BY_TYPE_POST_EDITOR_REQUEST = 'FETCH_PROPERTY_TYPES_BY_TYPE_POST_EDITOR_REQUEST'
export const FETCH_PROPERTY_TYPES_BY_TYPE_POST_EDITOR_SUCCESS = 'FETCH_PROPERTY_TYPES_BY_TYPE_POST_EDITOR_SUCCESS'
export const FETCH_PROPERTY_TYPES_BY_TYPE_POST_EDITOR_ERROR = 'FETCH_PROPERTY_TYPES_BY_TYPE_POST_EDITOR_ERROR'

export const FETCH_UOMS_BY_TYPE_POST_EDITOR_REQUEST = 'FETCH_UOMS_BY_TYPE_POST_EDITOR_REQUEST'
export const FETCH_UOMS_BY_TYPE_POST_EDITOR_SUCCESS = 'FETCH_UOMS_BY_TYPE_POST_EDITOR_SUCCESS'
export const FETCH_UOMS_BY_TYPE_POST_EDITOR_ERROR = 'FETCH_UOMS_BY_TYPE_POST_EDITOR_ERROR'

export const CHANGE_CATEGORY_POST_EDITOR_REQUEST = 'CHANGE_CATEGORY_POST_EDITOR_REQUEST'
export const CHANGE_UOM_POST_EDITOR_REQUEST = 'CHANGE_UOM_POST_EDITOR_REQUEST'

export const FETCH_ALL_NEWS_CATEGORIES_REQUEST = 'FETCH_ALL_NEWS_CATEGORIES_REQUEST'
export const FETCH_ALL_NEWS_CATEGORIES_SUCCESS = 'FETCH_ALL_NEWS_CATEGORIES_SUCCESS'
export const FETCH_ALL_NEWS_CATEGORIES_LOADING = 'FETCH_ALL_NEWS_CATEGORIES_LOADING'
export const FETCH_ALL_NEWS_CATEGORIES_ERROR = 'FETCH_ALL_NEWS_CATEGORIES_ERROR'

export const SEARCH_NEWS_REQUEST = 'SEARCH_NEWS_REQUEST'
export const SEARCH_NEWS_SUCCESS = 'SEARCH_NEWS_SUCCESS'
export const SEARCH_NEWS_LOADING = 'SEARCH_NEWS_LOADING'
export const SEARCH_NEWS_ERROR = 'SEARCH_NEWS_ERROR'
export const SEARCH_NEWS_ZERO_RESULTS = 'SEARCH_NEWS_ZERO_RESULTS'
export const SEARCH_NEWS_CHANGE_QUERY_REQUEST = 'SEARCH_NEWS_CHANGE_QUERY_REQUEST'


export const FETCH_NEWS_LASTEST_REQUEST = 'FETCH_NEWS_LASTEST_REQUEST'
export const FETCH_NEWS_LASTEST_SUCCESS = 'FETCH_NEWS_LASTEST_SUCCESS'
export const FETCH_NEWS_LASTEST_LOADING = 'FETCH_NEWS_LASTEST_LOADING'
export const FETCH_NEWS_LASTEST_ERROR = 'FETCH_NEWS_LASTEST_ERROR'

export const FETCH_NEWS_MOST_VIEW_REQUEST = 'FETCH_NEWS_MOST_VIEW_REQUEST'
export const FETCH_NEWS_MOST_VIEW_SUCCESS = 'FETCH_NEWS_MOST_VIEW_SUCCESS'
export const FETCH_NEWS_MOST_VIEW_LOADING = 'FETCH_NEWS_MOST_VIEW_LOADING'
export const FETCH_NEWS_MOST_VIEW_ERROR = 'FETCH_NEWS_MOST_VIEW_ERROR'

export const FETCH_NEWS_DETAILS_REQUEST = 'FETCH_NEWS_DETAILS_REQUEST'
export const FETCH_NEWS_DETAILS_SUCCESS = 'FETCH_NEWS_DETAILS_SUCCESS'
export const FETCH_NEWS_DETAILS_LOADING = 'FETCH_NEWS_DETAILS_LOADING'
export const FETCH_NEWS_DETAILS_ERROR = 'FETCH_NEWS_DETAILS_ERROR'

export const SEARCH_PROJECTS_REQUEST = 'SEARCH_PROJECTS_REQUEST'
export const SEARCH_PROJECTS_SUCCESS = 'SEARCH_PROJECTS_SUCCESS'
export const SEARCH_PROJECTS_LOADING = 'SEARCH_PROJECTS_LOADING'
export const SEARCH_PROJECTS_ERROR = 'SEARCH_PROJECTS_ERROR'

export const FETCH_PROJECT_DETAILS_REQUEST = 'FETCH_PROJECT_DETAILS_REQUEST'
export const FETCH_PROJECT_DETAILS_SUCCESS = 'FETCH_PROJECT_DETAILS_SUCCESS'
export const FETCH_PROJECT_DETAILS_LOADING = 'FETCH_PROJECT_DETAILS_LOADING'
export const FETCH_PROJECT_DETAILS_ERROR = 'FETCH_PROJECT_DETAILS_ERROR'

export const FETCH_ALL_PROJECT_CATEGORIES_REQUEST = 'FETCH_ALL_PROJECT_CATEGORIES_REQUEST'
export const FETCH_ALL_PROJECT_CATEGORIES_SUCCESS = 'FETCH_ALL_PROJECT_CATEGORIES_SUCCESS'
export const FETCH_ALL_PROJECT_CATEGORIES_ERROR = 'FETCH_ALL_PROJECT_CATEGORIES_ERROR'

export const CHANGE_SEARCH_PROJ_CATEGORY_REQUEST = 'CHANGE_SEARCH_PROJ_CATEGORY_REQUEST'
export const CHANGE_SEARCH_PROJ_CITY_REQUEST = 'CHANGE_SEARCH_PROJ_CITY_REQUEST'
export const CHANGE_SEARCH_PROJ_DISTRICT_REQUEST = 'CHANGE_SEARCH_PROJ_DISTRICT_REQUEST'
export const CHANGE_SEARCH_PROJ_WARD_REQUEST = 'CHANGE_SEARCH_PROJ_WARD_REQUEST'
export const CHANGE_SEARCH_PROJ_PRICE_MAX_REQUEST = 'CHANGE_SEARCH_PROJ_PRICE_MAX_REQUEST'
export const CHANGE_SEARCH_PROJ_PRICE_MIN_REQUEST = 'CHANGE_SEARCH_PROJ_PRICE_MIN_REQUEST'
export const FETCH_PROJ_DISTRICTS_BY_CITY_REQUEST = 'FETCH_PROJ_DISTRICTS_BY_CITY_REQUEST'
export const FETCH_PROJ_DISTRICTS_BY_CITY_SUCCESS = 'FETCH_PROJ_DISTRICTS_BY_CITY_SUCCESS'
export const FETCH_PROJ_DISTRICTS_BY_CITY_ERROR = 'FETCH_PROJ_DISTRICTS_BY_CITY_ERROR'
export const FETCH_PROJ_WARDS_BY_DISTRICT_REQUEST = 'FETCH_PROJ_WARDS_BY_DISTRICT_REQUEST'
export const FETCH_PROJ_WARDS_BY_DISTRICT_SUCCESS = 'FETCH_PROJ_WARDS_BY_DISTRICT_SUCCESS'
export const FETCH_PROJ_WARDS_BY_DISTRICT_ERROR = 'FETCH_PROJ_WARDS_BY_DISTRICT_ERROR'

export const FETCH_PROJECT_BY_ID_REQUEST = 'FETCH_PROJECT_BY_ID_REQUEST'
export const FETCH_PROJECT_BY_ID_SUCCESS = 'FETCH_PROJECT_BY_ID_SUCCESS'
export const FETCH_PROJECT_BY_ID_LOADING = 'FETCH_PROJECT_BY_ID_LOADING'
export const FETCH_PROJECT_BY_ID_ERROR = 'FETCH_PROJECT_BY_ID_ERROR'

export const FETCH_ALL_COMPANY_CATEGORIES_REQUEST = 'FETCH_ALL_COMPANY_CATEGORIES_REQUEST'
export const FETCH_ALL_COMPANY_CATEGORIES_SUCCESS = 'FETCH_ALL_COMPANY_CATEGORIES_SUCCESS'
export const FETCH_ALL_COMPANY_CATEGORIES_ERROR = 'FETCH_ALL_COMPANY_CATEGORIES_ERROR'

export const SEARCH_COMPANIES_REQUEST = 'SEARCH_COMPANIES_REQUEST'
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS'
export const SEARCH_COMPANIES_ERROR = 'SEARCH_COMPANIES_ERROR'
export const SEARCH_COMPANIES_ZERO_RESULTS = 'SEARCH_COMPANIES_ZERO_RESULTS'
export const CHANGE_SEARCH_COMPANY_CATEGORY_REQUEST = 'CHANGE_SEARCH_COMPANY_CATEGORY_REQUEST'
export const CHANGE_SEARCH_COMPANY_CITY_REQUEST = 'CHANGE_SEARCH_COMPANY_CITY_REQUEST'
export const CHANGE_SEARCH_COMPANY_DISTRICT_REQUEST = 'CHANGE_SEARCH_COMPANY_DISTRICT_REQUEST'
export const CHANGE_SEARCH_COMPANY_WARD_REQUEST = 'CHANGE_SEARCH_COMPANY_WARD_REQUEST'

export const FETCH_COMPANY_BY_ID_REQUEST = 'FETCH_COMPANY_BY_ID_REQUEST'
export const FETCH_COMPANY_BY_ID_SUCCESS = 'FETCH_COMPANY_BY_ID_SUCCESS'
export const FETCH_COMPANY_BY_ID_ERROR = 'FETCH_COMPANY_BY_ID_ERROR'

export const CHANGE_CITY_COMPANY_SEARCH_REQUEST = 'CHANGE_CITY_COMPANY_SEARCH_REQUEST'
export const FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_REQUEST = 'FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_REQUEST'
export const FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_SUCCESS = 'FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_SUCCESS'
export const FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_ERROR = 'FETCH_DISTRICTS_BY_CITY_COMPANY_SEARCH_ERROR'
export const FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_REQUEST = 'FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_REQUEST'
export const FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_SUCCESS = 'FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_SUCCESS'
export const FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_ERROR = 'FETCH_WARDS_BY_DISTRICT_COMPANY_SEARCH_ERROR'
export const CHANGE_DISTRICT_COMPANY_SEARCH_REQUEST = 'CHANGE_CITY_COMPANY_SEARCH_REQUEST'
export const CHANGE_WARD_COMPANY_SEARCH_REQUEST = 'CHANGE_WARD_COMPANY_SEARCH_REQUEST'
export const CHANGE_CATEGORY_COMPANY_SEARCH_REQUEST = 'CHANGE_CATEGORY_COMPANY_SEARCH_REQUEST'

export const SEARCH_AGENTS_REQUEST = 'SEARCH_AGENTS_REQUEST'
export const SEARCH_AGENTS_SUCCESS = 'SEARCH_AGENTS_SUCCESS'
export const SEARCH_AGENTS_ERROR = 'SEARCH_AGENTS_ERROR'

export const FETCH_AGENT_BY_ID_REQUEST = 'FETCH_AGENT_BY_ID_REQUEST'
export const FETCH_AGENT_BY_ID_SUCCESS = 'FETCH_AGENT_BY_ID_SUCCESS'
export const FETCH_AGENT_BY_ID_ERROR = 'FETCH_AGENT_BY_ID_ERROR'

export const CREATE_POST_REPORT_REQUEST = 'CREATE_POST_REPORT_REQUEST'
export const CREATE_POST_REPORT_SUCCESS = 'CREATE_POST_REPORT_SUCCESS'
export const CREATE_POST_REPORT_ERROR = 'CREATE_POST_REPORT_ERROR'

export const CHANGE_SEARCH_PARAMS_QUICKLINK_REQUEST = 'CHANGE_SEARCH_PARAMS_QUICKLINK_REQUEST'
export const CHANGE_POST_SEARCH_QUERY_REQUEST = 'CHANGE_POST_SEARCH_QUERY_REQUEST'