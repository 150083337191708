import React, { Component } from 'react'
import { translate, Trans } from "react-i18next";
import {DEFAUL_NO_IMAGE} from '../../configs/default-params'
import { Link } from "react-router-dom"
import moment from "moment"

class UserPostListItem extends Component {
	constructor(props) {
		super(props)
		this.handleRemove = this.handleRemove.bind(this)
		this.handleEdit = this.handleEdit.bind(this)
		this.state = {
			removedStatus: ['DRAFT', 'CANCEL'],
			editedStatus: ['DRAFT']
		}

	}
	handleRemove(event){
		event.preventDefault()
		if (this.props.actionRemove) {
			this.props.actionRemove(this.props.item.id)
		}
	}
	handleEdit(event) {
		event.preventDefault()
		if (this.props.actionEdit) {
			this.props.actionEdit(this.props.item)
		}
	}
	_renderEditButton() {
		let {item} = this.props
		let editBtn = false
		if (this.state.editedStatus.includes(item.status)) {
			editBtn = true
		}
		if (item.status === "CONFIRM") {
			let dateNow = moment(new Date())
			let createdAt = moment(item.createdAt)
			let diff = dateNow.diff(createdAt,'days')
			editBtn = diff >= 2? false: true
		}
		return editBtn
	}
	render (){
		const {t, item} = this.props
		let expiredAt = item.expiredAt
		if (expiredAt) {
			expiredAt = moment(expiredAt,moment.ISO_8601).format("DD/MM/YYYY")
		}
		let removeBtn = false
		if (this.state.removedStatus.includes(item.status)) {
			removeBtn = true
		}
		let editBtn = this._renderEditButton()

		return (
			<div className="card border-0 mb-2 col-12">
				<div className="row">
					<div className="col-md-6 col-12">
						{/*<Link to={'/listings/details/' + item.seoPath+ "/" + item.id}>

							<img className="card-img-top d-flex flex-wrap post-listings-item__image" src={item.imageUrl?item.imageUrl:DEFAUL_NO_IMAGE} alt={item.title}/>
						</Link>*/}
						<a href={'/listings/details/' + item.seoPath+ "/" + item.id}>
							<img className="card-img-top d-flex flex-wrap post-listings-item__image" src={item.imageUrl?item.imageUrl:DEFAUL_NO_IMAGE} alt={item.title}/>
						</a>
					</div>
					<div className="col-md-6 col-12" >
						<div className="card-body border-bottom post-listings-item__body">
							<h3 className="card-title text-truncate" style={{fontSize: "14px", color: "#222b45"}}>{item.title}</h3>
							<div className="row">
								<div className="col">
								<div className="d-flex"> 
								{item.noBedRooms &&<div className="mr-2 card-text d-flex" style={{fontSize: "14px", fontWeight:"bold"}}>
									<img className="mr-1" src="/images/bed.svg"/>{item.noBedRooms + ' ' +t('room')}
								</div>
								}
								{item.noBathRooms && <div className="mr-2 card-text d-flex" style={{fontSize: "14px", fontWeight:"bold"}}>
									<img className="mr-1" src="/images/shower.svg"/>{item.noBathRooms + ' ' +t('room')}
								</div>
								}
								{item.areaText && <div className="card-text d-flex" style={{fontSize: "14px", fontWeight:"bold"}}>
									<img className="mr-1" src="/images/sq.svg"/>{item.areaText}
								</div>}
								</div>
								</div>
							</div>
							<div className="address">
								<address className="text-truncate" style={{fontSize: "14px", color: "#222b45"}}>
									{item.address}
								</address>
							</div>
							<div className="row mb-1">
								<div className="col">
								<Link to={'/listings/details/' + item.seoPath+ "/" + item.id} className="float-left">
									<div className="post-listings-item__body--price">
										{item.priceText}
									</div>
								</Link>
								<time className="float-right"><i className="far fa-calendar-times mr-2"></i>{expiredAt}</time>
								</div>
							</div>
							<div className="row">
								<div className="col">
									{ removeBtn && <a href="#" className="btn btn-outline-primary float-right" onClick={this.handleRemove}><i className="fas fa-times"></i>&nbsp;&nbsp;{t('remove')}</a>}
									{ editBtn && <a href="#" className="btn btn-outline-primary float-right mr-1" onClick={this.handleEdit}><i className="fas fa-edit"></i>&nbsp;&nbsp;{t('edit')}</a>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default translate("translations")(UserPostListItem)