import {ROOT_URL, FEEDBACK_V1} from '../configs/configs'
import authServices from './auth'
export default {
	sendSayHello (reqData) {
		return fetch(ROOT_URL + FEEDBACK_V1.SAY_HELLO,{
			method: 'POST',
			headers: {
            	"Content-Type": "application/json; charset=utf-8",
       		},
			body: JSON.stringify(reqData)
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error
		});
		//return {status: 'OK', errors: null, data: 'Thanks for your attention.'}
	},
	sendSubscribe (email) {
		return fetch(ROOT_URL+FEEDBACK_V1.SUBSCRIBE,{
			method: 'POST',
			headers: {
            	"Content-Type": "application/json; charset=utf-8",
       		},
			body: JSON.stringify(email)
       	}).then(response => {
			return response.json();
		}).catch(error => {
			return error
		});
		//return {status: 'OK', errors: null, data: 'Thanks for your attention.'}
	},
	sendFeedback (reqData) {
		return fetch(ROOT_URL+FEEDBACK_V1.SEND_FEEDBACK,{
			method: 'POST',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				'X-Zetgoo-ClientId': localStorage.getItem('X-Zetgoo-ClientId'),
				"X-Authorization": authServices.headerAuthToken()
			},
			body: JSON.stringify(reqData)
	   	}).then(response => {
			return response.json();
		}).catch(error => {
			return error
		});
	}
	
}