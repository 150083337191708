import React, { Component } from 'react'
import { translate, Trans } from "react-i18next"
import { withRouter } from 'react-router-dom'
import NoItemListings from '../../components/NoItemListings'
import UserPostList from '../posts/UserPostList'
import postLocalStorages from '../../services/local/posts'
import postServices from '../../services/posts'
import authServices from '../../services/auth'
import queryString from 'query-string'
import Pagination from '../../components/Pagination'
import Loading from "react-spinners/ClockLoader"
import localStorageServices from '../../services/local/posts'

class UserPosts extends Component {
	constructor(props) {
		super(props)
		this.state = {
			total: 0,
			currentPage: 1,
			totalPages: 0,
			pageSize: 5,
			items: [],
			isLoading: false,
			isError: false,
			errors: {}
		}
		this.changePage = this.changePage.bind(this)
		this.nextPage = this.nextPage.bind(this)
		this.prevPage = this.prevPage.bind(this)
	}
	componentDidMount() {
		const values = queryString.parse(this.props.location.search)
		if (!values.page) {
			values.page = this.state.currentPage
		}
		if (!values.size) {
			values.size = this.state.pageSize
		}

		if (authServices.isLoggin()) {

		} else {
			let favItems = localStorageServices.getAllPostsByMe()
			let total = favItems.length
			let totalPages = Math.ceil(total/values.size)
			this.setState({total,totalPages,pageSize: values.size, currentPage:values.page})
			this._fetchListPostForAnonymous(values.page,values.size)
		}
	}
	_fetch() {
		this.setState({items: [], total: 0, totalPages: 0, errors: null, isLoading: true, isError: false })
		let postIds = postLocalStorages.getAllPostsByMe().map(item => item.id)
		if (postIds.length > 0) {
			postServices.fetchListItemsByIds(postIds)
			.then (response => {
				if (response.status === 'OK') {
					this.setState({items: response.data, total: 0, totalPages: 0, errors: null, isLoading: false, isError: false })
				} else {
					if (response.status === "ZERO_RESULTS") {
						this.setState({items: [], total: 0, totalPages: 0, errors: null, isLoading: false, isError: false })
					} else {
						this.setState({items: [], total: 0, totalPages: 0, errors: response.errors, isLoading: false, isError: true })
					}
				}

			})
			.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		}
	}
	_fetchPostListForLoggin() {

	}
	_fetchListPostForAnonymous(page, size) {
		this.setState({items: [], errors: {}, isLoading: true, isError: false })
		let favItems = localStorageServices.getAllPostsByMe()
		let pageItems = favItems.slice(size*page - size,size*page)
		let postIds = pageItems.map(item => item.id)
		if (postIds.length > 0) {
			postServices.fetchListItemsByIds(postIds)
			.then (response => {
				if (response.status === 'OK') {
					this.setState({items: response.data, errors: {}, isLoading: false, isError: false })
				} else {
					if (response.status === "ZERO_RESULTS") {
						this.setState({items: [], errors: {}, isLoading: false, isError: false })
					} else {
						this.setState({items: [], errors: response.errors, isLoading: false, isError: true })
					}
				}
			})
			.catch(error => this.setState({items: [], errors: error, isLoading: false, isError: true }))
		}
		window.scroll(0,0)
		
	}
	nextPage(event) {
		if (this.state.currentPage < this.state.totalPages) {
			this.changePage(this.state.currentPage+1)
		}
	}
	prevPage(event) {
		if (this.state.currentPage > 1) {
			this.changePage(this.state.currentPage-1)
		}
	}
	changePage(page) {
		let criteria = {}
		const values = queryString.parse(this.props.location.search)
		values.page = page
		if (!values.size) {
			values.size = this.state.pageSize
		}
		if (authServices.isLoggin()) {

		} else {
			this.setState({currentPage:values.page})
		}
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})
		//window.scrollTo(0, 0)
	}
	removeItem = id => {
		/*let ok = postLocalStorages.removeFavouritePost(id)
		if (ok) {
			this._fetch()
		}*/
	}
	editItem = item => {
		this.props.history.push('/posts/edit-ads?id='+item.id + '&token=' + item.token)
	}
	componentWillReceiveProps(newProps) {
		if (newProps === this.props) 
			return
		if (newProps.location.search !== this.props.location.search) {
			const values = queryString.parse(newProps.location.search)
			if(Object.entries(values).length === 0){
				this.setState({currentPage:1})
			}

		}
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		// If we have a snapshot value, we've just added new items.
		// Adjust scroll so these new items don't push the old ones out of view.
		// (snapshot here is the value returned from getSnapshotBeforeUpdate)
		if (this.props !== prevProps) {
			let values = queryString.parse(this.props.location.search)
			if (!values.page) {
				values.page = 1
			}
			if (!values.size) {
				values.size = this.state.pageSize
			}
			if (authServices.isLoggin()) {

			} else {
				this._fetchListPostForAnonymous(values.page, values.size) 
			}
    	}
	}
	_renderPaging() {
		if (this.state.total > this.state.pageSize) {
			return <Pagination totalPages = {this.state.totalPages} currentPage={this.state.currentPage} margin={2}
			 goto={this.changePage} next={this.nextPage} prev={this.prevPage}/>
		}
		return null
	}
	_renderPostList() {
		const {t} = this.props
		if (this.state.isLoading === true) {
			return null
		}
		if (this.state.items.length > 0 ) {
			return (
				<React.Fragment>
					<UserPostList items={this.state.items} actionRemove={this.removeItem} actionEdit={this.editItem}/>
				</React.Fragment>
			)
		}
		return (
			<React.Fragment>
				 <NoItemListings title={t('my_properties_no_items')}/>
			</React.Fragment>
		)
	}
	render (){
		const {t} = this.props
		const pagination = this._renderPaging()
		const postList = this._renderPostList()

		return (
			<React.Fragment>
				<div className="mb-3">
				<ul className="manage-list manage-list--my-property">
					<li className="manage-list__header">
						<span className="manage-list__title"><i className="far fa-bookmark"></i>&nbsp;&nbsp;{t('property_listings')}</span>
						<span className="manage-list__title"><i className="far fa-calendar-alt"></i>&nbsp;&nbsp;{t('expiration_date')}</span>
					</li>
				</ul>
				<Loading css={{display: 'block', margin: '0 auto', borderColor: 'red'}} size={70} color={"#0e1d41"} loading={this.state.isLoading} />
				{postList}
				{this.state.items.length > 0 && pagination}
				</div>
			</React.Fragment>
		)
	}
}
export default withRouter(translate("translations")(UserPosts))