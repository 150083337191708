import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import SearchBox from '../../components/projects/SearchBox'
import NewPostListings from '../../components/posts/NewPostListings'
import TopProjects from '../../components/projects/TopProjects'
import ProjectsInCity  from '../../components/projects/ProjectsInCity'
import ProjectImageGallery from '../../components/projects/ProjectImageGallery'
import Carousel from 'react-bootstrap/Carousel'
import NewsLetter from '../../components/NewsLetter'
import Breadcrumbs from '../../components/projects/Breadcrumbs'
import Parser from 'html-react-parser'
import projectServices from '../../services/projects'

// import ReactCountdownClock from 'react-countdown-clock'
class ProjectDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			isError: false,
			errors: null,
			details: null
		}
	}
	componentDidMount() {
		window.scrollTo(0, 0) //go to the top
		/// Fetch API to get Post Data
		projectServices.getById(this.props.match.params.id)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ details: response.data, isLoading: false, isError: false, errors: null })
			}else{
				//Not Found
				this.props.history.push('/error/not-found')
			}
		})
		.catch(error => this.setState({details: null, errors: error, isLoading: false, isError: true }))
	}
	renderHeader(details) {
		if (!details) {
			return (
				<Helmet>
					{/*facebook*/}
					<title> {"Thông tin dự án bất động sản tại Việt Nam"} </title>
					<meta property="og:title" content="Thông tin dự án bất động sản tại Việt Nam" />
					<meta property="og:description" content="Thông tin dự án bất động sản tại Việt Nam"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Thông tin dự án bất động sản tại Việt Nam"/>
					<meta name="twitter:description" content="Thông tin dự án bất động sản tại Việt Nam"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
			)
		}
		return (
			<Helmet>
				{/*facebook*/}
				<title> {details.name} </title>
				<meta property="og:title" content={details.name} />
				<meta property="og:description" content={details.name}/>
				<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
				<meta property="og:url" content="http://topreal.vn"/>
				{/*Twtiter*/}
				<meta name="twitter:title" content={details.name}/>
				<meta name="twitter:description" content={details.name}/>
				<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
				<meta name="twitter:card" content="summary_large_image"/>
			</Helmet>
		)

	}
	render () {
		const {t} = this.props
		const header = this.renderHeader(this.state.details)
		let description = null
		let images = []
		let crumbs = []

		if (this.state.details) {
			if (this.state.details.description) {
				description = this.state.details.description
			}
			if (this.state.details.imageList) {
				images = this.state.details.imageList
			}
			crumbs.push({name: this.state.details.name, to:"/news/" + this.state.details.seoPath + "/" + this.state.details.id, active: false})

		}
		return (
			<React.Fragment>
				{header}
				<Breadcrumbs items={crumbs}/>
				<div className="container">
					<div className="row m-0 mb-2">
						<div className="card border-0 col-12 mb-1" >
							<div className="row">
								{/*<img className="card-img border-0 col-md-8 col-12 p-0 m-0" style={{maxHeight: '600px'}} src={"/images/no_image.svg"} alt=""/>*/}
								<Carousel className="card-img border-0 col-md-8 col-12 p-0 m-0">
								{
									images.map((item,idx) => <Carousel.Item key={idx}>
										<img className="d-block w-100 project-details__image" src={item} alt={this.state.details?this.state.details.name:''}/>
										</Carousel.Item>)
								}
									
								</Carousel>
								<div className="card-body col-md-4 col-12 p-0" style={{backgroundColor: '#222b45'}}>
									<h1 className="card-title text-white ml-4 pt-3 pr-4" >{this.state.details?this.state.details.name:''}</h1>
									<address className="card-title ml-4 pr-2" style={{color: '#9b9b9b'}}>{this.state.details?this.state.details.address:''}</address>
									<a href="#" className="btn btn-success ml-4 mb-2">{t('receive_quotation')}</a>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<p className="project-details__description text-justify">{description?Parser(description):description}</p>
						</div>
					</div>
				</div>
				<ProjectsInCity cityName={"Hồ Chí Minh"} cityId={51}/>
				<ProjectsInCity cityName={"Hà Nội"} cityId={2}/>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(ProjectDetails)
