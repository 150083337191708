import {ROOT_URL, CORE_DATA_V1, CITY_V1} from '../configs/configs'
import format from 'string-format'
export default {
	fetchCities (countryId) {
		return fetch(ROOT_URL+format(CORE_DATA_V1.CITIES_BY_COUNTRY,countryId),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              //"Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
       	}).then(response => {
			return response.json();
		}).catch(error => {
			return error
		});
	},
	fetchCitiesByRanking (countryId) {
		return fetch(ROOT_URL+ CITY_V1.GET_RANKING,{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              //"Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
       	}).then(response => {
			return response.json();
		}).catch(error => {
			return error
		});
	},
	fetchDistricts (cityId) 
	{
		return fetch(ROOT_URL+format(CORE_DATA_V1.DISTRICTS_BY_CITY,cityId),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchWards (districtId) 
	{
		return fetch(ROOT_URL+format(CORE_DATA_V1.WARDS_BY_DISTRICT,districtId),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchStreets (districtId) 
	{
		return fetch(ROOT_URL+format(CORE_DATA_V1.STREETS_BY_DISTRICTS,districtId),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchPropertyTypesByParent(parentId)
	{
		return fetch(ROOT_URL+format(CORE_DATA_V1.PROPERTY_TYPES_BY_PARENT,parentId),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchPropertyCategoriesByType(type)
	{
		return fetch(ROOT_URL+format(CORE_DATA_V1.PROPERTY_CATEGORIES_BY_TYPE,type),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchUoMsByType(type)
	{
		return fetch(ROOT_URL+format(CORE_DATA_V1.UOMS_BY_TYPE,type),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchAllLegalStatus()
	{
		return fetch(ROOT_URL+CORE_DATA_V1.ALL_LEGAL_STATUSES,{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchAllReportTypes()
	{
		return fetch(ROOT_URL+CORE_DATA_V1.ALL_REPORT_TYES,{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchBuyPriceRanges () {
		return fetch(ROOT_URL + CORE_DATA_V1.BUY_PRICE_RANGES,{
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				// "Content-Type": "application/x-www-form-urlencoded",
				// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
       	}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchRentPriceRanges () 
	{
		return fetch(ROOT_URL + CORE_DATA_V1.RENT_PRICE_RANGES, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchAreaRanges () 
	{
		return fetch(ROOT_URL + CORE_DATA_V1.AREA_RANGES, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				// "Content-Type": "application/x-www-form-urlencoded",
				// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchAllCallingCodes() {
		return fetch(ROOT_URL + CORE_DATA_V1.GET_ALL_CALLING_CODES, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				// "Content-Type": "application/x-www-form-urlencoded",
				// 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});	
	}	
}