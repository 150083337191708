export function generateCaptchaSecret(lengthOtp) {
	const charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
	let captcha = [];
	for (let i = 0; i < lengthOtp; i++) {
	    //below code will not allow Repetition of Characters
	    let index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
	    if (captcha.indexOf(charsArray[index]) == -1)
	      captcha.push(charsArray[index]);
	    else i--;
	 }
	return captcha
}