import React, {Component}from 'react'
import { translate } from 'react-i18next'
import Loading from "react-spinners/ClockLoader"
import format from 'string-format'
import {formatNumber} from '../../helpers/number-utils'
import Carousel from 'react-bootstrap/Carousel'
import newsServices from '../../services/news'
import moment from 'moment'

class LatestNews extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
			count: 0,
			index: 0
		}
		this.handleSelect = this.handleSelect.bind(this)
	}
	componentDidMount() {
		newsServices.fetchLatestNews()
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data, isLoading: false, isError: false })
			}else{
				this.setState({items: [], errors: response.errors, isLoading: false, isError: true })
			}
		})
		.catch(error => this.setState({items: [], errors: error, isLoading: false, isError: true }))
	}
	handleSelect(selectedIndex) {
		this.setState({index: selectedIndex})
	}
	render () {
		const {t} = this.props
		let selectedItem = null
		let detailsUrl = "#"
		let lstItem = []
		if (this.state.items.length > 0) {
			selectedItem = this.state.items[this.state.index]
			detailsUrl = "/news/details/" + selectedItem.seoPath + '/' + selectedItem.id
			for (let i = 0; i < this.state.items.length;i++) {
				if (i !== this.state.index) {
					lstItem.push(<li className="d-flex" style={{color: '#63ab78'}}><img src="/images/link.svg"/><a href={"/news/details/" + this.state.items[i].seoPath + '/' + this.state.items[i].id} title={this.state.items[i].title} style={{color: '#63ab78'}}>{this.state.items[i].title}</a></li>)
				}
			}
		}
		return (
			<div className="mb-2">
				<div className="container mb-3">
					<div className="row">
						<div className="col">
							<div className="section_title text-center">
								<h3 className="mb-2">{t('latest_news')}</h3>
								<a href={'/news'} className="section_subtitle_a">{t('view_all')}</a>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="card border-0">
					<div className="row">
						<div className="card-img-top col-md-6 pr-md-0">
							<Carousel nextIcon={null} prevIcon={null} onSelect={this.handleSelect}>
								{
									this.state.items.map((item,idx) => 
										<Carousel.Item>
											<img className="card-latest-news__image" src={item.imageUrl?item.imageUrl: '/images/no_image.svg'} alt={item.title}/>
										</Carousel.Item>
										)
								}
							</Carousel>
						</div>
						<div className="card-body col-md-6 pl-md-0 pt-0 pb-5">
							<div className="card-latest-news__body" style={{backgroundColor: "#222b45"}}>
								<a href={detailsUrl}><h4 className="pt-5 pl-5 pr-2" style={{color: "#FFF"}}>{selectedItem?selectedItem.title: ""}</h4></a>
								<time className="pl-5 mt-2" style={{color: "#FFF"}}>{selectedItem?selectedItem.publishedAt?moment(selectedItem.publishedAt).format('DD/MM/YYYY'):null:null}</time>
								<p className="pl-5 mt-5 pr-2" style={{color: "#FFF"}}>{selectedItem?selectedItem.excerpt: ""}</p>
								<div className="pl-5 pr-3 mt-3">
									<ul className="pb-3">
										{lstItem}
									</ul>
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
			</div>
		)
	}
}
export default translate("translations")(LatestNews);