import React from 'react'
import { translate } from 'react-i18next'
import FeaturedPostListItem from './FeaturedPostListItem'
const FeaturedPostList = ({t, items, ids, src}) => (
	<div className="container">
		<div className="row">
		{items.map(item => <FeaturedPostListItem key={item.id} item={item} ids={ids} src={src}/>)}
		</div>
	</div>
)
export default translate("translations")(FeaturedPostList)