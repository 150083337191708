import React from 'react'
import { translate } from "react-i18next"
import {ACCOUNT_URL,APP_DOMAIN} from '../configs/configs'

function getYear () {
	return new Date().getFullYear()
}
const Footer = ({t, props}) => (
	<footer className="footer-area">
        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <a href="/" className="foo-logo"><img src="/images/logo.svg" alt="TopReal Logo"/></a>
                        <p>{t('the_top_real_is_a_real_estate_service_...')}</p>
                        <div className="footer-social-info">
                            <a href="https://www.facebook.com/OfficialTopReal" className="facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                            {/*
                            <a href="#" className="google-plus"><i className="fab fa-google-plus-g"></i></a>
                            <a href="#" className="instagram"><i className="fab fa-instagram"></i></a>
                            */}
                            <a href="https://twitter.com/TopRealVN" className="twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                            <a href="https://linkedin.com/in/top-real-8a7b27172" className="linkedin" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('account')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/posts/add-ads" title={t('submit_ads')} >{t('submit_ads')}</a></li>
                                <li><a href="https://topreal.zetgoo.com/register" title={t('register')} target="_blank">{t('register')}</a></li>
                                <li><a href={ACCOUNT_URL} title={t('login')}>{t('login')}</a></li>
                                <li><a href="https://account.topreal.vn" target="_blank">{"My TopReal"}</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('useful_links')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings">{t('listings')}</a></li>
                                <li><a href="/news">{t('news')}</a></li>
                                <li><a href="/companies?src=ulink&catId=2">{t('constructors')}</a></li>
                                <li><a href="/companies?src=ulink&catId=8">{t('our_agents')}</a></li>
                                <li><a href="/about">{t('about_us')}</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Kênh đầu tư</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/coming-soon">Dự án đất nền</a></li>
                                <li><a href="/coming-soon">Căn hộ chung cư</a></li>
                                <li><a href="/coming-soon">Khu nghỉ dưỡng</a></li>
                                <li><a href="/coming-soon">Chia sẻ</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Địa ốc TP Hồ Chí Minh</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=51&districtId=567">Bán đất Quận 2</a></li>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=51&districtId=576">Bán đất Quận 7</a></li>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=51&districtId=579">Bán nhà Bình Chánh</a></li>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=51&districtId=561">Bán đất Quận 9</a></li>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=51&districtId=565">Bán nhà Tân Phú</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Địa ốc Hà Nội</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=2&districtId=7">Bán nhà đất quận Cầu Giấy</a></li>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=2&districtId=8">Bán nhà đất quận Đống Đa</a></li>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=2&districtId=9">Bán nhà đất quận Ba Đình</a></li>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=2&districtId=11">Bán nhà đất quận Thanh Xuân</a></li>
                                <li><a href="/listings?src=qlink&method=1&catId=2&cityId=2&districtId=10">Bán nhà đất quận Hoàng Mai</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Mua bán bất động sản</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings?src=qlink&method=1&catId=2">Mua bán nhà riêng</a></li>
                                <li><a href="/listings?src=qlink&method=1&catId=1">Căn hộ chung cư</a></li>
                                <li><a href="/listings?src=qlink&catId=5&cityId=52">Đất nền Long An</a></li>
                                <li><a href="/listings?src=qlink&catId=5&cityId=48">Đất nền Bình Dương</a></li>
                                <li><a href="/listings?src=qlink&catId=5&cityId=33">Đất nền Đà Nẵng</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Cho thuê bất động sản</h6>
                       <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings?src=qlink&method=2&catId=14" >Cho thuê phòng trọ</a></li>
                                <li><a href="/listings?src=qlink&method=2&catId=10" >Cho thuê căn hộ chưng cư</a></li>
                                <li><a href="/listings?src=qlink&method=2&catId=15" >Cho thuê căn hộ văn phòng</a></li>
                                <li><a href="/listings?src=qlink&method=2&catId=11" >Cho thuê nhà nguyên căn</a></li>
                                <li><a href="/listings?src=qlink&method=2&cityId=51" >Cho thuê BĐS tại TpHCM</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Dự án bất động sản</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/projects/listings?src=qlink&catId=1">Căn hộ, chung cư</a></li>
                                <li><a href="/projects/listings?src=qlink&catId=10">Nhà ở xã hội</a></li>
                                <li><a href="/projects/listings?src=qlink&catId=11">Nghĩ dưỡng, sinh thái</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Doanh nghiệp bất động sản</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/companies/listings?src=qlink&catId=2">Thi công xây dựng</a></li>
                                <li><a href="/companies/listings?src=qlink&catId=5">Trang trí nội thất</a></li>
                                <li><a href="/companies/listings?src=qlink&catId=3">Tư vấn thiết kế</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Liên kết</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="https://toplabs.io" target="_blank">TopLabs</a></li>
                                <li><a href="https://topauto.vn" target="_blank">TopAuto</a></li>
                                <li><a href="https://zetgoo.com" target="_blank">Zetgoo</a></li>
                                <li><a href="https://thinktree.zetgoo.com" target="_blank">Tiên Tri</a></li>
                                <li><a href="https://skeleton.zetgoo.com" target="_blank">Công cụ</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('contact_info')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="/images/placeholder.svg" alt=""></img></div></div>
                                <div className="contact_info_text">{t('topreal_address')}</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="/images/phone-call.svg" alt=""></img></div></div>
                                <div className="contact_info_text">+8490 866 77 96</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="/images/phone-call.svg" alt=""></img></div></div>
                                <div className="contact_info_text">+8498 243 71 72</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="/images/phone-call.svg" alt=""></img></div></div>
                                <div className="contact_info_text">+84909 689 426</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="/images/message.svg" alt=""></img></div></div>
                                <div className="contact_info_text"><a href="mailto:contact.topreal@gmail.com?Subject=Hello" target="_top">contact.topreal@gmail.com</a></div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="/images/planet-earth.svg" alt=""></img></div></div>
                                <div className="contact_info_text"><a href="https://topreal.vn">https://topreal.vn</a></div>
                            </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
        </div>
        <div className="copywrite-area">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <p className="copywrite-text">
Bản quyền  ©  {getYear()} Tất cả được bảo vệ | Phát triển bởi <i className="far fa-heart" aria-hidden="true"></i> <a href="https://toplabs.io" target="_blank">TopLabs</a></p>
                    </div>
                    <div className="col-12 col-sm-6">
                        <nav className="footer-nav">
                            <ul>
                                <li><a href="/" title={t('home')}>{t('home')}</a></li>
                                <li><a href="/terms" title={t('terms')}>{t('terms')}</a></li>
                                <li><a href="/privacy" title={t('privacy')}>{t('privacy')}</a></li>
                                <li><a href="/posts/add-ads" title={t('submit_ads')}>{t('submit_ads')}</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)
export default translate("translations")(Footer)