import React, {Component} from 'react'
import NewsLetter from '../../components/NewsLetter'
import LawAskSection from '../../components/laws/LawAskSection'
import LawFAQSection from '../../components/laws/LawFAQSection'
import {Helmet} from "react-helmet"
import { translate } from "react-i18next"
import AsideRealEstateLaws from '../../components/laws/AsideRealEstateLaws'
import AsideContractTemplates from '../../components/laws/AsideContractTemplates'
import Breadcrumbs from '../../components/laws/Breadcrumbs'
import NewPostListings from '../../components/posts/NewPostListings'
import {NEWS_LIST, NEW_POST_IN_CITY_LIST} from '../../configs/mocks'

// import ReactCountdownClock from 'react-countdown-clock'


const realEstateItems = [
	{
		id: 1,
		title: 'Luật nhà ở 2014 (65/2014/QH13)',
		url: 'http://topreal.vn/static/files/laws/luat-nha-o-2014.doc'
	},
	{
		id: 2,
		title: 'Hướng dẫn luật nhà ở 99/2015/NĐ-CP',
		url: 'http://topreal.vn/static/files/laws/luat-nha-o-nghi-dinh-99-2015-ND-CP-huong-dan.doc'
	},
	{
		id: 3,
		title: 'Nghị định 30/2019/NĐ-CP sửa đổi Nghị định 99/2015/NĐ-CP hướng dẫn luật nhà ở',
		url: 'http://topreal.vn/static/files/laws/luat-nha-o-nghi-dinh-30-2019.pdf'
	},
	{
		id: 4,
		title: 'Thông tư 19/2016/TT-BXD hướng dẫn thực hiện Luật Nhà ở',
		url: 'http://topreal.vn/static/files/laws/luat-nha-o-2014.doc'
	},
	{
		id: 5,
		title: 'Luật kinh doanh BĐS 2014 (66/2014/QH13)',
		url: 'http://topreal.vn/static/files/laws/luat-kinh-doanh-bds-2014.doc'
	},
	{
		id: 6,
		title: 'Luật đất đai 2013 (45/2013/QH13)',
		url: 'http://topreal.vn/static/files/laws/luat-dat-dai-2013.doc'
	}
]
const otherItems = [
	{
		id: 1,
		title: 'Luật thuế thu nhập các nhân 2007 (04/2007/QH12)',
		url: ''
	},
	{
		id: 2,
		title: 'Luật thuế cá nhân sửa đổi 2012 (26/2012/QH13)',
		url: ''
	},
	{
		id: 3,
		title: 'Luật thuế cá nhân sửa đổi 2014 (71/2014/QH13)',
		url: ''
	},
	{
		id: 4,
		title: 'Luật thuế sử dụng đất phi nông nghiệp 2012 (48/2010/QH12)',
		url: ''
	},
	{
		id: 5,
		title: 'Nghị định 100/2015/NĐ-CP về phát triển và quản lý nhà ở xã hội',
		url: ''
	},
	{
		id: 6,
		title: 'Chiến lược phát triển nhà ở quốc gia đến năm 2020 và tầm nhìn đến năm 2030 (2127/QĐ-TTG)',
		url: ''
	}
]
const relatedItems = [
	{
		id: 1,
		title: 'Hợp đồng mua bán căn hộ, nhà chung cư',
		url: 'http://topreal.vn/static/files/laws/mau-hd-mua-ban-can-ho-nha-chung-cu.doc'
	},
	{
		id: 2,
		title: 'Hợp đồng mua bán tài sản gắn liền với đất',
		url: 'http://topreal.vn/static/files/laws/mau-hd-mua-ban-tai-san-glvd.doc'
	},
	{
		id: 3,
		title: 'Hợp đồng chuyển nhượng quyền sử dụng đất',
		url: 'http://topreal.vn/static/files/laws/mau-hd-chuyen-nhuong-qsdd.doc'
	},
	{
		id: 4,
		title: 'Hợp đồng thuê bán căn hộ, nhà chung cư',
		url: 'http://topreal.vn/static/files/laws/mau-hd-thue-can-ho-nha-chung-cu.doc'
	},
	{
		id: 5,
		title: 'Hợp đồng thuê quyền sử dụng đất và tài sản gắn liền với đất',
		url: 'http://topreal.vn/static/files/laws/mau-hd-thue-qsdd-va-tai-san-glvd.doc'
	},
	{
		id: 6,
		title: 'Hợp đồng ủy quyền',
		url: 'http://topreal.vn/static/files/laws/mau-hd-uy-quyen.doc'
	},
	{
		id: 7,
		title: 'Hợp đồng thuê quyền sử dụng đất',
		url: 'http://topreal.vn/static/files/laws/mau-hd-thue-quyen-su-dung-dat.doc'
	},
	{
		id: 8,
		title: 'Hợp đồng thuê tài sản gắn liền với đất',
		url: 'http://topreal.vn/static/files/laws/mau-hd-thue-tai-san-glvd.doc'
	}
]
class Laws extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const {t} = this.props
		return (

			<React.Fragment>
				<Helmet>
				{/*facebook*/}
					<title> {"Luật bất động sản tại Việt Nam - TopReal"} </title>
					<meta property="og:title" content="Luật bất động sản tại Việt Nam" />
					<meta property="og:description" content="Luật bất động sản tại Việt Nam"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Luật bất động sản tại Việt Nam"/>
					<meta name="twitter:description" content="Luật bất động sản tại Việt Nam"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<Breadcrumbs />
				<div className="container">
					<div className="row mt-4 mb-1">
						<div className="col-lg-8 pl-0 mb-3">
							<div className="row mb-3">
								<div className="col">
									<div className="card">
										<div className="card-header" style={{backgroundColor: "#e9eaec"}}>
											{'Quy trình mua bán - Chuyển nhượng nhà đất'}
										</div>
										<img className="card-img-top" src={"/images/buy_sell_process.png"} alt="Qui trình mua bán - chuyển nhượng nhà đất"/>
										<div className="card-body">
											<h5 className="card-title">Thủ tục mua bán - chuyển nhượng nhà đất</h5>
											<a href="https://luatvietnam.vn/dat-dai-nha-o/thu-tuc-mua-ban-dat-dai-567-20277-article.html" target="_blank" className="btn btn-danger btn-lg">{t('view_details')}</a>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<div className="card">
										<div className="card-header" style={{backgroundColor: "#e9eaec"}}>
											{'Quy trình cho thuê nhà đất'}
										</div>
										<img className="card-img-top" src={"/images/rental_process.png"} alt="Qui trình cho thuê nhà đất"/>
										<div className="card-body">
											<h5 className="card-title">Phương pháp cho thuê nhà đất theo qui định của pháp luật</h5>
											<a href="https://luatvietnam.vn/bieu-mau/mau-hop-dong-thue-nha-571-19399-article.html" target="_blank" className="btn btn-danger btn-lg">{t('view_details')}</a>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col">
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="row">
								<div className="col">
									<AsideRealEstateLaws title={t('laws_realesate')} items={realEstateItems}/>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<AsideRealEstateLaws title={t('laws_related_realesate')} items={otherItems}/>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<AsideContractTemplates title={t('contract_templates')} items={relatedItems} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<LawFAQSection />
				<NewPostListings cityName={"Hồ Chí Minh"} cityId={51} />
				<NewPostListings cityName={"Hà Nội"} cityId={2} />				
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Laws);