export default {
	getLatLonFromAddress(address) {
		let queryUrl = "https://maps.googleapis.com/maps/api/geocode/json?"
		let query = "key=AIzaSyCciBLb8DpANO0ltCE9xJ3K0RktaPXnpcU" + "&address="+address
		return fetch(queryUrl+query, {
			method: 'GET',
			headers: {
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	}
}