import React from 'react'
import { translate } from 'react-i18next'
import AsideNewsListItem from './AsideNewsListItem'
const AsideNewsList = ({t, items}) => (
	<div className="container">
		<div className="row">
		{items.map(item => <AsideNewsListItem key={item.id} item={item}/>)}
		</div>
	</div>
)
export default translate("translations")(AsideNewsList)