import { combineReducers } from 'redux'
import {coreDataCities,coreDataDistricts,coreDataWards,coreDataPropertyTypes, coreDataReportType,projectCategories} from './core-data'
import newsDetails from './news-details'
import newsCategories from './news-categories'
import {newsSearchParams} from './news'
import projectSearchParams from './projects-search'
import postSearchParams from './posts-search'
import {companySearchParams} from './companies'
export default combineReducers({
  coreDataCities,
  coreDataDistricts,
  coreDataWards,
  coreDataPropertyTypes,
  coreDataReportType,
  projectCategories,
  newsDetails,
  newsCategories,
  newsSearchParams,
  projectSearchParams,
  postSearchParams,
  companySearchParams
})