import React, {Component} from 'react'
import Select from '../Select'

class FieldDropdownItem extends Component {
	constructor(props) {
		super(props)

	}
	render () {
		const title = this.props.title
		const items = this.props.items
		return (
			<React.Fragment>
				<div className="form-group search-form___dropdown-item">
					<label className="search-form__dropdown-item-title">{title}</label>
					<Select selected={this.props.selected} onChange={this.props.onChange} classes={"search_from__select-input--while "} items={items} />
				</div>
			</React.Fragment>
		)
	}
}
export default FieldDropdownItem