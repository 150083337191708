import React, {Component}  from 'react'
import { translate, Trans } from "react-i18next"
class AdsBox extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const {t,item} = this.props
		return ( 
			<React.Fragment>
				<div className="card border-0 bg-dark text-white">
					<img className="card-img border-0" style={{maxHeight: '450px', minHeight: '250px', borderRadius: '0px'}} src="http://topreal.vn/images/no_image.png" alt="TopReal image"/>
					<div className="card-img-overlay d-flex flex-column justify-content-end">
						<h5 className="card-title" style={{color: "#dc3545"}}>{item.headline}</h5>
						<div className="d-flex flex-row">
							<p className="card-text" style={{color: "#dc3545"}}>{item.subTitle}</p>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
export default translate("translations")(AdsBox)