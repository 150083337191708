import React, {Component} from 'react'
import Footer from './components/Footer'
import Header from './components/Header'
import ScrollButton from './components/ScrollButton'
import Main from './Main'
import { translate } from "react-i18next"
import languageLocalServices from './services/local/languages'
import deviceServices from './services/devices'
import authServices from './services/auth'

import queryString from 'query-string'

import {BrowserRouter as Router} from 'react-router-dom'

class App extends Component {
  constructor(props) {
    super(props)
    this.props.getCities()
    this.props.getAreaRanges()
    //Check LocalStorage
    let lang = languageLocalServices.readLang()
    if (lang) {
      this.props.i18n.changeLanguage(lang)
    } else {
      this.props.i18n.changeLanguage("vi")
      languageLocalServices.saveLang('vi')
    }
    //let clientjs = new ClientJS()
    //console.log(clientjs)
    //console.log(clientjs.getFingerprint())
    if (!sessionStorage.getItem('X-Device-Token')) {
      deviceServices.fetchDeviceSession()
      .then(response => {
          sessionStorage.setItem('X-Device-Token', response.data.token)
          localStorage.setItem('X-Zetgoo-ClientId', response.data.fingerPrint)        
      })
      .catch(error => {
        console.log(error)
      })
    }
    
    //Store DeviceToken in sessionStore
    //Store fingerPrint & DeviceID to localStore
    const values = queryString.parse(this.props.location.search)
    if (values.authToken) {
      authServices.storeAuthToken(values.authToken)
    } else {
    }
    //console.log(values.authToken)
    ///CheckSSO Login from Zetgoo
  }
  render() {
    return (
    <React.Fragment>
      <Header />
      <Main />
      <Footer />
      <ScrollButton scrollStepInPx="50" delayInMs="16.66"/>
    </React.Fragment>
    );
  } 
}

export default translate("translations")(App);