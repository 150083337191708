import React, {Component} from 'react'
import Section from '../components/Section'
import NewsLetter from '../components/NewsLetter'

class Features extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<main role="main">
					<Section title="TopReal Support Center" subTitle="Every answer for you"/>
				</main>
			</React.Fragment>
		)
	}
}
export default Features;