export function isEmpty(val) {
	if (!val) {
		return true
	}
	if (val.trim().length === 0) {
		return true
	}
	return false
}
export function isEmail(val) {
	const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return re.test(val);
}
export function isPhone(val) {
	const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
	return re.test(val)
}