import {ROOT_URL, NEWS_V1} from '../configs/configs'
import format from 'string-format'

export default {
	fetchAllNewsCategories () {
		return fetch(ROOT_URL + NEWS_V1.GET_ALL_CATEGORIES_SEO).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	searchNews (criteria) {
		var query = ''
		if(criteria.page && criteria.page != -1)
		{
			query += 'page=' + criteria.page
		}
		if (criteria.size && criteria.size > 0) {
			query += '&size=' + criteria.size
		}
		if (criteria.catId && criteria.catId != -1) {
			query += '&catId=' + criteria.catId	
		}
		if(criteria.q)
		{
			query += '&q=' + criteria.q
		}
		return fetch(ROOT_URL + NEWS_V1.SEARCH_NEWS + '?' + query).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	getById (id) {
		return fetch(ROOT_URL + format(NEWS_V1.GET_DETAILS_BY_ID,id)).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchLatestNews () 
	{
		return fetch(ROOT_URL + NEWS_V1.GET_LASTEST_NEWS, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fetchMostViewNews () 
	{
		return fetch(ROOT_URL + NEWS_V1.GET_MOST_VIEW_NEWS, {
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	}
}