import React, {Component} from 'react'
import NewsLetter from '../components/NewsLetter'
import TopProjects from '../components/projects/TopProjects'
import NewPostListings from '../components/posts/NewPostListings'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"

import {NEW_POST_IN_CITY_LIST, PROJECT_LIST} from '../configs/mocks'
class Pricing extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Bảng báo giá dịch vụ đăng tin tại TopReal"} </title>
					<meta property="og:title" content="Bảng báo giá dịch vu đăng tin tại TopReal" />
					<meta property="og:description" content="Bảng báo giá dịch vụ đăng tin tại TopReal"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Bảng báo giá dịch vụ đăng tin tại TopReal"/>
					<meta name="twitter:description" content="Bảng báo giá dịch vụ đăng tin tại TopReal"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<section className="breadcrumb-area bg-img bg-overlay" style = {{backgroundImage: "url('/images/40.jpg')"}} >
					<div className="container h-100">
						<div className="row h-100 align-items-center">
								<div className="col-12"><div className="breadcrumb-content">
									<h2>{t('service_quotation')}</h2>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container mb-3">
						<div className="row">
							<div className="col">
								<div className="section_title text-center">
									<h3>{t('msg_publish_in_free_charge')}</h3>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/*<section className="section">
					<div className="container mb-3">
						<div className="row mb-3">
							<div className="col">
								<div className="section_title text-center">
									<h3>{"GÓI ĐĂNG TIN VIP"}</h3>
								</div>
							</div>
						</div>
						<div className="row card-deck">
							<div className="card">
								<div className="card-header bg-danger">
									<h3 style={{color: '#FFF'}}>{'ADV Diamond'}</h3>
								</div>
								<img className="card-img-top" src="http://topreal.vn/images/logo.svg" alt="Card image cap"/>
								<div className="card-body">
									<h6 className="card-title">Tăng lượt xem dự án gấp 10 lần dự án thường.</h6>
									<h6 className="card-title">Tiếp cận khách hàng hiệu quả.</h6>
									<h6 className="card-title">Được điều chỉnh thông tin của Nhà môi giới & Chính sách bán hàng của Dự án.</h6>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Xuất hiện trên Trang chủ.</li>
									<li className="list-group-item">Xuất hiện trước PROJ GOLD</li>
									<li className="list-group-item">Icon màu đỏ nổi bật.</li>
									<li className="list-group-item">Được nằm trong mục dự án HOT.</li>
									<li className="list-group-item">Chia sẽ bài viết trên Facebook TopReal.</li>
								</ul>
								<div className="card-footer bg-danger">
									<h4 style={{color: '#FFF'}}>{'Giá/ngày: 5.000 VNĐ'}</h4>
								</div>
								<div className="card-footer bg-danger">
									<h4 style={{color: '#FFF'}}>{'Giá/ 15 ngày: 5.000 VNĐ'}</h4>
								</div>
								<div className="card-footer bg-danger">
									<h4 style={{color: '#FFF'}}>{'Giá/ 30 ngày: 5.000 VNĐ'}</h4>
								</div>
							</div>
							<div className="card">
								<div className="card-header bg-warning">
									<h3 style={{color: '#FFF'}}>{'ADV Gold'}</h3>
								</div>
								<img className="card-img-top" src="http://topreal.vn/images/logo.svg" alt="Card image cap"/>
								<div className="card-body">
									<h6 className="card-title">Tăng lượt xem dự án gấp 5 lần dự án thường.</h6>
									<h6 className="card-title">Tiếp cận khách hàng hiệu quả.</h6>
									<h6 className="card-title">Được điều chỉnh thông tin của Nhà môi giới & Chính sách bán hàng của Dự án.</h6>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Xuất hiện trước PROJ SILVER</li>
									<li className="list-group-item">Icon màu vàng nổi bật.</li>
									<li className="list-group-item">Được nằm trong mục dự án HOT.</li>
								</ul>
								<div className="card-footer bg-warning">
									<h4 style={{color: '#FFF'}}>{'Giá/ngày: 3.000 VNĐ'}</h4>
								</div>
								<div className="card-footer bg-warning">
									<h4 style={{color: '#FFF'}}>{'Giá/ 15 ngày: 5.000 VNĐ'}</h4>
								</div>
								<div className="card-footer bg-warning">
									<h4 style={{color: '#FFF'}}>{'Giá/ 30 ngày: 5.000 VNĐ'}</h4>
								</div>
							</div>
							<div className="card">
								<div className="card-header bg-info">
									<h3 style={{color: '#FFF'}}>{'ADV Silver'}</h3>
								</div>
								<img className="card-img-top" src="http://topreal.vn/images/logo.svg" alt="Card image cap"/>
								<div className="card-body">
									<h6 className="card-title">Tăng lượt xem dự án gấp 3 lần dự án thường.</h6>
									<h6 className="card-title">Tiếp cận khách hàng hiệu quả.</h6>
									<h6 className="card-title">Được điều chỉnh thông tin của Nhà môi giới & Chính sách bán hàng của Dự án.</h6>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Xuất hiện trước PROJ BASIC</li>
									<li className="list-group-item">Icon màu xanh nổi bật.</li>
								</ul>
								<div className="card-footer bg-info">
									<h4 style={{color: '#FFF'}}>{'Giá/ngày: 1.000 VNĐ'}</h4>
								</div>
								<div className="card-footer bg-info">
									<h4 style={{color: '#FFF'}}>{'Giá/ 15 ngày: 5.000 VNĐ'}</h4>
								</div>
								<div className="card-footer bg-info">
									<h4 style={{color: '#FFF'}}>{'Giá/ 30 ngày: 5.000 VNĐ'}</h4>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container mb-3">
						<div className="row">
							<div className="col">
								<div className="section_title text-center">
									<h3>{"GÓI UP TIN"}</h3>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container mb-3">
						<div className="row mb-3" >
							<div className="col">
								<div className="section_title text-center">
									<h3>{"GÓI DỰ ÁN"}</h3>
								</div>
							</div>
						</div>
						<div className="row card-deck">
							<div className="card">
								<div className="card-header bg-danger">
									<h3 style={{color: '#FFF'}}>{'PROJ Diamond'}</h3>
								</div>
								<img className="card-img-top" src="http://topreal.vn/images/logo.svg" alt="Card image cap"/>
								<div className="card-body">
									<h6 className="card-title">Tăng lượt xem dự án gấp 10 lần dự án thường.</h6>
									<h6 className="card-title">Tiếp cận khách hàng hiệu quả.</h6>
									<h6 className="card-title">Được điều chỉnh thông tin của Nhà môi giới & Chính sách bán hàng của Dự án.</h6>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Xuất hiện trên Trang chủ.</li>
									<li className="list-group-item">Xuất hiện trước PROJ GOLD</li>
									<li className="list-group-item">Icon màu đỏ nổi bật.</li>
									<li className="list-group-item">Được nằm trong mục dự án HOT.</li>
									<li className="list-group-item">Chia sẽ bài viết trên Facebook TopReal.</li>
								</ul>
								<div className="card-footer bg-danger">
									<h4 style={{color: '#FFF'}}>{'Giá/bài: 3.500.000 VNĐ'}</h4>
								</div>
							</div>
							<div className="card">
								<div className="card-header bg-warning">
									<h3 style={{color: '#FFF'}}>{'PROJ Gold'}</h3>
								</div>
								<img className="card-img-top" src="http://topreal.vn/images/logo.svg" alt="Card image cap"/>
								<div className="card-body">
									<h6 className="card-title">Tăng lượt xem dự án gấp 5 lần dự án thường.</h6>
									<h6 className="card-title">Tiếp cận khách hàng hiệu quả.</h6>
									<h6 className="card-title">Được điều chỉnh thông tin của Nhà môi giới & Chính sách bán hàng của Dự án.</h6>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Xuất hiện trước PROJ SILVER</li>
									<li className="list-group-item">Icon màu vàng nổi bật.</li>
									<li className="list-group-item">Được nằm trong mục dự án HOT.</li>
								</ul>
								<div className="card-footer bg-warning">
									<h4 style={{color: '#FFF'}}>{'Giá/bài: 2.500.000 VNĐ'}</h4>
								</div>
							</div>
							<div className="card">
								<div className="card-header bg-info">
									<h3 style={{color: '#FFF'}}>{'PROJ Silver'}</h3>
								</div>
								<img className="card-img-top" src="http://topreal.vn/images/logo.svg" alt="Card image cap"/>
								<div className="card-body">
									<h6 className="card-title">Tăng lượt xem dự án gấp 5 lần dự án thường.</h6>
									<h6 className="card-title">Tiếp cận khách hàng hiệu quả.</h6>
									<h6 className="card-title">Được điều chỉnh thông tin của Nhà môi giới & Chính sách bán hàng của Dự án.</h6>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Xuất hiện trước PROJ BASIC</li>
									<li className="list-group-item">Icon màu xanh nổi bật.</li>
								</ul>
								<div className="card-footer bg-info">
									<h4 style={{color: '#FFF'}}>{'Giá/bài: 1.500.000 VNĐ'}</h4>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container mb-3">
						<div className="row mb-4">
							<div className="col">
								<div className="section_title text-center">
									<h3>{"GÓI BÀI PR"}</h3>
								</div>
							</div>
						</div>
						<div className="row card-deck">
							<div className="card">
								<div className="card-header bg-danger">
									<h3 style={{color: '#FFF'}}>{'PR Diamond'}</h3>
								</div>
								<img className="card-img-top" src="http://topreal.vn/images/logo.svg" alt="Card image cap"/>
								<div className="card-body">
									<h5 className="card-title">Xuất hiện trên Trang chủ: Tin tức.</h5>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Xuất hiện trên trang chuyên mục:Tin tức.</li>
									<li className="list-group-item">Chia sẽ bài viết trên Facebook TopReal.</li>
								</ul>
								<div className="card-footer bg-danger">
									<h4 style={{color: '#FFF'}}>{'Giá/bài: 8.900.000 VNĐ'}</h4>
								</div>
							</div>
							<div className="card">
								<div className="card-header bg-warning">
									<h3 style={{color: '#FFF'}}>{'PR Gold'}</h3>
								</div>
								<img className="card-img-top" src="http://topreal.vn/images/logo.svg" alt="Card image cap"/>
								<div className="card-body">
									<h5 className="card-title">Xuất hiện trên Trang chủ: Tin tức.</h5>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Xuất hiện trên trang chuyên mục:Tin tức.</li>
									<li className="list-group-item"></li>
								</ul>
								<div className="card-footer bg-warning">
									<h4 style={{color: '#FFF'}}>{'Giá/bài: 5.900.000 VNĐ'}</h4>
								</div>
							</div>
							<div className="card">
								<div className="card-header bg-info">
									<h3 style={{color: '#FFF'}}>{'PR Silver'}</h3>
								</div>
								<img className="card-img-top" src="http://topreal.vn/images/logo.svg" alt="Card image cap"/>
								<div className="card-body">
									<h5 className="card-title"></h5>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Xuất hiện trên trang chuyên mục:Tin tức.</li>
									<li className="list-group-item"></li>
								</ul>
								<div className="card-footer bg-info">
									<h4 style={{color: '#FFF'}}>{'Giá/bài: 2.900.000 VNĐ'}</h4>
								</div>
							</div>
						</div>
						<div className="row card-deck">
							<div className="card border-0">
								<div className="card-header border-0 bg-white">
									<h3><i class="fas fa-exclamation mr-2"></i>{'Lưu ý'}</h3>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">Một bài PR tối đa có 5 ảnh minh họa.</li>
									<li className="list-group-item">Bài PR gửi trước 2 ngày.</li>
									<li className="list-group-item">Bài PR xuất hiện ở trang chủ trong 1 ngày, xuất hiện trên trang chuyên mục Tin tức vĩnh viễn.</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container mb-3">
						<div className="row">
							<div className="col">
								<div className="section_title text-center">
									<h3>{"GÓI BÀI BANNER"}</h3>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container mb-3">
						<div className="row">
							<div className="col">
								<div className="section_title text-center">
									<h3>{"GÓI KHÁCH HÀNG TIỀM NĂNG"}</h3>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container mb-3">
						<div className="row">
							<div className="col">
								<div className="section_title text-center">
									<h3>{"GÓI DỊCH VỤ KHÁC"}</h3>
								</div>
							</div>
						</div>
					</div>
				</section>
			*/}
				<NewPostListings cityName={"Hồ Chí Minh"} cityId = {51} items={NEW_POST_IN_CITY_LIST}/>
				<TopProjects items={PROJECT_LIST}/> 
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Pricing)