import React, { Component } from 'react'
import { translate } from 'react-i18next'
import PostsByAdvertiserList from './PostsByAdvertiserList'
import postsServices from '../../services/posts'
import { Link } from 'react-router-dom'
import {formatNumber} from '../../helpers/number-utils'
import base64 from '../../lib/codecs/base64'
///MaxItems 6
///MinItems 3
class PostsByAdvertiser extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			count: 0,
			isLoading: false,
			isError: false,
			errors: null,
		}
	}
	componentDidMount() {
		if (this.props.fingerprint) {
			this.setState({ isLoading: true, isError: false, items:[], count:0})
			postsServices.getPostsListItemsAndCountByContact(this.props.fingerprint)
			.then(response => {
				if (response.status === 'OK')
				{
					this.setState({ items: response.data.result, count:response.data.total, isLoading: false, isError: false })
				}else{
					this.setState({ items: [], count: 0, isLoading: false, isError: false })
				}
			})
			.catch(error => this.setState({ items: [], errors: error, isLoading: false, isError: true }))
		}
	}
	componentWillReceiveProps(newProps) {
		if (newProps === this.props) 
			return
		if (newProps.fingerprint && newProps.fingerprint !== this.props.fingerprint) {
			this.setState({ isLoading: true, isError: false, items:[], count:0})
			postsServices.getPostsListItemsAndCountByContact(newProps.fingerprint)
			.then(response => {
				if (response.status === 'OK')
				{
					this.setState({ items: response.data.result, count:response.data.total, isLoading: false, isError: false })
				}else{
					this.setState({ items: [], count: 0, isLoading: false, isError: false })
				}
			})
			.catch(error => this.setState({ items: [], errors: error, isLoading: false, isError: true }))
		}
	}
	render() {
		const {t} = this.props
		let items = this.state.items
		if (this.props.deletedIds) {
			items = items.filter(item => this.props.deletedIds.indexOf(item.id) === -1)
		}
		let ids = items.map(item => item.id)
		if (items.length > 3) {
			items = items.slice(0,3)
		}
		if (items.length === 0) {
			return (
					<></>
				)
		}
		return (
			<div className="section" style={{backgroundColor: "#f5f5f5"}}>
				<div className="container-fluid mb-3" >
					<div className="row">
						<div className="col">
							<div className="section_title text-center">
								<h3>{t('the_same_advertiser')}</h3>
								<Link to={'/agents/posts/contacts/' + this.props.fingerprint} className="section_subtitle_a">{t('view_all') + ' '+ formatNumber(this.state.count) + ' ' + t('ads')}</Link>
							</div>
						</div>
					</div>
				</div>
				<PostsByAdvertiserList items={items} src={'/agents/posts/contacts/' + this.props.fingerprint} ids={ids}/>
			</div>
		)
	} 
}
export default translate("translations")(PostsByAdvertiser);