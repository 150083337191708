import React from 'react'
import { translate } from 'react-i18next'
import AsideListItem from './AsideListItem'
const AsideList = ({t, items}) => (
	<div className="container">
		<ul className="row">
		{items.map(item => <AsideListItem key={item.id} item={item}/>)}
		</ul>
	</div>
)
export default translate("translations")(AsideList)