import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AsideSearchBox from '../../components/posts/AsideSearchBox'
import {changeSearchWard,changeSearchMethod,changeSearchPropertyType,changeSearchCity,
changeSearchDistrict,changeSearchAreaMin,changeSearchAreaMax,changeSearchNoBedRooms,
changeSearchNoBathRooms,changeSearchPriceMin,changeSearchPriceMax, changeSearchSort, changeSearchAgePost,
changeSearchPostQuery,clearSearchPostParams} from '../../actions/posts-search-params'
const mapStateToProps = state => ({
	cities: state.coreDataCities.cities? state.coreDataCities.cities: [],
	method: state.postSearchParams.method,
	districts: state.coreDataDistricts.districts? state.coreDataDistricts.districts: [],
	wards: state.coreDataWards.wards? state.coreDataWards.wards: [],
	propertyTypes: state.coreDataPropertyTypes.types?  state.coreDataPropertyTypes.types: [],
	noBedRoomId: state.postSearchParams.noBedRoomId,
	noBathRoomId: state.postSearchParams.noBathRoomId,
	areaRanges: state.postSearchParams.areaRanges,
	priceRanges: state.postSearchParams.priceRanges?state.postSearchParams.priceRanges:[],
	sorts: state.postSearchParams.sorts,
	ageRanges: state.postSearchParams.ageRanges,
	propertyTypeId: state.postSearchParams.propertyTypeId,
	cityId: state.postSearchParams.cityId,
	districtId: state.postSearchParams.districtId,
	wardId: state.postSearchParams.wardId,
	noBathRooms: state.postSearchParams.noBathRooms,
	noBedRooms: state.postSearchParams.noBedRooms,
	priceMaxId: state.postSearchParams.priceMaxId,
	priceMinId: state.postSearchParams.priceMinId,
	areaMaxId: state.postSearchParams.areaMaxId,
	areaMinId: state.postSearchParams.areaMinId,
	sortId: state.postSearchParams.sortId,
	ageId: state.postSearchParams.ageId,
	query: state.postSearchParams.query

})
const mapDispatchToProps = (dispatch, ownProps) => ({
	onChangeMethod: (id) => dispatch(changeSearchMethod(id)),
	onChangePropertyType: (id) => dispatch(changeSearchPropertyType(id)),
	onChangeCity: (cityId) => dispatch(changeSearchCity(cityId)),
	onChangeDistrict: (districtId) => dispatch(changeSearchDistrict(districtId)),
	onChangeWard: (wardId) => dispatch(changeSearchWard(wardId)),
	onChangeNoBedRooms: (no) => dispatch(changeSearchNoBedRooms(no)),
	onChangeNoBathRooms: (no) => dispatch(changeSearchNoBathRooms(no)),
	onChangeAreaMin: (id) => dispatch(changeSearchAreaMin(id)),
	onChangeAreaMax: (id) => dispatch(changeSearchAreaMax(id)),
	onChangePriceMin: (id) => dispatch(changeSearchPriceMin(id)),
	onChangePriceMax: (id) => dispatch(changeSearchPriceMax(id)),
	changeSearchSort: (id) => dispatch(changeSearchSort(id)),
	changeSearchAgePost: (id) => dispatch(changeSearchAgePost(id)),
	changeSearchPostQuery: (id) => dispatch(changeSearchPostQuery(id)),
	clearSearchPostParams: (method) => dispatch(clearSearchPostParams(method))
})
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AsideSearchBox))