import React, {Component} from 'react'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"
import Breadcrumbs from '../../components/news/Breadcrumbs'
import NewsList from '../../components/news/NewsList'
import AsideNews from '../../components/news/AsideNews'
//import SearchBox from '../../components/news/SearchBox'
import SearchBox from '../../containers/news/SearchBox'

import AdsBox from '../../components/ads/AdsBox'
import KeepInTouchBox from '../../components/news/KeepInTouchBox'
import Pagination from '../../components/Pagination'
import NewPostListings from '../../components/posts/NewPostListings'
import NewsCategories from '../../components/news/NewsCategories'
import Parser from 'html-react-parser'
import moment from 'moment'
import newsServices from '../../services/news'
import ReactImageFallback from "react-image-fallback"

import {NEWS_V1} from '../../configs/configs'
import {NEWS_LIST, NEW_POST_IN_CITY_LIST} from '../../configs/mocks'
// import ReactCountdownClock from 'react-countdown-clock'
class NewsDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			isError: false,
			errors: null,
			details: null
		}
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
		this.handleFavourite = this.handleFavourite.bind(this)
	}
	componentDidMount() {
		window.scrollTo(0, 0) //go to the top
		/// Fetch API to get Post Data
		//Fetch NewDetails
		//this.setState({newsId: this.props.match.params.id});
		//this.props.getNewsDetails(this.props.match.params.id)
		newsServices.getById(this.props.match.params.id)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ details: response.data, isLoading: false, isError: false, errors: null })
			}else{
				//Not Found
				this.props.history.push('/error/not-found')
			}
		})
		.catch(error => this.setState({details: null, errors: error, isLoading: false, isError: true }))
	}
	handleSearchSubmit(event) {
		let criteria = {}
		if (this.props.query)
		{
			criteria.q = this.props.query
		}
		this.props.history.push({ pathname: '/news/listings', search: "?" + new URLSearchParams(criteria).toString()})
	}
	handleFavourite(event) {
		//If have User Store in server
		//If don't have user, store in local
	}
	_prepareHeader(news) {
		if (!news) {
			return (<Helmet>
					{/*facebook*/}
					<title>{"Tin tức thị trường bất động sản tại Việt Nam"}</title>
					<meta property="og:title" content="Tin tức thị trường bất động sản tại Việt Nam" />
					<meta property="og:description" content="Tin tức thị trường bất động sản tại Việt Nam"/>
					<meta property="og:image" content="http://topauto.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topauto.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Tin tức thị trường bất động sản tại Việt Nam"/>
					<meta name="twitter:description" content="Tin tức thị trường bất động sản tại Việt Nam"/>
					<meta name="twitter:image" content="http://topauto.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
			</Helmet>)
		}
		return  (<Helmet>
					{/*facebook*/}
					<title> {news.title}</title>
					<meta property="og:title" content={news.title} />
					<meta property="og:description" content={news.excerpt}/>
					<meta property="og:image" content={news.imageUrl?news.imageUrl:'http://topreal.vn/images/no_image.svg'}/>
					<meta property="og:url" content={'http://topreal.vn/news/' + this.state.id}/>
					{/*Twtiter*/}
					<meta name="twitter:title" content={news.title}/>
					<meta name="twitter:description" content={news.excerpt}/>
					<meta name="twitter:image" content={news.imageUrl?news.imageUrl:'http://topreal.vn/images/no_image.svg'}/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>)
	}	
  	render () {
		const {t} = this.props
		/// Get Category Path seo, Name
		/// Get New Details Name, SEO
		const header = this._prepareHeader(this.state.details)
		let title = null
		let excerpt = null
		let content = ""
		let crumbs = []
		let publishedAt = null
		let source = null
		let sourceUrl = null
		if (this.state.details) {
			title = this.state.details.title
			excerpt = this.state.details.excerpt
			content = this.state.details.content
			if (this.state.details.category) {
				crumbs.push({name: this.state.details.category.name, to:"/news/categories/" + this.state.details.category.seoPath + "?catId=" + this.state.details.category.id, active: true})

			}
			if (this.state.details.publishedAt) {
				publishedAt = moment(this.state.details.publishedAt).format('DD/MM/YYYY')
			}
			if (this.state.details.source) {
				source = t('source') + ': ' + this.state.details.source
			}
			if (this.state.details.sourceUrl) {
				sourceUrl = this.state.details.sourceUrl
			}
			crumbs.push({name: this.state.details.title, to:"/news/" + this.state.details.seoPath + "/" + this.state.details.id, active: false})
		}
		return (
			<React.Fragment>
				{header}
				<Breadcrumbs items={crumbs}/>
				<div className="container">
					<div className="row mb-1">
						<div className="col-lg-8 p-0 mb-3">
							<div className="container">
								<div className="row">
									<div className="col">
										<h1 className="news-details__title">{title}</h1>
									</div>
								</div>
								<div className="row mb-1">
									<div className="col">
										<time className="news-details__date card-text">{publishedAt}</time>
									</div>
								</div>
								<div className="row news-details__excerpt mb-2">
									<div className="col">
										<p>{excerpt}</p>
									</div>
								</div>
								<div className="row news-details__featured-image">
									<div className="col">
							      	<ReactImageFallback className="w-100" src={this.state.details?this.state.details.imageUrl: '/images/no_image.svg'} fallbackImage="/images/no_image.svg"
                    					initialImage="/images/no_image.svg"/>
                    				</div>
							    </div>
								<div className="row news-details__content">
									<div className="col">
										<p className="text-justify">{content?Parser(content):content}</p>
									</div>
								</div>
								<div className="row news-details__author mt-2">
									<div className="container">
										<h6 className="float-right mr-5">{this.state.details? this.state.details.author: ''}</h6>
									</div>
								</div>
								<div className="row news-details__source">
										<div className="col-12">
											{source}
										</div>
										<div className="col-12">{sourceUrl}</div>
									
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div>
								<SearchBox onSubmit={this.handleSearchSubmit}/>
							</div>
							<div className="row mb-3">
								<div className="col">
									<NewsCategories/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<AsideNews title={t('latest_news')} api={NEWS_V1.GET_LASTEST_NEWS} />
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<AsideNews title={t('most_views')} api={NEWS_V1.GET_MOST_VIEW_NEWS}/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<AdsBox item={{headline: "Liên hệ quảng cáo", subTitle: "0982437172"}}/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<KeepInTouchBox />
								</div>
							</div>
						</div>
					</div>
				</div>
				<NewPostListings cityName={"Hồ Chí Minh"} cityId={51} />
				<NewPostListings cityName={"Hà Nội"} cityId={2} />
			</React.Fragment>
		)
	}
}
export default translate("translations")(NewsDetails)
