import * as types from '../actions/action-types'
const INITIAL_STATE = {
	newsData: null,
	isLoading: false,
	hasError: false,
	errors: null
}
const newsDetails = (state=INITIAL_STATE, action) => {
	switch (action.type) {
		case types.FETCH_NEWS_DETAILS_REQUEST:
			return Object.assign({},state, { newsData: null, hasError: false, errors: null, isLoading: true}); 
    	case types.FETCH_NEWS_DETAILS_SUCCESS:
    		return Object.assign({},state, { newsData: action.payload, hasError: false, errors: null, isLoading: true});
    	case types.FETCH_NEWS_DETAILS_ERROR:
    		return Object.assign({},state, { newsData: null, hasError: true,errors: action.payload, isLoading: false} );  
    }
    return state
}
export default newsDetails