import React, {Component} from 'react'
import { translate } from "react-i18next"

class PageItem extends Component {
	constructor(props) {
		super(props)
		this.goto = this.goto.bind(this)

	}
	goto (event) {
		this.props.onClick(this.props.page)
	}
	render () {
		const {active, page} = this.props
		let item = null
		if (active === true) {
			item = <li className="page-item active" onClick={this.goto}><a className="page-link" style={{backgroundColor:"#7a808f", borderColor:"#dee2e6"}}>{page}</a></li>
		} else {
			item = <li className="page-item" onClick={this.goto}><a className="page-link">{page}</a></li>
		}
		return(
			<React.Fragment>
				{item}
			</React.Fragment>
		)
	}
}
class Pagination extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const {t} = this.props
		let pages = []
		const startPage = this.props.currentPage > this.props.margin ? this.props.currentPage - this.props.margin : 1
    	const endPage = this.props.currentPage + this.props.margin > this.props.totalPages ? this.props.totalPages : this.props.currentPage + this.props.margin
		if (this.props.totalPages > 1) {
			///add Previous page
			if (this.props.currentPage > 1) {
				pages.push(<li className="page-item" onClick={this.props.prev}><a className="page-link" >{t('previous')}</a></li>)
			}
			//Calculate from start to end
			for (let iPage = startPage; iPage <= endPage ;iPage++) {
				if (this.props.currentPage == iPage) {
					//pages.push(<li className="page-item active" onClick={this.props.goto}><a className="page-link">{iPage}</a></li>)
					pages.push(<PageItem active={true} onClick={this.props.goto} page={iPage}/>)
				} else {
					pages.push(<PageItem active={false} onClick={this.props.goto} page={iPage}/>)
				}
			}
			//add Next Page
			if (this.props.currentPage < this.props.totalPages - 1) {
				pages.push(<li className="page-item" onClick={this.props.next}><a className="page-link">{t('next')}</a></li>)
			}
		}
		return (
			<React.Fragment>
				<nav>
					<ul className="pagination justify-content-center">
						{pages}
						{/*<li className="page-item"><a className="page-link" href="#">Previous</a></li>
						<li className="page-item active"><a className="page-link" href="#">1</a></li>
						<li className="page-item"><a className="page-link" href="#">2</a></li>
						<li className="page-item"><a className="page-link" href="#">3</a></li>
						<li className="page-item"><a className="page-link" href="#">Next</a></li>
					*/}
					</ul>
				</nav>
			</React.Fragment>
		)
	}
}
export default translate("translations")(Pagination)