import React, {Component}from 'react'
import { translate } from 'react-i18next'
import FeaturedProjectsAsideList from './FeaturedProjectsAsideList'
import { css } from "@emotion/core"
import Loading from "react-spinners/ClockLoader"
import {ROOT_URL, NEWS_V1} from '../../configs/configs'
import projectServices from '../../services/projects'

//Props title, url
class FeaturedProjectsAside extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null
		}
	}
	componentDidMount() {
		this.setState({ isLoading: true, isError: false, items:[], count:0})
		projectServices.fetchFeaturedProjectsAndCount(3)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.projects, count: response.data.count, isLoading: false, isError: false })
			}else{
				this.setState({ items: [], count: 0, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({ items: [], count: 0, errors: error, isLoading: false, isError: true }))
	}
	render() {
		return (
			<div className="container">
				<div className="row border-bottom pb-2">
					<label className="news_aside__title">{this.props.title}</label>
				</div>
				<div className="row">
					<Loading css={{display: 'block', margin: '0 auto', borderColor: 'red'} } size={70} color={"#e9eaec"} loading={this.state.isLoading} />
				</div>
				<div className="row mt-2">
					<FeaturedProjectsAsideList items={this.state.items} />
				</div>
			</div>
		)
	}
}
export default translate("translations")(FeaturedProjectsAside)