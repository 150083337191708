import React from 'react'
import { translate } from 'react-i18next'
///card border-0 col-12 mb-5
import {ADS_PACK_COLORS} from '../../configs/configs'

const FeaturedProjectsAsideListItem = ({t, item, classes}) => (
	<div className="card border-0 col-12 mb-1" >
		<div className="row">
		<img className="card-img col-5 p-0" style={{maxHeight: '110px', objectFit: "cover"}} src={item.imageUrl?item.imageUrl:"/images/no_image.svg"} alt=""/>
		<div className="card-body col-7">
			<a href={'/projects/' + item.seoPath+ "/" + item.id} className="card-title news-card-body__headline" style={{fontSize: '16px', color:ADS_PACK_COLORS[item.adsPack]}} title={item.name}>{item.name}</a>
			<address className="text-truncate" style={{fontSize: "14px"}}>{item.address}</address>
		</div>
		</div>
	</div>
)
export default translate("translations")(FeaturedProjectsAsideListItem)