import React, {Component}from 'react'
import { translate } from 'react-i18next'
import CompanyList from './CompanyList'
import companyServices from '../../services/companies'
import {formatNumber} from '../../helpers/number-utils'
class TopCompanies extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
			count: 0
		}
	}
	componentDidMount() {
		/*this.setState({ isLoading: true, isError: false, items:[], count:0})
		companyServices.fetchFeaturedProjectsAndCount(3)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.projects, count: response.data.count, isLoading: false, isError: false })
			}else{
				this.setState({ items: [], count: 0, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({ items: [], count: 0, errors: error, isLoading: false, isError: true }))*/

	} 
	render () {
		const {t} = this.props
		return(
			<div className="section">
				<div className="container mb-3">
					<div className="row">
						<div className="col">
							<div className="section_title text-center">
								<h3>{t('top_companies')}</h3>
								<a href={'/companies/listings'} className="section_subtitle_a">{t('view_all') + ' '+ formatNumber(this.state.count) + ' ' + t('projects')}</a>
							</div>
						</div>
					</div>
				</div>
				<CompanyList items={this.state.items}/>
			</div>
		)
	}
}
export default translate("translations")(TopCompanies)