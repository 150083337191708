import React, { Component } from 'react'
import { translate, Trans } from "react-i18next";
class SearchBox extends Component {
	constructor(props) {
		super(props)
		this.state = {keywords:null}
		this.handleKeywordsChange = this.handleKeywordsChange.bind(this)
	}
	handleKeywordsChange(event){
		event.preventDefault()
		this.setState({keywords: event.target.value})
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<form>
					<div className="input-group mb-3">
						<input type="text" value={this.props.query} onChange={(e) => this.props.onChangeQuery(e.target.value)} className="form-control search-from__input-text" placeholder={t('search_keywords')} />
							<div className="input-group-append">
								<button className="btn btn-danger" type="button" onClick={this.props.onSubmit}><img src="/images/search.png" alt=""/></button>
							</div>
					</div>
				</form>
			</React.Fragment>
		)
	}
}
export default translate("translations")(SearchBox)
