import React, { Component } from 'react'
import { translate } from "react-i18next"
class ScrollButton extends React.Component {
	constructor() {
		super();
		this.state = {
			intervalId: 0,
			display: false
		};
		window.addEventListener('scroll', this.onScroll)
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll)
	}
	onScroll = event => {
		if(window.innerWidth < 992) {
			if(window.scrollY > 100)
			{
				this.setState({display:true})
			}
			else
			{
				this.setState({display:false})
			}
		}
		else
		{
			if(window.scrollY > 100)
			{
				this.setState({display:true})
			}
			else
			{
				this.setState({display:false})
			}
		}
        
    }
	scrollStep() {
		if (window.pageYOffset === 0) {
			clearInterval(this.state.intervalId);
		}
		window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
	}
	scrollToTop() {
		let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
		this.setState({ intervalId: intervalId });
	}
	render () {
		let {t} = this.props
		if (this.state.display === false) {
			return <></>
		}
		return <button title={t('back_to_top')} className='scroll' onClick={ () => { this.scrollToTop(); }}>
			<i className="fas fa-arrow-up"></i> 
			</button>
   }
}
export default translate("translations")(ScrollButton)