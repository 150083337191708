import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import SearchBox from '../../containers/projects/AsideSearchBox'
import ProjectListingsList from '../../components/projects/ProjectListingsList'
import Pagination from '../../components/Pagination'
import NewsLetter from '../../components/NewsLetter'
import queryString from 'query-string'
import Select from '../../components/Select'

import projectServices from '../../services/projects'
import {formatNumber} from '../../helpers/number-utils'

// import ReactCountdownClock from 'react-countdown-clock'
class Listings extends Component {
	constructor(props) {
		super(props)
		this.state = {
			total: 0,
			currentPage: 1,
			totalPages: 0,
			pageSize: 10,
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
			keywords: null,
			catId: -1,
			categories: []
		}
		this.changePage = this.changePage.bind(this)
		this.nextPage = this.nextPage.bind(this)
		this.prevPage = this.prevPage.bind(this)
		this.fetchData = this.fetchData.bind(this)
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this)

	}
	componentDidMount() {
        this.fetchData()
        window.scrollTo(0, 0);
    }
    fetchData() {
    	const values = queryString.parse(this.props.location.search)
    	if (values.catId) {
			this.props.changeSearchProjectCategory(values.catId)
		}
		if (values.cityId) {
			this.props.changeSearchProjectCity(values.cityId)
		}
		if (values.districtId) {
			this.props.changeSearchProjectDistrict(values.districtId)
		}
		if (values.wardId) {
			this.props.changeSearchProjectWard(values.wardId)
		}
		if (!values.page) {
			values.page = this.state.currentPage
		}
		if (!values.size) {
			values.size = this.state.pageSize
		}
		projectServices.searchProjects(values)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})		
	}
    handleSearchSubmit(event){
		let criteria = {}
		if (this.props.categoryId != -1) {
			criteria.catId = this.props.categoryId
		}
		if (this.props.wardId !== -1) {
			criteria.wardId = this.props.wardId	
		} else {
			if (this.props.districtId !== -1) {
				criteria.districtId = this.props.districtId	
			} else {
				if (this.props.cityId !== -1) {
					criteria.cityId = this.props.cityId
				}
			}
		}
		//this.props.onSubmitSearch(criteria)
		//this.props.history.push('/listings')
		projectServices.searchProjects(criteria)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(criteria).toString()})
		event.preventDefault()
	}
	nextPage(event) {
		if (this.state.currentPage < this.state.totalPages) {
			this.changePage(this.state.currentPage+1)
		}
	}
	prevPage(event) {
		if (this.state.currentPage > 1) {
			this.changePage(this.state.currentPage-1)
		}
	}
	changePage(page) {
		let criteria = {}
		const values = queryString.parse(this.props.location.search)
		values.page = page
		if (!values.size) {
			values.size = this.state.pageSize
		}
		projectServices.searchProjects(values)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})
      	window.scrollTo(0, 0)
	}
	
    componentWillReceiveProps(newProps) {
    	if (newProps === this.props) 
    		return
    }
	prepareHeader() {
		return(<Helmet>
			{/*facebook*/}
			<title> {"Tìm kiếm thông tin dự án bất động sản tại Việt Nam"} </title>
			<meta property="og:title" content="Tìm kiếm thông tin dự án bất động sản tại Việt Nam" />
			<meta property="og:description" content="Tìm kiếm thông tin dự án bất động sản tại Việt Nam"/>
			<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://topreal.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Tìm kiếm thông tin dự án bất động sản tại Việt Nam"/>
			<meta name="twitter:description" content="Tìm kiếm thông tin dự án bất động sản tại Việt Nam"/>
			<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>)
	}
	renderPaging() {
		if (this.state.total > this.state.pageSize) {
			return <Pagination totalPages = {this.state.totalPages} currentPage={this.state.currentPage} margin={2}
			 goto={this.changePage} next={this.nextPage} prev={this.prevPage}/>
		}
		return null
	}
	render () {
		const {t} = this.props
		const header = this.prepareHeader()
		const pagination = this.renderPaging()

		return (
			<React.Fragment>
				{header}
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="row m-3 p-0">
								<h3>{t('find_projects')}</h3>
							</div>
							<div className="row m-3">
								<div className="col-12 p-0">
									<SearchBox handleSubmit={this.handleSearchSubmit}/>
								</div>
							</div>
						</div>
						<div className="col-md-8 mb-4">
							<div className="row ml-0 mt-3 mb-3 p-0">
								<div className="col-md-6 p-0">
									<h3 className="text-lowercase">{formatNumber(this.state.total) + ' ' + t('results')}</h3>
								</div>
								<div className="col-md-6">
									<div className="d-flex float-right">
										<label style={{paddingTop: "4px"}}>{t('sort')}</label>
										<div>
											<Select classes={"search_from__select-input--while float-right"} items={[{id:1, name: "Phù hợp nhất"}, {id:2, name: "Giá cao nhất"}, {id:3, name: "Giá thấp nhất"}]} />
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<ProjectListingsList items={this.state.items}/>
									<nav className="mt-3">
										{pagination}
									</nav>
								</div>
							</div>
						</div>
					</div>
				</div>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Listings)
