import React, {Component}from 'react'
import { translate } from 'react-i18next'
import ProjectList from './ProjectList'
import cityServices from '../../services/cities'
import {formatNumber} from '../../helpers/number-utils'
class ProjectsInCity extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
			count: 0
		}
	}
	componentDidMount() {
		
		this.setState({ isLoading: true, isError: false, items:[], count:0})
		cityServices.fectchLatestProjectsAndCountByCityId(this.props.cityId)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.projects, count: response.data.count, isLoading: false, isError: false })
			}else{
				this.setState({ items: [], count: 0, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({ items: [], count: 0, errors: error, isLoading: false, isError: true }))
	}
	render () {
		const {t} = this.props
		return (
			<div className="section">
				<div className="container mb-3">
					<div className="row">
						<div className="col">
							<div className="section_title text-center">
								<h3 className="mb-2">{t('new_projects_listings_in') + " " + this.props.cityName}</h3>
								<a href={'/projects/listings?cityId=' + this.props.cityId} className="section_subtitle_a">{t('view_all') + ' '+ formatNumber(this.state.count) + ' ' + t('projects')}</a>
							</div>
						</div>
					</div>
				</div>
				<ProjectList items={this.state.items}/>
			</div>
		)
	}
}

export default translate("translations")(ProjectsInCity)