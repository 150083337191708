import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import NewsLetter from '../../components/NewsLetter'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

// import ReactCountdownClock from 'react-countdown-clock'
class FundingDetails extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"} </title>
					<meta property="og:title" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam" />
					<meta property="og:description" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"/>
					<meta property="og:image" content="http://topauto.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topauto.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"/>
					<meta name="twitter:description" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"/>
					<meta name="twitter:image" content="http://topauto.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<div className="container">
					<div className="row m-3">
						<div className="col-12">
							<h1 className="news-details__title text-center">{'Jollylook Auto - The Modern Vintage Instant Film Camera!'}</h1>
							<h5 className="text-center">{'Combining a classic design with environmentally friendly materials, a manual development mechanism, automatic shutter, and a flash.'}</h5>
						</div>
					</div>
					<div className="row">
						<div className="card border-0 col-12 mb-1" >
							<div className="row">
								<img className="card-img col-md-8 col-12 p-0" style={{height: '460px'}} src={"/images/no_image.svg"} alt=""/>
								<div className="card-body col-md-4 col-12 border-top border-success">
									<h5 className="card-title" style={{fontSize: '30px'}}>{'US$ 133,671'}</h5>
									<p className="card-text">{'pledged of US$ 20,000 goal'}</p>
									<h5 class="card-title" style={{fontSize: '24px'}}>{'10000'}</h5>
									<p className="card-text">{'backers'}</p>
									<h5 class="card-title" style={{fontSize: '24px'}}>{'20'}</h5>
									<p className="card-text">{'days ago'}</p>
									<a href="#" className="btn btn-success">{'back_this_rasing'}</a>
									<div>
									</div>
									<p className="card-text">{'All or nothing. This project will only be funded if it reaches its goal by Tue, February 18 2020 7:00 PM UTC +07:00.'}</p>
								</div>
							</div>
						</div>
						<div className="col-12">
						</div>
					</div>
					
				</div>
				<div className="container-fluid">
				<div className="row">
						<div className="col-12">
							<Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
								<Tab eventKey="home" title={t('description')}>
									<h1>{'home'}</h1>
								</Tab>
								<Tab eventKey="faqs" title={t('faqs')}>
									<h1>{'faqs'}</h1>
								</Tab>
								<Tab eventKey="updates" title={t('updates')}>
									<div> 
										<h1>{'updates'}</h1>
									</div>
								</Tab>
								<Tab eventKey="comments" title={t('comments')}>
									<div> 
										<h1>{'comments'}</h1>
									</div>
								</Tab>
							</Tabs>
						</div>
					</div>
					</div>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(FundingDetails)
