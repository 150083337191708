import {ROOT_URL, DEVICES_V1} from '../configs/configs'
import { sha256 } from 'js-sha256'
import 'clientjs'
export default {
	headerClientId() {
		localStorage.getItem('X-Zetgoo-ClientId')
	},
	fetchDeviceSession() {
		//try {
			const client = new ClientJS()  // eslint-disable-line no-undef
			var data = {
				'lang': client.getLanguage(),
				'timeZone': client.getTimeZone(),
				'deviceXDPI': client.getDeviceXDPI(),
				'deviceYDPI': client.getDeviceYDPI(),
				'availableResolution': client.getAvailableResolution(),
				'currentResolution': client.getCurrentResolution(),
				'colorDepth': client.getColorDepth(),
				'isMobileIOS': client.isMobileIOS(),
				'isIpod': client.isIpod(),
				'isIpad': client.isIpad(),
				'isIphone': client.isIphone(),
				'isMobile': client.isMobile(),
				'isMobileMajor': client.isMobileMajor(),
				'isMobileAndroid': client.isMobileAndroid(),
				'isMobileOpera': client.isMobileOpera(),
				'isMobileWindows': client.isMobileWindows(),
				'isMobileBlackBerry': client.isMobileBlackBerry(),
				'cpu': client.getCPU(),
				'os': client.getOS(),
				'osVersion': client.getOSVersion(),
				'isWindows': client.isWindows(),
				'isMac': client.isMac(),
				'isLinux': client.isLinux(),
				'isUbuntu': client.isUbuntu(),
				'isSolaris': client.isSolaris(),
				'engine': client.getEngine(),
				'engineVersion': client.getEngineVersion(),
				'browser': client.getBrowser(),
				'browserVersion': client.getBrowserVersion(),
				'browserMajorVersion': client.getBrowserMajorVersion(),
				'isIE': client.isIE(),
				'isFirefox': client.isFirefox(),
				'isSafari': client.isSafari(),
				'isOpera': client.isOpera(),
				'isMobileSafari': client.isMobileSafari(),
				'userAgent': client.getUserAgent(),
				'userAgentLowerCase': client.getUserAgentLowerCase(),
				'fingerprint': client.getFingerprint()
			}
			data.fingerprint = data.fingerprint + '-' + sha256(JSON.stringify(data))
			localStorage.setItem('X-Zetgoo-Client-FingerPrint', data.fingerprint)
			let headers = {
				"Content-Type": "application/json; charset=utf-8"
			}
			if (localStorage.getItem('X-Zetgoo-ClientId')) {
				headers['X-Zetgoo-ClientId'] = localStorage.getItem('X-Zetgoo-ClientId')
			}
			return fetch(ROOT_URL + DEVICES_V1.GET_DEVICE_SESSION, {
	    		method: 'POST',
	    		headers,
	    		body: JSON.stringify(data)
	  		}).then(response => {
				return response.json();
			}).catch(error => {
				return error;
			});
		/*} catch {
			console.log("ERROR-Cannot fetch fetchDeviceSession")
		}*/
		
	}
}