import React, {Component} from 'react'
import { translate } from "react-i18next"

class AdvertiserInfo extends Component {
	constructor(props) {
		super(props)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleSubmit(event){
		event.preventDefault()
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<div className="container">
					<form>
						<div className="form-group">
							<center>
							<img width="120px" height="120px" src="/images/icon-user-orange.svg" className="d-block" style={{objectFit: "cover", marginTop:"-60px", borderRadius:"35px"}} alt={this.props.name}/>
							</center>
						</div>
						<div className="form-group">
							<h4 className="text-center">{this.props.name}</h4>
						</div>
						<div className="form-group">
							<a className="btn btn-block" style={{backgroundColor: "#222b45", color:"#FFF", fontWeight: "bold"}} href={'tel:' + this.props.phone}><i className="fas fa-phone-volume mr-2"></i>{this.props.phone}</a>
						</div>
					</form>
				</div>
			</React.Fragment>
		)
	}
}

export default translate("translations")(AdvertiserInfo)