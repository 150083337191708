import React from 'react'
import { translate } from "react-i18next"
import QuestionAnswerList from '../../components/helps/QuestionAnswerList'
import { Form, Button, Accordion, Card } from 'react-bootstrap'

const fqaItems = [
				{
					id: 1,
					question: 'Đăng ký tài khoản sử dụng dịch vụ?',
					answer: 'Chúng tôi sử dịch vụ quản lý tài khoản người dùng được cung cấp bởi Zetgoo. Khi bạn đăng ký tài khoản Zetgoo, bạn có thể sử dụng toàn bộ hệ sinh thái ứng dụng của Zetgoo & TopLabs cung cấp.',
				},
				{
					id: 2,
					question: 'Đăng tin miễn phí như thế nào?',
					answer: 'Chúng tôi cung cấp dịch vụ đăng tin miễn phí trên web. Việc đăng tin này được áp dụng cho người dùng có tài khoản & không có tài khoản. Người dùng có tài khoản thành viên sẽ được sử dng hệ thống để theo dõi tình trạng tin đăng cũng như các số liệu thống kê liên quan.',
					open: false
				},
				{
					id: 3,
					question: 'Tôi có thể tải ứng dụng của TopReal ở đâu?',
					answer: 'Bạn có thể tải ứng dụng TopReal từ Google Play Store (đối với thiết bị chạy Android) hoặc App Store (đối với thiết bị chạy iOS) HOÀN TOÀN MIỄN PHÍ.',
				},
				{
					id: 4,
					question: 'Tại sao tôi không thể tải xuống Ứng dụng TopReal trên thiết bị của mình?',
					answer: 'Để cài đặt ứng dụng TopReal trên Android, thiết bị của bạn tối thiểu phải chạy hệ điều hành Android 4.0 Ice Cream Sandwich. Để cài đặt ứng dụng TopReal trên iOS, thiết bị của bạn tối thiểu phải chạy hệ điều hành iOS 7. Hãy bảo đảm rằng thiết bị của bạn kết nối với mạng 3G hoặc nguồn phát WiFi ổn định.',
				}				
			]
///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
const LawFAQSection = ({t}) => (
	<div className="section">
		<div className="container p-0">
			<div className="row">
				<div className="col">
					<div className="section_title text-center mb-3">
						<h3>{t('faq')}</h3>
						{/*<QuestionAnswerList items={fqaItems} />*/}
					</div>
					<div className="container p-0">
						<Accordion defaultActiveKey="0">
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										{'Sổ hồng và sổ đỏ khác nhau như thế nào?'}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body>
										<ol className="card-title text-justify">
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Sổ hồng và sổ đỏ chỉ là cách gọi mà người dân tự đặt để tiện phân biệt giữa các loại giấy chứng nhận.'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Sổ đỏ là mẫu do Bộ Tài nguyên và Môi trường ban hành, bìa có màu đỏ, với nội dung ghi nhận quyền sử dụng đất (có thể là đất ở, đất sản xuất nông nghiệp, vườn, ao, đất rừng…) nên mẫu này có tên gọi đúng là “Giấy chứng nhận quyền sử dụng đất”.'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Sổ hồng là mẫu do Bộ Xây dựng ban hành, bìa có màu hồng với nội dung là ghi nhận quyền sở hữu nhà ở và quyền sử dụng đất ở nên mẫu có tên gọi là “Giấy chứng nhận quyền sở hữu nhà ở và quyền sử dụng đất ở”.'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Tuy nhiên, để thống nhất thành một loại Giấy chứng nhận, ngày 19/10/2009 Chính phủ ban hành Nghị định số 88/2009/NĐ-CP và ngày 21/10/2009 Bộ Tài nguyên và Môi trường ban hành Thông tư số 17/2009/TT-BTNMT về cấp Giấy chứng nhận quyền sử dụng đất, quyền sở hữu nhà ở và tài sản khác gắn liền với đất. Theo đó, hai loại Giấy chứng nhận nói trên sẽ do Bộ Tài nguyên và Môi trường phát hành theo một mẫu thống nhất và được áp dụng trong phạm vi cả nước đối với mọi loại đất, nhà ở và tài sản khác gắn liền với đất.'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Hiện nay vẫn đang lưu hành cả 3 loại giấy tờ là Giấy chứng nhận quyền sử dụng đất, Giấy chứng nhận quyền sở hữu nhà ở và Giấy chứng quyền sử dụng đất, quyền sở hữu nhà ở và tài sản khác gắn liền với đất. Cả 3 đều có giá trị pháp lý như nhau.'}
											</li>
										</ol>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="2">
										{'Bên nào sẽ chịu trách nhiệm sang tên sổ đỏ khi mua bán?'}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="2">
									<Card.Body>
										<ol className="card-title text-justify">
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Các bên sẽ tiến hành thỏa thuận để 1 bên thực hiện nộp hồ sơ đề nghị cơ quan nhà nước có thẩm quyền cấp giấy chứng nhận đối với nhà ở đó.'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Nếu như trong trường hợp mua hay thuê mua nhà ở của chủ đầu tư dự án bất động sản nào đó thì chủ đầu tư sẽ có trách nhiệm làm thủ tục để cơ quan nhà nước có thẩm quyền cấp giấy chứng nhận quyền sử dụng đất cho bên mua. Trừ trường hợp bên mua tự nguyện làm thủ tục cấp giấy chứng nhận quyền sử dụng đất thì chủ đầu tư không cần phải thực hiện.'}
											</li>
										</ol>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="3">
										{'Nếu chưa hoàn tất thủ tục sang tên thì có được cấp sổ đỏ cho bên mua?'}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="3">
									<Card.Body>
										<ol className="card-title text-justify">
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Theo như nghị định 01/2017/NĐ-CP có hiệu lực từ ngày 03/03/2017 thì trường hợp đã chuyển quyền sử dụng đất nhưng chưa sang tên thì vẫn sẽ được cấp sổ đỏ, quyền sở hữu nhà ở và tài sản cho chủ mới.'}
											</li>
										</ol>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="4">
										{'Nếu mua bán nhà bằng giấy viết tay thì có được cấp sổ đỏ không?'}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="4">
									<Card.Body>
										<ol className="card-title text-justify">
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Theo như nghị định 01/2017/NĐ-CP có hiệu lực từ ngày 03/03/2017 thì việc mua bán nhà đất bằng giấy viết tay trước ngày 01/07/2014 thì sẽ được cấp sổ đỏ. Cụ thể:'}
												<ol className="pl-4">
													<li><i className="fas fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
														{'Người dân đang sử dụng đất mà miếng đất và tài sản gắn liền với đất do chuyển nhượng, nhận, tặng trong thời gian từ 1.1.2008 đến trước 1.7.2014 nếu đã có giấy tờ về quyền sử dụng đất thì sẽ được cấp sổ đỏ, quyền sở hữu nhà ở, tài sản gắn liền với đất lần đầu mà không cần phải thực hiện thủ tục chuyển quyền sử dụng đất.'}
													</li>
													<li><i className="fas fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
														{'Nếu như người dân đang sử dụng đất do nhận được chuyển nhượng, nhận tặng cho từ trước thời gian ngày 1.1.2008 đến trước ngày 1.7.2008 nếu không có giấy tờ chứng nhận về quyền sử dụng đất cũng sẽ được cấp giấy chứng nhận quyền sử dụng đất. Họ cũng sẽ không bị buộc phải làm thủ tục chuyển quyền sử dụng đất, các cơ quan tiếp nhận hồ sơ cũng sẽ không được bắt buộc những người đó phải nộp các hợp đồng hay văn bản chuyển quyền sử dụng đất.'}
													</li>
												</ol>
											</li>
										</ol>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="5">
										{'Thủ tục chuyển nhượng, mua bán nhà đất?'}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="5">
									<Card.Body>
										<ol className="card-title text-justify">
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Việc mua bán nhà đất phải được lập thành hợp đồng có công chứng. Lập xong hợp đồng mua bán nhà, hai bên làm thủ tục sang tên trước bạ và đóng thuế.'}
											</li>
										</ol>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="6">
										{'Các loại thuế phải đóng khi chuyển nhượng nhà đất?'}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="6">
									<Card.Body>
										<ol className="card-title text-justify">
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Theo quy định tại khoản 1 Điều 2 Nghị định 45/2011/NĐ-CP ngày 17/6/2011 đất là một trong các đối tượng chịu lệ phí trước bạ. Lệ phí trước bạ được tính như sau:'}
												<ol className="pl-4">
													<li><i className="fas fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
														{'Lệ phí trước bạ = 0.5% x Giá tính lệ phí trước bạ (giá do Ủy ban nhân dân tỉnh, thành phố trực thuộc Trung ương ban hành).'}
													</li>
												</ol>
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Căn cứ vào công văn số 17526/BTC-TCT về Triển khai thực hiện một số nội dung tại Luật sửa đổi, bổ sung một số điều của các Luật về thuế. Từ 01/01/2015, áp dụng một mức thuế suất 2% trên giá chuyển nhượng từng lần đối với chuyển nhượng bất động sản thay cho thực hiện 02 phương pháp tính thuế trước đây”.'}
												<ol className="pl-4">
													<li><i className="fas fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
														{'Thuế thu nhập cá nhân = 2% x (Giá chuyển nhượng).'}
													</li>
													<li><i className="fas fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
														{'Ngoài ra, theo Luật thuế thu nhập cá nhân 2007: “Thu nhập từ chuyển nhượng nhà ở, quyền sử dụng đất ở và tài sản gắn liền với đất ở của cá nhân trong trường hợp cá nhân chỉ có một nhà ở, đất ở duy nhất” sẽ được miễn thuế.'}
													</li>
												</ol>
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Lệ phí địa chính: 15.000 đồng'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Lệ phí thẩm định: 0.15% giá trị chuyển nhượng (tối thiểu 100.000 đồng và tối đa 5.000.000 đồng).'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Lệ phí công chứng: Mức thu lệ phí chứng thực hợp đồng, giao dịch liên quan đến bất động sản được xác định theo giá trị tài sản hoặc giá trị hợp đồng, giao dịch được quy định chi tiết tại Khoản 1 Điều 3 Thông tư liên tịch 62/2013/TTLT-BTC-BTP.'}
											</li>
										</ol>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="7">
										{'Hợp đồng thuê nhà có cần công chứng?'}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="7">
									<Card.Body>
										<ol className="card-title text-justify">
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Khoản 2, Điều 122, Luật Nhà ở năm 2014 (có hiệu lực từ ngày 01/07/2015) quy định về công chứng, chứng thực hợp đồng về nhà ở lại quy định đối với trường hợp cho thuê, cho mượn, cho ở nhờ, ủy quyền quản lý nhà ở thì KHÔNG BẮT BUỘC phải công chứng, chứng thực hợp đồng, trừ trường hợp các bên có nhu cầu.'}
											</li>
										</ol>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="8">
										{'Nhà riêng, căn hộ phải thỏa mãn điều kiện nào để cho thuê?'}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="8">
									<Card.Body>
										<ol className="card-title text-justify">
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Không thuộc diện đang có tranh chấp, khiếu nại, khiếu kiện về quyền sở hữu; đang trong thời hạn sở hữu nhà ở đối với trường hợp sở nhữ nhà ở có thời hạn.'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Không bị kê biên để thi hành án hoặc ko bị kê biên để chấp hành quyết định hành chính đã có hiệu lực pháp luật của cơ quan nhà nước có thẩm quyền.'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Không thuộc diện đã có quyết định thu hồi đất, có thông báo giải tỏa, phá dỡ nhà ở của cơ quan có thẩm quyền.'}
											</li>
										</ol>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="9">
										{'Cho thuê nhà có phải đóng thuế?'}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="9">
									<Card.Body>
										<ol className="card-title text-justify">
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Theo quy định của pháp luật, cá nhân, hộ gia đình khi cho thuê nhà sẽ phải nộp lệ phí môn bài, thuế giá trị gia tăng và thuế thu nhập cá nhân. Tuy nhiên cá nhân có hoạt động cho thuê tài sản nói chung và cho thuê nhà nói riêng KHÔNG phải nộp thuế và lệ phí môn bài đối với hoạt động cho thuê nhà khi có mức doanh thu dưới 100 triệu đồng/năm.'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Thuế giá trị gia tăng (GTGT) = Doanh thu tính thuế GTGT * 5% (tỷ lệ thuế GTGT).'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Thuế thu nhập cá nhân (TNCN) = Doanh thu tính thuế TNCN * 5% (tỷ lệ thuế TNCN).'}
											</li>
											<li><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>
												{'Lệ phí môn bài đối với cá nhân, hộ gia đình hoạt động sản xuất, kinh doanh hàng hóa, dịch vụ như sau:'}
												<ol className="pl-4">
													<li><i className="fas fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
														{'1,000,000 VND/năm cho cá nhân, hộ gia đình có doanh thu lớn hơn 500 triệu VND/năm.'}
													</li>
													<li><i className="fas fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
														{'500,000 VND/năm cho cá nhân, hộ gia đình có doanh thu từ 300 - 500 triệu VND/năm.'}
													</li>
													<li><i className="fas fa-arrow-alt-circle-right mr-2" aria-hidden="true"></i>
														{'300,000 VND/năm cho cá nhân, hộ gia đình có doanh thu từ 100 - 300 triệu VND/năm.'}
													</li>
												</ol>
											</li>
										</ol>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</div>
				</div>
			</div>
		</div>
	</div>
)
export default translate("translations")(LawFAQSection);