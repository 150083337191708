import React from 'react'
import { translate } from 'react-i18next'
import { Link } from 'react-router-dom'

const FeaturedPostListItem = ({t, history, item, src, ids}) => {
	let detailsUrl = '/listings/details/' + item.seoPath+ "/" + item.id
	let query = null
	if (ids) {
		query = "ids="+ids
	}
	if (src) {
		if (query == null) {
			query="src="+src
		} else {
			query+="&src="+src
		}
	}
	if (query) {
		detailsUrl += "?"+query
	}
	return (
	<div className="card border-0 text-center col-md-4 col-12" >
		<Link to={detailsUrl}>
			<img className="card-img-top" style={{borderRadius: '12px', height: '200px', objectFit: 'cover'}} src={item.imageUrl?item.imageUrl:"http://topreal.vn/images/no_image.png"} alt=""/>
		</Link >
		<div className="card-body">
			<div className="d-flex flex-row justify-content-center">
				{ item.noBedRooms ? (
					<div className="card-text post-listings-item__body--attributes">{item.noBedRooms + ' ' + t('rooms')}</div>): (<></>) }
				{ item.noBedRooms ? (<div className="card-text ml-2 mr-2 post-listings-item__body--attributes">{'|'}</div>): (<></>) }
				<div className="card-text post-listings-item__body--attributes">{item.areaText}
				</div>
			</div>
			<address className="card-text post-listings-item__body--address">{item.address}</address>
			<Link to={detailsUrl}>
				<div className="card-text post-listings-item__body--price">
					{item.priceText}
				</div>
			</Link>
		</div>
	</div>
)
}
export default translate("translations")(FeaturedPostListItem)