import React, {Component} from 'react'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"

import NewsLetter from '../../components/NewsLetter'
import Breadcrumbs from '../../components/users/Breadcrumbs'
import LeftNav from '../../components/users/LeftNav'
import ChangePassword from '../../components/users/ChangePassword'
import UserProfile from '../../components/users/UserProfile'
import UserFavouritePosts from '../../components/users/UserFavouritePosts'
import UserPosts from '../../components/users/UserPosts'
import UserAddPost from '../../components/users/UserAddPost'
import Wallet from '../../components/users/Wallet'
import Packages from '../../components/users/Packages'


import { Redirect, Link, Route, Switch } from "react-router-dom";
import PrivateRoute from '../../routers/PrivateRoute'
// import ReactCountdownClock from 'react-countdown-clock'
class UserAccount extends Component {
	constructor(props) {
		super(props)
		this.state = {
			current: this.props.match.params.path
		}
	}
	componentDidMount() {
	}
	componentWillReceiveProps(newProps) {
		if (newProps === this.props) 
			return
		if (newProps.match.params.path !== this.state.current) {
			this.setState({current:newProps.match.params.path})
		}
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Bảng báo giá dịch vụ đăng tin tại TopReal"} </title>
					<meta property="og:title" content="Bảng báo giá dịch vu đăng tin tại TopReal" />
					<meta property="og:description" content="Bảng báo giá dịch vụ đăng tin tại TopReal"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Bảng báo giá dịch vụ đăng tin tại TopReal"/>
					<meta name="twitter:description" content="Bảng báo giá dịch vụ đăng tin tại TopReal"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
					<meta name="robots" content="noindex"/>
				</Helmet>
				<Breadcrumbs/>
				<div className="container mt-3">
					<div className="row">
						<div className="col-md-3">
							<LeftNav current={this.state.current}/>
						</div>
						<div className="col-md-9">
							<Switch>
								<PrivateRoute exact path="/users/profile" component={UserProfile} />
								<Route exact path="/users/my-favourites" component={UserFavouritePosts} />
								<PrivateRoute exact path="/users/add-ads" component={UserAddPost} />
								<Route exact path="/users/my-properties" component={UserPosts} />
								<PrivateRoute exact path="/users/change-password" component={ChangePassword} />
								<PrivateRoute exact path="/users/my-wallet" component={Wallet} />
								<PrivateRoute exact path="/users/my-packages" component={Packages} />
					        </Switch>
						</div>
					</div>
				</div> 
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(UserAccount)