import React from 'react'
import { translate, Trans } from "react-i18next"
//import Parser from 'html-react-parser'

const ExampleAdsListItem = ({t, item}) => (
	<div className="post_example_item">
		<div>
			{t('title') + ":"} 
			<span className="post_example_item_title_text">
				{item.title}
			</span>
		</div>
		
		<div className="post_example_item_text">
			{/*Parser(item.description)*/}
			{item.description}
		</div>
		<div>
			{t('contact_name') + ":"}
			<span className="post_example_item_contact_text">
				{item.contactName}
			</span>
		</div>
		<div>
			{t('contact_phone') + ":"}
			<span className="post_example_item_contact_text">
				{item.contactPhone}
			</span>
		</div>
	</div>
)
export default translate("translations")(ExampleAdsListItem)