import React from 'react'
import { translate } from 'react-i18next'
///listings/details/:seo/:id?list=[]&back=?
const PostsByAdvertiserListItem = ({t, item,src, ids}) => (
	<div className="card border-0 text-center col-md-4 col-12 mb-3" style={{backgroundColor: "#f5f5f5"}}>
		<a href={'/listings/details/' + item.seoPath+ "/" + item.id + "?ids="+ids + "&src=" + src}>
			<img className="card-img-top" style={{height: '200px', objectFit: "cover"}} src={item.imageUrl?item.imageUrl: '/images/no_image1.svg'} alt={item.title}/>
		</a>
		<div className="card-body rounded-bottom" style={{backgroundColor: "#FFFFFF"}}>
			<div className="d-flex flex-row justify-content-center">
				{ item.noBedRooms ? (
					<div className="card-text post-listings-item__body--attributes">{item.noBedRooms}</div>): (<></>) }
				{ item.noBedRooms ? (<div className="card-text ml-2 mr-2 post-listings-item__body--attributes">{'|'}</div>): (<></>) }
				<div className="card-text post-listings-item__body--attributes">{item.areaText}
				</div>
			</div>
			<address className="card-text post-listings-item__body--address">{item.address}</address>
			<a href={'/listings/details/' + item.seoPath+ "/" + item.id + "?ids="+ids + "&src=" + src}>
				<div className="card-text post-listings-item__body--price">
					{item.priceText}
				</div>
			</a>
		</div>
	</div>
)
export default translate("translations")(PostsByAdvertiserListItem)