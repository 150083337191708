import React, {Component}from 'react'
import { translate } from 'react-i18next'
import PostInCityList from './PostInCityList'
import Loading from "react-spinners/ClockLoader"
import format from 'string-format'
import {ROOT_URL, CITY_V1} from '../../configs/configs'
import {formatNumber} from '../../helpers/number-utils'
class NewPostListings extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
			count: 0
		}
	}
	componentDidMount() {	
		this.setState({ isLoading: true, isError: false, items:[]})
		fetch(ROOT_URL + format(CITY_V1.GET_LATEST_POSTS_AND_COUNT,this.props.cityId))
			.then(response => response.json())
			.then(data => this.setState({ items: data.data.posts, count: data.data.count, isLoading: false, isError: false }))
			.catch(error => this.setState({ items: [], count: 0, errors: error, isLoading: false, isError: true }))
	}
	render () {
		const {t} = this.props
		return (
			<div className="mb-2">
				<div className="container mb-3">
					<div className="row">
						<div className="col">
							<div className="section_title text-center">
								<h3 className="mb-2">{t('new_posts_listings_in') + " " + this.props.cityName}</h3>
								<a href={'/listings?cityId=' + this.props.cityId} className="section_subtitle_a">{t('view_all') + ' '+ formatNumber(this.state.count) + ' ' + t('ads')}</a>
							</div>
						</div>
					</div>
				</div>
				<PostInCityList items={this.state.items}/>
			</div>
		)
	}
}
export default translate("translations")(NewPostListings);