import React from 'react'
import { connect } from 'react-redux'
import {fetchCities} from '../actions/core-data'
import {fetchAreaRanges,fetchBuyPriceRanges,fetchRentPriceRanges,changeSearchMethod,fetchSeachMethod} from '../actions/posts-search-params'
import Home from '../pages/Home'
import { withRouter } from 'react-router-dom'
const mapStateToProps = state => ({
	method: state.postSearchParams.method
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  getCities: () => dispatch(fetchCities()),
  getAreaRanges: () => dispatch(fetchAreaRanges()),
  getBuyPriceRanges: () => dispatch(fetchBuyPriceRanges()),
  getRentPriceRanges: () => dispatch(fetchRentPriceRanges()),
  changePostSearchMethod: (id) => dispatch(changeSearchMethod(id)),
  getSeachMethod: (id) => dispatch(fetchSeachMethod(id)) 
})
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Home))