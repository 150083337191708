import React, {Component} from 'react'
import { translate, Trans } from "react-i18next"
import AdvertiserContactBox from '../../components/advertisers/AdvertiserContactBox'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import postServices from '../../services/posts'
import { css } from "@emotion/core"
import Loading from "react-spinners/ClockLoader"
import {formatNumber} from '../../helpers/number-utils'
import PostList from '../../components/posts/PostList'
import Pagination from '../../components/Pagination'
class AgentPosts extends Component {
	constructor(props) {
		super(props)

		this.state = {
			phone: null,
			name: null,
			total: 0,
			currentPage: 1,
			totalPages: 0,
			pageSize: 10,
			items: [],
			isLoading: false,
			isError: false,
			errors: null
		}
		this.changePage = this.changePage.bind(this)
		this.nextPage = this.nextPage.bind(this)
		this.prevPage = this.prevPage.bind(this)
		//this.props.history.push('/agents/posts?fingerprint='+values.fingerprint)

	}
	componentDidMount() {
		const values = queryString.parse(this.props.location.search)
		if (!values.page) {
			values.page = this.state.currentPage
		}
		if (!values.size) {
			values.size = this.state.pageSize
		}
		this.setState({isLoading: true})
		postServices.getPostsListItemsAndCountByContact(this.props.match.params.fp,values.page,values.size)
		.then(response => {
			if (response.status === 'OK')
			{
				let name = null
				let phone = null
				if (response.data.result.length > 0) {
					name = response.data.result[0].contactName
					phone = response.data.result[0].contactPhone
				}
				this.setState({name, phone, items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})		

	}
	nextPage(event) {
		if (this.state.currentPage < this.state.totalPages) {
			this.changePage(this.state.currentPage+1)
		}
	}
	prevPage(event) {
		if (this.state.currentPage > 1) {
			this.changePage(this.state.currentPage-1)
		}
	}
	changePage(page) {
		let criteria = {}
		const values = queryString.parse(this.props.location.search)
		values.page = page
		if (!values.size) {
			values.size = this.state.pageSize
		}
		this.setState({isLoading: true})
		postServices.getPostsListItemsAndCountByContact(this.props.match.params.fp,values.page,values.size)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})
      	window.scrollTo(0, 0)
	}
	renderPaging() {
		if (this.state.total > this.state.pageSize) {
			return <Pagination totalPages = {this.state.totalPages} currentPage={this.state.currentPage} margin={2}
			 goto={this.changePage} next={this.nextPage} prev={this.prevPage}/>
		}
		return null
	}
	render () {
		const {t} = this.props
		const pagination = this.renderPaging()
		return (
			<React.Fragment>
				<div className="container mt-3 mb-3">
					<div className="row">
						<div className="col-lg-8">
							<div className="row m-3 p-0">
								<div className="col-md-6 p-0">
									<h3 className="text-lowercase">{formatNumber(this.state.total) + ' ' + t('results')}</h3>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-12">
									<PostList items={this.state.items}/>
									<div className="center">
										{pagination}
									</div>
								</div>
							</div>
							<div className="row">
								<Loading css={{display: 'block', margin: '0 auto', borderColor: 'red'}} size={70} color={"#0e1d41"} loading={this.state.isLoading} />
							</div>
						</div>
						<div className="col-lg-4">
							<AdvertiserContactBox phone={this.state.phone} name={this.state.name} refRes={"PROPERTY:" + "ALL"}/>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
export default withRouter(translate("translations")(AgentPosts))