import React, {Component} from 'react'
import NewsLetter from '../../components/NewsLetter'
import TopProjects from '../../components/projects/TopProjects'
import NewPostListings from '../../components/posts/NewPostListings'
import Breadcrumbs from '../../components/memberships/Breadcrumbs'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
class Memberships extends Component {
	constructor(props) {
		super(props)
	}
	renderHeader() {
		return(<Helmet>
			{/*facebook*/}
			<title> {"Chương trình thành viên tại TopReal"} </title>
			<meta property="og:title" content="Chương trình thành viên tại TopReal" />
			<meta property="og:description" content="Chương trình thành viên tại TopReal"/>
			<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://topreal.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Chương trình thành viên tại TopReal"/>
			<meta name="twitter:description" content="Chương trình thành viên tại TopReal"/>
			<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>)
	}
	render () {
		const {t} = this.props
		const header = this.renderHeader()
		return (
			<React.Fragment>
				{header}
				<Breadcrumbs/>
				<div className="section">
					<div className="container mb-3">
						<div className="row">
							<div className="col">
								<div className="section_title text-center">
									<h3>{t('memberships_in_topreal')}</h3>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<Tabs defaultActiveKey="diamond" id="uncontrolled-tab-example">
									<Tab eventKey="diamond" title="Diamond">
									</Tab>
									<Tab eventKey="gold" title="Gold">
									</Tab>
									<Tab eventKey="siler" title="Silver">
									</Tab>
									<Tab eventKey="new" title="New">
									</Tab>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
				<NewPostListings cityName={"Hồ Chí Minh"} cityId={51} />
				<TopProjects/> 
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Memberships)