import React, {Component}  from 'react'
import { translate, Trans } from "react-i18next"
import ExampleAdsListItem from './ExampleAdsListItem'
class ExampleAdsList extends Component {
	constructor(props) {
    	super(props)
  	}
  	state = {
		items: [
			{
				id: 1,
				title: "Bán đất mặt tiền dự án Thủ Đức House, đường Trần Não, Quận 2",
				description: "Cần bán đất dự án Thủ Đức House, Quận 2, mặt tiền đường 30m, dự án nằm ngay góc ngã 4 Trần Não và Lương Định Của, khu đô thị mới Thủ Thiêm <br/>- Lô L <br/>- M: diện tích 7,5x20m đường rộng 30m giá 83tr/m2. <br/>- Lô H: diện tích 7,5x20m đối diện công viên giá 75tr/m2. <br>- Lô M: diện tích7,5x20m đường 12m giá 75tr/m2.",
				contactName: "Nguyễn Văn A",
				contactPhone: "0905911xxx"
			},
			{
				id: 2,
				title: "Bán đất nền huyện Bình Chánh sổ hồng riêng, giá chỉ 189tr",
				description: "Cần bán đất nền dự án tại huyện Bình Chánh sổ hồng riêng, bao GPXD, diện tích đa dạng, tiện kinh doanh, buôn bán, xây phòng trọ cho thuê. <br/> - Diện tích 5x15, 5x16, 5x19, 5x20, 5x21, 5x26, 6x19, 6x20m. <br/>- Đường nhựa 16-42m, nước máy, điện âm, cáp, internet. <br/>- Ngay khu dân cư, đối diện BV Chợ Rẫy II, BV Đa Khoa Tân Tạo - Gần chợ, siêu thị Vĩnh Lộc, trường Cấp I, II, III. <br/>- Ngay giao lộ Trần Văn Giàu, gần Cao tốc Trung Lương lộ giới 60m.",
				contactName: "Nguyễn Văn B",
				contactPhone: "0908722xxx"
			}
		]
	}
	render () {
		const {t} = this.props
		return ( 
			<React.Fragment>
				<div className="card">
					<div className="card-header">{t('example_advertisement')}</div>
					<div className="card-body">
						{this.state.items.map(item => <ExampleAdsListItem key={item.id} item={item}/>)}
					</div> 
				</div>
			</React.Fragment>
		)
	}
}


export default translate("translations")(ExampleAdsList)