import coreDateServices from '../services/core-data'
import postServices from '../services/posts'
import * as types from './action-types'
import {SEARCH_BUY_PRICE_RANGE, SEARCH_RENT_PRICE_RANGE, SEARCH_AREA_PRICE_RANGE,
SEARCH_METHOD_BUY,SEARCH_METHOD_RENT} from '../configs/default-params'
import {fetchPropertyTypesByParent,fetchDistricts,fetchWards,fetchPropertyCategoriesByType} from './core-data'
export function fetchBuyPriceRanges()
{
	return function (dispatch) {
		dispatch(fetchBuyPriceRangesRequest())
		coreDateServices.fetchBuyPriceRanges()
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchBuyPriceRangesSuccess(response.data))
				}else{
					dispatch(fetchBuyPriceRangesError(response.errors))
				}
			})
			.catch(error => {dispatch(fetchBuyPriceRangesError(error))})
	}
}

export function fetchBuyPriceRangesRequest()
{
	return {type: types.FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_REQUEST, payload: null}
}
export function fetchBuyPriceRangesSuccess(ranges)
{
	return {type: types.FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_SUCCESS, payload: ranges}
}
export function fetchBuyPriceRangesError(errors)
{
	return {type: types.FETCH_SEARCH_PARAMS_BUY_PRICE_RANGE_ERROR, payload: errors}
}


export function fetchRentPriceRanges()
{
	return function (dispatch) {
		dispatch(fetchRentPriceRangesRequest())
		coreDateServices.fetchRentPriceRanges()
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchRentPriceRangesSuccess(response.data))
				}else{
					dispatch(fetchRentPriceRangesError(response.errors))
				}
			})
			.catch(error => {dispatch(fetchRentPriceRangesError(error))})
	}
}

export function fetchRentPriceRangesRequest()
{
	return {type: types.FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_REQUEST, payload: null}
}
export function fetchRentPriceRangesSuccess(ranges)
{
	return {type: types.FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_SUCCESS, payload: ranges}
}
export function fetchRentPriceRangesError(errors)
{
	return {type: types.FETCH_SEARCH_PARAMS_RENT_PRICE_RANGE_ERROR, payload: errors}
}
export function fetchAreaRanges()
{
	return function (dispatch) {
		dispatch(fetchAreaRangesRequest())
		coreDateServices.fetchAreaRanges()
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchAreaRangesSuccess(response.data))
				}else{
					dispatch(fetchAreaRangesError(response.errors))
				}
			})
			.catch(error => {dispatch(fetchAreaRangesError(error))})
	}
}

export function fetchAreaRangesRequest()
{
	return {type: types.FETCH_SEARCH_PARAMS_AREA_RANGE_REQUEST, payload: null}
}
export function fetchAreaRangesSuccess(ranges)
{
	return {type: types.FETCH_SEARCH_PARAMS_AREA_RANGE_SUCCESS, payload: ranges}
}
export function fetchAreaRangesError(errors)
{
	return {type: types.FETCH_SEARCH_PARAMS_AREA_RANGE_ERROR, payload: errors}
}
export function resetPriceRanges(ranges)
{
	return {type: types.RESET_SEARCH_PARAMS_PRICE_RANGE, payload: ranges}
}
export function fetchSeachMethod(id) {
	return function (dispatch) {
		// Change
		dispatch(changeSearchMethodRequest(id))
		dispatch(fetchPropertyCategoriesByType(id))
		if (id == SEARCH_METHOD_BUY)
			dispatch(fetchBuyPriceRanges())
		if (id == SEARCH_METHOD_RENT)
			dispatch(fetchRentPriceRanges())
		if (id == -1)
		{
			dispatch(resetPriceRanges([]))
		}
	}
}
export function changeSearchMethod(id)
{
	return function (dispatch) {
		// Change
		dispatch(changeSearchMethodRequest(id))
		dispatch(changeSearchPropertyType(-1))
		dispatch(changeSearchPriceMax(-1))
		dispatch(changeSearchPriceMin(-1))
		dispatch(changeSearchAreaMax(-1))
		dispatch(changeSearchAreaMin(-1))
		//dispatch(changeSearchNoBedRooms(-1))
		//dispatch(changeSearchNoBathRooms(-1))
		dispatch(fetchPropertyCategoriesByType(id))
		if (id == SEARCH_METHOD_BUY)
			dispatch(fetchBuyPriceRanges())
		if (id == SEARCH_METHOD_RENT)
			dispatch(fetchRentPriceRanges())
		if (id == -1)
		{
			dispatch(resetPriceRanges([]))
		}
	}
}
export function changeSearchMethodRequest(id)
{
	return {type: types.CHANGE_SEARCH_METHOD_REQUEST,payload:id}
}
export function changeSearchPropertyType(typeId)
{
	return {type: types.CHANGE_SEARCH_PROPERTY_TYPE_REQUEST,payload:typeId}
}
export function changeSearchCity(cityId)
{
	return function (dispatch) {
		if (cityId == -1) {
			dispatch(changeSearchDistrictRequest(-1))
			dispatch(changeSearchWard(-1))
		}
		dispatch(changeSearchCityRequest(cityId))
		dispatch(fetchDistricts(cityId))
	}
	
}
export function changeSearchCityRequest(id)
{
	return {type: types.CHANGE_SEARCH_CITY_REQUEST,payload:id}
}
export function changeSearchDistrict(districId)
{
	return function (dispatch) {
		if (districId == -1) {
			dispatch(changeSearchWard(-1))
		}
		dispatch(changeSearchDistrictRequest(districId))
		dispatch(fetchWards(districId))
	}
}

export function changeSearchDistrictRequest(id)
{
	return {type: types.CHANGE_SEARCH_DISTRICT_REQUEST,payload:id}
}
export function changeSearchWard(wardId)
{
	return {type: types.CHANGE_SEARCH_WARD_REQUEST,payload:wardId}
}
export function changeSearchNoBedRooms(noBedRooms)
{
	return {type: types.CHANGE_SEARCH_NO_BED_ROOMS_REQUEST,payload:noBedRooms}
}
export function changeSearchNoBathRooms(noBathRooms)
{
	return {type: types.CHANGE_SEARCH_NO_BATH_ROOMS_REQUEST,payload:noBathRooms}
}
export function changeSearchPriceMax(id)
{
	return {type: types.CHANGE_SEARCH_PRICE_MAX_REQUEST,payload:id}
}
export function changeSearchPriceMin(id)
{
	return {type: types.CHANGE_SEARCH_PRICE_MIN_REQUEST,payload:id}
}
export function changeSearchAreaMax(id)
{
	return {type: types.CHANGE_SEARCH_AREA_MAX_REQUEST,payload:id}
}
export function changeSearchAreaMin(id)
{
	return {type: types.CHANGE_SEARCH_AREA_MIN_REQUEST,payload:id}
}
export function changeSearchSort(id)
{
	return {type: types.CHANGE_SEARCH_SORT_REQUEST,payload:id}
}
export function changeSearchAgePost(id)
{
	return {type: types.CHANGE_SEARCH_AGE_POST_REQUEST,payload:id}
}
export function changeSearchPostQuery(query) {
	return {type: types.CHANGE_SEARCH_POST_QUERY_REQUEST,payload:query}	
}
export function clearSearchPostParams(id) {
	return function (dispatch) {
		dispatch(changeSearchMethodRequest(id))
		dispatch(changeSearchPropertyType(-1))
		dispatch(changeSearchPriceMax(-1))
		dispatch(changeSearchPriceMin(-1))
		dispatch(changeSearchAreaMax(-1))
		dispatch(changeSearchAreaMin(-1))
		dispatch(changeSearchNoBedRooms(-1))
		dispatch(changeSearchNoBathRooms(-1))
		dispatch(changeSearchSort(1))
		dispatch(changeSearchAgePost(-1))
		dispatch(changeSearchCity(-1))
		dispatch(fetchPropertyCategoriesByType(id))
		dispatch(changeSearchPostQuery(''))
		if (id == SEARCH_METHOD_BUY)
			dispatch(fetchBuyPriceRanges())
		if (id == SEARCH_METHOD_RENT)
			dispatch(fetchRentPriceRanges())
		if (id == -1)
		{
			dispatch(resetPriceRanges([]))
		}
	}
}
export function changeSearchPostCriteria(criteria) {
	return function (dispatch) {
		dispatch(changeSearchPostCriteriaRequest(criteria))
	}
}
export function changeSearchPostCriteriaRequest(criteria) {
	return {type: types.CHANGE_SEARCH_POST_CRITERIA_REQUEST,payload:criteria}	
}
export function changeSearchPostIds(ids) {
	return function (dispatch) {
		dispatch(changeSearchPostIdsRequest(ids))
	}
}
export function changeSearchPostIdsRequest(ids) {
	return {type: types.CHANGE_SEARCH_POST_IDS_REQUEST,payload:ids}	
}
