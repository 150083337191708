import React from 'react'
import { connect } from 'react-redux'
import {fetchCities} from '../actions/core-data'
import {fetchAreaRanges,fetchBuyPriceRanges,fetchRentPriceRanges} from '../actions/posts-search-params'
import App from '../App'
import { withRouter } from 'react-router-dom'
const mapStateToProps = state => ({
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  getCities: () => dispatch(fetchCities()),
  getAreaRanges: () => dispatch(fetchAreaRanges()),
  getBuyPriceRanges: () => dispatch(fetchBuyPriceRanges()),
  getRentPriceRanges: () => dispatch(fetchRentPriceRanges())
})
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(App))