import React from 'react'
import { translate } from "react-i18next"

///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
const LawAskSection = ({t}) => (
	<div className="section">
		<div className="container">
			<div className="row">
				<div className="col">
				</div>
			</div>
		</div>
	</div>
)
export default translate("translations")(LawAskSection);