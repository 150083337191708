import React, { Component } from 'react'
import { translate, Trans } from "react-i18next"
import DatePicker from "react-datepicker"
import { Link,withRouter } from "react-router-dom"
import "react-datepicker/dist/react-datepicker.css"
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw, ContentState,convertFromHTML } from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'
import {SEARCH_METHOD,SEARCH_NO_BED_ROOMS, SEARCH_NO_BATH_ROOMS, LEGAL_STATUSES, HOME_DIRECTIONS} from '../../configs/default-params'
import * as errorCodes from '../../configs/errors'
import {MAX_LEN_TITLE} from '../../configs/constraints'

import {APP_CONFIG_FIREBASE} from '../../configs/configs'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import ReactPlayer from 'react-player'
import moment from 'moment'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PostThumbnailList from './PostThumbnailList'
import firebase from "firebase/app"
import "firebase/auth"
import coreDataServices from '../../services/core-data'
import postServices from '../../services/posts'
import projectServices from '../../services/projects'
import postLocalStorage from '../../services/local/posts'
import userServices from '../../services/users'
import authServices from '../../services/auth'

import {isEmpty,isEmail,isPhone} from '../../helpers/string-utils'

import { withAlert,positions } from 'react-alert'
import compose from 'recompose/compose'
import { css } from "@emotion/core"
import BarLoader from "react-spinners/BarLoader"
import BeatLoader from "react-spinners/BeatLoader"

const customStyles = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused? "#fff":"#eeeeee"
	})
}
class PostDetailsBox extends Component {
	constructor(props) {
		super(props)
		const dateFrom = new Date()
		const dateTo = new Date(dateFrom.getTime() + 14*86400000)
		this.state = {
			methods: [],
			cities: [],
			districts: [],
			wards: [],
			streets: [],
			propertyTypes: [],
			uoms: [],
			homeDirections: [],
			legalStatuses: [],
			noBedRooms: [],
			noBathRooms: [],
			postPackages: [],
			projects: [],
			callingCodes: [],
			title: '',
			price: null,
			area: null,
			noFloors: null,
			dateFrom: dateFrom,
			dateTo: dateTo,
			contactName: '',
			contactEmail: '',
			contactPhone: '',
			contactAddress: '',
			editorState: EditorState.createEmpty(),
			selectedCity: null,
			selectedDistrict: null,
			selectedWard: null,
			selectedStreet: null,
			selectedProject: null,
			address: null,
			noStreet: null,
			selectedMethod: null,
			selectedPropertyType: null,
			selectedUoM: null,
			selectedHomeDirection: null,
			selectedLegalStatus: null,
			selectedNoBedRoom: null,
			selectedNoBathRoom: null,
			furniture: '',
			featuredVideoUrl: '',
			selectedPostPackage: null,
			totalAmount: 0,
			videoModalShow: false,
			featuredImage: {
				id: -1,
				url: '/images/no_image.svg',
				isLoading: false,
				isError: false,
				errors: null
			}, ///object {id, url}
			uploadImage: {
				url: '/images/no_image.svg',
				isLoading: false,
				isError: false,
				errors: null
			},
			imageList: [ 
			], ///Array of {id,url}
			errors: {
				dateFrom: '',
				dateTo: '',
				featuredVideoUrl: '',
				featuredImage: '',
				imageList: '',
				sendOTP: '',
				secret: '',
				contactPhone: '',
				contactName: '',
				title: '',
				city: '',
				district: '',
				ward: '',
				propertyType: '',
				uom: '',
				secret: '',
				description: ''
			},
			isPublished: false,
			isLoading: false,
			isError: true,
			showVerificationModal: false,
			secret: null,
			token: null,
			secretType: "Firebase",
			sendOTP: false,
			noSecretTrials: 0,
			id: null,
			showAddSuccessModal: false,
			noVerifyingTrials: 0,
			selectedCallingCode: {
				label: "VN (+84)",
				value: 243,
				callingCode: "+84"
			}
		}
		
		if (['view', 'edit'].includes(this.props.mode) === false) {
			if (!firebase.apps.length) {
				firebase.initializeApp(APP_CONFIG_FIREBASE);
			}
		}
		//this.handleDeleteImage = this.handleDeleteImage.bind(this)		
	}
	componentDidMount() {
		//coreDataServices.fetchUoMsByType
		this._fetchMethods()
		this._fetchDirections()
		this._fetchLegalStatuses()
		this._fetchNoBedRooms()
		this._fetchNoBathRooms()
		this._fetchCityRanking()
		if (this.props.free === false) {
			this._fetchPostPackages()
		}
		if (['view', 'edit'].includes(this.props.mode) === false) {
			this._fetchCallingCodes()
			window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container",
			{ 
				size:"invisible"// other options
			});
			window.recaptchaVerifier.render().then(function (widgetId) {
				window.recaptchaWidgetId = widgetId;
			});

		}
		this._fetchPostForEdit()
		this._fetchUserContactForCreatePost()		
	}
	_fetchMethods(method) {
		let selectedMethod = null
		let items = []
		SEARCH_METHOD.forEach((item) => {
			items.push({value: item.id, label: item.name})
			if (method &&  item.id === method) {
				selectedMethod = items[items.length-1]
			}
		})	
		this.setState({ methods: items, selectedMethod})
	}
	_fetchDirections(directionId) {
		let selectedHomeDirection = null
		let items = []
		HOME_DIRECTIONS.forEach((item) => {
			items.push({value: item.id, label: item.name})
			if (directionId &&  item.id === directionId) {
				selectedHomeDirection = items[items.length-1]
			}
		})	
		this.setState({ homeDirections: items, selectedHomeDirection})
	}
	_fetchLegalStatuses(legalStatusId) {
		let selectedLegalStatus = null
		let items = []
		LEGAL_STATUSES.forEach((item) => {
			items.push({value: item.id, label: item.name})
			if (legalStatusId &&  item.id === legalStatusId) {
				selectedLegalStatus = items[items.length-1]
			}
		})	
		this.setState({ legalStatuses: items, selectedLegalStatus})
	}
	_fetchNoBedRooms(noBedRoomId) {
		let selectedNoBedRoom = null
		let items = []
		SEARCH_NO_BED_ROOMS.forEach((item) => {
			items.push({value: item.id, label: item.name})
			if (noBedRoomId &&  item.id === noBedRoomId) {
				selectedNoBedRoom = items[items.length-1]
			}
		})	
		this.setState({ noBedRooms: items, selectedNoBedRoom})
	}
	_fetchNoBathRooms(noBathRoomId) {
		let selectedNoBathRoom = null
		let items = []
		SEARCH_NO_BED_ROOMS.forEach((item) => {
			items.push({value: item.id, label: item.name})
			if (noBathRoomId &&  item.id === noBathRoomId) {
				selectedNoBathRoom = items[items.length-1]
			}
		})	
		this.setState({ noBathRooms: items, selectedNoBathRoom})
	}
	_fetchCityRanking(cityId) {
		coreDataServices.fetchCitiesByRanking(243)
		.then(response => {
			if (response.status === 'OK')
			{
				let selectedCity = null
				let items = []
				response.data.forEach((item) => {
					items.push({value: item.id, label: item.name, prefix: item.prefix})
					if (cityId &&  item.id === cityId) {
						selectedCity = items[items.length-1]
					}
				})		
				this.setState({ cities: items, selectedCity})
			}else{
				this.setState({ cities: []})
			}
		})
		.catch(error => this.setState({ cities: []}))
	}
	_fetchCallingCodes() {
		coreDataServices.fetchAllCallingCodes()
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ callingCodes: response.data.map(function(item){ return {value: item.id, label: item.name, callingCode: item.callingCode}})})
			}else{
				this.setState({ callingCodes: []})
			}
		})
		.catch(error => this.setState({ callingCodes: []}))
	}
	_fetchPostPackages() {
		postServices.fetchAllPostPackages()
		.then(response => {
			if (response.status === 'OK')
			{
				if (response.data.length > 0) {
					let temp = {value: response.data[0].id, label: response.data[0].name, 
					price: response.data[0].price, limit: response.data[0].limit, currencyCode: response.data[0].currencyCode}
					this.setState({selectedPostPackage: temp})
				}
				this.setState({ postPackages: response.data.map(function(item){ return {value: item.id, label: item.name, 
					price: item.price, limit: item.limit, currencyCode: item.currencyCode}})})
			}else{
				this.setState({ postPackages: []})
			}
		})
		.catch(error => this.setState({ postPackages: []}))
	}
	_fetchUserContactForCreatePost() {
		if (authServices.isLoggin() && this.props.mode !== "edit") {
			userServices.fetchUserProfile()
			.then(response => {
				if (response.status === "OK") {
					this.setState({contactEmail: response.data.email, contactPhone: response.data.phoneNumber, 
						contactName: response.data.lastName + ' ' + response.data.middleName + ' ' + response.data.firstName})
				}
			})
			.catch(error => {
			})
		}
	}
	_fetchPostForEdit(){
		///Fetch Data For Editing
		if (this.props.id && this.props.token) {
			//Fetch data
			this.setState({isLoading: true, isError: false, details: null})

			postServices.getPostByIdForEditing(this.props.id, this.props.token)
			.then (response => {

				if (response.status === "OK") {
					console.log(response.data)
					let details = response.data //Ok -> create Pool
					///
					this._fetchCityRanking(details.cityId)
					this._fetchMethods(details.type)
					this._fetchPropertyCategoriesByType(details.type, details.categoryId)
					this._fetchUoMByType(details.type, details.uomId)
					this._fetchDistrics(details.cityId, details.districtId)
					this._fetchWards(details.districtId, details.wardId)
					this._fetchStreets(details.districtId, details.streetId)

					this._fetchDirections(details.homeDirectionId)
					this._fetchLegalStatuses(details.legalStatusId)
					this._fetchNoBedRooms(details.noBedRooms)
					this._fetchNoBathRooms(details.noBathRooms)
					this._fetchProjectById(details.projectId)
					let {featuredImage} = this.state
					if (details.photoId) {
						featuredImage.id = details.photoId
						featuredImage.url = details.photoUrl
						featuredImage.largeUrl = details.photoUrl
						featuredImage.mediumUrl = details.photoUrl
						featuredImage.smallUrl = details.photoUrl
						featuredImage.thumbnailUrl = details.photoUrl
					}
					
					let imageList = []
					if (details.photoIds) {
						for (let i = 0; i < details.photoIds.length; i++) {
							imageList.push({id:details.photoIds[i], 
								url: details.photoUrls[i],
								largeUrl: details.photoUrls[i],
								mediumUrl: details.photoUrls[i],
								smallUrl: details.photoUrls[i],
								thumbnailUrl: details.photoUrls[i]
							})
						}
					}
					
					console.log(details.description)
					let editorState = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(details.description)))
					///
					this.setState({isLoading: false, isError: false, details: response.data,
						title: details.title,
						address: details.address,
						area: details.areaM2,
						price: details.unitPrice,
						editorState,
						featuredImage,
						imageList,
 						furniture: details.furniture,
 						noFloors: details.noFloors,
						noStreet: details.homeNumber,
						featuredVideoUrl: details.videoUrl,
						contactName: details.contactName,
						contactPhone: details.contactPhone,
						contactEmail: details.contactEmail,
						contactAddress: details.contactAddress,
						//dateFrom: moment.utc(details.dateFrom),
						//dateTo: moment.utc(details.dateTo)
					})
				} else {
					this.setState({isLoading: false, isError: true, details: null})
				}
			})
			.catch (error => {
				this.setState({isLoading: false, isError: true, details: null})
			})
		}
	}
	onEditorStateChange = editorState => {
		///description: stateToHTML(editorState.getCurrentContent())
		this.setState({
			editorState: editorState
		});
	};
	
	_fetchDistrics(cityId, districtId) {
		coreDataServices.fetchDistricts(cityId)
		.then(response => {
			if (response.status === 'OK')
			{
				let selectedDistrict = null
				let items = []
				response.data.forEach((item) => {
					items.push({value: item.id, label: item.name, prefix: item.prefix})
					if (districtId &&  item.id === districtId) {
						selectedDistrict = items[items.length-1]
					}
				})	
				this.setState({ districts: items, selectedDistrict})
			}else{
				this.setState({ districts: []})
			}
		})
		.catch(error => this.setState({ districts: []}))
	}
	handleChangeCity = selectedCity => {
		let address = ''
		if (selectedCity) {
			address = selectedCity.label
		}
		this.setState({selectedCity,address: address, selectedDistrict: null, selectedWard:null,selectedStreet:null, streets: [], wards: []})
		//Fetch District
		this._fetchDistrics(selectedCity.value)
		
	};
	_fetchWards(districtId, wardId) {
		coreDataServices.fetchWards(districtId)
		.then(response => {
			if (response.status === 'OK')
			{
				let selectedWard = null
				let items = []
				response.data.forEach((item) => {
					items.push({value: item.id, label: item.name, prefix: item.prefix})
					if (wardId &&  item.id === wardId) {
						selectedWard = items[items.length-1]
					}
				})		
				this.setState({ wards: items, selectedWard})
			}else{
				this.setState({ wards: []})
			}
		})
	}
	_fetchStreets(districtId,streetId) {
		coreDataServices.fetchStreets(districtId)
		.then(response => {
			if (response.status === 'OK')
			{
				let selectedStreet = null
				let items = []
				response.data.forEach((item) => {
					items.push({value: item.id, label: item.name, prefix: item.prefix})
					if (streetId &&  item.id === streetId) {
						selectedStreet = items[items.length-1]
					}
				})	
				this.setState({ streets: items, selectedStreet})
			}else{
				this.setState({ streets: []})
			}
		})
		.catch(error => this.setState({ streets: []}))
	}
  	handleChangeDistrict = selectedDistrict => {
  		let address = ''
  		if (selectedDistrict) {
  			address = selectedDistrict.prefix + ' ' + selectedDistrict.label + ', ' + this.state.selectedCity.label 
  		}
  		this.setState({selectedDistrict,address: address, selectedWard:null,selectedStreet:null})
  		this._fetchWards(selectedDistrict.value)
  		this._fetchStreets(selectedDistrict.value)	
	};
	handleChangeWard = selectedWard => {
		let address = ''
		if (selectedWard) {
			address =  selectedWard.prefix + ' ' + selectedWard.label + ', ' + this.state.selectedDistrict.prefix + ' ' + this.state.selectedDistrict.label + ', ' + this.state.selectedCity.label 
		}
  		this.setState({selectedWard, selectedStreet:null, address: address})

	};
	handleChangeStreet = selectedStreet => {
		let address = ''
		if (selectedStreet) {
			address = "Đường " + selectedStreet.label +', ' + this.state.selectedWard.prefix + ' ' + this.state.selectedWard.label + ', ' + this.state.selectedDistrict.prefix + ' ' + this.state.selectedDistrict.label + ', ' + this.state.selectedCity.label 
		}
		this.setState({selectedStreet,address: address})	
	};
	_fetchUoMByType(type, uomId) {
		coreDataServices.fetchUoMsByType(type)
		.then(response => {
			if (response.status === 'OK')
			{

				let selectedUoM = null
				let items = []
				response.data.forEach((item) => {
					items.push({value: item.id, label: item.name,negotiated: item.negotiated})
					if (uomId &&  item.id === uomId) {
						selectedUoM = items[items.length-1]
					}
				})	
				this.setState({uoms: items,selectedUoM})
			}else{
				this.setState({ uoms: []})
			}
		})
		.catch(error => this.setState({ uoms: []}))
	}
	_fetchPropertyCategoriesByType(method,categoryId) {
		coreDataServices.fetchPropertyCategoriesByType(method)
		.then(response => {
			if (response.status === 'OK')
			{
				let selectedPropertyType = null
				let items = []
				response.data.forEach((item) => {
					items.push({value: item.id, label: item.name})
					if (categoryId &&  item.id === categoryId) {
						selectedPropertyType = items[items.length-1]
					}
				})	
				this.setState({ propertyTypes: items,selectedPropertyType})
			}else{
				this.setState({ propertyTypes: []})
			}
		})
		.catch(error => this.setState({ propertyTypes: []}))
	}
	handleChangeMethod = selectedMethod => {
		this.setState({selectedMethod, selectedUoM: null})
		//Fetch UoM
		this._fetchUoMByType(selectedMethod.value)
		this._fetchPropertyCategoriesByType(selectedMethod.value)
		//Property Types
		
	};
	handleChangePropertyType  = selectedPropertyType => {
		this.setState({selectedPropertyType})
	};
	handleChangeUoM  = selectedUoM => {
		this.setState({selectedUoM})
	};
	handleChangeHomeDirection  = selectedHomeDirection => {
		this.setState({selectedHomeDirection})
	};
	handleChangeLegalStatus  = selectedLegalStatus => {
		this.setState({selectedLegalStatus})
	};
	handleChangeNoBedRoom  = selectedNoBedRoom => {
		this.setState({selectedNoBedRoom})
	};
	handleChangeNoBathRoom  = selectedNoBathRoom => {
		this.setState({selectedNoBathRoom})
	};
	handleChangeProject  = selectedProject => {
		if (selectedProject != null) {
			if (selectedProject.value === -1) {
				selectedProject = null
			}
		}
		this.setState({selectedProject})
	};
	handleChangeDateFrom = date => {
		let {errors} = this.state
		let {t} = this.props
		if (!this.state.dateTo) {
			this.setState({dateFrom : date})
		} else {
			let dateFrom = moment(date)
			let dateTo = moment(this.state.dateTo)
			let diff = dateTo.diff(dateFrom,'days')
			if (diff < 0) {
				errors.dateFrom = t('date_from_must_be_greater_than_date_to')
				const dateTo = new Date(date.getTime() + 7*86400000)
				this.setState({dateFrom : date, dateTo: dateTo})
			} else {
				if (this.state.selectedPostPackage != null) {
					let totalAmount = this.state.selectedPostPackage.price*diff
					this.setState({totalAmount})

				}
				this.setState({dateFrom : date})
			}
      	}
	};
	handleChangeDateTo = date => {
		let {errors} = this.state
		let {t} = this.props
		if (!this.state.dateFrom)
			this.setState({dateTo : date})
		else {
			let dateFrom = moment(this.state.dateFrom)
			let dateTo = moment(date)
			let diff = dateTo.diff(dateFrom,'days')
			if (diff < 0) {
				errors.dateFrom = t('date_from_must_be_greater_than_date_to')
				const dateTo = new Date(date.getTime() + 7*86400000)
				this.setState({dateTo: date, errors})
			} else {
				if (this.state.selectedPostPackage != null) {
					let totalAmount = this.state.selectedPostPackage.price*diff
					this.setState({totalAmount})
				}
				this.setState({dateTo : date})
			}
		}
	};
	handleChangePostPackage = selectedPostPackage => {
		let {dateFrom,dateTo} = this.state
		if (dateFrom && dateTo) {
			dateFrom = moment(dateFrom)
			dateTo = moment(dateTo)
			let diff = dateTo.diff(dateFrom,'days')
			if (diff > 0) {
				let totalAmount = selectedPostPackage.price*diff
				this.setState({totalAmount})
			}

		}
		this.setState({selectedPostPackage})
	};
	_validateForm() {
		let {t} = this.props
		let {errors} = this.state
		let hasError = false
		if (isEmpty(this.state.title)) {
			hasError = true
			errors.title = t('required_field')
		}
		if (this.state.selectedMethod === null) {
			hasError = true
			errors.method = t('required_field')
		}
		if (this.state.selectedPropertyType === null) {
			hasError = true
			errors.propertyType = t('required_field')
		}
		if (this.state.selectedUoM === null) {
			hasError = true
			errors.uom = t('required_field')
		} else {
			///Check this case carefully
			if (this.state.selectedUoM.negotiated === false) {
				if (this.state.price === null) {
					hasError = true
					errors.price = t('required_field')	
				}
			}
		}
		if (this.state.selectedCity === null) {
			hasError = true
			errors.city = t('required_field')
		}
		if (this.state.selectedDistrict === null) {
			hasError = true
			errors.district = t('required_field')
		}
		if (this.state.selectedWard === null) {
			hasError = true
			errors.ward = t('required_field')
		}
		if (isEmpty(this.state.address)) {
			hasError = true
			errors.address = t('required_field')
		}
		if (isEmpty(this.state.contactName)) {
			hasError = true
			errors.contactName = t('required_field')
		}
		if (isEmpty(this.state.contactPhone)) {
			hasError = true
			errors.contactPhone = t('required_field')
		} else {
			if (isPhone(this.state.contactPhone) === false) {
				hasError = true
				errors.contactPhone = t('phone_incorrect')
			}
		}
		if (isEmpty(this.state.email) === false && isEmail(this.state.email) === false) {
			hasError = true
			errors.contactPhone = t('phone_incorrect')
		}
		if (this.state.dateFrom === null) {
			hasError = true
			errors.dateFrom = t('required_field')
		}
		if (this.state.dateTo === null) {
			hasError = true
			errors.dateTo = t('required_field')
		}
		if (!this.state.editorState.getCurrentContent().hasText()) {
			hasError = true
			errors.description = t('required_field')
		}
		this.setState({errors})
		return hasError
	}
	_processErrors(resErrors) {
		let {errors} = this.state
		if (resErrors[errorCodes.ERROR_CODE_POST_TITLE_NOT_EMPTY]) {
			errors.title = this.props.t('required_field')
		}
		if (resErrors[errorCodes.ERROR_CODE_POST_TITLE_TOO_LONG]) {
			errors.title = this.props.t('too_long_max_len') + MAX_LEN_TITLE
		}
		if (resErrors[errorCodes.ERROR_CODE_TYPE_NOT_EMPTY]) {
			errors.method = this.props.t('required_field')
		}
		if (resErrors[errorCodes.ERROR_CODE_POST_CATEGORY_NOT_EMPTY]) {
			errors.propertyType = this.props.t('required_field')
		}
		if (resErrors[errorCodes.ERROR_CODE_CATEGORY_ID_NOT_FOUND]) {
			errors.propertyType = this.props.t('required_field')
		}
		if (resErrors[errorCodes.ERROR_CODE_SECRET_NOT_EMPTY]) {
			errors.secret = this.props.t('required_field')
		}
		if (resErrors[errorCodes.ERROR_CODE_TOKEN_NOT_EMPTY]) {
			errors.token = this.props.t('required_field')
		}
		if (resErrors[errorCodes.ERROR_CODE_SECRET_TYPE_NOT_EMPTY]) {
			errors.secretType = this.props.t('required_field')
		}
		this.setState({errors})
	}
	handlePublish = (event) => {
		event.preventDefault()
		let {errors} = this.state
      	let {t} = this.props
      	errors.sendOTP = ''
      	this.setState({errors})

      	let ok = this._validateForm()
      	if (!ok)
      		this.setState({showVerificationModal:true})
	}
	handleSaveChange = (event) => {
		event.preventDefault()
		let ok = this._validateForm()
      	if (ok) {
      		return
      	}
		let description = stateToHTML(this.state.editorState.getCurrentContent())
		const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks
      	const descriptionApp = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim();
		let reqPost = {
			title: this.state.title,
			type: this.state.selectedMethod.value,
			categoryId: this.state.selectedPropertyType.value,
			projectId: this.state.selectedProject?this.state.selectedProject.value: null,
			uomId: this.state.selectedUoM.value,
			unitPrice: this.state.price?this.state.price: null,
			areaM2: this.state.area?this.state.area:null,
			cityId: this.state.selectedCity.value,
			districtId: this.state.selectedDistrict.value,
			wardId: this.state.selectedWard.value,
			streetId: this.state.selectedStreet?this.state.selectedStreet.value:null,
			homeNumber: this.state.noStreet,
			address: this.state.address,
			noFloors: this.state.noFloors,
			noBedRooms: this.state.selectedNoBedRoom?this.state.selectedNoBedRoom.value:null,
			noBathRooms: this.state.selectedNoBathRoom?this.state.selectedNoBathRoom.value: null,
			description: description,
			descriptionApp: descriptionApp,
			homeDirectionId: this.state.selectedHomeDirection?this.state.selectedHomeDirection.value:null,
			legalStatusId: this.state.selectedLegalStatus?this.state.selectedLegalStatus.value:null,
			furniture: this.state.furniture? this.state.furniture: null,
			contactName: this.state.contactName,
			contactEmail: this.state.contactEmail,
			contactPhone: this.state.contactPhone,
			contactAddress: this.state.contactAddress,
			videoUrl: this.state.featuredVideoUrl,
			totalAmount: this.state.totalAmount,
			photoId: this.state.featuredImage.id !== -1? this.state.featuredImage.id: null,
			photoIds: this.state.imageList.map(item => item.id),
			packageId: this.state.selectedPostPackage?this.state.selectedPostPackage.value:null,
			dateFrom: this.state.dateFrom,
			dateTo: this.state.dateTo
		}
		this.setState({isLoading: true, isError: false,})
		postServices.update(this.props.id, this.props.token,reqPost)
		.then (response => {
			if (response.status === "OK") {
				this.setState({isLoading: false, isError: false})
				this.props.alert.show(this.props.t('save_changes_successfully'),{ position: positions.CENTER })
				this.props.history.push('/users/my-properties')
			} else {
				this.setState({isLoading: false, isError: true})
				if (response.status == "INVALID_REQUEST")
					this._processErrors(response.errors)
				this.props.alert.show(this.props.t('save_changes_failure'),{ position: positions.CENTER })
			}
		})
		.catch(error => {
			this.props.alert.show(this.props.t('save_changes_failure'),{ position: positions.CENTER })
			this.setState({isLoading: false, isError: false})

		})
	}
	_updatePost() 
	{
		let description = stateToHTML(this.state.editorState.getCurrentContent())
		const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks
		const descriptionApp = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim();
		let reqPost = {
			title: this.state.title,
			type: this.state.selectedMethod.value,
			categoryId: this.state.selectedPropertyType.value,
			projectId: this.state.selectedProject?this.state.selectedProject.value: null,
			uomId: this.state.selectedUoM.value,
			unitPrice: this.state.price,
			area: this.state.area,
			cityId: this.state.selectedCity.value,
			districtId: this.state.selectedDistrict.value,
			wardId: this.state.selectedWard.value,
			streetId: this.state.selectedStreet?this.state.selectedStreet.value:null,
			homeNumber: this.state.noStreet,
			address: this.state.address,
			noFloors: this.state.noFloors,
			noBedRooms: this.state.selectedNoBedRoom?this.state.selectedNoBedRoom.value:null,
			noBathRooms: this.state.selectedNoBathRoom?this.state.selectedNoBathRoom.value: null,
			description: description,
			descriptionApp: descriptionApp,
			homeDirectionId: this.state.selectedHomeDirection?this.state.selectedHomeDirection.value:null,
			legalStatusId: this.state.selectedLegalStatus?this.state.selectedLegalStatus.value:null,
			contactName: this.state.contactName,
			contactEmail: this.state.contactEmail,
			contactPhone: this.state.contactPhone,
			contactAddress: this.state.contactAddress,
			featuredVideoUrl: this.state.featuredVideoUrl,
			totalAmount: this.state.totalAmount,
			featuredImage: this.state.featuredImage,
			imageList: this.state.imageList,
			packageId: this.state.selectedPostPackage?this.state.selectedPostPackage.value:null,
			dateFrom: this.state.dateFrom,
			dateTo: this.state.dateTo
		}
	}
	_createPost() {
		let description = stateToHTML(this.state.editorState.getCurrentContent())
		const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks
      	const descriptionApp = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim();
		let reqPost = {
			title: this.state.title,
			type: this.state.selectedMethod.value,
			categoryId: this.state.selectedPropertyType.value,
			projectId: this.state.selectedProject?this.state.selectedProject.value: null,
			uomId: this.state.selectedUoM.value,
			unitPrice: this.state.price?this.state.price: null,
			areaM2: this.state.area?this.state.area:null,
			cityId: this.state.selectedCity.value,
			districtId: this.state.selectedDistrict.value,
			wardId: this.state.selectedWard.value,
			streetId: this.state.selectedStreet?this.state.selectedStreet.value:null,
			homeNumber: this.state.noStreet,
			address: this.state.address,
			noFloors: this.state.noFloors,
			noBedRooms: this.state.selectedNoBedRoom?this.state.selectedNoBedRoom.value:null,
			noBathRooms: this.state.selectedNoBathRoom?this.state.selectedNoBathRoom.value: null,
			description: description,
			descriptionApp: descriptionApp,
			homeDirectionId: this.state.selectedHomeDirection?this.state.selectedHomeDirection.value:null,
			legalStatusId: this.state.selectedLegalStatus?this.state.selectedLegalStatus.value:null,
			furniture: this.state.furniture? this.state.furniture: null,
			contactName: this.state.contactName,
			contactEmail: this.state.contactEmail,
			contactPhone: this.state.contactPhone,
			contactAddress: this.state.contactAddress,
			videoUrl: this.state.featuredVideoUrl,
			totalAmount: this.state.totalAmount,
			photoId: this.state.featuredImage.id !== -1? this.state.featuredImage.id: null,
			photoIds: this.state.imageList.map(item => item.id),
			packageId: this.state.selectedPostPackage?this.state.selectedPostPackage.value:null,
			dateFrom: this.state.dateFrom,
			dateTo: this.state.dateTo,
			secret: this.state.secret,
			token: this.state.token,
			secretType: this.state.secretType
		}
		postServices.publishFree(reqPost)
		.then (response => {
			if (response.status === "OK") {
				postLocalStorage.addNewPost(response.data)
				this.setState({id:response.data, showAddSuccessModal: true, isLoading: false, isError: false})
			} else {
				if (response.status == "INVALID_REQUEST")
					this._processErrors(response.errors)
					this.setState({isLoading: false, isError: true})

			}
		})
		.catch(error => {
			this.props.alert.show(this.props.t('save_changes_failure'),{ position: positions.CENTER })
			this.setState({isLoading: false, isError: false})
		})
	}
	handleSaveDraft = (e) => {
		// Not User
		let description = stateToHTML(this.state.editorState.getCurrentContent())

		let draftPost = {
			title: this.state.title,
			price: this.state.price,
			area: this.state.area,
			noFloors: this.state.noFloors,
			dateFrom: this.state.dateFrom,
			dateTo: this.state.dateTo,
			contactName: this.state.contactName,
			contactEmail: this.state.contactEmail,
			contactPhone: this.state.contactPhone,
			contactAddress: this.state.contactAddress,
			description: description,
			cityId: this.state.selectedCity?this.state.selectedCity.value:-1,
			districtId: this.state.selectedDistrict?this.state.selectedDistrict.value:-1,
			wardId: this.state.selectedWard?this.state.selectedWard.value:-1,
			streetId: this.state.selectedStreet?this.state.selectedStreet.value:-1,
			method: this.state.selectedMethod?this.state.selectedMethod.value:-1,
			propertyTypeId: this.state.selectedPropertyType?this.state.selectedPropertyType.value:-1,
			uomId: this.state.selectedUoM?this.state.selectedUoM.value:-1,
			homeDirectionId: this.state.selectedHomeDirection?this.state.selectedHomeDirection.value:-1,
			legalStatusId: this.state.selectedLegalStatus?this.state.selectedLegalStatus.value:-1,
			noBedRoom: this.state.selectedNoBedRoom?this.state.selectedNoBedRoom.value:-1,
			noBathRoom: this.state.selectedNoBathRoom?this.state.selectedNoBathRoom.value: -1,
			featuredVideoUrl: this.state.featuredVideoUrl,
			packageId: this.state.selectedPostPackage?this.state.selectedPostPackage.value:-1,
			totalAmount: this.state.totalAmount,
			featuredImage: this.state.featuredImage,
			imageList: this.state.imageList,
			status: 1
		}
		//Loggin
		//Save to Server
		//Save to sessionStorage
	}
	handleAddVideo = (e) => {
		this.setState({videoModalShow: true})
	}
	handleCloseAddVideo = (e) => {
		this.setState({videoModalShow: false, featuredVideoUrl: ''})
	}
	handleAddVideoSave = (e) => {
		this.setState({videoModalShow: false})
	}
	handleAddFeaturedImage = (event) => {
		let {errors,featuredImage} = this.state
		let {t} = this.props
		errors.featuredImage = ''
		this.setState({errors})
		let file = event.target.files[0]
		// / 1024 / 1024 max file 5MB
		let sizeInMB = file.size/1024/1024
		//Send to ser
		let hasError = false
		if (sizeInMB > 5) {
			hasError = true
			errors.featuredImage = t('max_file_size_5_mb')
			this.setState({errors})
		}
		if (!hasError) {
			//Send server
			featuredImage.isLoading = true
			featuredImage.isError = false
			featuredImage.errors = null
			this.setState({featuredImage})
			postServices.uploadFeaturedImage(file)
			.then(response => {
				if (response.status === "OK") {
					featuredImage.isLoading = false
					featuredImage.isError = false
					featuredImage.id = response.data.id
					featuredImage.largeUrl = response.data.largeUrl
					featuredImage.mediumUrl = response.data.mediumUrl
					featuredImage.smallUrl = response.data.smallUrl
					featuredImage.thumbnailUrl = response.data.thumbnailUrl
					featuredImage.url = response.data.mediumUrl
					this.setState({featuredImage})
				} else {
					if (response.status === "UNKNOW_ERROR" || response.status ===500) {
						errors.featuredImage = t('featured_image_upload_failure')
						featuredImage.isLoading = false
						featuredImage.isError = true
						this.setState({errors,featuredImage})
					} else {
						if (response.errors) {
							this._processFeaturedImageErrors(response.errors)
							featuredImage.isLoading = false
							featuredImage.isError = true
							this.setState({featuredImage})
						}
					}
				}
			})
			.catch(error => {
				errors.featuredImage = t('featured_image_upload_failure')
				featuredImage.isLoading = false
				featuredImage.isError = true
				this.setState({errors,featuredImage})
			})
		}
	}
	_processFeaturedImageErrors(resErrors) {
		let errors = this.state.errors
		let {t} = this.props
		if (resErrors[errorCodes.ERROR_CODE_FILE_TOO_LARGE]) {
			errors.featuredImage = t('max_file_size_5_mb')
		}
		if (resErrors[errorCodes.ERROR_CODE_FILE_FORMAT_UNSUPPORT]) {
			errors.featuredImage = this.props.t('featured_image_supported_formats')
		}
		if (resErrors[errorCodes.ERROR_CODE_FEATURED_IMAGE_REQUIRED_MIN_SIZE]) {
			errors.featuredImage = this.props.t('featured_image_required_size')
		}
		this.setState({errors})
	}
	handleDeleteFeaturedImage = (e) => {
		let {featuredImage} = this.state
		//Call API Function

		/*postServices.deleteFeaturedImage(featuredImage.id)
		.then(response => {

		})
		.catch(error => {

		})*/
		featuredImage.id = -1
		featuredImage.url = "/images/no_image.svg"
		this.setState({featuredImage})
	}
	handleDeleteImage = (idx) =>{
		let {imageList} = this.state
		try {
			imageList.splice(idx,1)
			this.setState({imageList})
		} catch (error) {
			console.log(error)
		}

	}
	hanldeUploadImage = (event) => {
		let {errors,imageList,uploadImage} = this.state
		errors.imageList = ''
		this.setState({errors})
		if (this.state.imageList.length > 5) {
			hasError = true
			errors.imageList = t('max_uploaded_images_5')
			this.setState({errors})
			return
		}
		let {t} = this.props
		let file = event.target.files[0]
		// / 1024 / 1024 max file 5MB
		let sizeInMB = file.size/1024/1024
		//Send to ser
		let hasError = false
		if (sizeInMB > 5) {
			hasError = true
			errors.imageList = t('max_file_size_5_mb')
			this.setState({errors})
		}
		if (!hasError) {
			//Send server
			uploadImage.isLoading = true
			uploadImage.isError = false
			this.setState({uploadImage})
			postServices.uploadImage(file)
			.then(response => {
				if (response.status === "OK") {
					uploadImage.isLoading = false
					uploadImage.isError = false
					imageList.push({
						id: response.data.id,
						largeUrl: response.data.largeUrl,
						mediumUrl: response.data.mediumUrl,
						smallUrl: response.data.smallUrl,
						thumbnailUrl: response.data.thumbnailUrl
					})
					this.setState({imageList,uploadImage})
				} else {
					if (response.status === "UNKNOW_ERROR" || response.status ===500) {
						errors.imageList = t('featured_image_upload_failure')
						uploadImage.isLoading = false
						uploadImage.isError = true
						this.setState({errors,uploadImage})
					} else {
						if (response.errors){
							this._processUploadedImageErrors(response.errors)
							uploadImage.isLoading = false
							uploadImage.isError = true
							this.setState({uploadImage})

						}

					}
				}
			})
			.catch(error => {
				errors.imageList = t('featured_image_upload_failure')
				uploadImage.isLoading = false
				uploadImage.isError = true
				this.setState({errors,uploadImage})
			})
		}
	}
	_processUploadedImageErrors(resErrors) {
		let errors = this.state.errors
		let {t} = this.props
		if (resErrors[errorCodes.ERROR_CODE_FILE_TOO_LARGE]) {
			errors.imageList = t('max_file_size_5_mb')
		}
		if (resErrors[errorCodes.ERROR_CODE_FILE_FORMAT_UNSUPPORT]) {
			errors.imageList = this.props.t('featured_image_supported_formats')
		}
		if (resErrors[errorCodes.ERROR_CODE_FEATURED_IMAGE_REQUIRED_MIN_SIZE]) {
			errors.imageList = this.props.t('featured_image_required_size')
		}
		this.setState({errors})
	}
	handleAddImages = (event) => {
		let {errors} = this.state
		let {t} = this.props
		let files = event.target.files
		// / 1024 / 1024 max file 5MB
		let hasError = false
		if (files.length > 5) {
			hasError = true
			errors.imageList = t('max_uploaded_images_5')
			this.setState({errors})
			return
		}
		for (let i = 0; i < files.length; i++) { 
			let sizeInMB = files[i].size/1024/1024
			if (sizeInMB > 5) {
				hasError = true
				errors.imageList = t('max_file_size_5_mb')
				this.setState({errors})
				break
			}
		}
		if(hasError === false) {
			//this.props.postEditorUploadImages(event.target.files)
		}
		//Send to ser
	}
	handleCloseVerification = (e) => {
		this.setState({showVerificationModal: false, secret: '', sendOTP: false, verificationId: null})

	}
	hanldeVerificationCode = (e) => {
		let {errors} = this.state
		let {t} = this.props

		if (window.confimationResult) {
			window.confimationResult.confirm(this.state.secret).
			then(result => {
				// User signed in successfully.
				//Call API to Server
				this.setState({showVerificationModal: false})
				this._createPost()

		}).catch(error => {
				// User couldn't sign in (bad verification code?)
				errors.secret = t('otp_incorrect')
				this.setState({errors})
			});
		}
	}
	handleCheckPhone = (e) => {
		//Call Firebase Phone Validatetion
		let {errors} = this.state
      	let {t} = this.props
		const phoneNumber = this.state.contactPhone;
		//const phoneNumber = '+849';
		let phone = phoneNumber.trim().replace(" ",'')
		if (phone[0] === '0') {
			phone = this.state.selectedCallingCode.callingCode + phone.substring(1,phone.length)
		}else {
			phone = this.state.selectedCallingCode.callingCode + phone
		}
		const appVerifier = window.recaptchaVerifier;
		firebase.auth().signInWithPhoneNumber(phone, appVerifier)
		.then(confirmResult => {
			if (confirmResult.code === "auth/invalid-phone-number") {
				errors.contactPhone = t('phone_incorrect')
				this.setState({errors})
			} else {
				// success
				// Show Enter Verification Code
				this.setState({sendOTP: true, token: confirmResult.verificationId})
				window.confimationResult = confirmResult
			}

			/*let verificationId = confirmResult.verificationId
			console.log(confirmResult)
			confirmResult.confirm("TEST").then(function (result) {
				// User signed in successfully.
				console.log(result)
				var user = result.user;
			}).catch(function (error) {
				// User couldn't sign in (bad verification code?)
				console.log(error)
			});*/
		})
		.catch(error => {
			// error
			      // Error; SMS not sent
      		// ...
      		let counter = this.state.noVerifyingTrials
      		if (error.code==="auth/internal-error") {
      			counter = this.state.noVerifyingTrials + 1
      		}
      		errors.sendOTP = t('send_otp_failure')
      		this.setState({sendOTP:false, noVerifyingTrials:counter,errors})
		});
	}
	handleChangeCallingCode = selectedCallingCode => {
		this.setState({selectedCallingCode})
	}
	_renderPhoneVerificationModal() {
		const {t} = this.props
		return (
			<Modal show={this.state.showVerificationModal} onHide={this.handleCloseVerification}>
				<Modal.Header closeButton>
					<Modal.Title>{t('phone_verification')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.state.sendOTP === true && <div className="form-group row">
						<label className="col-sm-3 form-control-label">{t('otp')}</label>
						<div className="col-sm-9">
							<input type="input" className="form-control submit-property__field" onChange={(e) => this.setState({secret: e.target.value})} value={this.state.secret} aria-describedby="code" placeholder={t('otp')}/>
							<small className="form-text text-danger">{this.state.errors.secret}</small>											  			
						</div>
					</div>
					}
					{this.state.sendOTP === false && <div className="form-group row">
						<label className="col-sm-3 form-control-label">{t('country')}</label>
						<div className="col-sm-4 mb-2">
							<ReactSelect styles={customStyles} placeholder={t('calling_code')} options={this.state.callingCodes} onChange={this.handleChangeCallingCode} value={this.state.selectedCallingCode}/>		  			
						</div>
						<div className="col-sm-5 mb-2">
							<input type="input" className="form-control submit-property__field" value={this.state.contactPhone} aria-describedby="phone" placeholder={t('phone')} disabled/>
						</div>
						<small className="col-12 form-text text-danger">{this.state.errors.contactPhone}</small>
						<small className="col-12 form-text text-danger">{this.state.errors.sendOTP}</small>

					</div>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" className="float-left" onClick={this.handleCloseVerification}>
						{t('discard')}
					</Button>
					{this.state.sendOTP === true && <Button variant="primary" className="float-right" onClick={this.hanldeVerificationCode}>{t('verify')}</Button>}
					{this.state.sendOTP === false && <Button variant="primary" className="float-right" onClick={this.handleCheckPhone}>{t('ok')}</Button>}
				</Modal.Footer>
			</Modal>
		)
	}
	_renderVideoModal() {
		const {t} = this.props
		return (
			<Modal show={this.state.videoModalShow} onHide={this.handleCloseAddVideo}>
				<Modal.Header closeButton>
					<Modal.Title>{t('video')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<label className="col-sm-4 form-control-label d-flex">{t('video_url')}<span> (*) </span></label>
						<div className="col-sm-8">
							<input type="input" onChange={(e) => this.setState({featuredVideoUrl: e.target.value})} value={this.state.featuredVideoUrl} className="form-control" aria-describedby="emailHelp" placeholder={t('web_url')}/>
							<small className="form-text text-danger">{this.state.errors.featuredVideoUrl}</small>											  			
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleCloseAddVideo}>
						{t('discard')}
					</Button>
					<Button variant="primary" onClick={this.handleAddVideoSave}>{t('save')}</Button>
				</Modal.Footer>
			</Modal>
		)
	}
	handleCloseAddSuccessModal = (e) => {
		this.setState({showAddSuccessModal:false})
		this.props.history.push('/users/my-properties')
	}
	_renderAddSucessModal() {
		const {t} = this.props
		return (
			<Modal show={this.state.showAddSuccessModal} onHide={this.handleCloseAddSuccessModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t('published_successfully')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<p className="alert alert-success">{t('published_successfully_alert')}</p>
						<Link to={"/listings/preview/" + this.state.id}>{t('preview')}</Link> 
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={this.handleCloseAddSuccessModal}>{t('ok')}</Button>
				</Modal.Footer>
			</Modal>
		)
	}
	handleCancel = (e) => {
		this.props.history.push('/users/my-properties')
	}
	loadProjects = (inputValue, callback) => {
		if (!inputValue)
		{
			return;
			
		} else {
			setTimeout(()=> {
			projectServices.loadOptions(inputValue)
			.then(response=> {
				if (response.status === "OK") {
					console.log(response.data)
					let items = response.data.map(item => ({value: item.id, label: item.name}))
					callback([{value: -1, label: 'Không chọn dự án'}, ...items])
				}
			})
		},1000)
		}
	}
	handleInputChangeProject = (newValue) => {
    	const projectName = newValue.replace(/\W/g, '');
    	this.setState({ projectName });
    	return projectName;
  	};
  	_fetchProjectById(id) {
		let {selectedProject} = this.state
		if (!id) {
			return;
		}
		projectServices.getProjectForOptionsById(id)
		.then(response => {
			if (response.status === 'OK')
			{
				selectedProject = {value: response.data.id, label: response.data.name}
				this.setState({selectedProject})
			}
		})
		.catch(error => {

		})
	}
	render (){
		const {t} = this.props
		let videoPlayer = null
  		if (this.state.featuredVideoUrl) {
  			videoPlayer = <div className='player-wrapper'><ReactPlayer className='react-player' url={this.state.featuredVideoUrl} width='100%' height='100%'/>
	        </div>
  		}
  		let totalAmount = this.state.totalAmount.toFixed(3)
  		if (totalAmount > 0) {
	  		if (this.state.selectedPostPackage) {
	  			totalAmount += ' ' + this.state.selectedPostPackage.currencyCode
	  		}
  		}
  		let videoModel = this._renderVideoModal()

  		//Mode 1: create 2: view 3 edit if not have loggin then have access_token
  		if (this.state.isLoading == false && this.state.isError == false && this.state.id && this.props.mode === 'create') {
  			//Sento server successfully
  		}
  		let showPrice = true
  		if (this.state.selectedUoM) {
  			if (this.state.selectedUoM.negotiated === true) {
  				showPrice = false
  			}
  		}
  		let verificationModal = this._renderPhoneVerificationModal()
  		let addSuccessModal = this._renderAddSucessModal()
  		let actionPublish = true
  		if (['view', 'edit'].includes(this.props.mode)) {
  			actionPublish = false
  		}
  		let actionSaveChange = false
  		if (this.props.mode === 'edit') {
  			actionSaveChange = true
  		}
  		let actionCancel = true 
  		if (this.props.mode === 'view') {
  			actionCancel = false
  		}
		return (
			<div className="container">
				{/*<BeatLoader css={{display: 'block', width: '100%'}} color={"#1fc341"} loading={true} />*/}

				<div className="row">
					<div className="col">
						<div className="submit-property__block">
							<h3 className="submit-property__headline" style={{fontWeight: 'bold'}}>{t('basic_info')}</h3>
							<div className="form-group submit-property__group">
								<label className="submit-property__label">{t('title') + ' *' }</label>
								<input type="text" className="form-control submit-property__field" placeholder={t('title')} onChange={(e) => this.setState({title: e.target.value})} value={this.state.title}/>
								<small className="form-text text-danger">{t(this.state.errors.title)}</small>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('method') + ' *'}</label>
							<ReactSelect styles={customStyles} placeholder={'Chọn hình thức'} value={this.state.selectedMethod} onChange={this.handleChangeMethod} options={this.state.methods}/>
							<small className="form-text text-danger">{t(this.state.errors.method)}</small>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('property_type') + ' *'}</label>
							<ReactSelect styles={customStyles} placeholder={'Chọn loại tài sản'} value={this.state.selectedPropertyType} onChange={this.handleChangePropertyType} options={this.state.propertyTypes}/>
							<small className="form-text text-danger">{t(this.state.errors.propertyType)}</small>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('project')}</label>
							<AsyncSelect styles={customStyles} placeholder={'Chọn dự án'} cacheOptions defaultOptions={false} loadOptions={this.loadProjects} onChange={this.handleChangeProject} value={this.state.selectedProject}/>
							<small className="form-text text-danger">{t(this.state.errors.project)}</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('uom')}</label>
							<ReactSelect styles={customStyles} placeholder={'Chọn đơn vị'} value={this.state.selectedUoM} onChange={this.handleChangeUoM} options={this.state.uoms}/>
							<small className="form-text text-danger">{t(this.state.errors.uom)}</small>
						</div>
					</div>
					
					{ showPrice === true && 
						<div className="col-md-4">
							<div className="form-group submit-property__group">
								<label className="submit-property__label">{t('price') + ' *'}</label>
								<input type="number" className="form-control submit-property__field" placeholder={t('price')} onChange={(e) => this.setState({price: e.target.value})} value={this.state.price}/>
								<small className="form-text text-danger">{t(this.state.errors.price)}</small>
							</div>
						</div>
					}
					
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('area')}</label>
							<input type="number" className="form-control submit-property__field" id="property-title" placeholder={t('area')} onChange={(e) => this.setState({area: e.target.value})} value={this.state.area}/>
							<span className="submit-property__unit">{t('uom_sq')}</span>
							<small className="form-text text-danger">{t(this.state.errors.area)}</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('city') + ' *'}</label>
							<ReactSelect styles={customStyles} value={this.state.selectedCity} onChange={this.handleChangeCity} options={this.state.cities} placeholder={'Chọn tỉnh/Thành phố'}/>
							<small className="form-text text-danger">{t(this.state.errors.city)}</small>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('district') + ' *'}</label>
							<ReactSelect styles={customStyles} value={this.state.selectedDistrict} onChange={this.handleChangeDistrict} options={this.state.districts} placeholder={'Chọn quận/huyện'}/>
							<small className="form-text text-danger">{t(this.state.errors.district)}</small>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('ward') + ' *'}</label>
							<ReactSelect styles={customStyles} classNname="submit-property__field" value={this.state.selectedWard} onChange={this.handleChangeWard} options={this.state.wards} placeholder={'Chọn phường/xã'}/>
							<small className="form-text text-danger">{t(this.state.errors.ward)}</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('street')}</label>
							<ReactSelect styles={customStyles} className="submit-property__field" value={this.state.selectedStreet} onChange={this.handleChangeStreet} options={this.state.streets} placeholder={'Chọn đường'}/>
							<small className="form-text text-danger">{t(this.state.errors.street)}</small>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('street_no')}</label>
							<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('street_no')} onChange={(e) => this.setState({noStreet: e.target.value})} value={this.state.noStreet}/>
							<small className="form-text text-danger">{t(this.state.errors.noStreet)}</small>
						</div>
					</div>
					<div className="col-md-12">
						<div className="form-group submit-property__group">
							<label className="submit-property__label">{t('address')}</label>
							<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('address')} value={this.state.address} onChange={(e) => this.setState({address: e.target.value})}/>
							<small className="form-text text-info">{t('msg_address_auto')}</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<hr width="100%"/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="submit-property__block">
							<h3 className="submit-property__headline" style={{fontWeight: 'bold'}}>{t('description')}</h3>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
					<Editor editorState={this.state.editorState} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="form-control submit-property__field" onEditorStateChange={this.onEditorStateChange}/>
					<small className="form-text text-danger">{t(this.state.errors.description)}</small>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<hr width="100%"/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="submit-property__block">
								<h3 className="submit-property__headline" style={{fontWeight: 'bold'}}>{t('detail_info')}</h3>
								<div className="row">
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('home_direction')}</label>
											<ReactSelect styles={customStyles} placeholder={'Chọn hướng'} value={this.state.selectedHomeDirection} onChange={this.handleChangeHomeDirection} options={this.state.homeDirections}/>
											<small className="form-text text-danger">{t(this.state.errors.homeDirection)}</small>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('legal_status')}</label>
											<ReactSelect styles={customStyles} placeholder={'Chọn pháp lý'} value={this.state.selectedLegalStatus} onChange={this.handleChangeLegalStatus} options={this.state.legalStatuses}/>
											<small className="form-text text-danger">{t(this.state.errors.legalStatus)}</small>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('floors_no')}</label>
											<input type="number" className="form-control submit-property__field" placeholder={t('floors_no')} onChange={(e) => this.setState({noFloors: e.target.value})} value={this.state.noFloors}/>
											<small className="form-text text-danger">{t(this.state.errors.noFloors)}</small>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('no_beds')}</label>
											<ReactSelect styles={customStyles} placeholder={'Chọn số phòng'} value={this.state.selectedNoBedRoom} onChange={this.handleChangeNoBedRoom} options={this.state.noBedRooms}/>
											<small className="form-text text-danger">{t(this.state.errors.noBedRooms)}</small>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('no_baths')}</label>
											<ReactSelect styles={customStyles} placeholder={'Chọn số phòng'} value={this.state.selectedNoBathRoom} onChange={this.handleChangeNoBathRoom} options={this.state.noBathRooms}/>
											<small className="form-text text-danger">{t(this.state.errors.noBathRooms)}</small>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('furniture')}</label>
											<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('furniture')} onChange={(e) => this.setState({furniture: e.target.value})} value={this.state.furniture}/>
											<small className="form-text text-danger">{t(this.state.errors.furniture)}</small>
										</div>
									</div>
								</div>
							</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<hr width="100%"/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="submit-property__block">
								<h3 className="submit-property__headline" style={{fontWeight: 'bold'}}>{t('gallery')}</h3>
								<div className="row">
	                                <div className="col-md-5">
	                                    <div className="submit-property__group">
	                                        <label className="submit-property__label">{t('featured_image')}</label>
	                                        <span className="submit-property__upload">{t('featured_image_upload_msg')}
	                                            <input type="file" accept=".jpg, .png, .jpeg" onChange={this.handleAddFeaturedImage} disabled={this.state.featuredImage.isLoading}/>
	                                            <BarLoader css={{display: 'block', width: '100%'}} color={"#1fc341"} loading={this.state.featuredImage.isLoading} />
	                                        </span>
	                                        { this.state.errors.featuredImage.length > 0 && <small className="form-text text-danger">{this.state.errors.featuredImage}</small>}
	                                        <div className="mt-2">
	                                        	{this.state.featuredImage.id !== -1 && <img src={this.state.featuredImage.mediumUrl} className="submit-property__featured-image"/>}
	                                        	{this.state.featuredImage.id !== -1 && <div className="submit-property__featured-image--overlay">
	                                        		<button className="btn btn-dark rounded-circle" onClick={this.handleDeleteFeaturedImage}><i className="fas fa-trash" style={{color:"#FFF"}}></i></button>
	                                        	</div>
	                                        	}
	                                        </div>
	                                    </div>
	                                </div>
	                                <div className="col-md-7">
	                                    <div className="submit-property__group">
	                                        <label className="submit-property__label">{t('images')}</label>
	                                        <span className="submit-property__upload">
                                                {t('all_images_upload_msg')}
                                                	<input type="file" accept=".jpg, .png, .jpeg" onChange={this.hanldeUploadImage} disabled={this.state.uploadImage.isLoading}/>
	                                            <BarLoader css={{display: 'block', width: '100%'}} color={"#1fc341"} loading={this.state.uploadImage.isLoading} />

	                                        </span>
	                                        { this.state.errors.imageList.length > 0 && <small className="form-text text-danger">{this.state.errors.imageList}</small>}

	                                        <div className="mt-2">
	                                        	<PostThumbnailList items={this.state.imageList} actionDelete={this.handleDeleteImage}/>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
							</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<hr width="100%"/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="submit-property__block">
								<h3 className="submit-property__headline" style={{fontWeight: 'bold'}}>{t('video')}</h3>
								<div className="row">
	                                <div className="col-md-5">
	                                    <div className="submit-property__group">
	                                        <label className="submit-property__label">{t('video')}</label>
	                                        <div className="submit-property__upload--video">
	                                            <button className="btn btn-lg btn-block" type="button" onClick={this.handleAddVideo}>{t('add_video')}</button>
	                                        </div>
	                                    </div>
	                                </div>
	                                <div className="col-md-7">
	                                	{videoPlayer}
	                                </div>
	                            </div>
							</div>
					</div>
				</div>
				{videoModel}
				<div className="row">
					<div className="col">
						<hr width="100%"/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="submit-property__block">
								<h3 className="submit-property__headline" style={{fontWeight: 'bold'}}>{t('contact_info')}</h3>
								<div className="row">
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('name') + ' *'}</label>
											<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('name')} onChange={(e) => this.setState({contactName: e.target.value})} value={this.state.contactName}/>
											<small className="form-text text-danger">{t(this.state.errors.contactName)}</small>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('phone') + ' *'}</label>
											<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('phone')} onChange={(e) => this.setState({contactPhone: e.target.value})} value={this.state.contactPhone}/>
											<small className="form-text text-danger">{t(this.state.errors.contactPhone)}</small>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('email')}</label>
											<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('email')} onChange={(e) => this.setState({contactEmail: e.target.value})} value={this.state.contactEmail}/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('address')}</label>
											<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('address')} onChange={(e) => this.setState({contactAddress: e.target.value})} value={this.state.contactAddress}/>
										</div>
									</div>
								</div>
								<small className="form-text text-danger">{t('msg_post_contact_info')}</small>

							</div>
					</div>
				</div>
				{verificationModal}
				{addSuccessModal}
				<div className="row">
					<div className="col">
						<hr width="100%"/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-12">
						<div className="submit-property__block">
								<h3 className="submit-property__headline" style={{fontWeight: 'bold'}}>{t('publish_plan')}</h3>
								<div className="row">
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('date_from') + ' *'}</label>
											<div className="form-control submit-property__field">
												<DatePicker dateFormat={"dd/MM/yyyy"} className="submit-property__field" selected={this.state.dateFrom} onChange={this.handleChangeDateFrom}/>
												<small className="form-text text-danger">{t(this.state.errors.dateFrom)}</small>

											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('date_to') + ' *'}</label>
											<div className="form-control submit-property__field">
												<DatePicker dateFormat={"dd/MM/yyyy"} className="submit-property__field" selected={this.state.dateTo} onChange={this.handleChangeDateTo}/>
												<small className="form-text text-danger">{t(this.state.errors.dateTo)}</small>
											</div>										
										</div>
									</div>
									{this.props.free === false && <div className="col-md-4">
										<div className="form-group submit-property__group">
											<label className="submit-property__label">{t('ads_package') + ' *'}</label>
											<ReactSelect styles={customStyles} placeholder={'Chọn gói tin'} value={this.state.selectedPostPackage} onChange={this.handleChangePostPackage} options={this.state.postPackages}/>
											<small className="form-text text-info"><a href="/pricing" target="_blank">{t('learn_more')}</a></small>
										</div>
									</div>
									}
								</div>
								{
									this.state.totalAmount > 0 && 
									<div className="row">
										<div className="col">
											<div className="form-group submit-property__group float-right">
												<label className="submit-property__label" style={{fontWeight: "bold"}}>{t('total_amount')}</label>
												<input type="text" className="form-label" value={totalAmount}/>
											</div>
										</div>
									</div>
								}
						</div>
					</div>
					{
						this.state.totalAmount > 0 && 
						<div className="col-12 mb-2">
							<small className="form-text text-danger">{t('msg_publish_post_in_charge')}</small>
						</div>
					}
					{this.props.free === true &&
						<div className="col-12 mb-2">
							<small className="form-text text-danger">{t('msg_publish_in_free_charge')}</small>
						</div>
					}
					<div className="col-12">
						{actionPublish === true && <button id="recaptcha-container" className="btn btn-info float-left mr-2" onClick={this.handlePublish} disabled={this.state.isLoading}>{t('publish')}</button>}
						{actionSaveChange === true && <button className="btn btn-info float-left mr-2" onClick={this.handleSaveChange} disabled={this.state.isLoading}>{t('save_changes')}</button>}
						{/*<button className="btn btn-info float-left mr-2" onClick={this.handleSaveDraft}>{t('save_draft')}</button>*/}
						{actionCancel === true && <button className="btn btn-dark float-right" onClick={this.handleCancel} disabled={this.state.isLoading}>{t('cancel')}</button>}
					</div>
				</div>
			</div>
		)
	}
}

//export default withRouter(translate("translations")(PostDetailsBox))
export default compose(translate("translations"),withAlert())(withRouter(PostDetailsBox))

/*
<div className="submit-property__upload">
	                                            <div className="submit-property__upload-inner">
	                                                <span className="submit-property__upload-desc">{t('all_images_upload_msg')}
	                                                	<input type="file" accept=".jpg, .png, .jpeg" onChange={this.hanldeUploadImage} disabled={this.state.uploadImage.isLoading}/>
	                                                </span>
	                                            </div>
	                                            <BarLoader css={{display: 'block', width: '100%'}} color={"#1fc341"} loading={this.state.uploadImage.isLoading} />

	                                        </div>*/