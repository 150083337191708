import * as types from '../actions/action-types'

export const coreDataCities = (state={hasError: false, errors: null, cities: [], loading: false}, action) => {
	switch (action.type) {
		case types.FETCH_CITIES_BY_COUNTRY_REQUEST:
			return Object.assign({},state, {loading: true, cities: [], errors: null, hasError: false})
    	case types.FETCH_CITIES_BY_COUNTRY_SUCCESS:
    		return Object.assign({},state, {loading: false, cities: action.payload, errors: null, hasError: false})
    	case types.FETCH_CITIES_BY_COUNTRY_ERROR:
    		return Object.assign({},state, {loading: false, cities: [], errors: action.payload, hasError: true})
    	default:
    		return state
    }
}

export const coreDataDistricts = (state={hasError: false, errors: null, districts: [], loading: false, cityId: -1}, action) => {
    switch (action.type) {
        case types.FETCH_DISTRICTS_BY_CITY_REQUEST:
            return Object.assign({},state, {loading: true, cityId: action.payload, districts: [{ id: -1, name: 'Tất cả' }], errors: null, hasError: false})
        case types.FETCH_DISTRICTS_BY_CITY_SUCCESS:
            return Object.assign({},state, {loading: false, districts: action.payload, errors: null, hasError: false})
        case types.FETCH_DISTRICTS_BY_CITY_ERROR:
            return Object.assign({},state, {loading: false, districts: [], errors: action.payload, hasError: true})
        default:
            return state
    }
}
export const coreDataWards = (state={hasError: false, errors: null, wards: [], loading: false, districtId: -1}, action) => {
    switch (action.type) {
        case types.FETCH_WARDS_BY_DISTRICT_REQUEST:
            return Object.assign({},state, {loading: true, districtId: action.payload, wards: [], errors: null, hasError: false})
        case types.FETCH_WARDS_BY_DISTRICT_SUCCESS:
            return Object.assign({},state, {loading: false, wards: action.payload, errors: null, hasError: false})
        case types.FETCH_WARDS_BY_DISTRICT_ERROR:
            return Object.assign({},state, {loading: false, wards: [], errors: action.payload, hasError: true})
        default:
            return state
    }
}
export const coreDataPropertyTypes = (state={hasError: false, errors: null, types: [], loading: false, propertyTypeId: -1}, action) => {
    switch (action.type) {
        case types.FETCH_PROPERTY_TYPES_BY_PARENT_REQUEST:
            return Object.assign({},state, {loading: true, propertyTypeId: action.payload, types: [], errors: null, hasError: false})
        case types.FETCH_PROPERTY_TYPES_BY_PARENT_SUCCESS:
            return Object.assign({},state, {loading: false, types: action.payload, errors: null, hasError: false})
        case types.FETCH_PROPERTY_TYPES_BY_PARENT_ERROR:
            return Object.assign({},state, {loading: false, types: [], errors: action.payload, hasError: true})
        default:
            return state
    }
}
export const coreDataPropertyCategoriesByType = (state={hasError: false, errors: null, types: [], loading: false, propertyTypeId: -1}, action) => {
    switch (action.type) {
        case types.FETCH_PROPERTY_TYPES_BY_PARENT_REQUEST:
            return Object.assign({},state, {loading: true, propertyTypeId: action.payload, types: [], errors: null, hasError: false})
        case types.FETCH_PROPERTY_TYPES_BY_PARENT_SUCCESS:
            return Object.assign({},state, {loading: false, types: action.payload, errors: null, hasError: false})
        case types.FETCH_PROPERTY_TYPES_BY_PARENT_ERROR:
            return Object.assign({},state, {loading: false, types: [], errors: action.payload, hasError: true})
        default:
            return state
    }
}
export const coreDataReportType = (state={hasError: false, errors: null, types: [], loading: false}, action) => {
    switch (action.type) {
        case types.FETCH_ALL_REPORT_TYPES_REQUEST:
            return Object.assign({},state, {loading: true, types: [], errors: null, hasError: false})
        case types.FETCH_ALL_REPORT_TYPES_SUCCESS:
            return Object.assign({},state, {loading: false, types: action.payload, errors: null, hasError: false})
        case types.FETCH_ALL_REPORT_TYPES_ERROR:
            return Object.assign({},state, {loading: false, types: [], errors: action.payload, hasError: true})
        default:
            return state
    }
}


export const projectCategories = (state={categories: [], isLoading: false, hasError: false, errors: null }, action) => {
    switch (action.type) {
        case types.FETCH_ALL_PROJECT_CATEGORIES_REQUEST:
            return Object.assign({},state, { categories: [], hasError: false, errors: null, isLoading: true}); 
        case types.FETCH_ALL_PROJECT_CATEGORIES_SUCCESS:
            return Object.assign({},state, {categories: action.payload, hasError: false, errors: null, isLoading: false}); 
        case types.FETCH_ALL_PROJECT_CATEGORIES_ERROR:
            return Object.assign({},state, {categories: [], hasError: true,errors: action.payload, isLoading: false} ); 
    }
    return state
}