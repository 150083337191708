import React from 'react'
import { translate } from 'react-i18next'
const ProjectImageGallery = ({t, items}) => (
	<div className="container" style={ {maxHeight: '450px'} }>
		<div className="row no-gutters">

			<div className="card col-md-10 col-12">
				<img className="card-img-top img-fluid" style={{borderRadius: '0px'}} src="/images/home_slider_bcg.jpg"/>
				<div className="card-img-overlay d-flex flex-column justify-content-end">
				</div>
			</div>
			<div className="col-md-2 col-12" style={{backgroundColor: '#0e1d41'}}>
				<div>
					<img className="img-fluid border-0 p-0 m-2" src="/images/home_slider_bcg.jpg"/>
					<img className="img-thumbnail border-0 p-0 m-2" src="/images/home_slider_bcg.jpg"/>
					<img className="img-thumbnail border-0 p-0 m-2" src="/images/home_slider_bcg.jpg"/>
					<img className="img-thumbnail border-0 p-0 m-2" src="/images/home_slider_bcg.jpg"/>
					<img className="img-thumbnail border-0 p-0 m-2" src="/images/home_slider_bcg.jpg"/>
				</div>
			</div>
		</div>
	</div>
)
export default translate("translations")(ProjectImageGallery)