export const ERROR_CODE_CATEGORY_ID_NOT_FOUND = 33
export const ERROR_CODE_POST_ID_NOT_NULL = 39
export const ERROR_CODE_POST_REPORT_TYPE_ID_NOT_NULL = 40
export const ERROR_CODE_PHONE_NOT_EMPTY = 111
export const ERROR_CODE_PHONE_INCORRECT = 112
export const ERROR_CODE_EMAIL_NOT_EMPTY = 114
export const ERROR_CODE_EMAIL_INCORRECT = 115
export const ERROR_CODE_NAME_TOO_LONG = 117
export const ERROR_CODE_NAME_NOT_EMPTY = 118
export const ERROR_CODE_FILE_TOO_LARGE = 127
export const ERROR_CODE_CONTENT_NOT_EMPTY = 128
export const ERROR_CODE_CONTENT_TOO_LONG = 129
export const ERROR_CODE_RECEIVER_NOT_EMPTY = 130
export const ERROR_CODE_FILE_FORMAT_UNSUPPORT = 131
export const ERROR_CODE_FEATURED_IMAGE_REQUIRED_MIN_SIZE = 132
export const ERROR_CODE_PRICE_NOT_EMPTY = 57
export const ERROR_CODE_PROJECT_ID_NOT_FOUND = 58
export const ERROR_CODE_POST_UOM_ID_NOT_FOUND = 59
export const ERROR_CODE_POST_PACKAGE_ID_NOT_FOUND = 60
export const ERROR_CODE_POST_TITLE_NOT_EMPTY = 61
export const ERROR_CODE_POST_TITLE_TOO_LONG = 62
export const ERROR_CODE_POST_ADDRESS_NOT_EMPTY = 63
export const ERROR_CODE_TYPE_NOT_EMPTY = 75
export const ERROR_CODE_POST_CATEGORY_NOT_EMPTY = 76
export const ERROR_CODE_SECRET_NOT_EMPTY = 79
export const ERROR_CODE_TOKEN_NOT_EMPTY = 80
export const ERROR_CODE_SECRET_TYPE_NOT_EMPTY = 81
export const ERROR_CODE_SECRET_IN_CORRECT = 138


