import React, {Component} from 'react'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"
import Carousel from 'react-bootstrap/Carousel'
import NewsLetter from '../../components/NewsLetter'
import companyServices from '../../services/companies'
import queryString from 'query-string'
import SearchBox from '../../containers/companies/SearchBox'
import NewPostListings from '../../components/posts/NewPostListings'

import TopCompanies from '../../components/companies/TopCompanies'

// import ReactCountdownClock from 'react-countdown-clock'
class Companies extends Component {
	constructor(props) {
		super(props)
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
	}
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	handleSearchSubmit (event) {
		let criteria = {}
		if (this.props.categoryId && this.props.categoryId != -1) {
			criteria.catId = this.props.categoryId
		}
		if (this.props.wardId && this.props.wardId !== -1) {
			criteria.wardId = this.props.wardId	
		}
		if (this.props.districtId && this.props.districtId !== -1) {
			criteria.districtId = this.props.districtId	
		}
		if (this.props.cityId && this.props.cityId !== -1) {
			criteria.cityId = this.props.cityId
		}
		this.props.history.push({ pathname: '/companies/listings',
		search: "?" + new URLSearchParams(criteria).toString()})
		event.preventDefault()
	}
	_renderHeader() {
		return (<Helmet>
			{/*facebook*/}
			<title> {"Thông tin doanh nghiệp bất động sản, địa ốc tại Việt Nam"} </title>
			<meta property="og:title" content="Thông tin doanh nghiệp bất động sản, địa ốc tại Việt Nam" />
			<meta property="og:description" content="Thông tin doanh nghiệp bất động sản, địa ốc tại Việt Nam"/>
			<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://topauto.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Thông tin doanh nghiệp bất động sản, địa ốc tại Việt Nam"/>
			<meta name="twitter:description" content="Thông tin doanh nghiệp bất động sản, địa ốc tại Việt Nam"/>
			<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>)
	}
	render () {
		const {t} = this.props
		const header = this._renderHeader()
		return (
			<React.Fragment>
				{header}
				<div className="container">
					<div className="row m-3">
						<div className="col-md-4">
							<h3>{t('find_companies')}</h3>
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-md-4 col-12">
							<SearchBox handleSubmit={this.handleSearchSubmit}/>
						</div>
						<div className="col-sm-8 col-12">
							<Carousel>
								<Carousel.Item>
							    <img
							      className="d-block w-100 h-450"
							      src="/images/listing_video.jpg"
							      alt="First slide"
							    />
							    <Carousel.Caption>
							      <h3>First slide label</h3>
							      <p></p>
							    </Carousel.Caption>
							  </Carousel.Item>
							  <Carousel.Item>
							    <img
							      className="d-block w-100"
							      src="/images/home_background.jpg"
							      alt="Third slide"
							    />

							    <Carousel.Caption>
							      <h3>Second slide label</h3>
							      <p></p>
							    </Carousel.Caption>
							  </Carousel.Item>
							  <Carousel.Item>
							    <img
							      className="d-block w-100"
							      src="/images/home_slider_bcg.jpg"
							      alt="Third slide"
							    />
							    <Carousel.Caption>
							      <h3>Third slide label</h3>
							      <p></p>
							    </Carousel.Caption>
							  </Carousel.Item>
							</Carousel>
						</div>
					</div>
					<div className="row">
					</div>
				</div>
				<TopCompanies />
				<NewPostListings cityName={"Hồ Chí Minh"} cityId={51} />
				<NewPostListings cityName={"Hà Nội"} cityId={2}/>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Companies)