import React, {Component} from 'react'
import { translate } from "react-i18next"
import FieldDropdownItem from '../searches/FieldDropdownItem'
class SearchBox extends Component {
	constructor(props) {
		super(props)
		this.props.fetchAllCompanyCategories()
		this.changeCity = this.changeCity.bind(this)
		this.changeDistrict = this.changeDistrict.bind(this)
		this.changeWard = this.changeWard.bind(this)
		this.changeCategory = this.changeCategory.bind(this)	
	}
	changeCategory(item) {
		if (item) {
			this.props.changeCategory(item)
		}
	}
	changeCity(item) {
		if (item) {
			this.props.changeCity(item)
		}
	}
	changeDistrict(item) {
		if (item) {
			this.props.changeDistrict(item)
		}
	}
  	changeWard(item) {
  		if (item) {
  			this.props.changeWard(item)
  		}
  	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<div className="container search-box">
					<div className="row pt-4 pb-2">
						<div className="col-12">
							<FieldDropdownItem title={t('project_category')} selected={this.props.categoryId} onChange={this.changeCategory} items={[{ id: -1, name: 'Tất cả' }, ...this.props.categories]}/>
						</div>
						<div className="col-12">
							<FieldDropdownItem title={t('city')} selected={this.props.cityId} onChange={this.changeCity} items={[{ id: -1, name: 'Tất cả' }, ...this.props.cities]}/>
						</div>
						<div className="col-6">
							<FieldDropdownItem title={t('district')} selected={this.props.districtId} onChange={this.changeDistrict} items={[{ id: -1, name: 'Tất cả' },...this.props.districts]}/>
						</div>
						<div className="col-6">
							<FieldDropdownItem title={t('ward')} selected={this.props.wardId} onChange={this.changeWard} items={[{ id: -1, name: 'Tất cả' },...this.props.wards]}/>
						</div>
						<div className="col-12">
							<div className="form-group">
								<button type="button" onClick={this.props.handleSubmit} className="btn btn-danger btn-block" style={{height: "60px", fontSize:"20px"}}>{t('search')}</button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default translate("translations")(SearchBox)