import React from 'react'
import { connect } from 'react-redux'
import News from '../../pages/news/News'
import {searchNewsChangeQuery} from '../../actions/news'

const mapStateToProps = state => ({
	query: state.newsSearchParams.query
})
const mapDispatchToProps = (dispatch, ownProps) => ({
	onChangeQuery: (query) => dispatch(searchNewsChangeQuery(query))
})
export default connect(mapStateToProps,mapDispatchToProps)(News)