import React from 'react'
import { translate } from "react-i18next"

///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
const InDevelopmentBox = ({t}) => (
	<div className="container">
		<div className="row">
			<div className="col">
				<div className="jumbotron">
					<h1>{t('in_development')}</h1>
					<p className="lead text-justify">{t('in_development_subheadline')}</p>
				</div>
			</div>
		</div>
		
	</div>
)
export default translate("translations")(InDevelopmentBox);