import React from 'react'
import { translate } from 'react-i18next'
import ContractTemplateListItem from './ContractTemplateListItem'
const ContractTemplateList = ({t, items}) => (
	<div className="container">
		<ul className="row">
		{items.map(item => <ContractTemplateListItem key={item.id} item={item}/>)}
		</ul>
	</div>
)
export default translate("translations")(ContractTemplateList)