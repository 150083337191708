import React, { Component } from 'react'
import { translate, Trans } from "react-i18next"
import queryString from 'query-string'
import Pagination from '../../components/Pagination'
import FavouritePostList from '../posts/FavouritePostList'
import localStorageServices from '../../services/local/posts'
import NoItemListings from '../../components/NoItemListings'
import Loading from "react-spinners/ClockLoader"
import postServices from '../../services/posts'
import authServices from '../../services/auth'

class UserFavouritePosts extends Component {
	constructor(props) {
		super(props)
		this.state = {
			total: 0,
			currentPage: 1,
			totalPages: 0,
			pageSize: 5,
			items: [],
			isLoading: false,
			isError: false,
			errors: {}
		}
		this.handleRemoveAll = this.handleRemoveAll.bind(this)
		this.changePage = this.changePage.bind(this)
		this.nextPage = this.nextPage.bind(this)
		this.prevPage = this.prevPage.bind(this)
	}
	componentDidMount() {
		this._fetch()
	}

	_fetch() {
		const values = queryString.parse(this.props.location.search)
		if (!values.page) {
			values.page = this.state.currentPage
		}
		if (!values.size) {
			values.size = this.state.pageSize
		}

		this.setState({items: [], total: 0, totalPages: 0, errors: {}, isLoading: true, isError: false })
		if (authServices.isLoggin()) {

		} else {
			let favItems = localStorageServices.getFavouritePosts()
			let total = favItems.length
			let totalPages = Math.ceil(total/values.size)
			this.setState({total,totalPages,pageSize: values.size, currentPage:values.page})
			this._fetchListPostForAnonymous(values.page,values.size)
			//this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})	
		}
		
	}
	_fetchPostListForLoggin() {

	}
	_fetchListPostForAnonymous(page, size) {
		let favItems = localStorageServices.getFavouritePosts()
		let pageItems = favItems.slice(size*page - size,size*page)
		let postIds = pageItems.map(item => item.id)
		if (postIds.length > 0) {
			this.setState({items: [], errors: {}, isLoading: true, isError: false })
			postServices.fetchListItemsByIds(postIds)
			.then (response => {
				if (response.status === 'OK') {
					this.setState({items: response.data, errors: {}, isLoading: false, isError: false })
				} else {
					if (response.status === "ZERO_RESULTS") {
						this.setState({items: [], errors: {}, isLoading: false, isError: false })
					} else {
						this.setState({items: [], errors: response.errors, isLoading: false, isError: true })
					}
				}
			})
			.catch(error => this.setState({items: [], errors: error, isLoading: false, isError: true }))
		}
		window.scrollTo(0, 0)

	}
	nextPage(event) {
		if (this.state.currentPage < this.state.totalPages) {
			this.changePage(this.state.currentPage+1)
		}
	}
	prevPage(event) {
		if (this.state.currentPage > 1) {
			this.changePage(this.state.currentPage-1)
		}
	}
	changePage(page) {
		let criteria = {}
		let values = queryString.parse(this.props.location.search)
		values.page = page
		if (!values.size) {
			values.size = this.state.pageSize
		}
		if (authServices.isLoggin()) {

		} else {
			//let favItems = localStorageServices.getFavouritePosts()
			//let pageItems = favItems.slice(values.size*values.page - values.size,values.size*values.page)
			//let postIds = pageItems.map(item => item.id)
			this.setState({currentPage:values.page})
			//this._fetchListPostForAnonymous(postIds)
		}
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})
		//window.scrollTo(0, 0)
	}
	componentWillReceiveProps(newProps) {
		if (newProps === this.props) 
			return
		if (newProps.location.search !== this.props.location.search) {
			const values = queryString.parse(newProps.location.search)
			if(Object.entries(values).length === 0){
				this.setState({currentPage:1})
			}

		}
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		// If we have a snapshot value, we've just added new items.
		// Adjust scroll so these new items don't push the old ones out of view.
		// (snapshot here is the value returned from getSnapshotBeforeUpdate)
		if (this.props !== prevProps) {
			let values = queryString.parse(this.props.location.search)
			if (!values.page) {
				values.page = 1
			}
			if (!values.size) {
				values.size = this.state.pageSize
			}
			if (authServices.isLoggin()) {

			} else {
				this._fetchListPostForAnonymous(values.page, values.size) 
			}
    	}
	}
	handleRemoveAll(event) {
		event.preventDefault()
		localStorageServices.removeAllFavouritePosts()
		this._fetch()

	}
	_renderPaging() {
		if (this.state.total > this.state.pageSize) {
			return <Pagination totalPages = {this.state.totalPages} currentPage={this.state.currentPage} margin={2}
			 goto={this.changePage} next={this.nextPage} prev={this.prevPage}/>
		}
		return null
	}
	/*_renderFavourites() {
		let {items} = this.state
		const {t} = this.props
		if (items.length === 0) {
			return <></>
		}
		let liItems = []
		items.forEach ( (item) => {
			let tmp = <li className="manage-list__item">
								<div className="manage-list__item-container">
									<div className="manage-list__item-img">
									<a href="single_property_1.html">
										<img with="140px" height="100px" src={'https://images.unsplash.com/photo-1496782850948-d8e6bbe11a9f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'} alt="Weston Hightpointe Place" className="listing__img"/>
									</a>
								</div>
								<div className="manage-list__item-detail">
									<h6 className="listing__title"><a href="single_property_1.html">{item.title}</a></h6>
									<p className="listing__location"><i class="fab fa-adversal"></i>&nbsp;&nbsp;{item.address}</p>
									<p className="listing__price"><i class="far fa-money-bill-alt"></i>&nbsp;&nbsp;{item.priceText}</p>
									</div>
								</div>
								<div className="manage-list__action">
									<a className="listing__location"><i className="fas fa-times mr-1"></i>{t('delete')}</a>
								</div>
						</li>
			liItems.push(tmp)
		})
		return liItems
	}*/
	removeItem = id => {
		let ok = localStorageServices.removeFavouritePost(id)
		if (ok) {
			this._fetch()
		}
	}
	_renderPostList() {
		const {t} = this.props
		if (this.state.isLoading === true) {
			return null
		}
		if (this.state.items.length > 0 ) {
			return (
				<React.Fragment>
					<FavouritePostList items={this.state.items} actionRemove={this.removeItem} src={'/users/my-favourites'} ids={this.state.items.map(item => item.id)}/>
				</React.Fragment>
			)
		}
		return (
			<React.Fragment>
				 <NoItemListings title={t('my_properties_no_items')}/>
			</React.Fragment>
		)
	}
	render (){
		const {t} = this.props
		const pagination = this._renderPaging()
		const postList = this._renderPostList()
		return (
			<React.Fragment>
				<div className="mb-4">
					<ul className="manage-list manage-list--bookmark">
						<li className="manage-list__header">
							<span className="manage-list__title"><i className="far fa-bookmark"></i>&nbsp;&nbsp;{t('favourite_listings')}</span>
							<div className="manage-list__action">
								<a href="#" onClick={this.handleRemoveAll}><i className="far fa-trash-alt"></i>&nbsp;&nbsp;{t('remove_all')}</a>
							</div>
						</li>
					</ul>
					<Loading css={{display: 'block', margin: '0 auto', borderColor: 'red'}} size={70} color={"#0e1d41"} loading={this.state.isLoading} />
					{postList}
					{this.state.items.length > 0 && pagination}
				</div>
			</React.Fragment>
		)
	}
}
export default translate("translations")(UserFavouritePosts)