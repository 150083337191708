import React, {Component} from 'react'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"
import NewsLetter from '../../components/NewsLetter'
import Breadcrumbs from '../../components/cities/Breadcrumbs'
import CitiesStats from '../../components/cities/CitiesStats'
import AdsBox from '../../components/ads/AdsBox'

// import ReactCountdownClock from 'react-countdown-clock'
class Cities extends Component {
	constructor(props) {
		super(props)
	}
	_renderHeader() {
		return (<Helmet>
					{/*facebook*/}
					<title> {"TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam"} </title>
					<meta property="og:title" content="TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam" />
					<meta property="og:description" content="TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam"/>
					<meta name="twitter:description" content="TopReal | Bảng giá tham khảo thị trường bất động sản tại Việt Nam"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
			</Helmet>
		)
	}
	render () {
		const {t} = this.props
		const header = this._renderHeader()
		return (
			<React.Fragment>
				{ header }
				<Breadcrumbs/>
				<div className="container mt-3">
					<div className="row">
						<div className="col-md-8">
				 			<CitiesStats/>
				 		</div>
				 		<div className="col-md-4">
				 			<div className="row mb-3">
								<div className="col">
									<AdsBox item={{headline: "Liên hệ quảng cáo", subTitle: "0982437172"}}/>
								</div>
							</div>
				 		</div>
				 	</div>
				</div> 
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Cities)