import React, {Component}from 'react'
import { translate } from 'react-i18next'
import {NEW_POST_IN_CITY_LIST, CLIENT_PREFER_CITIES} from '../../configs/mocks'

class ClientsPrefer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: CLIENT_PREFER_CITIES,
			isLoading: false,
			isError: false,
			errors: null,
		}
	}
	componentDidMount() {
		
	}
	render() {
		const {t} = this.props
		return (

		<div className="mb-2">
			<div className="container mb-3">
				<div className="row">
					<div className="col">
						<div className="section_title text-center">
							<h3>{t('clients_prefer')}</h3>
							<a href="/cities" className="section_subtitle_a">{t('view_all')}</a>
						</div>
					</div>
				</div>
			</div>
		<div className="container">
			<div className="row">
				{
					this.state.items.map ((item,i) => 
					<div key={i} className="col-xl-6 col-12 mb-4">
						<div className="card border-0 bg-dark text-white" style={{borderRadius: '12px'}}>
							<img className="card-img" style={{height: '300px', borderRadius: '12px', backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7), #000000)"}} src={item.imageUrl?item.imageUrl:"/images/no_image.png"} alt={item.name}/>
							<div className="card-img-overlay d-flex flex-column justify-content-end">
								<h5 className="card-title">{item.name}</h5>
								<div className="d-flex flex-row">
									<p className="card-text mr-3">{item.priceInM2}</p>
									<p className="card-text">{item.count}</p>
								</div>
							</div>
						</div>
					</div>
					)
				}				
			</div>
		</div>
	</div>
	)
	}
}
export default translate("translations")(ClientsPrefer);