import React from 'react'
import { translate } from "react-i18next"
const Breadcrumbs = ({t}) => (
	<section className="section__breadcrumbs">
		<div className="container">
			<header role="title-page" className="text-center">
				<h2>{t('laws_lib')}</h2>
				<h4>{t('laws_lib_subtitle')}</h4>
			</header>
		</div>
    </section>
)
export default translate("translations")(Breadcrumbs);