import React, { Component } from 'react'
import { translate, Trans } from "react-i18next"
import { Redirect, Link, Route, Switch } from "react-router-dom"
import authServices from '../../services/auth'

class LeftNav extends Component {
	constructor(props) {
		super(props)
		this.state = {
			keywords:null,
			fullName: null,
		}
	}
	render (){
		const {t} = this.props 
		let wellcome = null
		if (this.state.fullName) {
			wellcome = 	<h2 className="bookmarked-listing__headline d-flex">{t('hello')}, <strong>{this.state.fullName}!</strong></h2>
		} else {
			wellcome = 	<h2 className="bookmarked-listing__headline d-flex">{t('hello')}</h2>
		}
		let isLoggin = authServices.isLoggin()
		return (
			<React.Fragment>
				{wellcome}
				<div className="settings-block">
					<span className="settings-block__title">{t('manage_account')}</span>
					<ul className="settings">
						{isLoggin == true && <li className={this.props.current === 'profile'? 'setting setting--current': 'setting'}><Link to="/users/profile" className="setting__link d-flex align-items-center"><i className="far fa-user pr-2 setting__icon"></i>{t('my_profile')}</Link></li>}
						{isLoggin == true && <li className={this.props.current === 'my-wallet'? 'setting setting--current': 'setting'}><Link to="/users/my-wallet" className="setting__link d-flex align-items-center"><i className="fas fa-wallet pr-2 setting__icon"></i>{t('my_wallet')}</Link></li>}
						<li className={this.props.current === 'my-favourites'? 'setting setting--current': 'setting'}><Link to="/users/my-favourites" className="setting__link d-flex align-items-center"><i className="far fa-heart pr-2 setting__icon"></i>{t('my_favourites')}</Link></li>
					</ul>
				</div>
				<div className="settings-block">
					<span className="settings-block__title">{t('manage_listing')}</span>
					<ul className="settings">
						{isLoggin == true && <li className={this.props.current === 'my-packages'? 'setting setting--current': 'setting'}><Link to="/users/my-packages" className="setting__link d-flex align-items-center"><i className="fas fa-shopping-cart pr-2 setting__icon"></i>{t('my_packages')}</Link></li>}
						<li className={this.props.current === 'my-properties'? 'setting setting--current': 'setting'}><Link to="/users/my-properties" className="setting__link d-flex align-items-center"><i className="fas fa-home pr-2 setting__icon"></i>{t('my_properties')}</Link></li>
						{isLoggin == true && <li className={this.props.current === 'add-ads'? 'setting setting--current': 'setting'}><Link to="/users/add-ads" className="setting__link d-flex align-items-center"><i className="fas fa-upload pr-2 setting__icon"></i>{t('submit_ads')}</Link></li>}
					</ul>
				</div>
				{isLoggin == true && <div className="settings-block">
					<ul className="settings">
						{/*<li className={this.props.current === 'change-password'? 'setting setting--current': 'setting'}><Link to="/users/change-password" className="setting__link d-flex align-items-center"><i className="fas fa-unlock-alt pr-2 setting__icon"></i>{t('change_password')}</Link></li>*/}
						<li className={this.props.current === 'logout'? 'setting setting--current': 'setting'}><a href="/" className="setting__link d-flex align-items-center"><i className="fas fa-sign-out-alt pr-2 setting__icon"></i>{t('logout')}</a></li>
					</ul>
				</div>
				}
			</React.Fragment>
		)
	}
}
export default translate("translations")(LeftNav)