import React from 'react'
import { translate } from 'react-i18next'
import FavouritePostListItem from './FavouritePostListItem'
const FavouritePostList = ({t, items, ids, src, actionRemove}) => (
	<div className="container">
		<div className="row">
		{items.map((item,idx) => <FavouritePostListItem key={idx} item={item} actionRemove={actionRemove} ids={ids} src={src}/>)}
		</div>
	</div>
)
export default translate("translations")(FavouritePostList)