import * as types from './action-types'
import coreDataServices from '../services/core-data'
export function fetchCities()
{
	return function (dispatch) {
		dispatch(fetchCitiesRequest())
		coreDataServices.fetchCitiesByRanking(243)
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchCitiesSuccess(response.data))
				}else{
					dispatch(fetchCitiesError(response.errors))
				}
			})
			.catch(error => {dispatch(fetchCitiesError(error))})
	}
}
export function fetchCitiesRequest()
{
	return {type: types.FETCH_CITIES_BY_COUNTRY_REQUEST, payload: null}
}
export function fetchCitiesSuccess(cities)
{
	return {type: types.FETCH_CITIES_BY_COUNTRY_SUCCESS, payload: cities}
}
export function fetchCitiesError(errors)
{
	return {type: types.FETCH_CITIES_BY_COUNTRY_ERROR, payload: errors}
}
export function fetchDistricts(cityId)
{
	return function (dispatch) {
		dispatch(fetchDistrictsRequest(cityId))
		coreDataServices.fetchDistricts(cityId)
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchDistrictsSuccess(response.data))
				}else{
					dispatch(fetchDistrictsSuccess([]))
				}
			})
			.catch(error => {dispatch(fetchDistrictsError(error))})
	}
}
export function fetchDistrictsRequest(cityId)
{
	return {type: types.FETCH_DISTRICTS_BY_CITY_REQUEST, payload: cityId}
}
export function fetchDistrictsSuccess(districts)
{
	return {type: types.FETCH_DISTRICTS_BY_CITY_SUCCESS, payload: districts}
}
export function fetchDistrictsError(errors)
{
	return {type: types.FETCH_DISTRICTS_BY_CITY_ERROR, payload: errors}
}
export function fetchWards(districtId) 
{
	return function (dispatch) {
		dispatch(fetchWardsRequest(districtId))
		coreDataServices.fetchWards(districtId)
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchWardsSuccess(response.data))
				}else{
					dispatch(fetchWardsError(response.errors))
				}
			})
			.catch(error => {dispatch(fetchWardsError(error))})
	}
}
export function fetchWardsRequest(districtId)
{
	return {type: types.FETCH_WARDS_BY_DISTRICT_REQUEST, payload: districtId}
}
export function fetchWardsSuccess(wards)
{
	return {type: types.FETCH_WARDS_BY_DISTRICT_SUCCESS, payload: wards}
}
export function fetchWardsError(errors)
{
	return {type: types.FETCH_WARDS_BY_DISTRICT_ERROR, payload: errors}
}

export function fetchPropertyTypesByParent(parentId)
{
	return function (dispatch) {
		dispatch(fetchPropertyTypesByParentRequest(parentId))
		coreDataServices.fetchPropertyTypesByParent(parentId)
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchPropertyTypesByParentSuccess(response.data))
				}else{
					dispatch(fetchPropertyTypesByParentError(response.errors))
				}
			})
			.catch(error => {dispatch(fetchPropertyTypesByParentError(error))})
	}
}
export function fetchPropertyTypesByParentRequest(parentId)
{
	return {type: types.FETCH_PROPERTY_TYPES_BY_PARENT_REQUEST, payload: parentId}
}
export function fetchPropertyTypesByParentSuccess(propertyTypes)
{
	return {type: types.FETCH_PROPERTY_TYPES_BY_PARENT_SUCCESS, payload: propertyTypes}
}
export function fetchPropertyTypesByParentError(errors)
{
	return {type: types.FETCH_PROPERTY_TYPES_BY_PARENT_ERROR, payload: errors}
}

export function fetchPropertyCategoriesByType(type)
{
	return function (dispatch) {
		dispatch(fetchPropertyCategoriesByTypeRequest(type))
		coreDataServices.fetchPropertyCategoriesByType(type)
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchPropertyCategoriesByTypeSuccess(response.data))
				}else{
					dispatch(fetchPropertyTypesByParentError(response.errors))
				}
			})
			.catch(error => {dispatch(fetchPropertyCategoriesByTypeError(error))})
	}
}
export function fetchPropertyCategoriesByTypeRequest(type)
{
	return {type: types.FETCH_PROPERTY_TYPES_BY_PARENT_REQUEST, payload: type}
}
export function fetchPropertyCategoriesByTypeSuccess(catetories)
{
	return {type: types.FETCH_PROPERTY_TYPES_BY_PARENT_SUCCESS, payload: catetories}
}
export function fetchPropertyCategoriesByTypeError(errors)
{
	return {type: types.FETCH_PROPERTY_TYPES_BY_PARENT_ERROR, payload: errors}
}
export function fetchAllReportTypes()
{
	return function (dispatch) {
		dispatch(fetchAllReportTypesRequest())
		coreDataServices.fetchAllReportTypes()
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchAllReportTypesSuccess(response.data))
				}else{
					dispatch(fetchAllReportTypesSuccess([]))
				}
			})
			.catch(error => {dispatch(fetchAllReportTypesError(error))})
	}
}
export function fetchAllReportTypesRequest()
{
	return {type: types.FETCH_ALL_REPORT_TYPES_REQUEST, payload: null}
}
export function fetchAllReportTypesSuccess(categories)
{
	return {type: types.FETCH_ALL_REPORT_TYPES_SUCCESS, payload: categories}
}
export function fetchAllReportTypesError(errors)
{
	return {type: types.FETCH_ALL_REPORT_TYPES_ERROR, payload: errors}
}