import React, {Component} from 'react'
import {generateCaptchaSecret} from '../helpers/captcha-utils'
///https://lavrton.com/using-react-with-html5-canvas-871d07d8d753/
class VisionCaptcha extends Component {
	constructor(props) {
        super(props)
        this.refresh = this.refresh.bind(this)
        this.state = {secret: this.props.secret}
    }
    componentDidMount() {
        this.updateCanvas();
    }
    componentWillReceiveProps(nextProps) {
	  if (this.props.secret !== nextProps.secret) {
	    this.setState({
	      secret: nextProps.secret
	    });
	  }
	}
    updateCanvas() {
        const ctx = this.refs.canvas.getContext('2d');
    	ctx.fillStyle = "#FFFFFF";
    	ctx.fillRect(0,0, this.props.width, this.props.height);
        ctx.font = "25px Georgia";
  		ctx.strokeText(this.props.secret, 0, this.props.height*0.8);
    }
    refresh() {
    	const ctx = this.refs.canvas.getContext('2d');
    	ctx.fillStyle = "#FFFFFF";
    	ctx.fillRect(0,0, this.props.width, this.props.height);
        ctx.font = "25px Georgia";
        let secret = generateCaptchaSecret(6).join('')
        this.setState({secret: secret})
  		ctx.strokeText(secret, 0, this.props.height*0.8);
  		this.props.refresh(secret)
    }
    render () 
    {
    	return (
        	<React.Fragment>
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="input-group mb-3 d-flex">
							    <canvas ref="canvas" width={this.props.width} height={this.props.height}/>
							    <div className="input-group-append">
							      <button type="button" className="btn" onClick={this.refresh}><i className="fas fa-sync-alt"></i></button>
							    </div>
							</div>
							
							
						</div>
					</div>
				</div>
			</React.Fragment>
    	)
    }
}


export default VisionCaptcha