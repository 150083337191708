import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Listings from '../../pages/projects/Listings'
import {changeSearchProjectCategory,changeSearchProjectCity,changeSearchProjectDistrict,changeSearchProjectWard
,changeSearchProjectPriceMax,changeSearchProjectPriceMin} from '../../actions/projects'
const mapStateToProps = state => ({
	cities: state.coreDataCities.cities? state.coreDataCities.cities: [],
	districts: state.projectSearchParams.districts? state.projectSearchParams.districts: [],
	wards: state.projectSearchParams.wards? state.projectSearchParams.wards: [],
	categories: state.projectCategories.categories?  state.projectCategories.categories: [],
	priceRanges: state.projectSearchParams.priceRanges?state.projectSearchParams.priceRanges:[],
	categoryId: state.projectSearchParams.categoryId,
	cityId: state.projectSearchParams.cityId,
	districtId: state.projectSearchParams.districtId,
	wardId: state.projectSearchParams.wardId,
	pMinId: state.projectSearchParams.minId
})
const mapDispatchToProps = (dispatch, ownProps) => ({
	changeSearchProjectCategory: (id) => dispatch(changeSearchProjectCategory(id)),
	changeSearchProjectCity: (cityId) => dispatch(changeSearchProjectCity(cityId)),
	changeSearchProjectDistrict: (districtId) => dispatch(changeSearchProjectDistrict(districtId)),
	changeSearchProjectWard: (wardId) => dispatch(changeSearchProjectWard(wardId)),
	changeSearchProjectPriceMax: (id) => dispatch(changeSearchProjectPriceMax(id)),
	changeSearchProjectPriceMin: (id) => dispatch(changeSearchProjectPriceMin(id)),
})
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Listings))