import React from 'react'
import { translate } from "react-i18next"

///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
const Breadcrumbs = ({t}) => (
	<section className="breadcrumb-area bg-img bg-overlay" style = {{backgroundImage: "url('images/40.jpg')"}} >
		<div className="container h-100">
			<div className="row h-100 align-items-center">
					<div className="col-12"><div class="breadcrumb-content">
						<h2>{t('coming_soon')}</h2>
					</div>
				</div>
			</div>
		</div>
	</section>
)
export default translate("translations")(Breadcrumbs)