import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import Section from '../../components/Section'
import {Helmet} from "react-helmet"
import PostSearchBoxListing from '../../components/posts/PostSearchBoxListing'
import {SEARCH_METHOD} from '../../configs/default-params' 
import SeoBreadcrumbs from '../../components/posts/SeoBreadcrumbs'
import PostList from '../../components/posts/PostList'
import Pagination from '../../components/Pagination'
import NewsLetter from '../../components/NewsLetter'
import FeaturedPostsAside from '../../components/posts/FeaturedPostsAside'
import queryString from 'query-string'
import postServices from '../../services/posts'
import Loading from "react-spinners/ClockLoader"
import {SEO_PATH_QUERIES} from '../../configs/constants'
import {NEWS_V1} from '../../configs/configs'
import AsideNews from '../../components/news/AsideNews'
import FeaturedProjectsAside from '../../components/projects/FeaturedProjectsAside'
class SeoPosts extends Component {
	constructor(props) {
		super(props)
		this.state = {
			method: 1, //:1 Mua ban, 2 cho thue
			categoryId: null,
			cityId: null,
			districtId: null,
			crumbs: [],
			total: 0,
			currentPage: 1,
			totalPages: 0,
			pageSize: 10,
			items: [],
			isLoading: false,
			isError: false,
			errors: null
		}
		this.changePage = this.changePage.bind(this)
		this.nextPage = this.nextPage.bind(this)
		this.prevPage = this.prevPage.bind(this)
	}
	componentDidMount() {
		//Fetch Data
		let values = {}
		if (SEO_PATH_QUERIES[this.props.location.pathname]) {
			let method = SEO_PATH_QUERIES[this.props.location.pathname].method
			if (method && method != -1) {
				values.method = method
			} 
			let catId = SEO_PATH_QUERIES[this.props.location.pathname].catId
			if (catId && catId != -1) {
				values.catId = catId
			}
			let cityId = SEO_PATH_QUERIES[this.props.location.pathname].cityId
			if (cityId && cityId != -1) {
				values.cityId = cityId
			}
			let districtId = SEO_PATH_QUERIES[this.props.location.pathname].districtId
			if (districtId && districtId != -1) {
				values.districtId = districtId
			}
		} else {
			if (this.props.location.pathname.startsWith("/mua-ban")) {
				values.method = 1
			} else {
				values.method = 2
			}
			
		}
		this.setState({isLoading: true})
		postServices.searchPosts(values)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})		
		/**
		{
			method: mua-ban,
			category: nha-dat,
			city: ho-chi-minh,
			district: quan-7

		}
		--> method: 1,
			categoryId:
			cityId:
			district: ID
			title: Mua ban nha dat tai quan 7, Ho Chi Minh,
			PagingInfo.
		**/
	}
	_generateTitle() {
		let title = null
		if (SEO_PATH_QUERIES[this.props.location.pathname]) {
			title = SEO_PATH_QUERIES[this.props.location.pathname].title
				
		}
		if (!title) {
			if (this.props.location.pathname.startsWith("/mua-ban")) {
				title = "Mua bán"
			} else {
				title = "Cho thuê"
			}
		}
		return title
	}
	_renderBreadcrumbs() {
		let crumbs = [] 
		if (SEO_PATH_QUERIES[this.props.location.pathname]){
			crumbs = SEO_PATH_QUERIES[this.props.location.pathname].breadcrumbs
		}
		if (!crumbs) {
			crumbs = []
		}
		return <SeoBreadcrumbs items={crumbs}/>
	}
	nextPage(event) {
		if (this.state.currentPage < this.state.totalPages) {
			this.changePage(this.state.currentPage+1)
		}
	}
	prevPage(event) {
		if (this.state.currentPage > 1) {
			this.changePage(this.state.currentPage-1)
		}
	}
	changePage(page) {
		let criteria = {}
		const values = queryString.parse(this.props.location.search)
		values.page = page
		if (!values.size) {
			values.size = this.state.pageSize
		}
		this.setState({isLoading: true})
		postServices.searchPosts(values)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data.result, total: response.data.total, pageSize: response.data.pageSize, 
					currentPage: response.data.page, totalPages: Math.ceil(response.data.total/response.data.pageSize), isLoading: false, isError: false })
			}else{
				this.setState({ items: [], total: 0, totalPages: 0, currentPage: 1, isLoading: false, isError: false })

			}
		})
		.catch(error => this.setState({items: [], total: 0, totalPages: 0, errors: error, isLoading: false, isError: true }))
		this.props.history.push({ search: "?" + new URLSearchParams(values).toString()})
      	window.scrollTo(0, 0)
	}
	renderPaging() {
		if (this.state.total > this.state.pageSize) {
			return <Pagination totalPages = {this.state.totalPages} currentPage={this.state.currentPage} margin={2}
			 goto={this.changePage} next={this.nextPage} prev={this.prevPage}/>
		}
		return null
	}
	render () {
		const {t} = this.props
		let title = this._generateTitle()
		const pagination = this.renderPaging()
		const ids = this.state.items.map(item => item.id)
		const src = this.props.location.pathname + this.props.location.search
		let breadcrumbs = this._renderBreadcrumbs()
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title>{title}</title>
					<meta property="og:title" content={title} />
					<meta property="og:description" content={title}/>
					<meta property="og:image" content="/images/logo.svg"/>
					<meta property="og:url" content="https://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content={title}/>
					<meta name="twitter:description" content={title}/>
					<meta name="twitter:image" content="/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				{breadcrumbs}
				<div className="container mb-2">
					
				</div>
				<div className="listings mb-5">
					<div className="container">
						<div className="row">
							<div className="col-lg-8">
								<div className="row mb-2">
									<div className="col">
										<h1 className="listings__title">{title}</h1>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<Loading css={{display: 'block', margin: '0 auto', borderColor: 'red'}} size={70} color={"#0e1d41"} loading={this.state.isLoading} />
									</div>
								</div>
								{ this.state.isLoading === false &&
									(<div className="row mb-4">
										<div className="col-12">
											<PostList items={this.state.items} ids={ids} src={src}/>
											<div className="center">
												{pagination}
											</div>
										</div>
									</div>)
								}
							</div>
							<div className="col-lg-4">
								<div className="row">
									<div className="col">
										<AsideNews title={t('latest_news')} api={NEWS_V1.GET_LASTEST_NEWS} />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<FeaturedPostsAside title={t('featured_posts')}/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<FeaturedProjectsAside title={t('top_projects')}/>
									</div>
								</div>
								
							</div>

						</div>
					</div>
				</div>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(SeoPosts)