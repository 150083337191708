import React from 'react'
import { translate } from 'react-i18next'
import FeaturedProjectsAsideListItem from './FeaturedProjectsAsideListItem'
const FeaturedProjectsAsideList = ({t, items}) => (
	<div className="container">
		<div className="row">
		{items.map(item => <FeaturedProjectsAsideListItem key={item.id} item={item}/>)}
		</div>
	</div>
)
export default translate("translations")(FeaturedProjectsAsideList)