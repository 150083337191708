import {ROOT_URL, CITY_V1} from '../configs/configs'
import format from 'string-format'

export default {
	fetchHottestMarkets (limit) {
		var query = ''
		if(limit && limit > 0)
		{
			query += 'limit=' + limit
		}
		return fetch(ROOT_URL + CITY_V1.GET_HOTTEST_MARKETS + '?' + query,{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              //"Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
       	}).then(response => {
			return response.json();
		}).catch(error => {
			return error
		});
	},
	fetchHottestMarketsStats (limit) {
		var query = ''
		if(limit && limit > 0)
		{
			query += 'limit=' + limit
		}
		return fetch(ROOT_URL + CITY_V1.GET_HOTTEST_MARKETS_STATISTICS + '?' + query,{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              //"Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
       	}).then(response => {
			return response.json();
		}).catch(error => {
			return error
		});
	},
	fetchNewPostsAndCountInCity(cityId) {

		return fetch(ROOT_URL + format(CITY_V1.GET_LATEST_POSTS_AND_COUNT,cityId),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              //"Content-Type": "application/x-www-form-urlencoded",
			  //'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
       	}).then(response => {
			return response.json();
		}).catch(error => {
			return error
		});
	},
	fectchLatestProjectsAndCountByCityId (cityId) {
		return fetch(ROOT_URL + format(CITY_V1.GET_LATEST_PROJECTS_AND_COUNT,cityId),{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	},
	fectchAllCitiesStatsPrices () {
		return fetch(ROOT_URL + CITY_V1.FETCH_ALL_CITY_STATS_PRICES,{
			method: 'GET',
			headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
			  // 'X-Authorization': 'APIKey HV1O5Y3+03NfDqAymIL5zw==wJw648DCOSo4Y5hefG0jYXNGAqSAZiCN+oxn9S5GRdY='
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	}
}