import React, {Component} from 'react'
import { translate, Trans } from "react-i18next"
import {Helmet} from "react-helmet"
import BreadcrumbsArea from '../../components/BreadcrumbsArea'
import NewsLetter from '../../components/NewsLetter'
import ExampleAdsList from '../../components/posts/ExampleAdsList'
import AdsBox from '../../components/ads/AdsBox'
import PostDetailsBox from '../../components/posts/PostDetailsBox'

class AddAdvertise extends Component {
	constructor(props) {
		super(props)
		

	}
	componentDidMount() {
		window.scrollTo(0,0)
	}
	renderHeader() {
		return(<Helmet>
			{/*facebook*/}
			<title> {"Đăng tin mua bán nhà đất miễn phí tại Việt Nam"} </title>
			<meta property="og:title" content="Đăng tin mua bán nhà đất miễn phí tại Việt Nam" />
			<meta property="og:description" content="Đăng tin mua bán nhà đất miễn phí tại Việt Nam"/>
			<meta property="og:image" content="http://toreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://toreal.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Đăng tin mua bán nhà đất miễn phí tại Việt Nam"/>
			<meta name="twitter:description" content="Đăng tin mua bán nhà đất miễn phí tại Việt Nam"/>
			<meta name="twitter:image" content="http://toreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>)
	}
	render () {
		const {t} = this.props
		const header = this.renderHeader()
		return (
			<React.Fragment>
				{header}
				<BreadcrumbsArea title={'add_new_advertise'} />
				<div className="container mt-3">
					<div className="row mt-2">
						<div className="col-lg-8 mb-4">
							<PostDetailsBox free={true}/>
						</div>
						<div className="col-lg-4">
							<div className="row mb-3">
								<div className="col-12">
									<div className="row border-bottom pb-2">
										<div className="col">
											<label className="news_aside__title">{t('ads_guideline')}</label>
										</div>
									</div>
									<div className="row">
										<div className="col">
										<ul className="list-inline text-justify">
											<li className="list-inline-item">
												{'- Thông tin có dấu (*) là bắt buộc'}.
											</li>
											<li className="list-inline-item">
												{'- Không gộp nhiều bất động sản trong một tin rao.'}
											</li>
											<li className="list-inline-item">
												{'- Không đăng lại tin đã đăng trên topreal.vn.'}
											</li>
											<li className="list-inline-item">
												{'Để quá trình đăng tin và duyệt nhanh hơn, xin lưu ý: gõ tiếng việt có dấu và không viết tắt...'}
											</li>
											<li className="list-inline-item">
												{'- Vui lòng điền đầy đủ thông tin liên lạc. Chúng tôi sẽ liên hệ với bạn nếu tin có vấn đề. Sau thời gian 2 ngày nếu không có phản hồi từ bạn thì tin sẽ bị xoá khỏi hệ thống.'}
											</li>
										</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-12">
									<div className="row border-bottom pb-2">
										<div className="col-12">
											<label className="news_aside__title">{t('example_advertisement')}</label>
										</div>
									</div>
								<div className="row">
									<div className="col">
										<div className="post_example_item text-justify">
											<h5>{'Bán đất mặt tiền dự án Thủ Đức House, đường Trần Não, Quận 2'}</h5>
											<div className="post_example_item_text">Cần bán đất dự án Thủ Đức House, Quận 2, mặt tiền đường 30m, dự án nằm ngay góc ngã 4 Trần Não và Lương Định Của, khu đô thị mới Thủ Thiêm 
											<br/>- Lô L 
											<br/>- M: diện tích 7,5x20m đường rộng 30m giá 83tr/m2. 
											<br/>- Lô H: diện tích 7,5x20m đối diện công viên giá 75tr/m2. 
											<br/>- Lô M: diện tích7,5x20m đường 12m giá 75tr/m2.</div>
											<div className="d-flex">{"Liên hệ:"}&nbsp;&nbsp;<span className="post_example_item_contact_text">Nguyễn Văn A</span>
											</div>
											<div className="d-flex">{"Số điện thoại:"}&nbsp;&nbsp;<span className="post_example_item_contact_text">0905911xxx</span>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<hr width="100%" />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="post_example_item text-justify">
											<h5>{'Bán đất nền huyện Bình Chánh sổ hồng riêng, giá chỉ 189tr'}</h5>
											<div className="post_example_item_text">Cần bán đất nền dự án tại huyện Bình Chánh sổ hồng riêng, bao GPXD, diện tích đa dạng, tiện kinh doanh, buôn bán, xây phòng trọ cho thuê. 
											<br/>- Diện tích 5x15, 5x16, 5x19, 5x20, 5x21, 5x26, 6x19, 6x20m.  
											<br/>- Đường nhựa 16-42m, nước máy, điện âm, cáp, internet.  
											<br/>- Ngay khu dân cư, đối diện BV Chợ Rẫy II, BV Đa Khoa Tân Tạo - Gần chợ, siêu thị Vĩnh Lộc, trường Cấp I, II, III.  
											<br/>- Ngay giao lộ Trần Văn Giàu, gần Cao tốc Trung Lương lộ giới 60m.</div>
											<div className="d-flex">{"Liên hệ:"}&nbsp;&nbsp;<span className="post_example_item_contact_text">Nguyễn Văn B</span>
											</div>
											<div className="d-flex">{"Số điện thoại:"}&nbsp;&nbsp;<span className="post_example_item_contact_text">0908722xxx</span>
											</div>
										</div>
									</div>
								</div>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col">
									<AdsBox item={{headline: "Liên hệ quảng cáo", subTitle: "0982437172"}}/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(AddAdvertise)