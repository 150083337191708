import * as types from '../actions/action-types'
const INITIAL_STATE = {
	categories: [],
	isLoading: false,
	hasError: false,
	errors: null
}
const newsCategories = (state=INITIAL_STATE, action) => {
	switch (action.type) {
		case types.FETCH_ALL_NEWS_CATEGORIES_REQUEST:
			return Object.assign({},state, { categories: [], hasError: false, errors: null, isLoading: true}); 
		case types.FETCH_ALL_NEWS_CATEGORIES_SUCCESS:
			return Object.assign({},state, { categories: action.payload, hasError: false, errors: null, isLoading: false}); 
		case types.FETCH_ALL_NEWS_CATEGORIES_ERROR:
			return Object.assign({},state, {categories: [], hasError: true,errors: action.payload, isLoading: false} ); 
    }
    return state
}
export default newsCategories