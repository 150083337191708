import React from 'react'
import { translate } from 'react-i18next'
///card border-0 col-12 mb-5
import moment from 'moment'

const NewsListItem = ({t, item, classes}) => (
	<div className={classes} >
		<img className="card-img-top" src={item.imageUrl?item.imageUrl:"/images/no_image.svg"} alt=""/>
		<div className="card-body p-0">
			<a href={'/news/details/' + item.seoPath + "/" + item.id} className="card-title news-card-body__headline text-justify">{item.title}</a>
			<time className="card-text">{item.publishedAt?moment(item.publishedAt).format('DD/MM/YYYY'):''}</time>
			<div className="card-text news-card-body__excerpt text-justify">
				{item.excerpt}
			</div>
		</div>
	</div>
)
export default translate("translations")(NewsListItem)