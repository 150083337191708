import React from 'react';
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import AboutBreadcrumbsSection from '../components/AboutBreadcrumbsSection'

const AboutUs = ({t}) => (
	<React.Fragment>
		<Helmet>
			{/*facebook*/}
			<title> {"Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"} </title>
			<meta property="og:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam" />
			<meta property="og:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
			<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://topreal.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
			<meta name="twitter:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
			<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>
		<AboutBreadcrumbsSection />
			<div className="container mt-4 mb-3">
				<div className="row">
					<div className="col-lg-6">
						<div className="estate_contact_form">
							<div className="contact_title">{t('say_hello')}</div>
							<div className="estate_contact_form_container">
								<form id="estate_contact_form" className="estate_contact_form" action="post">
									<input id="estate_contact_form_name" className="estate_input_field estate_contact_form_name" type="text" placeholder={t('name')}required="required" data-error="Name is required."></input>
									<input id="estate_contact_form_email" className="estate_input_field estate_contact_form_email" type="email" placeholder={t('email')} required="required" data-error="Valid email is required."></input>
									<input id="estate_contact_form_subject" className="estate_input_field estate_contact_form_subject" type="email" placeholder={t('about_subject')} required="required" data-error="Subject is required."></input>
									<textarea id="estate_contact_form_message" className="estate_text_field estate_contact_form_message" name="message" placeholder={t('about_message')} required="required" data-error="Please, write us a message."></textarea>
									<button id="estate_contact_send_btn" type="submit" className="estate_contact_send_btn trans_200" value="Submit">{t('send')}</button>
								</form>
							</div>
						</div>
					</div>
					<div className="col-lg-3 contact_col">
						<div className="contact_title">{t('contact_info')}</div>
						<ul className="contact_info_list estate_contact">
							<li className="contact_info_item d-flex flex-row">
								<div><div className="contact_info_icon"><img src="images/placeholder.svg" alt=""></img></div></div>
								<div className="contact_info_text">{t('topreal_address')}</div>
							</li>
							<li className="contact_info_item d-flex flex-row">
								<div><div className="contact_info_icon"><img src="images/phone-call.svg" alt=""></img></div></div>
								<div className="contact_info_text">+8490 866 77 96</div>
							</li>
							<li className="contact_info_item d-flex flex-row">
								<div><div className="contact_info_icon"><img src="images/message.svg" alt=""></img></div></div>
								<div className="contact_info_text"><a href="mailto:contact.topreal@gmail.com?Subject=Hello" target="_top">contact.topreal@gmail.com</a></div>
							</li>
							<li className="contact_info_item d-flex flex-row">
								<div><div className="contact_info_icon"><img src="images/planet-earth.svg" alt=""></img></div></div>
								<div className="contact_info_text"><a href="https://topreal.vn">http://topreal.vn</a></div>
							</li>
						</ul>
						<div className="estate_social">
							<ul className="estate_social_list">
								<li className="estate_social_item"><a href="https://www.facebook.com/OfficialTopReal" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
								<li className="estate_social_item"><a href="https://www.youtube.com/channel/UCxTsAKMjR1VRmu8fF0U-vnA"><i className="fab fa-youtube"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 contact_col">
						<div className="contact_title">{t('about_us')}</div>
						<div className="estate_about_text">
							<p>{t('the_top_real_is_a_real_estate_service_...')}</p>
							<p></p>
						</div>
					</div>
				</div>
			</div>
	</React.Fragment>
)

export default translate("translations")(AboutUs)