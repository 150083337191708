import React, { Component } from 'react'
import { translate, Trans } from "react-i18next"
import userServices from '../../services/users'
class UserProfile extends Component {
	constructor(props) {
		super(props)
		this.state = {
			profile: null,
			isLoading: false,
			isError: false,
			errors: null,
		}
	}
	componentDidMount() {
		this.setState({ profile: null, isLoading: true, isError: false })
		userServices.fetchUserProfile()
		.then(response => {
			if (response.status === "OK") {
				this.setState({ profile: response.data, isLoading: false, isError: false })
			} else {
				this.setState({ profile: null, errors: response.errors, isLoading: false, isError: true })
			}
		})
		.catch(error => {
			this.setState({ profile: null, errors: error, isLoading: false, isError: true })
		})
	}
	render (){
		const {t} = this.props
		if (!this.state.profile) {
			return (
					<></>
				)
		}
		return (
			<React.Fragment>
				<div className="row">
						<div className="col-md-6 form-group submit-property__group">
							<label for="property-title" className="submit-property__label">{t('last_name')}</label>
							<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('title')} required="true" value={this.state.profile.lastName} disabled/>
						</div>
						<div className="col-md-6 form-group submit-property__group">
							<label for="property-title" className="submit-property__label">{t('first_name')}</label>
							<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('title')} required="true" value={this.state.profile.firstName} disabled/>
						</div>
						<div className="col-md-6 form-group submit-property__group">
							<label for="property-title" className="submit-property__label">{t('email')}</label>
							<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('title')} required="true" value={this.state.profile.email} disabled/>
						</div>
						<div className="col-md-6 form-group submit-property__group">
							<label for="property-title" className="submit-property__label">{t('phone')}</label>
							<input type="text" className="form-control submit-property__field" id="property-title" placeholder={t('title')} required="true" value={this.state.profile.phoneNumber} disabled/>
						</div>
				</div>
				
			</React.Fragment>
		)
	}
}
export default translate("translations")(UserProfile)