import React, { Component } from 'react'
import { translate, Trans } from "react-i18next"
import InDevelopmentBox from '../InDevelopmentBox'

class Packages extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
		}
	}
	handleSaveChange(event) {
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<InDevelopmentBox />
				<div className="container">
					<div className="row">
						<div className="col">
							<ul className="manage-list manage-list--my-property">
								<li className="manage-list__header">
									<span className="manage-list__title"><i className="far fa-bookmark"></i>&nbsp;&nbsp;{t('package_listings')}</span>
									<span className="manage-list__title"><i className="far fa-calendar-alt"></i>&nbsp;&nbsp;{t('expiration_date')}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				
			</React.Fragment>
		)
	}
}
export default translate("translations")(Packages)