import React from 'react'
import { translate } from "react-i18next"
function Breadcrumbs ({t, items}){
	let crumbs = []
	for (let i = 0; i < items.length; i++) {
		if (items[i].active === true ) {
			crumbs.push(<li className="breadcrumb-item active" aria-current="page"><a href={items[i].to}>{items[i].name}</a></li>)
		} else {
			crumbs.push(<li className="breadcrumb-item" aria-current="page"><a>{items[i].name}</a></li>)
		}
	}
	return (
		<div className="container">
			<div className="row mt-2">
				<div className="col-12 p-0">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb bg-white">
							<li className="breadcrumb-item active" aria-current="page"><a href="/">{t('home')}</a></li>
							<li className="breadcrumb-item active" aria-current="page"><a href="/companies/listings">{t('companies')}</a></li>
							{crumbs}
						</ol>
					</nav>
				</div>
			</div>
		</div>
	)
}
export default translate("translations")(Breadcrumbs)