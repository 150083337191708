import React, {Component}from 'react'
import { translate } from 'react-i18next'
import FeaturedPostList from './FeaturedPostList'
import postsServices from '../../services/posts'

class FeaturedPosts extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			isLoading: false,
			isError: false,
			errors: null,
		}
	}
	componentDidMount() {
		
		this.setState({ isLoading: true, isError: false, items:[]})
		postsServices.fetchFeaturedPosts(6)
		.then(response => {
			if (response.status === 'OK')
			{
				this.setState({ items: response.data, isLoading: false, isError: false })
			}else{
				this.setState({ items: [], isLoading: false, isError: false })
			}
		})
		.catch(error => this.setState({ items: [], errors: error, isLoading: false, isError: true }))
	}
	render () {
		const {t} = this.props
		return (
			<div className="mb-2">
				<div className="container mb-3">
					<div className="row">
						<div className="col">
							<div className="section_title text-center">
								<h3>{t('featured_posts')}</h3>
								<a href="/listings" className="section_subtitle_a">{t('view_all')}</a>
							</div>
						</div>
					</div>
				</div>
				<FeaturedPostList items={this.state.items}/>
			</div>
		)
	} 
}
export default translate("translations")(FeaturedPosts);