import React, {Component} from 'react'
import { translate } from "react-i18next"
import SearchParamSelector from "../SearchParamSelector"
class PostSearchBoxListing extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		let t = this.props.t
		return (
			<React.Fragment>
				<div className="search_box">
					<div className="search_box_content">

						<div className="search_box_title text-center">
							<div className="search_box_title_inner">
								<div className="search_box_title_icon d-flex flex-column align-items-center justify-content-center"><img src="/images/search.png" alt=""></img></div>
								<span>{t('search_your_home')}</span>
							</div>
						</div>

						<form className="search_form" onSubmit={this.handleSubmit}>
							<div className="search_box_container">
								<ul className="dropdown_row clearfix">
									<li className="dropdown_item">
										<div className="dropdown_item_title">{t('methods')}</div>
											<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchMethod} items={this.props.methods} onChange={this.props.onChangeMethod}/>
									</li>
									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('property_type')}</div>
											<SearchParamSelector classes={"dropdown_item_select"} selected= {this.props.searchPropertyTypeId} items={this.props.propertyTypes} onChange={this.props.onChangePropertyType}/>
									</li>
									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('city')}</div>
										<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchCityId} items={[{ id: -1, name: 'Tất cả' }]} onChange={this.props.onChangeCity}/>

									</li>

									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('district')}</div>
										<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchDistrictId} items={this.props.districts} onChange={this.props.onChangeDistrict}/>
									</li>
									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('ward')}</div>
										<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchWardId} items={this.props.wards} onChange={this.props.onChangeWard}/>
									</li>
									
									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('bedrooms_no')}</div>
										<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchNoBedRooms}  items={this.props.noBedRooms} onChange={this.props.onChangeNoBedRooms}/>
									</li>
									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('bathrooms_no')}</div>
										<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchNoBathRooms} items={this.props.noBathRooms} onChange={this.props.onChangeNoBathRooms}/>
									</li>
									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('min_price')}</div>
										<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchPriceMinId} items={this.props.priceRanges} onChange={this.props.onChangePriceMin}/>
									</li>
									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('max_price')}</div>
										<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchPriceMaxId} items={this.props.priceRanges} onChange={this.props.onChangePriceMax}/>
									</li>
									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('min_sq ft')}</div>
										<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchAreaMinId} items={this.props.areaRanges} onChange={this.props.onChangeAreaMin}/>
									</li>
									<li className="dropdown_item even_dropdown_item_half">
										<div className="dropdown_item_title">{t('max_sq_ft')}</div>
										<SearchParamSelector classes={"dropdown_item_select"} selected={this.props.searchAreaMaxId}  items={this.props.areaRanges} onChange={this.props.onChangeAreaMax}/>
									</li>
								</ul>
							</div>

							<div className="search_features_container">

								<div className="search_button">
									<input value={t('search')} type="submit" className="search_submit_button"></input>
								</div>
							</div>
						</form>
					</div>	
				</div>
			</React.Fragment>
		)
	}
}
export default translate("translations") (PostSearchBoxListing);