import React from 'react'
import { translate } from "react-i18next"
function CityStatsItem ({t, item}){
	/*let crumbs = []
	for (let i = 0; i < items.length; i++) {
		if (items[i].active === true ) {
			crumbs.push(<li className="breadcrumb-item active" aria-current="page"><a href={items[i].to}>{items[i].name}</a></li>)
		} else {
			crumbs.push(<li className="breadcrumb-item" aria-current="page"><a>{items[i].name}</a></li>)
		}
	}*/
	let prevMonth = null
	if (item.avgPiceSalePrevMonth) {
		let diff = item.avgPiceSale - item.avgPiceSalePrevMonth
		if (diff < 0) {
			diff = (-diff*100/item.avgPiceSalePrevMonth).toFixed(2) + "%"
			prevMonth = <p className="listing__location"><i className="fas fa-arrow-down" style={{color: "red"}}></i>{diff + " " + t('last_month')}</p>
		} else {
			diff = (diff*100/item.avgPiceSalePrevMonth).toFixed(2) + "%"
			prevMonth = <p className="listing__location"><i className="fas fa-arrow-up" style={{color: "green"}}></i>{diff + " " + t('last_month')}</p>

		}
	}
	let prevYear = null
	if (item.avgPiceSalePrevYear) {
		let diff = item.avgPiceSale - item.avgPiceSalePrevYear
		if (diff < 0) {
			diff = (-diff*100/item.avgPiceSalePrevYear).toFixed(2) + "%"
			prevYear = <p className="listing__location"><i className="fas fa-arrow-down" style={{color: "red"}}></i>{diff + " " + t('last_year')}</p>
		} else {
			diff = (diff*100/item.avgPiceSalePrevYear).toFixed(2) + "%"
			prevYear = <p className="listing__location"><i className="fas fa-arrow-up" style={{color: "green"}}></i>{diff + " " + t('last_year')}</p>

		}
	}
	return (
		<li className="manage-list__item">
			<div className="manage-list__item-container">
				<div className="manage-list__item-img">
				<a>
					<img with="140px" height="100px" src={'https://images.unsplash.com/photo-1496782850948-d8e6bbe11a9f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'} alt={item.name} className="listing__img"/>
				</a>
			</div>
			<div className="manage-list__item-detail">
				<h3 className="listing__title"><a href="#">{item.name}</a></h3>
				<p className="listing__location"><i class="fab fa-adversal"></i>&nbsp;&nbsp;{item.totalAds}</p>
				<p className="listing__price"><i class="far fa-money-bill-alt"></i>&nbsp;&nbsp;{item.avgPiceSale.toFixed(2) + " triệu/M2"}</p>
				</div>
			</div>
			<div className="manage-list__action">
				{prevMonth}
				{prevYear}
			</div>
		</li>
	)
}
export default translate("translations")(CityStatsItem)