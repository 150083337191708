import React, {Component} from 'react'
import { translate } from "react-i18next"
import AdvertiserContactForm from './AdvertiserContactForm'
import AdvertiserInfo from './AdvertiserInfo'
class AdvertiserContactBox extends Component {
	constructor(props) {
		super(props)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleSubmit(event){
		event.preventDefault()
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<div className="container advertiser_panel pt-4 pb-5">
					<div className="row">
						<div className="col-12">
							<AdvertiserContactForm phone={this.props.phone} refRes={this.props.refRes}/>
						</div>
					</div>
				</div>
				<div className="container advertiser_panel mt-3 pb-2">
					<div className="row">
						<div className="col-12">
							<AdvertiserInfo phone={this.props.phone} name={this.props.name}/>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default translate("translations")(AdvertiserContactBox)