import React from 'react'
import { Switch as RouterSwitch, Route,Redirect } from 'react-router-dom'
import Home from './containers/Home'
import PostsListings from './containers/posts/Listings'
import PostDetails from './pages/posts/PostDetails'
import PostAddAdvertise from './pages/posts/AddAdvertise'
import PostEditAdvertise from './pages/posts/EditAdvertise'
import SeoPosts from './pages/posts/SeoPosts'

import FAQ from './pages/FAQ'
import Contact from './pages/Contact'
import Pricing from './pages/Pricing'
import NotFound from './pages/errors/NotFound'
import AboutUs from './pages/AboutUs'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import ComingSoon from './pages/ComingSoon'
import Projects from './pages/projects/Projects'
import ProjectDetails from './pages/projects/ProjectDetails'
import ProjectListings from './containers/projects/Listings'
import Companies from './containers/companies/Companies'
import CompanyDetails from './pages/companies/CompanyDetails'
import CompanyListings from './containers/companies/Listings'


import Laws from './pages/laws/Laws'
//import News from './pages/news/News'
import News from './containers/news/News'
import NewsDetails from './containers/news/NewsDetails' //import NewsDetails from './pages/news/NewsDetails'
//import NewsDetails from './pages/news/NewsDetails'
import Fundings from './pages/fundings/Fundings'
import FundingDetails from './pages/fundings/FundingDetails'

import FundingsListings from './pages/fundings/Listings'
import UserAccount from './pages/users/UserAccount'
import Cities from './pages/cities/Cities'
import CityDetails from './pages/cities/CityDetails'
import Help from './pages/Help'
import Memberships from './pages/memberships/Memberships'
import AgentPosts from './pages/agents/AgentPosts'

/**

listings --> all posts
listings/:seo? --> all posts
listings/:id -> details
listings/details/:seo/:id -> details
listings/cities/:city-name --> all posts
listings/districts/:distict-name --> all posts

http://localhost:3000/posts/edit-ads/1?access_token=fdafd-fdasfdasfdasf

projects
projects/:id ->details
projects/details/:seo/:id -> details
projects/cities/:city-name --> news Alls
projects/listings/:seo?query
projects/listings?query

news
news/:id ->details
news/details/:seo/:id -> details
news/categories --> news Alls
news/categories/:seo:/id --> News all ByCategories

agents
agents/:id -> details
agents/id/posts
agents/phone/:phone/posts -> all posts by phones

**/
const Main = ({props}) => (
	<RouterSwitch>
		<Route exact path="/" component={Home}/>
		<Route exact path="/home" component={Home}/>
		<Route exact path="/listings" component={PostsListings}/>
		<Route exact path="/listings/preview/:id" component={PostDetails}/>
		<Route exact path="/listings/:id" component={PostDetails}/>
		<Route exact path="/listings/details/:seo/:id" component={PostDetails}/>
		<Route exact path="/posts/add-ads" component={PostAddAdvertise}/>
		<Route exact path="/posts/edit-ads" component={PostEditAdvertise}/>
		<Route exact path="/projects" component={Projects}/>
		<Route exact path="/projects/listings" component={ProjectListings}/>
		<Route exact path="/projects/listings/:seo" component={ProjectListings}/>
		<Route exact path="/projects/:id" component={ProjectDetails}/>
		<Route exact path="/projects/:seo/:id" component={ProjectDetails}/>
		<Route exact path="/companies" component={Companies}/>
		<Route exact path="/companies/listings" component={CompanyListings}/>
		<Route exact path="/companies/:id" component={CompanyDetails}/>
		<Route exact path="/fundings" component={Fundings}/>
		<Route exact path="/fundings/:id" component={FundingDetails}/>
		<Route exact path="/fundings/listings" component={FundingsListings}/>
		<Route exact path="/laws" component={Laws}/>
		<Route exact path="/investment" component={FAQ}/>
		<Route exact path="/news" component={News}/>
		<Route exact path="/news/listings" component={News}/>
		<Route exact path="/news/:id" component={NewsDetails}/>
		<Route exact path="/news/details/:seo/:id" component={NewsDetails}/>
		<Route exact path="/news/categories" component={News}/>
		<Route exact path="/news/categories/:seo" component={News}/>
		<Route exact path="/cities" component={Cities}/>
		<Route exact path="/cities/:id" component={CityDetails}/>
		<Route exact path="/memberships" component={Memberships}/>
		<Route exact path="/agents/posts/contacts/:fp" component={AgentPosts}/>
		<Route exact path="/pricing" component={Pricing}/>
		<Route exact path="/faq" component={FAQ}/>
		<Route exact path="/helps" component={Help}/>
		<Route exact path="/helps" component={Help}/>
		<Route exact path="/contact" component={Contact}/>
		<Route exact path="/about" component={AboutUs}/>
		<Route exact path="/terms" component={Terms}/>
		<Route exact path="/privacy" component={Privacy}/>
		<Route exact path="/users/:path" component={UserAccount}/>
		<Route exact path="/coming-soon" component={ComingSoon}/>

		/*SEO
		method/category/city/district
		mua-ban/chung-cu/ho-chi-minh
		mua-ban/chung-cu/ho-noi
		cho-thue/chung-cu/ho-noi
		cho-thue/chung-cu/ho-noi
		*/
		<Route exact path="/mua-ban" component={SeoPosts}/>
		<Route exact path="/mua-ban/:cat" component={SeoPosts}/>
		<Route exact path="/mua-ban/:cat/:city" component={SeoPosts}/>
		<Route exact path="/mua-ban/:cat/:city/:district" component={SeoPosts}/>
		<Route exact path="/cho-thue" component={SeoPosts}/>
		<Route exact path="/cho-thue/:cat" component={SeoPosts}/>
		<Route exact path="/cho-thue/:cat/:city" component={SeoPosts}/>
		<Route exact path="/cho-thue/:cat/:city/:district" component={SeoPosts}/>

		<Route component={NotFound}/>
	</RouterSwitch>
)
export default Main;