import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Listings from '../../pages/companies/Listings'
import {changeCategory,changeSearchCity,changeSearchDistrict,changeSearchWard} from '../../actions/companies'
const mapStateToProps = state => ({
	cities: state.coreDataCities.cities? state.coreDataCities.cities: [],
	districts: state.companySearchParams.districts? state.companySearchParams.districts: [],
	wards: state.companySearchParams.wards? state.companySearchParams.wards: [],
	categories: state.companySearchParams.categories?  state.companySearchParams.categories: [],
	categoryId: state.companySearchParams.categoryId,
	cityId: state.companySearchParams.cityId,
	districtId: state.companySearchParams.districtId,
	wardId: state.companySearchParams.wardId,
})
const mapDispatchToProps = (dispatch, ownProps) => ({
	changeCategory: (id) => dispatch(changeCategory(id)),
	changeCity: (id) => dispatch(changeSearchCity(id)),
	changeDistrict: (id) => dispatch(changeSearchDistrict(id)),
	changeWard: (id) => dispatch(changeSearchWard(id)) 
})
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Listings))