import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import SearchBox from '../../components/fundings/SearchBox'
import NewPostListings from '../../components/posts/NewPostListings'
import TopFundings from '../../components/fundings/TopFundings'
import FundingsInCity from '../../components/fundings/FundingsInCity'
import Carousel from 'react-bootstrap/Carousel'
import NewsLetter from '../../components/NewsLetter'


import {NEW_POST_IN_CITY_LIST} from '../../configs/mocks'

// import ReactCountdownClock from 'react-countdown-clock'
class Fundings extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Tìm kiếm thông tin salon ô tô tại Việt Nam"} </title>
					<meta property="og:title" content="Tìm kiếm thông tin salon tại Việt Nam" />
					<meta property="og:description" content="Tìm kiếm thông tin salon tại Việt Nam"/>
					<meta property="og:image" content="http://topauto.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topauto.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Tìm kiếm thông tin salon tại Việt Nam"/>
					<meta name="twitter:description" content="Tìm kiếm thông tin salon tại Việt Nam"/>
					<meta name="twitter:image" content="http://topauto.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<div className="container">
					<div className="row m-3">
						<div className="col-md-4">
							<h3>{t('find_salons')}</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-4 col-12">
							<SearchBox />
						</div>
						<div className="col-sm-8 col-12">
							<div className="salon-banner h-350">
								<Carousel>
								  <Carousel.Item>
								    <img
								      className="d-block w-100 h-450"
								      src="/images/listing_video.jpg"
								      alt="First slide"
								    />
								    <Carousel.Caption>
								      <h3>First slide label</h3>
								      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
								    </Carousel.Caption>
								  </Carousel.Item>
								  <Carousel.Item>
								    <img
								      className="d-block w-100"
								      src="/images/home_background.jpg"
								      alt="Third slide"
								    />

								    <Carousel.Caption>
								      <h3>Second slide label</h3>
								      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
								    </Carousel.Caption>
								  </Carousel.Item>
								  <Carousel.Item>
								    <img
								      className="d-block w-100"
								      src="/images/home_slider_bcg.jpg"
								      alt="Third slide"
								    />
								    <Carousel.Caption>
								      <h3>Third slide label</h3>
								      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
								    </Carousel.Caption>
								  </Carousel.Item>
								</Carousel>
							</div>
						</div>
					</div>
				</div>
				<TopFundings items={NEW_POST_IN_CITY_LIST}/>
				<FundingsInCity cityName={"Hồ Chí Minh"} items={NEW_POST_IN_CITY_LIST}/>
				<FundingsInCity cityName={"Hà Nội"} items={NEW_POST_IN_CITY_LIST}/>
				<NewPostListings cityName={"Hồ Chí Minh"} items={NEW_POST_IN_CITY_LIST}/>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Fundings)
