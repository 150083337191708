import React, { Component } from 'react'
import { translate, Trans } from 'react-i18next'
import FieldDropdownItem from '../searches/FieldDropdownItem'
import FieldInputCheck from '../searches/FieldInputCheck'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
class SearchBox extends Component {
	constructor(props) {
		super(props)
		this.state = { showMoreCriteria: false }
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleMoreSearchCriteria = this.handleMoreSearchCriteria.bind(this)
		this.handleClose = this.handleClose.bind(this)
		this.handleOnChangeMethod = this.handleOnChangeMethod.bind(this)
		this.handleReset = this.handleReset.bind(this)
		this.handleMoreSearchCriteriaSubmit = this.handleMoreSearchCriteriaSubmit.bind(this)

	}
	componentDidMount() {
		//this.fetchData()
		//this.props.onChangeMethod(this.props.method)
	}
	handleSubmit(event){
		let criteria = {}
		if (this.props.method) {
			criteria.method = this.props.method
		}
		if (this.props.propertyTypeId && this.props.propertyTypeId != -1) {
			criteria.catId = this.props.propertyTypeId
		}
		if (this.props.cityId !== -1) {
			criteria.cityId = this.props.cityId
		}
		if (this.props.priceMinId && this.props.priceMinId != -1) {
			criteria.pMin = this.props.priceMinId
		}
		if (this.props.noBedRoomId && this.props.noBedRoomId != -1) {
			criteria.noBeds = this.props.noBedRoomId
		}
		if (this.props.query) {
			criteria.q = this.props.query
		}
		this.props.history.push({ pathname: '/listings',
		search: "?" + new URLSearchParams(criteria).toString()})
		event.preventDefault()
	}
	handleMoreSearchCriteriaSubmit(event) {
		this.setState({showMoreCriteria: false})
		let criteria = {}
		if (this.props.method) {
			criteria.method = this.props.method
		}
		if (this.props.propertyTypeId && this.props.propertyTypeId != -1) {
			criteria.catId = this.props.propertyTypeId
		}
		if (this.props.cityId !== -1) {
			criteria.cityId = this.props.cityId
		}
		if (this.props.districtId !== -1) {
			criteria.districtId = this.props.districtId
		}
		if (this.props.wardId !== -1) {
			criteria.wardId = this.props.wardId
		}
		if (this.props.priceMinId && this.props.priceMinId != -1) {
			criteria.pMin = this.props.priceMinId
		}
		if (this.props.priceMaxId && this.props.priceMaxId != -1) {
			criteria.pMax = this.props.priceMinId
		}
		if (this.props.areaMinId && this.props.areaMinId != -1) {
			criteria.aMin = this.props.areaMinId
		}
		if (this.props.areaMaxId && this.props.areaMaxId != -1) {
			criteria.aMax = this.props.areaMaxId
		}
		if (this.props.noBedRoomId && this.props.noBedRoomId != -1) {
			criteria.noBeds = this.props.noBedRoomId
		}
		if (this.props.noBathRoomId && this.props.noBathRoomId != -1) {
			criteria.noBaths = this.props.noBathRoomId
		}
		if (this.props.sortId && this.props.sortId != -1) {
			criteria.sort = this.props.sortId
		}
		if (this.props.ageId && this.props.ageId != -1) {
			criteria.age = this.props.ageId
		}
		if (this.props.query) {
			criteria.q = this.props.query
		}
		this.props.history.push({ pathname: '/listings',
		search: "?" + new URLSearchParams(criteria).toString()})
		event.preventDefault()
	}
	handleOnChangeMethod(event) {
		this.props.onChangeMethod(event.target.value)
	}
	handleMoreSearchCriteria(event) {
		if (this.showMoreCriteria) {
			this.setState({showMoreCriteria: false})
		} else {
			this.setState({showMoreCriteria: true})
		}
	}
	handleClose () {
		this.setState({showMoreCriteria: false})
	}
	handleReset(event) {
		this.props.clearSearchPostParams(this.props.method)
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<div className="container search-box">
					<div className="row pt-3">
						<div className="form-group d-flex flex-row">
							<FieldInputCheck title={t('buy')} name={"method1"} value={1} onChange={this.handleOnChangeMethod} checked={this.props.method===1}/>
							<FieldInputCheck title={t('rent')} name={"method1"} value={2} onChange={this.handleOnChangeMethod} checked={this.props.method===2}/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3 order-1">
							<FieldDropdownItem title={t('property_type')} selected= {this.props.propertyTypeId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.propertyTypes]} onChange={this.props.onChangePropertyType}/>
						</div>
						<div className="col-md-2 order-2">
							<FieldDropdownItem title={t('city')} selected= {this.props.cityId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.cities]} onChange={this.props.onChangeCity}/>
						</div>
						<div className="col-md-2 col-6 order-2">
							<FieldDropdownItem title={t('price_from')} selected={this.props.priceMinId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.priceRanges]} onChange={this.props.onChangePriceMin}/>
						</div>
						<div className="col-md-2 col-6 order-4">
							<FieldDropdownItem title={t('rooms_no')} selected={this.props.noBedRoomId} items={[{id:-1, name: 'Tất cả'},...this.props.noBedRooms]} onChange={this.props.onChangeNoBedRooms}/>
						</div>
						<div className="col-md-3 col-12 order-md-5 order-12">
							<div className="form-group">
								<button type="button" className="btn btn-danger btn-block" onClick={this.handleSubmit}>{t('search')}</button>
								<label onClick={this.handleMoreSearchCriteria} className="search-from__more_title"><i className="fas fa-plus"></i>&nbsp;
{t('more_search_criteria')}</label>
							</div>
						</div>
						{/*
						<div className="col-md-4 col-12 order-6">
							<div className="form-group">
								<input className="form-control search-from__input-text" type="text" value={this.props.query} placeholder= {t('search_properties_or_keywords')} onChange={(e) => this.props.changeSearchPostQuery(e.target.value)}/>
							</div>
						</div>
						*/}
						{/*<div className="col-md-4 col-12 order-7">
							<div className="form-group">
								<a onClick={this.handleMoreSearchCriteria} className="btn btn-block text-left d-flex flex-row" role="button"><img className="mr-1" src={"/images/filter.svg"}/>{t('more_search_criteria')}</a>
							</div>
						</div>*/}
					</div>
				</div>
				<div className="container">
					<Modal size={"lg"} scrollable="true" show={this.state.showMoreCriteria} onHide={this.handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>{t('filter')}</Modal.Title>
							<Button variant="link" onClick={this.handleReset}>{t('reset')}</Button>
						</Modal.Header>
						<Modal.Body className="p-0">
							<div className="container search-box">
								<div className="row pt-3">
									<div className="col">
									<div className="form-group d-flex flex-row">
										<FieldInputCheck title={t('buy')} name={"method2"} value={1} onChange={this.handleOnChangeMethod} checked={this.props.method===1}/>
										<FieldInputCheck title={t('rent')} name={"method2"} value={2} onChange={this.handleOnChangeMethod} checked={this.props.method===2}/>
									</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<FieldDropdownItem title={t('property_type')} selected= {this.props.propertyTypeId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.propertyTypes]} onChange={this.props.onChangePropertyType}/>
									</div>
									<div className="col-md-3">
										<FieldDropdownItem title={t('city')} selected= {this.props.cityId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.cities]} onChange={this.props.onChangeCity}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('district')} selected= {this.props.districtId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.districts]} onChange={this.props.onChangeDistrict}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('ward')} selected= {this.props.wardId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.wards]} onChange={this.props.onChangeWard}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('price_from')} selected= {this.props.priceMinId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.priceRanges]} onChange={this.props.onChangePriceMin}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('price_to')} selected= {this.props.priceMaxId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.priceRanges]} onChange={this.props.onChangePriceMax}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('area_from')} selected= {this.props.areaMinId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.areaRanges]} onChange={this.props.onChangeAreaMin}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('area_to')} selected= {this.props.areaMaxId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.areaRanges]} onChange={this.props.onChangeAreaMax}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('rooms_no')} selected={this.props.noBedRoomId} items={[{id:-1, name: 'Tất cả'},...this.props.noBedRooms]} onChange={this.props.onChangeNoBedRooms}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('toliets_no')} selected={this.props.noBathRoomId} items={[{id:-1, name: 'Tất cả'},...this.props.noBathRooms]} onChange={this.props.onChangeNoBathRooms}/>
									</div>
									<div className="col-md-3">
										<FieldDropdownItem title={t('published_since')} selected={this.props.ageId} items={[{id:-1, name: 'Tất cả'}, ... this.props.ageRanges]} onChange={this.props.changeSearchAgePost}/>
									</div>
									<div className="col-md-3">
										<FieldDropdownItem title={t('sort')} selected={this.props.sortId} items={this.props.sorts} onChange={this.props.changeSearchSort}/>
									</div>
								</div>
							</div>
						</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={this.handleMoreSearchCriteriaSubmit}>{t('search')}</Button>
							</Modal.Footer>
					</Modal>
				</div>
			</React.Fragment>
		)
	}
}

export default translate("translations")(SearchBox)
