import {ROOT_URL, AGENT_V1} from '../configs/configs'
import format from 'string-format'

export default {
	send(reqData) {
		return fetch(ROOT_URL + AGENT_V1.CREATE_CONTACT_MESSAGE, {
    		method: 'POST',
    		headers: {
               "Content-Type": "application/json; charset=utf-8"
			},
    		body: JSON.stringify(reqData),      
  		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	}
}