import React, { Component } from 'react'
import { translate, Trans } from "react-i18next"
import PostDetailsBox from '../posts/PostDetailsBox'

class UserAddPost extends Component {
	constructor(props) {
		super(props)
	}
	
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<PostDetailsBox free={true} />
			</React.Fragment>
		)
	}
}
export default translate("translations")(UserAddPost)