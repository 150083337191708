import React, {Component} from 'react'
import { Form, Button } from 'react-bootstrap';
import Section from '../components/Section'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"

class Contact extends Component {
	constructor(props) {
		super(props)
        this.state = {
            fullName: null,
            email: null,
            content: null
        }
	}
	render () {
		const {t} = this.props
        return (
			<React.Fragment>
            <Helmet>
                    {/*facebook*/}
                    <title> {"TopReal | Liên lạc với TopReal"} </title>
                    <meta property="og:title" content="Liên lạc với TopReal" />
                    <meta property="og:description" content="Liên lạc với TopReal"/>
                    <meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
                    <meta property="og:url" content="http://topreal.vn"/>
                    {/*Twtiter*/}
                    <meta name="twitter:title" content="Liên lạc với TopReal"/>
                    <meta name="twitter:description" content="Liên lạc với TopReal"/>
                    <meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                </Helmet>
			<main role="main">
				<Section title={t('msg_contact_support')} subTitle={t('msg_contact_support_guarantee')}/>
				<section className="section-contact" id="section-six">
            	<div className="container">
                	 <header role="title-page" className="text-center">
                        <h4>{t('get_in_touch')}</h4>
                        <h2>{t('get_in_touch_questions')}<br/>{t('get_in_touch_answer')}</h2>
                    </header>
                    <div className="contact-form">
                        <div id="message"></div>
                            <form name="cform" id="cform">
                            	<div className="container">
                            		<div className="row">
                            			<div className="col-md-6 col-lg-6 col-sm-12">

                                	<input name="name" type="text" placeholder={t('fullname') + ' (*)'}/>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12">
                                	<input name="email" id="email" type="email" placeholder={t('email') + ' (*)'}/>
                                </div>
                            		</div>
                                </div>
                                <div className="clearfix"></div>

                                <textarea name="comments" placeholder={ t('content') + ' (*)' }></textarea>

                                <div className="clearfix"></div>
                                <div id="simple-msg">{'(*) ' + t('required_field') }</div>
                                <input name="" type="button" value={t('send_email')}/>

                                <div id="simple-msg"></div>

                        	</form>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="map-wrapper">
                	<div id="surabaya"></div>
                </div>

            </section>
			</main>
				
			</React.Fragment>
		)
	}
}
export default translate("translations")(Contact)
/*
<Form>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" />
						<Form.Text className="text-muted">We'll never share your email with anyone else.
						</Form.Text>
					</Form.Group>
					<Form.Group controlId="formBasicPassword">
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" placeholder="Password" />
						</Form.Group>
					<Form.Group controlId="formBasicChecbox">
						<Form.Check type="checkbox" label="Check me out" />
					</Form.Group>
					<Button variant="primary" type="submit">
					Submit
					</Button>
				</Form>
*/