import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/AppContainer';
import {BrowserRouter as Router} from 'react-router-dom'
import i18n from "./i18n/index";
import { I18nextProvider } from "react-i18next";
import store from './stores'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
// optional cofiguration
const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 3000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}


ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<AlertProvider template={AlertTemplate} {...options}>
			<Provider store={store}>
				<Router>
					<App />
				</Router>
			</Provider>
		</AlertProvider>
	</I18nextProvider>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
