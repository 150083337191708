import {ROOT_URL, USERS_V1} from '../configs/configs'
import authServices from './auth'
export default {
	fetchUserProfile() {
		return fetch(ROOT_URL + USERS_V1.GET_ME, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				"X-Authorization": authServices.headerAuthToken()
			}
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
		return true
	}
}