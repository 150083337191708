import React, {Component} from 'react'
import HomeSearchBoxSection from '../components/HomeSearchBoxSection'
import NewsLetter from '../components/NewsLetter'
// import ReactCountdownClock from 'react-countdown-clock'
import SearchBox from '../containers/posts/SearchBox'
import { translate, Trans } from "react-i18next"
import FeaturedPosts from '../components/posts/FeaturedPosts'
import NewPostListings from '../components/posts/NewPostListings'
import ClientsPrefer from '../components/cities/ClientsPrefer'
import {Helmet} from "react-helmet"
import LatestNews from '../components/news/LatestNews'
import queryString from 'query-string'

// import ReactCountdownClock from 'react-countdown-clock'
class Home extends Component {
	constructor(props) {
		super(props)
	}
	componentDidMount() {
		window.scrollTo(0, 0)
		if (this.props.method === -1) {
			this.props.getSeachMethod(1) //Defautl SALE
		} else {
			this.props.getSeachMethod(this.props.method)
		}
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"} </title>
					<meta name="description" content="Bất động sản trong kỷ nguyên chuyển đổi số với công nghê nổi trội như AI, Bigdata, Blockchain. Chúng tôi tự tin mang đến cho bạn kênh thông tin mua bán, cho thuê bất động sản tin cậy và uy tín tại Việt Nam" />
					<meta name="keywords" content="topreal, topreal vn,bất động sản, bat dong san, địa ốc, dia oc, mua bán bất động sản, cho thuê bất động sản, mua ban cho thue bat dong san, bất động sản Tp Hồ Chí Minh, bất động sản Hà Nội, địa ốc Alibaba, bạch ốc alibaba" />
					<meta name="news_keywords" content="topreal, topreal vn, bất động sản, bat dong san, địa ốc, dia oc, mua bán bất động sản, cho thuê bất động sản, mua ban cho thue bat dong san" />
					<meta name="author" content="TopReal" />
					<meta name="copyright" content="TopReal" />
					<meta name="geo.placename" content="Quan 7, Ho Chi Minh, Viet Nam"/>
					<meta name="geo.region" content="VN-HCM"/>
					<meta name="google-site-verification" content="meFrGc5HrbFsGNphKzgM1wtATUz7SJVlYroRJwDQZwM" />
					<link rel="canonical" href="http://topreal.vn"/>
					<meta property="og:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam" />
					<meta property="og:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
					<meta name="twitter:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<main role="main">
					<div className="container">
						<div className="row m-3 p-0">
							<h3>{t('find_houses')}</h3>
						</div>
						<div className="row m-3">
							<div className="col-12 p-0">
								<SearchBox />
							</div>
						</div>
					</div>
					<FeaturedPosts/>
					<ClientsPrefer/>
					<LatestNews />
					<NewPostListings cityName={"Hồ Chí Minh"} cityId={51} />
					<NewPostListings cityName={"Hà Nội"} cityId={2} />
					<NewsLetter />
				</main>
			</React.Fragment>
		)
	}
}
export default translate("translations")(Home);