import React, { Component } from 'react'
import { translate, Trans } from "react-i18next";
import FieldDropdownItem from '../searches/FieldDropdownItem'
import FieldInputCheck from '../searches/FieldInputCheck'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import withSizes from 'react-sizes'
import compose from 'recompose/compose'
const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480
})
class AsideSearchBox extends Component {
	constructor(props) {
		super(props)
		this.state = { 
			showMoreCriteria: false,
			expanded: false
		}
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleMoreSearchCriteria = this.handleMoreSearchCriteria.bind(this)
		this.handleClose = this.handleClose.bind(this)
		this.handleOnChangeMethod = this.handleOnChangeMethod.bind(this)
		this.handleSearchSumit = this.handleSearchSumit.bind(this)
		this.handleMoreSearchSumit = this.handleMoreSearchSumit.bind(this)
		this.handleReset = this.handleReset.bind(this)
	}
	componentDidMount() {
		//this.fetchData()
		//this.props.onChangeMethod(this.props.method)
	}
	handleSubmit(event){
		event.preventDefault()
	}
	handleMoreSearchCriteria(event) {
		if (this.showMoreCriteria) {
			this.setState({showMoreCriteria: false})
		} else {
			this.setState({showMoreCriteria: true})
		}
		this.setState({expanded: !this.state.expanded})
	}
	handleClose () {
		this.setState({showMoreCriteria: false})
	}
	handleOnChangeMethod(event) {
		this.props.onChangeMethod(event.target.value)
	}
	handleSearchSumit(event) {
		this.props.searchSubmit()
		this.setState({expanded: !this.state.expanded})
		this.setState({showMoreCriteria: false})
	}
	handleMoreSearchSumit(event) {
		this.props.searchMoreSubmit()
		this.setState({expanded: !this.state.expanded})
		this.setState({showMoreCriteria: false})
	}
	handleReset(event) {
		this.props.clearSearchPostParams(this.props.method)
	}
	_renderExpandFilter () {
		let {t} = this.props
		let tmp = <React.Fragment>
			<div className="row pt-3">
						<div className="form-group d-flex flex-row">
							<FieldInputCheck title={t('buy')} name={"method1"} value={1} onChange={this.handleOnChangeMethod} checked={this.props.method===1}/>
							<FieldInputCheck title={t('rent')} name={"method1"} value={2} onChange={this.handleOnChangeMethod} checked={this.props.method===2}/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<FieldDropdownItem title={t('property_type')} selected= {this.props.propertyTypeId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.propertyTypes]} onChange={this.props.onChangePropertyType}/>
						</div>
						<div className="col-12">
							<FieldDropdownItem title={t('city')} selected= {this.props.cityId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.cities]} onChange={this.props.onChangeCity}/>
						</div>
						<div className="col-12">
							<FieldDropdownItem title={t('price_from')} selected={this.props.priceMinId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.priceRanges]} onChange={this.props.onChangePriceMin}/>
						</div>
						<div className="col-12">
							<FieldDropdownItem title={t('rooms_no')} selected={this.props.noBedRoomId} items={[{id:-1, name: 'Tất cả'},...this.props.noBedRooms]} onChange={this.props.onChangeNoBedRooms}/>
						</div>
						<div className="col-12">
							<div className="form-group">
							<input className="form-control search-from__input-text" type="text" placeholder= {t('search_properties_or_keywords')} value={this.props.query} onChange={(e) => this.props.changeSearchPostQuery(e.target.value)}/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<a onClick={this.handleMoreSearchCriteria} className="btn btn-block text-left d-flex flex-row" role="button"><img className="mr-1" src={"/images/filter.svg"}/>{t('more_search_criteria')}</a>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<button type="button" className="btn btn-danger btn-lg btn-block" onClick={this.handleSearchSumit}>{t('search')}</button>
							</div>
						</div>
					</div>
			</React.Fragment>
		if (this.props.isMobile === true) {
			if (this.state.expanded === false) {
				tmp = null
			}
		}
		return tmp
	}
	handleShowFilter = (e) => {
		this.setState({expanded: !this.state.expanded})
	}
	_renderShowFilterButton() {
		let {t} = this.props
		if (this.props.isMobile === false) {
			return null
		}
		if (this.state.expanded === true) {
			return null
		}
		return (
			<div className="row pt-2 pb-2">
				<div className="col-12">
					<button type="button" className="btn btn-danger btn-block" onClick={this.handleShowFilter}>{t('show_filter')}</button>
				</div>
			</div>
		)
	}
	render (){
		const {t} = this.props
		const showFilterBtn = this._renderShowFilterButton()
		const expandedFilter = this._renderExpandFilter()
		return (
			<React.Fragment>
				<div className="container search-box">
					{expandedFilter}
				</div>
				{showFilterBtn}
				<div className="container">
					<Modal size={"lg"} scrollable="true" show={this.state.showMoreCriteria} onHide={this.handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>{t('filter')}</Modal.Title>
							<Button variant="link" onClick={this.handleReset}>{t('reset')}</Button>
						</Modal.Header>
						<Modal.Body className="p-0">
							<div className="container search-box">
								<div className="row pt-3">
									<div className="col">
									<div className="form-group d-flex flex-row">
										<FieldInputCheck title={t('buy')} name={"method2"} value={1} onChange={this.handleOnChangeMethod} checked={this.props.method===1}/>
										<FieldInputCheck title={t('rent')} name={"method2"} value={2} onChange={this.handleOnChangeMethod} checked={this.props.method===2}/>
									</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<FieldDropdownItem title={t('property_type')} selected= {this.props.propertyTypeId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.propertyTypes]} onChange={this.props.onChangePropertyType}/>
									</div>
									<div className="col-md-3">
										<FieldDropdownItem title={t('city')} selected= {this.props.cityId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.cities]} onChange={this.props.onChangeCity}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('district')} selected= {this.props.districtId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.districts]} onChange={this.props.onChangeDistrict}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('ward')} selected= {this.props.wardId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.wards]} onChange={this.props.onChangeWard}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('price_from')} selected= {this.props.priceMinId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.priceRanges]} onChange={this.props.onChangePriceMin}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('price_to')} selected= {this.props.priceMaxId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.priceRanges]} onChange={this.props.onChangePriceMax}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('area_from')} selected= {this.props.areaMinId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.areaRanges]} onChange={this.props.onChangeAreaMin}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('area_to')} selected= {this.props.areaMaxId} items={[{ id: -1, name: 'Tất cả' }, ...this.props.areaRanges]} onChange={this.props.onChangeAreaMax}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('rooms_no')} selected={this.props.noBedRoomId} items={[{id:-1, name: 'Tất cả'},...this.props.noBedRooms]} onChange={this.props.onChangeNoBedRooms}/>
									</div>
									<div className="col-md-3 col-6">
										<FieldDropdownItem title={t('toliets_no')} selected={this.props.noBathRoomId} items={[{id:-1, name: 'Tất cả'},...this.props.noBathRooms]} onChange={this.props.onChangeNoBathRooms}/>
									</div>
									<div className="col-md-3">
										<FieldDropdownItem title={t('published_since')} selected={this.props.ageId} items={[{id:-1, name: 'Tất cả'}, ... this.props.ageRanges]} onChange={this.props.changeSearchAgePost}/>
									</div>
									<div className="col-md-3">
										<FieldDropdownItem title={t('sort')} selected={this.props.sortId} items={this.props.sorts} onChange={this.props.changeSearchSort}/>
									</div>
								</div>
							</div>
						</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={this.handleMoreSearchSumit}>{t('search')}</Button>
							</Modal.Footer>
					</Modal>
				</div>
			</React.Fragment>
		)
	}
}
export default compose(translate("translations"),withSizes(mapSizesToProps))(AsideSearchBox)
