import React from 'react'
import { translate } from 'react-i18next'
import UserPostListItem from './UserPostListItem'
const UserPostList = ({t, items, actionRemove, actionEdit}) => (
	<div className="container">
		<div className="row">
		{items.map((item,idx) => <UserPostListItem key={idx} item={item} actionRemove={actionRemove} actionEdit={actionEdit}/>)}
		</div>
	</div>
)
export default translate("translations")(UserPostList)