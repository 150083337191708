import React, { useState } from 'react'
import { translate } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
function QuestionAnswerListItem({t, item}) {
  const [open, setOpen] = useState(false)

  return (
    <div className="col-12">
      <Button variant="link" className="pl-0 pr-0"
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open} block>
        <div className="float-left">{item.question}</div>
      </Button>
      <Collapse in={open}>
        <div id="example-collapse-text" className="text-justify">
          {item.answer}
        </div>
      </Collapse>
    </div>
  );
}
export default translate("translations")(QuestionAnswerListItem)
