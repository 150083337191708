import React, {Component} from 'react'
import { translate } from "react-i18next"
import {isEmpty,isEmail,isPhone} from '../../helpers/string-utils'
import agenServices from '../../services/agents'
import { withAlert } from 'react-alert'
import compose from 'recompose/compose'
import {positions} from 'react-alert'
const MAX_LEN_NAME = 100
const MAX_LEN_CONTENT = 255
const ERROR_CODE_PHONE_NOT_EMPTY = 111
const ERROR_CODE_PHONE_INCORRECT = 112
const ERROR_CODE_EMAIL_NOT_EMPTY = 114
const ERROR_CODE_EMAIL_INCORRECT = 115
const ERROR_CODE_NAME_TOO_LONG = 117
const ERROR_CODE_NAME_NOT_EMPTY = 118
const ERROR_CODE_CONTENT_NOT_EMPTY = 128
const ERROR_CODE_CONTENT_TOO_LONG = 129
const ERROR_CODE_RECEIVER_NOT_EMPTY = 130
class AvertiserContactForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			name: "",
			email: "",
			phone: "",
			content: "",
			receiver: "",
			errors: {
				name: "",
				email: "",
				phone: "",
				content: ""
			}
		}
		this.handleSend = this.handleSend.bind(this)
	}
	_resetError () {
		let {errors} = this.state
		errors.name = ""
		errors.email = ""
		errors.phone = ""
		errors.content = ""
		this.setState({errors})
	}
	_validateForm() {
		let {errors} = this.state
		let hasError = false
		if (isEmpty(this.state.name) === false) {
			if (this.state.name.length > MAX_LEN_NAME) {
				errors.name = this.props.t('too_long_max_len') + MAX_LEN_NAME
				hasError = true
			}
		} else {
			errors.name = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(this.state.content) === false) {
			if (this.state.content.length > MAX_LEN_CONTENT) {
				errors.content = this.props.t('too_long_max_len') + MAX_LEN_CONTENT
				hasError = true
			}
		} else {
			errors.content = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(this.state.email) === false) {
			if (!isEmail(this.state.email)) {
				hasError = true
				errors.email = this.props.t('email_incorrect')
			}
		} else {
			errors.email = this.props.t('required_field')
			hasError = true
		}
		if (isEmpty(this.state.phone) === false) {
			if (!isPhone(this.state.phone)) {
				hasError = true
				errors.phone = this.props.t('phone_incorrect')
			}
		} else {
			errors.phone = this.props.t('required_field')
			hasError = true
		}
		this.setState({errors})
		return hasError
	}
	_processErrors(resErrors) {
		let errors = this.state.errors
		if (resErrors[ERROR_CODE_CONTENT_NOT_EMPTY]) {
			errors.content = this.props.t('required_field')
		}
		if (resErrors[ERROR_CODE_CONTENT_TOO_LONG]) {
			errors.content = this.props.t('too_long_max_len') + MAX_LEN_CONTENT
		}
		if (resErrors[ERROR_CODE_NAME_NOT_EMPTY]) {
			errors.name = this.props.t('required_field')
		}
		if (resErrors[ERROR_CODE_NAME_TOO_LONG]) {
			errors.name = this.props.t('too_long_max_len') + MAX_LEN_NAME
		}
		if (resErrors[ERROR_CODE_EMAIL_NOT_EMPTY]) {
			errors.email = this.props.t('required_field')
		}
		if (resErrors[ERROR_CODE_EMAIL_INCORRECT]) {
			errors.email = this.props.t('email_incorrect')
		}
		if (resErrors[ERROR_CODE_PHONE_NOT_EMPTY]) {
			errors.phone = this.props.t('required_field')
		}
		if (resErrors[ERROR_CODE_PHONE_INCORRECT]) {
			errors.phone = this.props.t('phone_incorrect')
		}
		this.setState({errors})
	}
	handleSend(event){
		///Validate Form
		event.preventDefault()
		this._resetError()
		let hasError = this._validateForm()
		if (!hasError) {
			///Cal API
			agenServices.send({name: this.state.name, 
				email: this.state.email, 
				phone: this.state.phone, 
				receiver: this.props.phone,
				content: this.state.content,
				ref: this.props.refRes})
			.then (response => {
				if (response.status === "OK") {
					this.props.alert.show(this.props.t('msg_send_contact_message_success'), { position: positions.BOTTOM_RIGHT })
					///Reset Datat State
					this.setState({
						name: "",
						email: "",
						phone: "",
						content: "",
						receiver: ""})
				} else {
					this._processErrors(response.errors)
				}
			})
			.catch(error => {
				this.props.alert.show(this.props.t('msg_send_contact_message_failure'),{ position: positions.BOTTOM_RIGHT })
			})
		}
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<div className="container search-box">
					<form>
						<div className="form-group">
							<input type="text" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} className="form-control form-control__bg--gray" placeholder={t('enter_your_name')}/>
							{this.state.errors.name.length > 0 && <small className="form-text text-danger">{this.state.errors.name}</small>}
						</div>
						<div className="form-group">
							<input type="text" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} className="form-control form-control__bg--gray" placeholder={t('enter_your_email')}/>
							<small className="form-text text-danger">{this.state.errors.email}</small>
						</div>
						<div className="form-group">
							<input type="text" value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})} className="form-control form-control__bg--gray" placeholder={t('enter_your_phone')}/>
							<small className="form-text text-danger">{this.state.errors.phone}</small>						
						</div>
						<div className="form-group">
							<textarea value={this.state.content} onChange={(e) => this.setState({content: e.target.value})} className="form-control form-control__bg--gray" placeholder={t('content')}/>
							<small className="form-text text-danger">{this.state.errors.content}</small>
						</div>
						<div className="form-group">
							<button className="btn btn-danger btn-block" onClick={this.handleSend}>{t('send')}</button>
						</div>
					</form>
				</div>
			</React.Fragment>
		)
	}
}
export default compose(translate("translations"),withAlert())(AvertiserContactForm)