export const MAX_ITEM_SIZE = 100
export const MAX_DRAFT_ITEM_SIZE = 5
export const KEY_FAVOURITE_POSTS = 'posts-favourites'
export const KEY_MY_POSTS = 'posts-me' //Store Ids when publish to server
export const KEY_AUTH_TOKEN = 'auth-token'
export const KEY_USER_ID = "uid"
export const KEY_USER_NAME = "usrname"
export const KEY_USER_AVATAR = "user-avatar"
export const KEY_USER_LIST = 'user-list' //format {id: 1, name: 'Vo Hoai Viet', login: username, createdAt, token: ''}
export const KEY_DARFT_POSTS = 'user-draft-posts' //store when user is not loggin

export const MODE_VIEW = 'view'
export const MODE_EDIT = 'edit'
export const MODE_CREATE = 'create'
export const NO_IMAGE_GRAY = 'https://res.cloudinary.com/topreal/image/upload/v1587550603/commons/no_image_uyu3du.png'
export const NO_IMAGE_GREEN = 'https://res.cloudinary.com/topreal/image/upload/v1587550629/commons/no_image1_tzbqiu.png'
export const CITY_IMAGES = {
	'51': 'https://res.cloudinary.com/topreal/image/upload/v1587550210/vietnam-cities/hochiminh-1_p40xfi.jpg', //HOCHIMINH
	'2': 'https://res.cloudinary.com/topreal/image/upload/v1587550375/vietnam-cities/hanoi-1_gfjd1f.jpg', //HANOI
	'3': 'https://res.cloudinary.com/topreal/image/upload/v1587550441/vietnam-cities/dongnai-1_dhc11v.jpg', //DONG NAI
	'5': 'https://res.cloudinary.com/topreal/image/upload/v1587550482/vietnam-cities/binhduong-1_jemge4.jpg', //BINH DUONG
	'6': 'https://res.cloudinary.com/topreal/image/upload/v1587550409/vietnam-cities/danang-1_ynsdmm.jpg', //DA NANG
}
export const SEO_PATH_QUERIES = {
	'/mua-ban': {
		method: 1,
		title: "Mua bán",
		breadcrumbs: [
			{
				active: false,
				to: "/mua-ban",
				name: "Mua bán"
			}
		]
	},
	'/mua-ban/nha-dat': {
		method: 1,
		catId: 6,
		title: "Mua bán nhà đất",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: false,
				to: "/mua-ban/nha-dat",
				name: "Nhà đất"
			}
		]
	},
	'/mua-ban/nha-dat/chinh-chu': {
		method: 1,
		catId: 6,
		title: "Mua bán nhà đất chính chủ",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-dat",
				name: "Nhà đất"
			},
			{
				active: false,
				to: "/mua-ban/nha-dat/chinh-chu",
				name: "Chính chủ"
			}
		]
	},
	'/mua-ban/can-ho': {
		method: 1,
		catId: 5,
		title: "Mua bán căn hộ chung cư",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: false,
				to: "/mua-ban/can-ho",
				name: "Căn hộ chung cư"
			}
		]
	},
	'/mua-ban/can-ho/chinh-chu': {
		method: 1,
		catId: 5,
		title: "Mua bán căn hộ chung cư chính chủ",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/can-ho",
				name: "Căn hộ chung cư"
			},
			{
				active: false,
				to: "/mua-ban/can-ho/chinh-chu",
				name: "Chính chủ"
			}
		]
	},
	'/mua-ban/nha-rieng': {
		method: 1,
		catId: 1,
		title: "Mua bán nhà riêng",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: false,
				to: "/mua-ban/nha-rieng",
				name: "Nhà riêng"
			}
		]
	},
	'/mua-ban/nha-rieng/chinh-chu': {
		method: 1,
		catId: 1,
		title: "Mua bán nhà riêng chính chủ",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-rieng",
				name: "Nhà riêng"
			},
			{
				active: true,
				to: "/mua-ban/nha-rieng/chinh-chu",
				name: "Chính chủ"
			}
		]
	}, 
	///Ho Chi Minh
	'/mua-ban/nha-dat/ho-chi-minh': {
		method: 1,
		catId: 6,
		cityId: 51,
		title: "Mua bán nhà đất tại thành phố Hồ Chí Minh",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-dat",
				name: "Nhà đất"
			},
			{
				active: false,
				to: "/mua-ban/nha-dat/ho-chi-minh",
				name: "Hồ Chí Minh"
			}
		]
	},
	'/mua-ban/can-ho/ho-chi-minh': {
		method: 1,
		catId: 5,
		cityId: 51,
		title: "Mua bán căn hộ chung cư tại thành phố Hồ Chí Minh",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/can-ho",
				name: "Căn hộ chung cư"
			},
			{
				active: false,
				to: "/mua-ban/can-ho/ho-chi-minh",
				name: "Hồ Chí Minh"
			}
		]
	},
	'/mua-ban/nha-rieng/ho-chi-minh': {
		method: 1,
		catId: 1,
		cityId: 51,
		title: "Mua bán nhà riêng tại thành phố Hồ Chí Minh",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-rieng",
				name: "Nhà riêng"
			},
			{
				active: false,
				to: "/mua-ban/nha-rieng/ho-chi-minh",
				name: "Hồ Chí Minh"
			}
		]
	},
	'/mua-ban/nha-dat/ho-chi-minh/quan-7': {
		method: 1,
		catId: 6,
		cityId: 51,
		districtId: 576,
		title: "Mua bán nhà đất tại thành phố Hồ Chí Minh, Quận 7",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-dat",
				name: "Nhà đất"
			},
			{
				active: true,
				to: "/mua-ban/nha-dat/ho-chi-minh",
				name: "Hồ Chí Minh"
			},
			{
				active: true,
				to: "/mua-ban/nha-dat/ho-chi-minh/quan-7",
				name: "Quận 7"
			}
		]
	},
	'/mua-ban/can-ho/ho-chi-minh/quan-8': {
		method: 1,
		catId: 5,
		cityId: 51,
		districtId: 574,
		title: "Mua bán căn hộ chung cư tại thành phố Hồ Chí Minh, Quận 8",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/can-ho",
				name: "Căn hộ chung cư"
			},
			{
				active: true,
				to: "/mua-ban/can-ho/ho-chi-minh",
				name: "Hồ Chí Minh"
			},
			{
				active: false,
				to: "/mua-ban/can-ho/ho-chi-minh/quan-8",
				name: "Quận 8"
			}
		]
	},
	'/mua-ban/nha-rieng/ho-chi-minh/binh-chanh': {
		method: 1,
		catId: 1,
		cityId: 51,
		districtId: 579,
		title: "Mua bán nhà riêng tại thành phố Hồ Chí Minh, Huyện Bình Chánh",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-rieng",
				name: "Nhà riêng"
			},
			{
				active: true,
				to: "/mua-ban/nha-rieng/ho-chi-minh",
				name: "Hồ Chí Minh"
			},
			{
				active: false,
				to: "/mua-ban/nha-rieng/ho-chi-minh/binh-chanh",
				name: "Huyện Bình Chánh"
			}
		]
	}, //End Ho Chi Minh
	'/mua-ban/nha-dat/ha-hoi': {
		method: 1,
		catId: 6,
		cityId: 2,
		title: "Mua bán nhà đất tại Hà Nội",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-dat",
				name: "Nhà đất"
			},
			{
				active: false,
				to: "/mua-ban/nha-dat/ha-hoi",
				name: "Hà Nội"
			}
		]
	},
	'/mua-ban/can-ho/ha-noi': {
		method: 1,
		catId: 5,
		cityId: 2,
		title: "Mua bán căn hộ chung cư tại Hà Nội",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/can-ho",
				name: "Căn hộ chung cư"
			},
			{
				active: false,
				to: "/mua-ban/can-ho/ha-noi",
				name: "Hà Nội"
			}
		]
	},
	'/mua-ban/nha-rieng/ha-noi': {
		method: 1,
		catId: 1,
		cityId: 2,
		title: "Mua bán nhà riêng tại Hà Nội",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-rieng",
				name: "Nhà riêng"
			},
			{
				active: false,
				to: "/mua-ban/nha-rieng/ha-noi",
				name: "Hà Nội"
			}
		]
	},
	'/mua-ban/nha-dat/ha-noi/cau-giay': {
		method: 1,
		catId: 6,
		cityId: 2,
		districtId: 7,
		title: "Mua bán nhà đất tại Hà Nội, Quận Cầu Giấy",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-dat",
				name: "Nhà đất"
			},
			{
				active: true,
				to: "/mua-ban/nha-dat/ha-noi",
				name: "Hà Nội"
			},
			{
				active: true,
				to: "/mua-ban/nha-dat/ha-noi/cau-giay",
				name: "Cầu Giấy"
			}
		]
	},
	'/mua-ban/can-ho/ha-noi/dong-da': {
		method: 1,
		catId: 5,
		cityId: 51,
		districtId: 8,
		title: "Mua bán căn hộ chung cư tại Hà Nội, Quận Đống Đa",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/can-ho",
				name: "Căn hộ chung cư"
			},
			{
				active: true,
				to: "/mua-ban/can-ho/ha-noi",
				name: "Hà Nội"
			},
			{
				active: false,
				to: "/mua-ban/can-ho/ha-noi/dong-da",
				name: "Quận Đống Đa"
			}
		]
	},
	'/mua-ban/nha-rieng/ha-noi/ba-dinh': {
		method: 1,
		catId: 1,
		cityId: 51,
		districtId: 9,
		title: "Mua bán nhà riêng tại Hà Nội, Quận Ba Đình",
		breadcrumbs: [
			{
				active: true,
				to: "/mua-ban",
				name: "Mua bán"
			},
			{
				active: true,
				to: "/mua-ban/nha-rieng",
				name: "Nhà riêng"
			},
			{
				active: true,
				to: "/mua-ban/nha-rieng/ha-noi",
				name: "Hà Nội"
			},
			{
				active: false,
				to: "/mua-ban/nha-rieng/ha-noi/ba-dinh",
				name: "Ba Đình"
			}
		]
	}, //End Ha Noi
	'/cho-thue': {
		method: 2
	},
	'/cho-thue/van-phong': {
		method: 2,
		catId: 15
	},
	'/cho-thue/phong-tro': {
		method: 2,
		catId: 14
	},
	'/cho-thue/nha-tro': {
		method: 2,
		catId: 14
	},
	'/cho-thue/can-ho': {
		method: 2,
		catId: 10
	},
	'/cho-thue/nha-rieng': {
		method: 2,
		catId: 11
	}
}