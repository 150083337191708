import {KEY_AUTH_TOKEN,KEY_USER_ID, KEY_USER_NAME} from '../configs/constants'
import jwtDecode from 'jwt-decode'

export default {
	isLoggin() {
		///return true
		return localStorage.getItem(KEY_AUTH_TOKEN)? true : false
	},
	headerAuthToken () {
		let token = localStorage.getItem(KEY_AUTH_TOKEN)
		if (token) {
			return 'Bearer ' + token
		} else {
			return null
		}
	},
	authHeader() {
		// return authorization header with jwt token
		let token = localStorage.getItem(KEY_AUTH_TOKEN)
		if (token) {
			return { 'X-Authorization': 'Bearer ' + token}
		} else {
			return null
		}
	},
	storeAuthToken(token) {
		let userToken = jwtDecode(token)
		var date = new Date(0)
		date.setUTCSeconds(userToken.exp)
		if (date < new Date()) {
			localStorage.removeItem(KEY_AUTH_TOKEN)
			localStorage.removeItem(KEY_USER_ID)
			localStorage.removeItem(KEY_USER_NAME)
        	return false
        }
        localStorage.setItem(KEY_USER_ID,Number(userToken.uid))
        localStorage.setItem(KEY_USER_NAME,userToken.fna + ' ' + userToken.lna)
        localStorage.setItem(KEY_AUTH_TOKEN,token)
		return true
	},
	getUserProfileFromAuthToken() {
		let token = localStorage.getItem(KEY_AUTH_TOKEN)
		if (token)
		{
			let userToken = jwtDecode(token)
			var date = new Date(0)
        	date.setUTCSeconds(userToken.exp)
        	if (date < new Date()) {
        		localStorage.removeItem(KEY_AUTH_TOKEN)
        		return null
        	}
			return userToken
		}
		return null
	},
	logout() {
		localStorage.removeItem(KEY_AUTH_TOKEN)
        localStorage.removeItem(KEY_USER_ID)
        localStorage.removeItem(KEY_USER_NAME)
	}
}