import * as types from './action-types'
import newsServices from '../services/news'


export function fetchAllNewsCategories()
{
	return function (dispatch) {
		dispatch(fetchAllNewsCategoriesRequest())
		newsServices.fetchAllNewsCategories()
			.then(response => {
				if (response.status === 'OK')
				{
					dispatch(fetchAllNewsCategoriesSuccess(response.data))
				}else{
					dispatch(fetchAllNewsCategoriesError(response.errors))
				}
			})
			.catch(error => {dispatch(fetchAllNewsCategoriesError(error))})
	}
}
export function fetchAllNewsCategoriesRequest()
{
	return {type: types.FETCH_ALL_NEWS_CATEGORIES_REQUEST, payload: null}
}
export function fetchAllNewsCategoriesSuccess(categories)
{
	return {type: types.FETCH_ALL_NEWS_CATEGORIES_SUCCESS, payload: categories}
}
export function fetchAllNewsCategoriesError(errors)
{
	return {type: types.FETCH_ALL_NEWS_CATEGORIES_ERROR, payload: errors}
}

export function searchNews(criteria)
{
	return function (dispatch) {
		dispatch(searchNewsRequest())
		newsServices.searchNews(criteria)
		.then(response => {
			if (response.status == 'OK')
			{
				dispatch(searchNewsSucccess(response.data))
			}else{
				if (response.status == "ZERO_RESULTS") {
					dispatch(searchNewsZeroResults())
				} else {
					dispatch(searchNewsError(response.errors))
				}
			}
		})
		.catch(error => dispatch(searchNewsError(error)))
	}
}
export function searchNewsRequest()
{
	return {type: types.SEARCH_NEWS_REQUEST,payload: null}
}
export function searchNewsSucccess(resData)
{
	return {type: types.SEARCH_NEWS_SUCCESS,payload: resData}
}
export function searchNewsZeroResults()
{
	return {type: types.SEARCH_NEWS_ZERO_RESULTS,payload: null}
}
export function searchNewsError(errors)
{
	return {type: types.SEARCH_NEWS_ERROR,payload: errors}
}

export function fetchNewsDetailsById(id)
{
	return function (dispatch) {
		dispatch(fetchNewsDetailsByIdRequest())
		newsServices.getById(id)
		.then(response => {
			if (response.status === 'OK')
			{
				dispatch(fetchNewsDetailsByIdSuccess(response.data))
			}else{
				dispatch(fetchNewsDetailsByIdError(response.errors))
			}
		})
		.catch(error => dispatch(fetchNewsDetailsByIdError(error)))
	}
}
export function fetchNewsDetailsByIdRequest()
{
	return {type: types.FETCH_NEWS_DETAILS_REQUEST,payload: null}
}
export function fetchNewsDetailsByIdSuccess(resData)
{
	return {type: types.FETCH_NEWS_DETAILS_SUCCESS,payload: resData}
}
export function fetchNewsDetailsByIdError(errors)
{
	return {type: types.FETCH_NEWS_DETAILS_ERROR,payload: errors}
}

export function fetchLastestNews()
{
	return function (dispatch) {
		dispatch(fetchLastestNewsRequest())
		newsServices.fetchLastestNews()
		.then(response => {
			if (response.status === 'OK')
			{
				dispatch(fetchLastestNewsSuccess(response.data))
			}else{
				dispatch(fetchLastestNewsError(response.errors))
			}
		})
		.catch(error => dispatch(fetchLastestNewsError(error)))
	}
}
export function fetchLastestNewsRequest()
{
	return {type: types.FETCH_NEWS_LASTEST_REQUEST,payload: null}
}
export function fetchLastestNewsSuccess(resData)
{
	return {type: types.FETCH_NEWS_LASTEST_SUCCESS,payload: resData}
}
export function fetchLastestNewsError(errors)
{
	return {type: types.FETCH_NEWS_LASTEST_ERROR,payload: errors}
}
export function fetchMostViewNews()
{
	return function (dispatch) {
		dispatch(fetchMostViewNewsRequest())
		newsServices.fetchMostViewNews()
		.then(response => {
			if (response.status === 'OK')
			{
				dispatch(fetchMostViewNewsSuccess(response.data))
			}else{
				dispatch(fetchMostViewNewsError(response.errors))
			}
		})
		.catch(error => dispatch(fetchMostViewNewsError(error)))
	}
}
export function fetchMostViewNewsRequest()
{
	return {type: types.FETCH_NEWS_MOST_VIEW_REQUEST,payload: null}
}
export function fetchMostViewNewsSuccess(resData)
{
	return {type: types.FETCH_NEWS_MOST_VIEW_SUCCESS,payload: resData}
}
export function fetchMostViewNewsError(errors)
{
	return {type: types.FETCH_NEWS_MOST_VIEW_ERROR,payload: errors}
}
export function searchNewsChangeQuery(query) {
	console.log("query:" + query)
	return {type: types.SEARCH_NEWS_CHANGE_QUERY_REQUEST,payload: query}	
}