import React from 'react'
import AsideList from './AsideList'
const AsideRealEstateLaws = ({title, items}) => (
	<div className="container">
		<div className="row border-bottom pb-2">
			<label className="news_aside__title">{title}</label>
		</div>
		<div className="row mt-2">
			<AsideList items={items}/>
		</div>
	</div>
)
export default AsideRealEstateLaws